import React from 'react';
import Modal from 'react-modal';

const SamagriKitView = ({ isOpen, onClose, kit }) => {
    if (!kit) return null;

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="Samagri Kit"
            size="lg"
            aria-labelledby="example-modal-sizes-title-lg"
            backdrop="static"
            keyboard={false}
            ariaHideApp={false}
            style={{
                content: {
                    maxWidth: '600px',
                    margin: '0 auto',
                    padding: '20px',
                    borderRadius: '8px',
                    top: '100px', 
                    position: 'fixed', 
                },
            }}
        >
            <h2 style={{ color: 'rgb(169, 0, 0)' , fontSize: '23px' }}>View Samagri Kit</h2>
            <hr />

            <div style={{ marginBottom: '20px' }}>
                <h3 style={{ color: 'red' , fontSize: '18px' }}>Kit Name</h3>
                {kit.samagri.map((item, index) => (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div>
                        <label htmlFor="name" style={{ marginRight: '10px' }}>Kit Name:</label>
                        <input
                            type="text"
                            id="name"
                            value={kit.pack_name}
                            readOnly
                            style={{ padding: '5px', borderRadius: '4px', border: '1px solid #ccc' }}
                        />
                    </div>
                    <div>
                        <label htmlFor="amount" style={{ marginRight: '10px' }}>Enter Amount:</label>
                        <input
                            type="number"
                            id="amount"
                            value={kit.finalAmount}
                            readOnly
                            style={{ padding: '5px', borderRadius: '4px', border: '1px solid #ccc' }}
                        />
                    </div>
                </div>
                ))}
            </div>
            <hr />

            <div style={{ marginBottom: '20px' }}>
                <h3  style={{ color: 'red' , fontSize: '18px' }}>Added Items</h3>
                {kit.samagri.map((item, index) => (
                    <div key={index} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px' }}>
                        <div>
                            <label htmlFor={`item-${index}`} style={{ marginRight: '10px' }}>Item:</label>
                            <input
                                type="text"
                                id={`item-${index}`}
                                value={item.samagri_name}
                                readOnly
                                style={{ padding: '5px', borderRadius: '4px', border: '1px solid #ccc', marginRight: '10px' }}
                            />
                            <label htmlFor={`quantity-${index}`} style={{ marginRight: '10px' }}>Quantity:</label>
                            <input
                                type="text"
                                id={`quantity-${index}`}
                                value={item.quantity}
                                readOnly
                                style={{ padding: '5px', borderRadius: '4px', border: '1px solid #ccc', marginRight: '10px' }}
                            />
                        </div>
                        <div>
                            <label htmlFor={`image-${index}`} style={{ marginRight: '10px' }}>Upload Image:</label>
                            <input
                                type="file"
                                id={`image-${index}`}
                                // value={item.image}
                                readOnly
                                style={{ padding: '5px', borderRadius: '4px', border: '1px solid #ccc' }}
                            />
                        </div>
                    </div>
                ))}
            </div>

            <button
                onClick={onClose}
                style={{
                    padding: '8px 16px',
                    borderRadius: '4px',
                    backgroundColor: '#28a745',
                    color: '#fff',
                    border: 'none',
                    cursor: 'pointer',
                    display: 'block',
                    margin: '20px auto 0',
                }}
            >
                Close
            </button>
        </Modal>
    );
};

export default SamagriKitView;
