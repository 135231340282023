import { Navigate } from "react-router-dom";
import React from "react";

export const PrivateRoute = ({ element: Component, ...rest }) => {
  const isLoggedIn = !!localStorage.getItem('token');

  return (
    <>
      {isLoggedIn ? <Component /> : <Navigate to="/" />}
    </>
  );
};
