import React, { useEffect, useState } from 'react'
import PageHeader from '../../PageHeader/PageHeader'
import DataTable from 'react-data-table-component'
import { useForm } from 'react-hook-form'
import { jsPDF } from 'jspdf'
import html2canvas from 'html2canvas'
import 'jspdf-autotable'
import * as XLSX from 'xlsx'
import { toast } from 'react-toastify'
import {
  getAllPackages,
  updatePackage,
  createPackage,
  deletePackage,
} from '../../API/Api'
import Header from '../../Header/Header'
import Sidebar from '../../Sidebar/Sidebar'
import Footer from '../../Footer/Footer'

function AddNewPackage() {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm()

  const user_id = localStorage.getItem('user_id')
  const [packages, setPackages] = useState([])
  const [selectedPackage, setSelectedPackage] = useState(null)
  const [selectedStatus, setSelectedStatus] = useState('')
  const [loading, setLoading] = useState(true)

  const fetchTableData = async () => {
    try {
      const data = await getAllPackages()
      const sortedData = data.sort(
        (a, b) =>
          new Date(b.created_at.split('/').reverse().join('-')) -
          new Date(a.created_at.split('/').reverse().join('-')),
      )
      setPackages(sortedData)
      // setPackages(data)
      setLoading(false)
    } catch (error) {
      console.error('Error fetching table data:', error)
      setLoading(false)
    }
  }

 
  useEffect(() => {
    fetchTableData()
  }, [])

  const customStyles = {
    rows: {
      style: {
        // border: '0.1px solid #ddd',
      },
    },
    headCells: {
      style: {
        paddingLeft: '8px',
        paddingRight: '8px',
        color: 'black',
        fontSize: '14px',
        backgroundColor: '#ccc',
        fontFamily: 'Poppins',
        fontWeight: '600',
        border: '0.1px solid #ddd',
        display: 'flex',
        justifyContent: 'center',
      },
    },
    cells: {
      style: {
        paddingLeft: '8px',
        paddingRight: '8px',
        border: '0.1px solid #ddd',
        display: 'flex',
        justifyContent: 'center',
      },
    },
  }

  const columns = [
    {
      name: 'Sl.No',
      selector: (row) => row['slno'],
      sortable: true,
      format: (row, rowIndex) => rowIndex + 1,
    },
    {
      name: 'No Of Pandals',
      selector: (row) => row['no_of_pandals'],
      sortable: true,
    },
    {
      name: 'Package Name',
      selector: (row) => row['package_name'],
      sortable: true,
    },
    {
      name: 'Status',
      cell: (row) => (row.status === '1' ? 'Active' : 'Inactive'),
    },
    {
      name: 'Price',
      selector: (row) => row['price'],
      sortable: true,
    },
    {
      name: 'Date Added',
      selector: (row) => row['created_at'],
      sortable: true,
    },
    {
      name: 'Actions',
      cell: (row) => (
        <div className="d-flex">
          <button
            className="btn btn-sm btn-edit me-2"
            onClick={() => EditPackage(row)}
          >
            <i className="fa-regular fa-pen-to-square"></i>{' '}
          </button>
          <button
            className="btn btn-sm btn-delete"
            onClick={() => DeletePackage(row.id)}
          >
            <i className="fa-solid fa-trash-can"></i>
          </button>
        </div>
      ),
    },
  ]

  const EditPackage = (package_name) => {
    setSelectedPackage(package_name)
    setValue('package_name', package_name.package_name)
    setValue('no_of_pandals', package_name.no_of_pandals)
    setValue('id', package_name.id)
    setValue('price', package_name.price)
    setSelectedStatus(package_name.status)
  
  }


  const addNewPackage = async (data) => {
    try {
      const package_name = data.package_name || ''
      const no_of_pandals = data.no_of_pandals || ''
      const price = data.price || ''
      const id = data.id || ''
      const created_by = user_id
      const updated_by = user_id
      const requestData = {
        package_name,
        no_of_pandals,
        price,
        status: selectedStatus,
        created_by,
        updated_by,
        id,
      }
      if (selectedPackage) {
        await updatePackage(selectedPackage.id, requestData)
        const successMessage = 'Package Updated'
        toast.success(successMessage, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
        reset()
        setSelectedStatus('')
        setSelectedPackage(null)
        fetchTableData()
      } else {
        await createPackage(requestData)
        const successMessage = 'New Package Added'
        toast.success(successMessage, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
        reset()
        setSelectedStatus('')
        fetchTableData()
      }
    } catch (error) {
      // console.log(error.response, 'response')
      if (error.response.status === 422) {
        Object.values(error.response.data.errors).map((item) => {
          toast.error(item[0], {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        })
      } else {
        toast.error(error.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      }
    }
  }

  const DeletePackage = async (id) => {
    try {
      await deletePackage(id)
      const successMessage = 'Package Deleted'
      toast.success(successMessage, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      fetchTableData()
    } catch (error) {
      toast.error(error.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }



  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value)
  }

  const handleKeyDown = (event, callback) => {
    if (event.key === 'Enter' && event.shiftKey === false) {
      event.preventDefault()
      callback(addNewPackage)
    }
  }

  //Downloading PDF Format
  const columnsForPDF = [
    {
      name: 'Sl.No',
      selector: (row) => row['slno'],
      sortable: true,
      format: (rowIndex) => rowIndex + 1,
    },
    {
      name: 'No Of Pandals',
      selector: (row) => row['no_of_pandals'],
      sortable: true,
    },
    {
      name: 'Package Name',
      selector: (row) => row['package_name'],
      sortable: true,
    },
    {
      name: 'Status',
      cell: (row) => (row.status === '1' ? 'Active' : 'Inactive'),
      sortable: true,
    },
    {
      name: 'Price',
      selector: (row) => row['price'],
      sortable: true,
    },
    {
      name: 'Date Added',
      selector: (row) => row['created_at'],
      sortable: true,
    },
  ]
  const handleDownloadPDF = () => {
    const table = document.querySelector('.table-responsive table')
    html2canvas(table).then((canvas) => {
      const pdf = new jsPDF()
      const pdfData = packages.map((package_data, index) => [
        index + 1,
        package_data['no_of_pandals'],
        package_data['package_name'],
        package_data['status'] === '1' ? 'Active' : 'Inactive',
        package_data['price'],
        package_data['created_at'],
      ])

      pdf.autoTable({
        head: [columnsForPDF.map((column) => column.name)],
        body: pdfData,
      })

      pdf.save('packages_data.pdf')
    })
  }

  //Downloading Excel Format
  const handleDownloadExcel = () => {
    const columnsWithoutActions = columns.filter(
      (column) => column.name !== 'Actions',
    )
    const tableData = packages.map((package_data, index) => [
      index + 1,
      package_data['no_of_pandals'],
      package_data['package_name'],
      package_data['status'] === '1' ? 'Active' : 'Inactive',
      package_data['price'],
      package_data['created_at'],
    ])
    const worksheet = XLSX.utils.aoa_to_sheet([
      columnsWithoutActions.map((column) => column.name),
      ...tableData,
    ])
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Table Data')
    const excelFile = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
    const blob = new Blob([excelFile], { type: 'application/octet-stream' })
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = 'packages_data.xlsx'
    a.click()
    URL.revokeObjectURL(url)
  }

  //Downloading CSV Format
  const handleDownloadCSV = () => {
    const columnsWithoutActions = columns.filter(
      (column) => column.name !== 'Actions',
    )
    const formatDate = (dateString) => {
      if (!dateString) {
        return ''
      }
      const dateParts = dateString.split('/')
      if (dateParts.length !== 3) {
        // console.log('Invalid date format:', dateString)
        return ''
      }
      const formattedDate = `${dateParts[1]}/${dateParts[0]}/${dateParts[2]}`
      const timestamp = Date.parse(formattedDate)
      if (isNaN(timestamp)) {
        // console.log('Invalid date:', dateString)
        return ''
      }
      const date = new Date(timestamp)
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' }
      return new Intl.DateTimeFormat('en-US', options).format(date)
    }
    const tableData = packages.map((package_data, index) => [
      index + 1,
      package_data['no_of_pandals'],
      package_data['package_name'],
      package_data['status'] === '1' ? 'Active' : 'Inactive',
      package_data['price'],
      formatDate(package_data['created_at']),
    ])
    const worksheet = XLSX.utils.aoa_to_sheet([
      columnsWithoutActions.map((column) => column.name),
      ...tableData,
    ])
    const columnWidths = columnsWithoutActions.map((column) => ({
      wch: column.name.length + 2,
    }))
    worksheet['!cols'] = columnWidths
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Table Data')
    const excelFile = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
    const blob = new Blob([excelFile], { type: 'application/octet-stream' })
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = 'packages_data.csv'
    a.click()
    URL.revokeObjectURL(url)
  }

  return (
    <>
      <div className="main-content">
        <Header />
        <div className="app-menu navbar-menu" style={{ display: 'flex' }}>
          <Sidebar />
        </div>
        <div className="page-content">
          <PageHeader HeaderName="Masters > Add New Packages" />

          <div className="container-fluid pt-3">
            <div className="row">
              <div className="col-xl-12 col-md-12">
                <div className="card">
                  <div className="card-body">
                    <form
                      onSubmit={handleSubmit(addNewPackage)}
                      onKeyDown={(e) => {
                        handleKeyDown(e, handleSubmit)
                      }}
                    >
                      <div className="row">
                        <div className="col-md-2 col-6">
                          <div>
                            <small>No. of Pandals</small>
                            <input
                              type="text"
                              className="form-control"
                              name="no_of_pandals"
                              {...register('no_of_pandals')}
                            />
                          </div>
                        </div>
                        <div className="col-md-2 col-6">
                          <div>
                            <small>Package Name</small>
                            <input
                              type="text"
                              className="form-control"
                              name="package_name"
                              {...register('package_name')}
                            />
                          </div>
                        </div>
                        <div className="col-md-2 col-6">
                          <div>
                            <small>Price</small>
                            <input
                              type="text"
                              className="form-control"
                              name="price"
                              {...register('price')}
                            />
                          </div>
                        </div>
                        <div className="col-md-2 col-6">
                          <div>
                            <small>Status</small>
                            <select
                              name="status"
                              className="form-select"
                              {...register('status')}
                              onChange={handleStatusChange}
                              value={selectedStatus}
                            >
                              <option value="">Select Status</option>
                              <option value="1">Active</option>
                              <option value="2">InActive</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-2 col-6">
                          <div>
                            <small style={{ display: 'none' }}>ID</small>
                            <input
                              type="text"
                              className="form-control"
                              name="id"
                              {...register('id')}
                              style={{ display: 'none' }}
                            />
                          </div>
                        </div>
                        <div className="col-md-2 text-end">
                          <small>&nbsp;</small>
                          <div className="col-md-12 text-end">
                            <button
                              type="submit"
                              className="btn btn-add waves-effect waves-light w_100"
                            >
                              {selectedPackage ? 'Update' : 'Add'}
                            </button>
                            <small>&nbsp;</small>
                            <button
                              type="reset"
                              className="btn btn-warning waves-effect waves-light w_100"
                              onClick={() => {
                                setSelectedPackage(null)
                                setValue('package_name', '')
                                setValue('price', '')
                                setValue('no_of_pandals', '')
                                setSelectedStatus('')
                              }}
                            >
                              Clear
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                {loading ? (
                  <p>Loading...</p>
                ) : packages.length === 0 ? (
                  <p>Data Not Found</p>
                ) : (
                  <>
                    <div className="card">
                      <div className="table-responsive">
                        <table className="table table-bordered table-striped m-0">
                          <DataTable
                            columns={columns}
                            data={packages}
                            pagination
                            customStyles={customStyles}
                            highlightOnHover
                            noDataComponent="Loading..."
                          />
                        </table>
                      </div>
                    </div>
                    <div className="col-md-12 text-end">
                      <div className="button-group">
                        <button
                          title="Download Excel"
                          className="button-excel-format"
                          onClick={handleDownloadExcel}
                        >
                          Excel
                        </button>
                        <button
                          title="Download CSV"
                          className="button-csv-format"
                          onClick={handleDownloadCSV}
                        >
                          CSV
                        </button>
                        <button
                          title="Download PDF"
                          className="button-pdf-format"
                          onClick={handleDownloadPDF}
                        >
                          PDF
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default AddNewPackage
