import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import PageHeader from '../PageHeader/PageHeader'
import DataTable from 'react-data-table-component'
import { LiveParticipantAuctions } from '../API/Api'
import Header from '../Header/Header'
import Sidebar from '../Sidebar/Sidebar'
import Footer from '../Footer/Footer'


function AuctionOneView() {
  // const navigate = useNavigate()
  const location = useLocation()
  const details = location.state
  const [loading, setLoading] = useState(true)

  const [liveParticipants, setLiveParticipants] = useState([])
  const fetchLiveParticipants = async () => {
    try {
      const data = await LiveParticipantAuctions(details.id)
      setLiveParticipants(data)
      setLoading(false)
    } catch (error) {
      console.error('Error fetching table data:', error)
      setLoading(false)
    }
  }
  useEffect(() => {
    fetchLiveParticipants()
  }, [])

  const customStyles = {
    rows: {
      style: {},
    },
    headCells: {
      style: {
        paddingLeft: '8px',
        paddingRight: '8px',
        color: 'black',
        backgroundColor: 'rgb(204, 204, 204)',
        fontFamily: 'Poppins',
        fontWeight: '600',
      },
    },
    cells: {
      style: {
        paddingLeft: '8px',
        paddingRight: '8px',
      },
    },
  }
  const liveParticipantColums = [
    {
      name: 'Sl.No',
      selector: (row) => row['slno'],
      sortable: true,
      format: (row, rowIndex) => rowIndex + 1,
    },
    {
      name: 'Participant Name',
      selector: (row) => row['user_name'],
      sortable: true,
    },
    {
      name: 'Auction Amount',
      selector: (row) => row['amount'],
      sortable: true,
    },
  ]

  return (
    <>
      <div className="main-content">
        <Header />
        <div className="app-menu navbar-menu" style={{ display: 'flex' }}>
          <Sidebar />
        </div>
        <div className="page-content">
          <PageHeader HeaderName="Ongoing Auctions - Details View" />
          <div className="container-fluid pt-3">
            <div className="row">
              <div className="col-xl-12 col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="col-md-3 ms-auto text-end">
                      <Link to="/auctions" className="btn btn-info">
                        Back
                      </Link>
                    </div>

                    <div className="row">
                      <div className="col-md-4 mb-3">
                        <small>Auction Name</small>
                        <input
                          type="text"
                          name="auction_name"
                          value={details.auction_name}
                          className="form-control"
                          readOnly
                        />
                      </div>
                      <div className="col-md-4 mb-3">
                        <small>Auction Estimation</small>
                        <input
                          type="text"
                          name="estimation"
                          value={details.estimation}
                          className="form-control"
                          readOnly
                        />
                      </div>
                      <div className="col-md-4 mb-3">
                        <small>Auction Start Date and Time</small>
                        <input
                          type="datetime-local"
                          name="auction_from_datetime"
                          value={details.auction_from_datetime}
                          className="form-control"
                          readOnly
                        />
                      </div>
                      <div className="col-md-4 mb-3">
                        <small>Auction End Date and Time</small>
                        <input
                          type="datetime-local"
                          name="auction_to_datetime"
                          value={details.auction_to_datetime}
                          className="form-control"
                          readOnly
                        />
                      </div>
                      <hr />
                      <div className="col-md-12 mt-3 mb-4 d-flex align-items-center">
                        <p className="me-2 m-0">
                          <span className="live">
                            <small className="blink-txt">
                              <i className="fa fa-circle"></i> Live
                            </small>
                          </span>{' '}
                        </p>
                        <h6 className="m-0">Live Participants</h6>
                      </div>
                      <div className="col-md-12 table-responsive">
                        {loading ? (
                          <p>Loading...</p>
                        ) : liveParticipants.length === 0 ? (
                          <p>Data Not Found</p>
                        ) : (
                          <>
                            <div className="col-md-12 table-responsive">
                              <table className="table table-bordered table-striped text-center m-0">
                                <DataTable
                                  columns={liveParticipantColums}
                                  data={liveParticipants}
                                  pagination
                                  customStyles={customStyles}
                                  highlightOnHover
                                  noDataComponent="Loading..."
                                />
                              </table>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    
      </div>
      <Footer />
    </>
  )
}

export default AuctionOneView
