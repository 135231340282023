import React, { useEffect, useState, useMemo } from 'react'
import PageHeader from '../PageHeader/PageHeader'
import { useNavigate } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import * as XLSX from 'xlsx'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import Papa from 'papaparse'
import Header from '../Header/Header'
import Sidebar from '../Sidebar/Sidebar'
import { getAllUsers } from '../API/Api'
import Footer from '../Footer/Footer'

function Users() {
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const [data, setData] = useState([])

  const clickSevaDetails = (row) => {
    navigate('/users-full-details', { state: row })
  }

  const fetchData = async () => {
    try {
      const data = await getAllUsers()
      const sortedData = data.sort(
        (a, b) =>
          new Date(b.date.split('/').reverse().join('-')) -
          new Date(a.date.split('/').reverse().join('-')),
      )
      setData(sortedData)
      setLoading(false)
    } catch (error) {
      console.error('Error fetching table data:', error)
      setLoading(false)
    }
  }


  const downloadAsPDF = () => {
    const columnsForPDF = [...columns]
    columnsForPDF.pop()
    const doc = new jsPDF()
    const pdfData = []
    data.forEach((row, index) => {
      pdfData.push([
        index + 1,
        row.name,
        row.mobile,
        row.email,
        row.date,

        // Add the image name to the row data
      ])
    })

    doc.autoTable({
      head: [columnsForPDF.map((column) => column.name)],
      body: pdfData,
      showHead: 'firstPage',
    })
    doc.save('table_data.pdf')
  }
  const downloadAsExcel = () => {
    const excelData = data.map((item, index) => ({
      'S.No': index + 1,
      'User Name': item.name,
      'Phone Number': item.mobile,
      'Email Id': item.email,
      'Date Of Joining': item.date,

      // Age: item.age,
    }))

    const worksheet = XLSX.utils.json_to_sheet(excelData)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
    XLSX.writeFile(workbook, 'table_data.xlsx')
  }
  const handleDownloadCSV = () => {
    const csvData = data.map((item, index) => ({
      'S.No': index + 1,
      'User Name': item.name,
      'Phone Number': item.mobile,
      'Email Id': item.email,
      'Date Of Joining': item.date,
    }))

    const csv = Papa.unparse(csvData)
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.setAttribute('href', url)
    link.setAttribute('download', 'table_data.csv')
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
  // const formatDate = (dateString) => {
  //   const [year, month, day] = dateString.split('-')
  //   return `${day}-${month}-${year}`
  // }
  const columns = useMemo(
    () => [
      {
        name: 'Sl.No',
        selector: 'slno',
        sortable: true,
        format: (row, rowIndex) => rowIndex + 1,
        width: '10%'
      },
      {
        name: 'User Name',
        selector: 'name',
        sortable: true,
      },
      {
        name: 'Phone No',
        selector: 'mobile',
        sortable: true,
      },
      {
        name: 'Email Id',
        selector: 'email',
        sortable: true,
      },
      // {
      //   name: 'Location',
      //   selector: 'pandal_address',
      //   sortable: true,
      // },
      {
        name: 'Date Of Joining',
        selector: 'date',
        sortable: true,
      },
      {
        name: 'Actions',
        width: '15%',
        cell: (row) => (
          <div>
            <button
              class="btn btn-sm btn-edit me-2"
              style={{ width: '100%' }}
              onClick={() => clickSevaDetails(row)}
            >
              <i className="fa fa-eye"></i>&nbsp; View
            </button>
          </div>
        ),
      },
    ],
    [],
  )
  const customStyles = {
    rows: {
      style: {
        // minHeight: '72px', // override the row height
        border: '0.1px solid #ddd',
      },
    },
    headCells: {
      style: {
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '8px',
        color: 'black',
        fontSize: '14px',
        backgroundColor: '#ccc',
        fontFamily: 'Poppins',
        fontWeight: '600',
        // fontSize:'10px'
        border: '0.1px solid #ddd',
      },
    },
    cells: {
      style: {
        paddingLeft: '8px', // override the cell padding for data cells
        paddingRight: '8px',
        border: '0.1px solid #ddd',
      },
    },
  }

  useEffect(() => {
    fetchData()
  })

  return (
    <>
      <div className="main-content">
        <Header />
        <div className="app-menu navbar-menu" style={{ display: 'flex' }}>
          <Sidebar />
        </div>
        <div className="page-content">
          <PageHeader HeaderName="List of all Users Registered" />

          <div className="container-fluid pt-3">
            <div className="row mb-5">
              {/* <div className="col-md-3 ms-auto mb-3">
                <input
                  type="text"
                  className="form-control"
                  name=""
                  placeholder="Search..."
                />
                <button type="submit" className="from-icon btn">
                  <i className="fa fa-search"></i>
                </button>
              </div> */}
              <div>
                <div className="card card-body">
                  <div className="col-xl-12 col-md-12">
                    {loading ? (
                      <p>Loading...</p>
                    ) : columns.length === 0 ? (
                      <p>Data Not Found</p>
                    ) : (
                      <>
                        <div className="table-responsive registered-img bg-white">
                          <DataTable
                            pagination
                            customStyles={customStyles}
                            data={data}
                            columns={columns}
                            highlightOnHover
                            noDataComponent="Loading..."
                          />
                        </div>
                        <div className="col-md-12 text-end mt-3">
                          <div className="button-group">
                            <button
                              title="Download Excel"
                              className="button-excel-format"
                              onClick={downloadAsExcel}
                            >
                              Excel
                            </button>
                            <button
                              title="Download CSV"
                              className="button-csv-format"
                              onClick={handleDownloadCSV}
                            >
                              CSV
                            </button>
                            <button
                              title="Download PDF"
                              className="button-pdf-format"
                              onClick={downloadAsPDF}
                            >
                              PDF
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </div>

                  {/* <!--end row--> */}
                </div>
              </div>
            </div>
          </div>
        </div>
       
      </div>
      <Footer />
    </>
  )
}

export default Users
