import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBus, faPlane, faTrain, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import Header from '../../Header/Header';
import Sidebar from '../../Sidebar/Sidebar';
import PageHeader from '../../PageHeader/PageHeader';
import { GetTempleProfile } from '../../API/TempleApis/profile';
import { useNavigate } from 'react-router-dom';
import { imgUrl } from '../../Pujari/Constants';


const TempleProfile = () => {
  const [profile, setProfile] = useState([]);
  // const [profileImage, setProfileImage] = useState([]);
  const navigate = useNavigate();

  const GetProfile = async () => {
    const response = await GetTempleProfile();
    console.log(response.data.data, 'temple profile response');
    setProfile(response.data.data[0]);
  };

  useEffect(() => {
    GetProfile();
  }, []);

  

  return (
    <>
      <div className="main-content">
        <Header />
        <div className="app-menu navbar-menu" style={{ display: 'flex' }}>
          <Sidebar />
        </div>
        <div className="page-content">
          <PageHeader HeaderName="Profile" />
          <div className="container-fluid pt-3">
            <div className="row mb-2 mt-4">
              <div style={{ display: 'flex', marginRight: '50px' }}>
                <div className="col-md-6">
                <img src={imgUrl+profile.image } alt="Temple Profile" style={{ width: '400px', height: '400px' }}/>
                </div>
               
                <div className="col-md-6">
                  <h4 style={{ color: '#A90000' }}>
                    <strong>Temple Details</strong>
                  </h4>
                  <div className="row mt-5">
                    <div className="col-md-3">
                      <p><strong>Temple</strong></p>
                      <p><strong>Mobile</strong></p>
                      <p><strong>E-mail</strong></p>
                      <p><strong>Address</strong></p>
                      <p><strong>Latitude</strong></p>
                      <p><strong>Longitude</strong></p>
                      <p><strong>Description</strong></p>
                    </div>
                    <div className="col-md-9">
                    <p>{profile.temple_name}</p>
                      <p>{profile.phone_number}</p>
                      <p>{profile.email}</p>
                      <p>{profile.address}</p>
                      <p>{profile.latitude}</p>
                      <p>{profile.longitude}</p>
                      <p>{profile.description}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-2 mt-4">
              <div style={{ display: 'flex', marginRight: '50px' }}>
                <div className="col-md-6">
                  <h4 style={{ color: '#A90000' }}>
                    <strong>Timings</strong>
                  </h4>
                  <div className="row mt-3">
                    <div className="col-md-3">
                      <h5 style={{ color: '#A90000' }}>Opening Time </h5>
                      <p><strong>Daily Pooja</strong></p>
                      {profile.opening_time}
                      <h5 style={{ color: '#A90000' }}> Closing Time</h5>
                      <p><strong>Daily Pooja</strong></p>
                      {profile.closing_time}
                    </div>
                    <div className="col-md-6">
                      <p  style={{ marginTop: '27px',marginLeft:'45px' }}>{profile?.opening_time}</p>
                      <p style={{ marginTop: '45px',marginLeft:'45px' }}>{profile?.closing_time}</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-6">
                      <h4 style={{ color: '#A90000' }}>
                        <strong>Amenities</strong>
                      </h4>
                      <div className="row">
                        <div className="col-md-1">
                          <p>
                            <span style={{ color: profile?.drinking_water ? 'green' : 'red' }}>
                              {profile?.drinking_water ? (
                                <FontAwesomeIcon icon={faCheck} style={{ fontSize: '13px' }} />
                              ) : (
                                <FontAwesomeIcon icon={faTimes} />
                              )}
                            </span>
                          </p>
                          <p>
                            <span style={{ color: profile?.restaurants ? 'green' : 'red' }}>
                              {profile?.restaurants ? (
                                <FontAwesomeIcon icon={faCheck} style={{ fontSize: '13px' }} />
                              ) : (
                                <FontAwesomeIcon icon={faTimes} />
                              )}
                            </span>
                          </p>
                          <p>
                            <span style={{ color: profile?.rest_rooms ? 'green' : 'red' }}>
                              {profile?.rest_rooms ? (
                                <FontAwesomeIcon icon={faCheck} style={{ fontSize: '13px' }} />
                              ) : (
                                <FontAwesomeIcon icon={faTimes} />
                              )}
                            </span>
                          </p>
                          <p>
                            <span style={{ color: profile?.marriage_halls ? 'green' : 'red' }}>
                              {profile?.marriage_halls ? (
                                <FontAwesomeIcon icon={faCheck} style={{ fontSize: '13px' }} />
                              ) : (
                                <FontAwesomeIcon icon={faTimes} />
                              )}
                            </span>
                          </p>
                          <p>
                            <span style={{ color: profile?.pooja_items ? 'green' : 'red' }}>
                              {profile?.pooja_items ? (
                                <FontAwesomeIcon icon={faCheck} style={{ fontSize: '13px' }} />
                              ) : (
                                <FontAwesomeIcon icon={faTimes} />
                              )}
                            </span>
                          </p>
                          <p>
                            <span style={{ color: profile?.darmashala ? 'green' : 'red' }}>
                              {profile?.darmashala ? (
                                <FontAwesomeIcon icon={faCheck} style={{ fontSize: '13px' }} />
                              ) : (
                                <FontAwesomeIcon icon={faTimes} />
                              )}
                            </span>
                          </p>
                        </div>
                        <div className="col-md-6">
                          <p><strong>Drinking Water</strong></p>
                          <p><strong>Restaurants</strong></p>
                          <p><strong>Rest Rooms</strong></p>
                          <p><strong>Marriage Halls</strong></p>
                          <p><strong>Pooja Items</strong></p>
                          <p><strong>Dharmashala</strong></p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <h4 style={{ color: '#A90000' }}>
                        <strong>Get there</strong>
                      </h4>
                      <div className="get-there-item">
                        <h5><strong>Nearest Bus Station</strong></h5>
                        <span className="icon-text">
                          <FontAwesomeIcon icon={faBus} className="icon" style={{ marginRight: '10px' }}/> 
                          {profile.near_by_busstand} 
                        </span>
                      </div>
                      <div className="get-there-item mt-3">
                        <h5><strong>Nearest Airport</strong></h5>
                        <span className="icon-text">
                          <FontAwesomeIcon icon={faPlane} className="icon"  style={{ marginRight: '10px' }}/>
                          {profile.near_by_airport}
                        </span>
                      </div>
                      <div className="get-there-item mt-3">
                        <h5><strong>Nearest Railway Station</strong></h5>
                        <span className="icon-text">
                          <FontAwesomeIcon icon={faTrain} className="icon"  style={{ marginRight: '10px' }} /> 
                          {profile.near_by_railway_station}
                        </span>
                      </div>
                      <button className="add-location-btn mt-5" style={{ backgroundColor: 'firebrick', width: '170px', height: '45px', color: 'white', border: 'none', borderRadius: '10px' }}>
                        Add Location
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-2 mt-4">
              <div className="col-md-12 mt-3">
                <h4 style={{ color: '#A90000' }}>
                  <strong>Description:</strong>
                </h4>
                <p>{profile?.description}</p>
              </div>
              <div className="col-md-12 mt-3">
                <h4 style={{ color: '#A90000' }}>
                  <strong>History:</strong>
                </h4>
                <p>{profile?.history}</p>
              </div>
              <div className="col-md-12 mt-3">
                <h4 style={{ color: '#A90000' }}>
                  <strong>Significance:</strong>
                </h4>
                <p>{profile?.significance}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TempleProfile;
