import React, { useEffect, useState, useRef } from 'react'
import Header from '../Header/Header'
import PujariSidebar from './PujariSidebar'
import PageHeader from '../PageHeader/PageHeader'
import { getApiData, postApiResponse } from './API';
import { CalenderBlockCreate, GetAllPujariBlockDates, PujariDateEvent, PujariEventApproveDeny } from './Constants';
import { showToast } from './Toast';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import "./calendar.css";

function PujariCalender() {
    const calendarRef = useRef(null);
    const [events, setEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [pujariBlockDate, setPujariBlockDate] = useState([]);
    const [pujariEvents, setPujariEvents] = useState([]);
    const [formInput, setFormInput ] = useState({
        start_date: '',
        end_date: '',
    });

    useEffect(() => {
        if (events.length > 0 && selectedDate) {
            const eventsForSelectedDate = events.filter(
                (event) => event.start === selectedDate
            );
            setSelectedEvent(
                eventsForSelectedDate.length > 0 ? eventsForSelectedDate[0] : null
            );
        }
    }, [events, selectedDate]);

    const handleEventClick = (clickInfo) => {
        const clickedEvent = clickInfo.event;
        setSelectedEvent(clickedEvent);
    };

    const handleDateClick = (clickInfo) => {
        const clickedDate = clickInfo.dateStr;

        // Check if the clicked date is blocked
        const isBlocked = pujariBlockDate.some(date => {
            let startDate = new Date(date.start_date);
            let endDate = new Date(date.end_date);
            endDate.setDate(endDate.getDate() + 1); // Include the end date in the range
            return clickedDate >= date.start_date && clickedDate < endDate.toISOString().split('T')[0];
        });

        // If the date is blocked, do nothing
        if (isBlocked) {
            return;
        }

        // Otherwise, proceed with the existing logic
        setSelectedEvent(null);
        setSelectedDate(clickInfo.date);

        // Call the new function to handle the API request
        handleDateApiCall(clickedDate);

        const dayElements = document.querySelectorAll('.fc-daygrid-day');
        dayElements.forEach(day => day.classList.remove('clicked'));
        clickInfo.dayEl.classList.add('clicked');
    };

    useEffect(() => {
        if (calendarRef.current) {
            const calendar = calendarRef.current.getApi();
            calendar.gotoDate(new Date());
        }
    }, [calendarRef]);

    const GetPujariBlockDates = async () => {
        const data = await getApiData(GetAllPujariBlockDates);
        setPujariBlockDate(data);
        console.log(data, 'data of block dates pujari')
    }

    useEffect(() => {
        GetPujariBlockDates();
    }, [])

    useEffect(() => {
        if (pujariBlockDate.length > 0) {
            // const blockedDates = pujariBlockDate.map(date => {
            //     return {
            //         title: 'Blocked',
            //         start: date.start_date,
            //         end: date.end_date,
            //         display: 'background',
            //         backgroundColor: '#ff6347',
            //         selectable: false
            //     };
            // });
            const blockedDates = pujariBlockDate.map(date => {
                let adjustedEndDate = new Date(date.end_date);
                adjustedEndDate.setDate(adjustedEndDate.getDate() + 1);
                return {
                    title: 'Blocked',
                    start: date.start_date,
                    end: adjustedEndDate.toISOString().split('T')[0],
                    display: 'background',
                    backgroundColor: '#ff6347',
                    selectable: false
                };
            });

            setEvents(blockedDates);

            // const calendar = calendarRef.current.getApi();
            const blockedDateElements = document.querySelectorAll('.fc-bgevent');
            blockedDateElements.forEach(element => {
                const date = element.getAttribute('data-date');
                const isBlocked = blockedDates.some(event => event.start === date);
                if (isBlocked) {
                    element.style.backgroundColor = ''; // Clear the background color
                }
            });
        }
    }, [pujariBlockDate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            for (let i in formInput) {
                formData.append(i, formInput[i]);
            }

            var response = await postApiResponse(CalenderBlockCreate, formData);
            if (response.status === 200) {
                showToast(`Block Dates are Created`, 'success');
                // showToast(`${response.data.start_date} Was Created`, 'success');
                setFormInput({
                    start_date: '',
                    end_date: '',
                });
                GetPujariBlockDates();
            } else {
                showToast('Something Went wrong', 'info');
            }
        } catch (error) {
            console.log(error.response)
            const validation = error.response.data.errors;
            if (error.response.status == 422) {
                for (let i in validation) {
                    showToast(validation[i][0], 'error');
                }
            }
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormInput((prevData) => ({ ...prevData, [name]: value }))
    }

    const handleDateApiCall = async (date) => {
        try {
            const requestData = {
                date: date,
            };
            var response = await postApiResponse(PujariDateEvent, requestData);
            setPujariEvents(response.data.data);
            console.log(response.data.data, 'response from dates sleected')
            if (response.status === 200) {
                showToast('Events', 'success');
            } else {
                showToast('Failed to send date to the API', 'error');
            }
        } catch (error) {
            console.log(error.response)
            const validation = error.response.data.errors;
            if (error.response.status == 422) {
                for (let i in validation) {
                    showToast(validation[i][0], 'error');
                }
            }
        }
    };

    const handleEventStatusClick = async (eventId, status) => {
        try {
            const response = await postApiResponse(PujariEventApproveDeny, {
                id: eventId,
                status: status
            });

            if (response.status === 200) {
                const statusMessage = status === 1 ? 'approved' : 'denied';
                showToast(`Event ${statusMessage} successfully`, 'success');
            } else {
                showToast(`Failed to ${status === 1 ? 'approve' : 'deny'} event`, 'error');
            }
        } catch (error) {
            console.error(error);
            showToast(`An error occurred while ${status === 1 ? 'approving' : 'denying'} event`, 'error');
        }
    };

    const handleApproveClick = (eventId) => {
        handleEventStatusClick(eventId, 1);
    };

    const handleDenyClick = (eventId) => {
        handleEventStatusClick(eventId, 0);
    };

    return (
        <>
            <div className="main-content">
                <Header />
                <div className="app-menu navbar-menu" style={{ display: 'flex' }}>
                    <PujariSidebar />
                </div>
                <div className="page-content">
                    <PageHeader HeaderName="Pujari Calender" />
                    <div className='container'>
                        <div className='row mt-3'>
                            <div className='col-md-7'>
                                <FullCalendar
                                    ref={calendarRef}
                                    plugins={[dayGridPlugin, interactionPlugin]}
                                    initialView="dayGridMonth"
                                    events={events}
                                    dateClick={handleDateClick}
                                    eventClick={handleEventClick}
                                    eventRender={eventRender}
                                />
                                <form onSubmit={handleSubmit}>
                                    <div className='row mt-3'>
                                        <h4>Block Date</h4>
                                        <div className='col-md-3'>
                                            <input type="date" onChange={handleChange} className='form-control' name="start_date" value={formInput.start_date} />
                                        </div>
                                        <div className='col-md-2 text-center'>
                                            <h5>To</h5>
                                        </div>
                                        <div className='col-md-3'>
                                            <input type="date" onChange={handleChange} className='form-control' name="end_date" value={formInput.end_date} />
                                        </div>
                                        <div className='col-md-3'>
                                            <button type="submit" className='btn button-container'
                                                style={{
                                                    marginTop: "0px",
                                                    height: "35px",
                                                    width: "150px",
                                                    marginLeft: "30px",
                                                }}
                                            >Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="col-md-5">
                                <div
                                    style={{
                                        backgroundColor: "#F69727",
                                        height: "100px",
                                        textAlign: "center",
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        color: "#fff",
                                    }}
                                >
                                    {/* Display selected event or date */}
                                    {
                                        selectedEvent ? (
                                            <>
                                                <span style={{ fontSize: "20px" }}>
                                                    {selectedEvent.start.getDate()}
                                                </span>
                                                <span>
                                                    {selectedEvent.start.toLocaleDateString("en-US", {
                                                        month: "long",
                                                        year: "numeric",
                                                    })}
                                                </span>
                                            </>
                                        ) :
                                            selectedDate ? (
                                                <>
                                                    <span style={{ fontSize: "20px" }}>
                                                        {selectedDate.getDate()}
                                                    </span>
                                                    <span>
                                                        {selectedDate.toLocaleDateString("en-US", {
                                                            month: "long",
                                                            year: "numeric",
                                                        })}
                                                    </span>
                                                </>
                                            ) : (
                                                <>
                                                    <span style={{ fontSize: "20px" }}>
                                                        {new Date().getDate()}
                                                    </span>
                                                    <span>
                                                        {new Date().toLocaleDateString("en-US", {
                                                            month: "long",
                                                            year: "numeric",
                                                        })}
                                                    </span>
                                                </>
                                            )}
                                </div>

                                <div
                                    style={{
                                        backgroundColor: "#fff",
                                        textAlign: "center",
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        color: "#fff",
                                    }}
                                >
                                    {pujariEvents && pujariEvents.length > 0 ? (
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                padding: "10px",
                                            }}
                                        >
                                            <span style={{ color: "#A90000" }}>Available Events</span>
                                            <span style={{ color: "#000000" }}>
                                                {pujariEvents.map(event => (
                                                    <div key={event.id} className='text-start mt-3' style={{ marginBottom: '10px' }}>
                                                        <div style={{ border: '2px solid rgb(246, 151, 39)', padding: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

                                                            <div style={{ flex: 1, textAlign: 'left' }}>
                                                                <div style={{ fontWeight: 'bold', color: '#A90000' }}>{event.poojaName}</div>

                                                                <div>{event.dateTIme}</div>
                                                            </div>

                                                            <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                                                                {/* <div style={{ width: '100px', height: '100px', borderRadius: '50%', overflow: 'hidden' }}>
                                                                    <img src={event.image} alt="event" style={{ width: '100%', height: '100%' }} />
                                                                </div> */}
                                                                <div style={{ fontWeight: 'bold', color: '#A90000' }}>
                                                                    Dakshina
                                                                    <br />
                                                                    <span style={{ color: '#000', }}>{event.dakshina}</span>
                                                                </div>

                                                            </div>

                                                            <div style={{ flex: 1, textAlign: 'right' }}>
                                                                <button
                                                                    style={{
                                                                        marginBottom: '5px',
                                                                        backgroundColor: '#A90000',
                                                                        color: "#fff",
                                                                        borderRadius: '5px',
                                                                        border: '2px solid #A90000'
                                                                    }}
                                                                    onClick={() => handleApproveClick(event.id)}
                                                                >Approve</button>
                                                                &nbsp;
                                                                <button
                                                                    style={{
                                                                        marginTop: '5px',
                                                                        color: "#A90000",
                                                                        borderRadius: '5px',
                                                                        border: '2px solid #A90000'
                                                                    }}
                                                                    onClick={() => handleDenyClick(event.id)}
                                                                >Deny</button>
                                                            </div>

                                                        </div>
                                                    </div>
                                                ))}
                                            </span>
                                        </div>
                                    ) : (
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                padding: "10px",
                                            }}
                                        >
                                            <span style={{ color: "#A90000" }}>No Events Available</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

    function eventRender(info) {
        const isBlocked = pujariBlockDate.some(date => {
            let startDate = new Date(date.start_date);
            let endDate = new Date(date.end_date);
            endDate.setDate(endDate.getDate() + 1);
            return info.event.startStr >= date.start_date && info.event.endStr < endDate.toISOString().split('T')[0];
        });

        if (isBlocked) {
            info.el.style.backgroundColor = '#ff6347';
            info.el.classList.add('blocked');
        } else {
            info.el.style.backgroundColor = '';
        }
    }

}

export default PujariCalender