import React, { useState, useEffect } from "react";
import Header from "../../Header/Header";
import Sidebar from "../../Sidebar/Sidebar";
import PageHeader from "../../PageHeader/PageHeader";
import DataTable from "react-data-table-component";
import { GetAllBookingDetails } from "../../API/TempleApis/bookingsApi";
import { format } from 'date-fns';

const MyBookings = () => {
  const [ongoingBookings, setOngoingBookings] = useState([]);
  const [upcomingBookings, setUpcomingBookings] = useState([]);
  const [completedBookings, setCompletedBookings] = useState([]);
  const [cancelledBookings, setCancelledBookings] = useState([]);

  const fetchBookingsData = async () => {
    try {
      const response = await GetAllBookingDetails(); // Fetch all booking details
      const { products, pooja, seva } = response; // Destructure different types of bookings
  
      // Combine all bookings into one array with a type property
      const allBookings = [
        ...products.map(item => ({ ...item, type: 'product' })),
        ...pooja.map(item => ({ ...item, type: 'pooja' })),
        ...seva.map(item => ({ ...item, type: 'seva' })),
      ];
  
      // Categorize bookings based on status and date
      const now = new Date();
  
      const ongoing = allBookings.filter(item => item.status === 1);
      const upcoming = allBookings.filter(item => item.status === 1 && new Date(item.date_time) <= now);
      const completed = allBookings.filter(item => item.status === 2); 
      const cancelled = allBookings.filter(item => item.status === 3);
  
      setOngoingBookings(ongoing);
      setUpcomingBookings(upcoming);
      setCompletedBookings(completed);
      setCancelledBookings(cancelled);
    } catch (error) {
      console.log(error);
    }
  };
  
  

  useEffect(() => {
    fetchBookingsData();
  }, []);

  const columns = [
    {
      name: "S.No",
      selector: (_, index) => index + 1,
      sortable: true,
      width: "70px",
      center: true,
    },
    {
      name: "Name",
      selector: "user_id",
      sortable: true,
      cell: row => row.user_id || "N/A",
    },
    {
      name: "Service",
      selector: "product_id",
      sortable: true,
      cell: row => row.product_id || "N/A",
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
      cell: row => {
        switch (row.status) {
          case 1:
            return "Ongoing";
          case 2:
              return "Upcoming";
          case 3:
            return "Completed";
          case 4:
            return "Cancelled";
          default:
            return "Unknown";
        }
      },
    },
    {
      name: "Date & Time",
      selector: "date_time",
      sortable: true,
      cell: row => {
        const date = new Date(row.date_time);
        return isNaN(date) ? "N/A" : format(date, "dd-MM-yyyy HH:mm");
      },
    },
  ];

  const customStyles = {
    rows: {
      style: {
        // border: '0.1px solid #ddd',
      },
    },
    headCells: {
      style: {
        paddingLeft: '8px',
        paddingRight: '8px',
        color: 'black',
        fontSize: '14px',
        backgroundColor: '#ccc',
        fontFamily: 'Poppins',
        fontWeight: '600',
        border: '0.1px solid #ddd',
        display: 'flex',
        justifyContent: 'center',
      },
    },
    cells: {
      style: {
        paddingLeft: '8px',
        paddingRight: '8px',
        border: '0.1px solid #ddd',
        display: 'flex',
        justifyContent: 'center',
      },
    },
  }

  return (
    <div className="main-content">
      <Header />
      <div className="app-menu navbar-menu" style={{ display: "flex" }}>
        <Sidebar />
      </div>
      <div className="page-content">
        <PageHeader HeaderName="My Bookings" />
        <div className="container-fluid pt-3">
          <ul className="nav nav-tabs">
            <li className="nav-item mb-3">
              <a className="nav-link active" data-bs-toggle="tab" href="#ongoing">
                Ongoing
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-bs-toggle="tab" href="#upcoming">
                Upcoming
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-bs-toggle="tab" href="#completed">
                Completed
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-bs-toggle="tab" href="#cancelled">
                Cancelled
              </a>
            </li>
          </ul>
          <div className="tab-content">
            <div className="tab-pane fade show active" id="ongoing">
              <DataTable
                columns={columns}
                data={ongoingBookings}
                pagination
                customStyles={customStyles}
                highlightOnHover
              />
            </div>
            <div className="tab-pane fade" id="upcoming">
              <DataTable
                columns={columns}
                data={upcomingBookings}
                pagination
                customStyles={customStyles}
                highlightOnHover
              />
            </div>
            <div className="tab-pane fade" id="completed">
              <DataTable
                columns={columns}
                data={completedBookings}
                pagination
                customStyles={customStyles}
                highlightOnHover
              />
            </div>
            <div className="tab-pane fade" id="cancelled">
              <DataTable
                columns={columns}
                data={cancelledBookings}
                pagination
                customStyles={customStyles}
                highlightOnHover
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyBookings;
