import React, {useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom';
import { fetchSevaTemples } from '../API/FetchAPI';

function PujariPooja  ()  {
  const navigate = useNavigate();
  const [pooja, setPooja] = useState([]);

  const handleButtonClick = () => {
    navigate('/PujariPoojaViewDetails');
  };

  async function loadTemplesData() {  
    try {
      const data = await fetchSevaTemples('pooja/pooja-details/14');
      // console.log('Data for Temples:', data);
      setPooja(data.data);
      // Handle the data as needed
    } catch (error) {
      console.error('Error loading temples data:', error);
    }
  }

  useEffect(()=> {
    loadTemplesData();
  }, [])


  return (
    <div>
      <div className='Card_form'>
      <table className='table table-bordered table-hover'>
                <thead>
                  <tr className='table-primary'>
                    <th style={{ textAlign: 'center' }}>Sl.No</th>
                    <th style={{ textAlign: 'center' }}>Pooja Name</th>
                    <th style={{ textAlign: 'center' }}>Image</th>
                    <th style={{ textAlign: 'center' }}>Description</th>
                    <th style={{ textAlign: 'center' }}>Created Date</th>
                    <th style={{ textAlign: 'center' }}>Action</th>
                  </tr>
                </thead>
                <tbody className='table_tbody'>
                {pooja.map((pooja, index) => ( 
                  <tr key={index}>
                    <td>{pooja.id}</td>
                    <td>{pooja.pooja_name}</td>
                    <td>
                      <img src={pooja.image} alt="Temple" />
                    </td>
                    <td>{pooja.description}</td>
                    <td>{pooja.created_by}</td>
                    <td>
                      <button className='btn btn-primary' onClick={handleButtonClick}> View Full details </button>
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>
      </div>
    </div>
  )
}

export default PujariPooja
