import React, { useState, useEffect } from 'react';
import Modal from "react-bootstrap/Modal";
import axios from 'axios';
import { postApiResponse } from './API';
import { showToast } from './Toast';
import { PoojaCreate, PoojaUpdate } from './Constants';

function CreatePoojaTempleModel(props) {
  const { openModal, setOpenModal, formInput, setFormInput, editId } = props;
  const [templeList, setTempleList] = useState([]);
  const token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiYjhiZmVmNTMzZWY5MzhmZDg2OGNjMTkyOGIxYjQ5MDlmODA1YzdjMzY5NmM2NjdhYWVmODZlZDNkNmFmYTkyNTMxYWU3ZDU2MjQ3NWFiOTkiLCJpYXQiOjE3MTgxOTQ0ODUuNzczMjIyOTIzMjc4ODA4NTkzNzUsIm5iZiI6MTcxODE5NDQ4NS43NzMyMjUwNjkwNDYwMjA1MDc4MTI1LCJleHAiOjE3NDk3MzA0ODUuNzY5MzM0MDc3ODM1MDgzMDA3ODEyNSwic3ViIjoiOSIsInNjb3BlcyI6W119.tKuFhSX5NtMZknv8gDmd94GheT4chdebFkXK_glSEEWyF6FdhpUJj9Q8zNfMY0Ob24aSsMTMYBaf0VaCXpW6j0lMJA8XH95tsJGOYsjrL5q6Lnjst4opRjQsG_cu9Q6wH4B9W-H9XAqLQ76PAM3V19pXLsTLmo1hlLtOhzdPkaS6lGcf-D3cK9hH8VPiz0-Ly-jNXXMAEBGmnrO2tgiiExvxNNbYUy30GIoZPR0nOdjyRqOi1cRPMuFYmtkz0R2CtED9eHdZDKUNZCAeEDsXVnI8g9uP62lFvGCtFZ8s2C_PRzJXJnNMDbXf4bKUoE5O57Yjt-DQtiNC3LnWFGlB8IOwxGVtiAVz46jSOGaqGTQcDwyyelqY3VFeGt63Mamewzza8hzaez_Nf9GJirdyim69uJ7RvYUaw4eknWa1kYGujBUDvWrfmg2NKnwOlJdIKHedwE_Ywj9ja5O6gCEcWMJmZnm8CzA_uVPt_Db4s7RbIj5Xvbo2acTeYx6G7VnTX4HISV92aJfS4XEfwWa8CJsKxS0m05qwRyOUIhHPt4DyuQ6POHJns5VP7Bqkuc9b0tuHW6uRzuw8PUM-l2fdRz1_cLucz9U57mO-EgfAr-jh6o2DFAnmH7HM4fY8medM4whWbZP3V26wAS0tN8AgrJe9wxkQ04CSu_uLm-z-lXo"; // Replace this with your actual token

  useEffect(() => {
    const fetchTempleList = async () => {
      try {
        const response = await axios.get('https://gowinda.in/api/api/appUser/templeList', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        if (response.status === 200 && response.data.status === 200 && Array.isArray(response.data.data)) {
          setTempleList(response.data.data);
          console.log(response.data, "responseeeeeeeeeeeeeeeeee")
        } else {
          showToast('Failed to fetch temple list', 'error');
        }
      } catch (error) {
        showToast('Failed to fetch temple list', 'error');
      }
    };

    fetchTempleList();
  }, [token]);


  useEffect(() => {
    console.log("Temple List State:", templeList);
  }, [templeList]);



  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormInput((prevData) => ({ ...prevData, [name]: value }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      for (let i in formInput) {
        formData.append(i, formInput[i]);
      }

      let response;
      if (editId) {
        response = await postApiResponse(PoojaUpdate, formData);
      } else {
        response = await postApiResponse(PoojaCreate, formData);
      }
      if (response.status === 200) {
        handleClose();
        editId ? showToast(`${response.data.pooja_name} Was Updated`, 'success') : showToast(`${response.data.pooja_name} Was Created`, 'success');
      } else {
        showToast('Something Went wrong', 'info');
      }
    } catch (error) {
      console.log(error.response)
      const validation = error.response.data.errors;
      if (error.response.status === 422) {
        for (let i in validation) {
          showToast(validation[i][0], 'error');
        }
      }
    }
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <Modal
      show={openModal}
      onHide={() => handleClose()}
      size="lg"
      aria-labelledby="example-modal-sizes-title-lg"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title
          id="example-modal-sizes-title-lg"
          style={{ color: "#A90000" }}
        >
          {editId ? "Update Temple" : "Create Temple"}
        </Modal.Title>
      </Modal.Header>
      <hr />
      <Modal.Body style={{ marginTop: "-20px" }}>
        <form onSubmit={handleSubmit} id="poojaForm">
          <div className="d-flex">
            <div className="col-md-6 mb-3">
              <div className="form-group">
                <label className="form-label">Temple Name</label>
                <input
                  type="hidden"
                  className="form-control"
                  name="id"
                  value={editId}
                />
                <select
                  name="pooja_name"
                  className='form-select'
                  onChange={handleChange}
                  value={formInput.pooja_name}
                >
                  <option value="">Select Temple</option>
                  {Array.isArray(templeList) && templeList.map((temple) => (
                    <option key={temple.id} value={temple.id}>{temple.temple_name}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <hr />
          <div className="modal-footer d-flex justify-content-end">
            <button
              type="button"
              className="back-button"
              onClick={handleClose}
            >
              Back
            </button>
            <button
              type="submit"
              className="save-button"
            >
              {editId ? "Update" : "Save"}
            </button>
          </div>
        </form>

        <form id="poojaForm">
          <div className="d-flex">
            <div className="col-md-6 mb-3">
              <div className="form-group">
                <label className="form-label">Availability</label>
                <input
                  type="text"
                  className="form-control"
                  name="availability"
                  onChange={handleChange}
                  value={formInput.availability}
                />
              </div>
            </div>
          </div>
          <hr />
          <div className="modal-footer d-flex justify-content-end">
            <button
              type="button"
              className="back-button"
              onClick={handleClose}
            >
              Back
            </button>
            <button
              type="submit"
              className="save-button"
            >
              {editId ? "Update" : "Save"}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}

export default CreatePoojaTempleModel;
