/* eslint-disable no-undef */
import React from 'react';
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import PageHeader from "../PageHeader/PageHeader";
import TempleDetailsTabs from './TempleDetailsTabs';



function ViewFulldetails() {
  return (
    <div>
      <div className="main-content">
                <Header />
                <div className="app-menu navbar-menu" style={{ display: 'flex' }}>
                <Sidebar />
                </div>
                <div className="page-content">
                    <PageHeader HeaderName="Dashboard " />
                    <div className='container'>
                    <div className='row mt-3 justify-content-between'>
                        <div className='col-md-6'>
                            <h4>Temple Full Details</h4>
                        </div>
                        <div className='col-md-3  '>
                        <div class="input-group">
                          <input type="text" class="form-control" name="" placeholder="" />
                            <button type="submit" class="input-group-text"><i class="fa fa-search"></i></button>
                            </div>  
                            </div>
                    </div>
                  <TempleDetailsTabs />
                    </div>
                </div>
            </div>
    </div>
  )
}

export default ViewFulldetails
