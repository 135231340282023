import React from "react";
import { toast } from "react-toastify";

export const showToast = (message, type) => {
    toast[type](message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
  };