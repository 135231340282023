import { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useForm } from 'react-hook-form';
import Header from '../Header/Header';

function TempleRegistration() {
    const { register, handleSubmit, setValue, watch, formState: { errors } } = useForm();
    const [imagePreview, setImagePreview] = useState(null);
    const email = watch('email');
    const documentTypeMap = {
        aadharcard: 1,
        driver_license: 2,
        id_card: 3

    };
    const ditiesIdMap = {
        Dities1: 1,
        Dities2: 2,
        Dities3: 3

    };
    const otherDitiesIdMap = {
        Dities1: 1,
        Dities2: 2,
        Dities3: 3

    };
    const dayIdMap = {
        Monday: 1,
        Tuesday: 2,
        Wednesday: 3,
        Thursday: 4,
        Friday: 5,
        Saturday: 6,
        Sunday: 7,
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImagePreview(URL.createObjectURL(file));
        }
    };

    const formData = new FormData();
    const onSubmit = async (data) => {
        // console.log(data,'form data')
         if (Object.keys(errors).length > 0) {
         
        showValidationErrors(errors);
        return;
    }
        
        try {
            const formData = new FormData();
       
            const ditiesIdValue = ditiesIdMap[data.dities_id];
            formData.append('document_type', data.document_type);
            formData.append('dities_id', ditiesIdValue);

            let otherDitiesIdValues = [];
            if (data.other_dities_id) {
                otherDitiesIdValues = Array.isArray(data.other_dities_id,)
                    ? data.other_dities_id.map(id => otherDitiesIdMap[id])
                    : [otherDitiesIdMap[data.other_dities_id]];
            }

            formData.append('temple_name', data.temple_name);
            formData.append('phone_number', data.phone_number);
            formData.append('email', data.email);
            formData.append('password', data.password);
            formData.append('temple_desc', data.temple_desc);
            formData.append('significance', data.significance);
            formData.append('history', data.history);
            // formData.append('document_type', documentTypeValue);
            // formData.append('dities_id', ditiesIdValue);
            formData.append('temple_address', data.temple_address);
            formData.append('latitude', data.latitude);
            formData.append('longitude', data.longitude);
            formData.append('drinking_water', data.drinking_water);
            formData.append('restaurants', data.restaurants);
            formData.append('pooja_items', data.pooja_items);
            formData.append('rest_rooms', data.rest_rooms);
            formData.append('marriage_halls', data.marriage_halls);
            formData.append('darmashala', data.darmashala );
            formData.append('status', 3);
            formData.append('near_by_busstand', data.near_by_busstand );
            formData.append('near_by_railway_station', data.near_by_railway_station );
            formData.append('near_by_airport', data.near_by_airport );
            formData.append('other_dities_id[]', otherDitiesIdValues.join(','));

             const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
        days.forEach(day => {
            const dayId = dayIdMap[day];
            const openingTime = data[`${day.toLowerCase()}opening_time`] || '';
            const closingTime = data[`${day.toLowerCase()}closing_time`] || '';
            formData.append('day_id[]', dayId);
            formData.append('opening_time[]', openingTime);
            formData.append('closing_time[]', closingTime);
        });

            if (data.document[0]) {
                formData.append('document', data.document[0]);
            }

            if (data.image[0]) {
                formData.append('image', data.image[0]);
            }

            const response = await fetch(`https://gowinda.in/api/api/temple/create`, {
                method: 'POST',
                body: formData,
            });

            

            if (response.ok) {
                toast.success('Temple registered successfully');
            }else if(response.status === 422){
                const errorValidate = await response.json();
                    Object.keys(errorValidate.errors).forEach((key) => {
                        errorValidate.errors[key].forEach((message) => {
                        toast.error(message);
                        });
                    });
            } else {
                const errorData = await response.json();
                toast.error('Failed to register temple');
                console.error('Error:', errorData);
            }
        } catch (error) {
            console.error('Error registering temple:', error);
            toast.error('Error registering temple');
        }
    };

    const showValidationErrors = (errors) => {

        Object.keys(errors).forEach((key) => {
            if (errors[key]?.message) {
                toast.error(errors[key].message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        });
    };
    

    return (
        <div className='pb-5'>
            <Header />
            <div className="container pt-5 mt-5">
                {/* <div className='text-center pt-1' style={{backgroundColor:"#e0a14e"}}>                
                    <h2 style={{ color: 'white', marginBottom: '25px' }}>Temple Registration</h2>
                </div> */}
                <div className='mb-3' style={{backgroundColor: "#e38695", padding: "1px"}}>
                    <h5 className="m-2 text-white">BASIC DETAILS</h5>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>

                <div className='row mb-4'>
                    <div className='col-md-9'>
                        <div className='row'>
                            <div className='col-md-6 mb-4'>
                                <small>Temple Name</small>
                                <input
                                    type="text"
                                    name="name"
                                    className="form-control"
                                    {...register('temple_name', { required: 'Temple name is required' })}
                                />
                                {errors.temple_name && <span style={{ color: 'red' }}>{errors.temple_name.message}</span>}
                        
                            </div>
                            <div className='col-md-6 mb-4'>
                                <small>Phone Number</small>
                                <input
                                    type="number"
                                    id="phoneNumber"
                                    className="form-control"
                                    {...register('phone_number' ,  { required: 'Phone Number is required' })}
                                />
                                {errors.phone_number && <span style={{ color: 'red' }}>{errors.phone_number.message}</span>}
                        
                            </div>

                            <div className='col-md-6 mb-4'>
                            <small>Email ID</small>
                                <input
                                    type="text"
                                    id="email"
                                    className="form-control"
                                    {...register('email' ,  { required: 'Email is required' })}
                                    onChange={(e) => setValue('email', e.target.value)}
                                />
                                {errors.email && <span style={{ color: 'red' }}>{errors.email.message}</span>          }
                            </div>
                            <div className='col-md-6 mb-4'>
                                <small>Password</small>
                                <input
                                    type="text"
                                    id="password"
                                    className="form-control"
                                    {...register('password' ,  { required: 'Password is required' })}
                                />
                                {errors.password && <span style={{ color: 'red' }}>{errors.password.message}</span>}
                                
                            </div>
                            <div className="col-md-12 mb-4">
                                <label htmlFor="address">Address</label>
                                <textarea
                                    id="address"
                                    rows={4}
                                    className="form-control"
                                    {...register('temple_address' ,  { required: 'Address is required' })}
                                />
                                {errors.temple_address && <span style={{ color: 'red' }}>{errors.temple_address.message}</span>}
                            </div>
                            
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className='row'>
                            <div className='col-md-12 mb-4'>
                                {imagePreview && <img src={imagePreview} alt="Image Preview" style={{ marginTop: '10px', maxHeight: '300px' , maxWidth: "200px"}} />}
                            </div>
                            <div className='col-md-12'>
                                <input type="file" id="image" className="form-control" accept=".jpg,.jpeg,.png"
                                    {...register('image', { required: 'Image is required' })}
                                    onChange={handleImageChange}
                                    style={{ display: 'none' }}
                                />
                                <label htmlFor="image" className="custom-file-upload">
                                    <button className='btn btn-success' type="button" onClick={() => document.getElementById('image').click()}>
                                        Upload Image
                                    </button>
                                </label>
                                {errors.image && <span style={{ color: 'red' }}>{errors.image.message}</span>}
                            </div>
                        </div>
                    
                        

                        
                    </div>
                </div>

                    
                    <div className="row mb-4">
                        <div className='col-md-4'>
                            <small>H.No</small>
                            <input
                                type="text"
                                id="hNo"
                                className="form-control"
                                {...register('hNo' ,  { required: 'Hno is required' })}
                            />
                            {errors.hNo && <span style={{ color: 'red' }}>{errors.hNo.message}</span>}
                        </div>
                        <div className="col-md-4">
                            <small>Latitude</small>
                            <input
                                type="text"
                                id="latitude"
                                className="form-control"
                                {...register('latitude', {
                                    required: 'Latitude is required',
                                
                                })}
                            />
                            {errors.latitude && <span style={{ color: 'red' }}>{errors.latitude.message}</span>}
                        </div>
                        <div className="col-md-4">
                            <small>Longitude</small>
                            <input
                                type="text"
                                id="longitude"
                                className="form-control"
                                {...register('longitude', {
                                    required: 'Longitude is required',
                                    // pattern: {
                                    //     value: /^-?([1-9]?[0-9]|[1]?[0-7][0-9]|[1]?[0-8][0])\.{1}\d{1,6}$/,
                                    //     message: 'Invalid longitude format'
                                    // }
                                })}
                            />
                            {errors.longitude && <span style={{ color: 'red' }}>{errors.longitude.message}</span>}
                        </div>
                    </div>

              
                    <div className='mb-3' style={{backgroundColor: "#e38695", padding: "1px"}}>
                        <h5 className="m-2 text-white">KYC DOCUMENTS</h5>
                    </div>
                    <div className="row mb-4">
                        <div className="col-md-4">
                            <label htmlFor="document_type">Select Document</label>
                            <select
                                id="document_type"
                                className="form-control"
                                {...register('document_type' ,  { required: 'Document type is required' })}
                            >

                                <option value="">select</option>
                                <option value="1">Aadhar Card</option>
                                <option value="2">PAN</option>
                                <option value="3">Voter ID</option>
                                <option value="4">Passport</option>
                            </select>
                            {errors.document_type && <span style={{ color: 'red' }}>{errors.document_type.message}</span>}
                        </div>
                        <div className="col-md-4">
                            <small>Upload Document</small>
                            <input
                                type="file"
                                id="kycDocument"
                                className="form-control"
                                {...register('document', { 
                                    required: 'Document is required',
                                    validate: {
                                        fileType: (value) => {
                                            const allowedTypes = ['application/pdf', 'image/jpeg', 'image/png'];
                                            return value && value[0] && allowedTypes.includes(value[0].type) || 'Invalid file type';
                                        }
                                    }
                                })}
                            />
                            {errors.document && <span style={{ color: 'red' }}>{errors.document.message}</span>}
                        </div>
                    </div>
                    <div className='mb-3' style={{backgroundColor: "#e38695", padding: "1px"}}>
                        <h5 className="m-2 text-white">HIGHLIGHTS</h5>
                    </div>
                    <div className="row mb-4">
                        <div className="col-md-4">
                            <small>Dities</small>
                            <select
                                id="Dities"
                                className="form-control"
                                {...register('dities_id', {required : 'Dities is required'}  )}
                            >
                                <option value= ""> select</option>
                                <option value="Dities1">Dities1</option>
                                <option value="Dities2">Dities2</option>
                                <option value="Dities3">Dities3</option>
                            </select>
                            {errors.dities_id && <span style={{ color: 'red' }}>{errors.dities_id.message}</span>}
                        </div>
                        <div className="col-md-4">
                            <small>Other Dities</small>
                            <select
                                id="otherDities"
                                className="form-control"
                                {...register('other_dities_id', { required: 'Other Dities is required' })}
                            >
                                <option value = "">select</option>
                                <option value="Dities1">Dities1</option>
                                <option value="Dities2">Dities2</option>
                                <option value="Dities3">Dities3</option>
                            </select>
                            {errors.other_dities_id && <span style={{ color: 'red' }}>{errors.other_dities_id.message}</span>}
                        
                            
                        </div>
                    </div>
                    <div className='mb-3' style={{backgroundColor: "#e38695", padding: "1px"}}>
                        <h5 className="m-2 text-white">TIMINGS</h5>
                    </div>
                    {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day, index) => (
                        <div className="row" key={index}>
                            <div className="col-md-1">
                                <h5 style={{ marginTop: '40px' }}>{day}</h5>
                            </div>
                            <div className="col-md-2 mt-3">
                                <small>Opening Time</small>
                                <input
                                    type="time"
                                    id={`${day.toLowerCase()}OpeningTime`}
                                    className="form-control"
                                    {...register(`${day.toLowerCase()}opening_time`, { required: `${day} opening time is required` })}
                                />
                                {/* <span style={{ color: 'red' }}>{errors[`${day.toLowerCase()}opening_time`].message}</span> */}
                            </div>
                            <div className="col-md-2 mt-3">
                                <small>Closing Time</small>
                                <input
                                    type="time"
                                    id={`${day.toLowerCase()}ClosingTime`}
                                    className="form-control"
                                    {...register(`${day.toLowerCase()}closing_time`, { required: `${day} closing time is required` })}
                                />
                                {/* <span style={{ color: 'red' }}>{errors[`${day.toLowerCase()}closing_time`].message}</span> */}
                            </div>
                        </div>
                    ))}
                    <div className='mb-3 mt-3' style={{backgroundColor: "#e38695", padding: "1px"}}>
                        <h5 className="m-2 text-white">ABOUT</h5>
                    </div>
                    <div className="row mb-4">
                        <div className="col-md-12">
                            <small>Description</small>
                            <textarea
                                id="description"
                                className="form-control"
                                {...register('temple_desc' , { required: 'Description is required' })}
                            />
                            {errors.temple_desc && <span style={{ color: 'red' }}>{errors.temple_desc.message}</span>}
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-md-12">
                            <small>History</small>
                            <textarea
                                id="history"
                                className="form-control"
                                {...register('history' , {required : 'Histroy is required'})}
                            />
                            {errors.history && <span style={{ color: 'red' }}>{errors.history.message}</span>}
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-md-12">
                            <small>Significance</small>
                            <textarea
                                id="significance"
                                className="form-control"
                                {...register('significance' , {required : 'Significance is required'})}
                            />
                            {errors.significance && <span style={{ color: 'red' }}>{errors.significance.message}</span>}
                        </div>
                    </div>
                   
                    <div className='mb-3' style={{backgroundColor: "#e38695", padding: "1px"}}>
                        <h5 className="m-2 text-white">AMENITIES</h5>
                    </div>
                    <div className="row mb-4">
                        <div className="col-md-3">
                            
                            <small>Drinking Water</small>
                            <select
                                id="drinkingWater"
                                className="form-control"
                                {...register('drinking_water' , {required : 'Drinking water is required'})}
                            >
                                <option value="">select</option>
                                <option value="1">Yes</option>
                                <option value="2">No</option>
                            </select>
                            {errors.drinking_water && <span style={{ color: 'red' }}>{errors.drinking_water.message}</span>}
                        </div>
                        <div className="col-md-3">
                            <small>Restaurants</small>
                            <select
                                id="restaurants"
                                className="form-control"
                                {...register('restaurants' , {required: 'Restaurants is required'})}
                            >
                                <option value="">select</option>
                                <option value="1">Yes</option>
                                <option value="2">No</option>
                            </select>
                            {errors.restaurants && <span style={{ color: 'red' }}>{errors.restaurants.message}</span>}
                        </div>
                        <div className="col-md-3">
                            <small>Pooja Items</small>
                            <select
                                id="poojaitems"
                                className="form-control"
                                {...register('pooja_items', {required : 'Pooja items is required'})}
                            >
                                <option value="">select</option>
                                <option value="1">Yes</option>
                                <option value="2">No</option>
                            </select>
                            {errors.pooja_items && <span style={{ color: 'red' }}>{errors.pooja_items.message}</span>}
                        </div>
                    </div>

                    <div className="row mb-4">
                        <div className="col-md-3">
                            <small>Rest Rooms</small>
                            <select
                                id="restrooms"
                                className="form-control"
                                {...register('rest_rooms' , {required : 'Rest rooms is required'})}
                            >
                                <option value="">select</option>
                                <option value="1">Yes</option>
                                <option value="2">No</option>
                            </select>
                            {errors.rest_rooms && <span style={{ color: 'red' }}>{errors.rest_rooms.message}</span>}
                        </div>
                        <div className="col-md-3">
                            <small>Marriage Halls</small>
                            <select
                                id="marriagehalls"
                                className="form-control"
                                {...register('marriage_halls' , {required : 'Marriage halls is required'})}
                            >
                                <option value="">select</option>
                                <option value="1">Yes</option>
                                <option value="2">No</option>
                            </select>   
                            {errors.marriage_halls && <span style={{ color: 'red' }}>{errors.marriage_halls.message}</span>}
                        </div>
                        <div className="col-md-3">
                            <small>Dharmashala</small>
                            <select
                                id="dharmashala"
                                className="form-control"
                                {...register('darmashala' , {required : 'Dharmashala is required'})}
                            >
                                <option value="">select</option>
                                <option value="1">Yes</option>
                                <option value="2">No</option>
                            </select>
                            {errors.darmashala && <span style={{ color: 'red' }}>{errors.darmashala.message}</span>}
                        </div>
                    </div>
                    
                    <div className='mb-3' style={{backgroundColor: "#e38695", padding: "1px"}}>
                        <h5 className="m-2 text-white">HOW TO REACH</h5>
                    </div>
                    <div className="row mb-4">
                        <div className="col-md-4">
                        <small>Bus Stand</small>
                            <textarea
                                id="busstand"
                                className="form-control"
                                {...register('near_by_busstand',{required : 'Busstand is required'})}
                            />
                            {errors.near_by_busstand && <span style={{ color: 'red' }}>{errors.near_by_busstand.message}</span>}
                        </div>
                        <div className="col-md-4">
                        <small>Railway Station</small>
                            <textarea
                                id="railway"
                                className="form-control"
                                {...register('near_by_railway_station', {required : 'Railway is required'})}
                            />
                            {errors.near_by_railway_station && <span style={{ color: 'red' }}>{errors.near_by_railway_station.message}</span>}
                        </div>
                        <div className="col-md-4">
                            <small>Airport</small>
                            <textarea
                                id="airport"
                                className="form-control"
                                {...register('near_by_airport', {required : 'Airport is required'})}
                            />
                            {errors.near_by_airport && <span style={{ color: 'red' }}>{errors.near_by_airport.message}</span>}
                            </div>
                    </div>
                    <div className='text-center mb-5'>
                        <button type="submit" className="btn btn-success mt-3">REGISTER TEMPLE</button>
                    </div>
                </form>
                <ToastContainer />
            </div>
        </div>
    );
}

export default TempleRegistration;
