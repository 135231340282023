import React from 'react'
import {  useLocation, useNavigate } from 'react-router-dom'
import Header from '../Header/Header'
import Sidebar from '../Sidebar/Sidebar'
import Footer from '../Footer/Footer' 

function ViewPost() {
  const navigate = useNavigate()
  const location = useLocation()
  const receivedData = location.state
  // const [back,setBack] = useState()
  // const onBack = () => [navigate('/pandal-full-details')]

  // const onDeleteData = async (id) => {
  //   try {
  //     await deletePandalPost(id)
  //     const successMessage = 'Pandal Post Deleted'
  //     toast.success(successMessage, {
  //       position: 'top-right',
  //       autoClose: 3000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //     })
  //     navigate(`/pandal-full-details/${receivedData.pandal_id}`,{
  //       state: receivedData,
  //     })
  //   } catch (error) {
  //     toast.error(error.message, {
  //       position: 'top-right',
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //     })
  //   }
  // }

  const clickSevaDetails = () => {
    navigate(`/users-full-details`, {
      state: receivedData,
    })
  }

  return (
    <>
      <div className="main-content">
        <Header />
        <div className="app-menu navbar-menu" style={{ display: 'flex' }}>
          <Sidebar />
        </div>
        <div className="page-content">
          <div className="breadcrum">
            <div className="row align-items-between">
              <div className="col-md-6 col-6">
                <p>
                  <b>View Post</b>
                </p>
              </div>
              <div className="col-md-6 col-6 text-end">
                <p>
                  <b>Username</b>: Admin
                </p>
              </div>
            </div>
          </div>
          <div className="container-fluid pt-3">
            <div className="row">
              <div className="col-xl-12 col-md-12">
                <div className="card">
                  <div className="col-md-3 ms-auto text-end">
                    <button
                      onClick={() => clickSevaDetails(receivedData)}
                      className="btn btn-info"
                    >
                      Back
                    </button>
                  </div>
                  <div className="card-body">
                    <form method="">
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <small>Post/Feed</small>
                          <textarea
                            className="form-control"
                            rows="4"
                            readOnly
                            value={receivedData?.description}
                          ></textarea>
                        </div>
                        <div className="col-md-12 mb-3">
                          <small>Image/Video</small>
                          <div className="col-md-2">
                            <img
                              alt="pujam_pandal"
                              src={receivedData?.images[0]?.image}
                              style={{ width: '100%' }}
                              className="img-fluid"
                              readOnly
                            />
                          </div>
                        </div>
                        {/* <div className="col-md-2">
                          <a
                            className="btn btn-danger"
                            onClick={() => onDeleteData(receivedData.id)}
                          >
                            <i className="fa fa-trash-alt"></i>&nbsp; Delete
                            Post
                          </a>
                        </div> */}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default ViewPost
