import React, {useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom';
import { fetchSevaTemples } from '../API/FetchAPI';

function Templepoojari()  {
    const navigate = useNavigate();

    const [Pooja, setPooja] = useState([]);

    const handleButtonClick = () => {
        navigate('/TemplepoojariViewdetails');
      };


      async function loadTemplesData() {  
        try {
          const data = await fetchSevaTemples('pujari/getByTempleId/1');
          // console.log('Data for Temples:', data);
          setPooja(data);
          // Handle the data as needed
        } catch (error) {
          console.error('Error loading temples data:', error);
        }
      }

      useEffect(()=> {
        loadTemplesData();
      }, [])
      


  return (
    <div>
         <div className='Card_form'>
      <table className='table table-bordered table-hover'>
                <thead>
                  <tr className='table-primary'>
                    <th style={{ textAlign: 'center' }}>Sl.No</th>
                    <th style={{ textAlign: 'center' }}> Name</th>
                    <th style={{ textAlign: 'center' }}>Age</th>
                    <th style={{ textAlign: 'center' }}>Category</th>
                    <th style={{ textAlign: 'center' }}>Mobile</th>
                    <th style={{ textAlign: 'center' }}>Designation</th>
                    <th style={{ textAlign: 'center' }}>Action</th>
                  </tr>
                </thead>
                <tbody className='table_tbody'>
                {Array.isArray(Pooja) && Pooja.map((pooja, index) => (
                  <tr key={index}>
                    <td>{Pooja.id}</td>
                    <td>{Pooja.name}</td>
  
                    <td>{Pooja.dob}</td>
                    <td>
                      {Pooja.category}
                    </td>
                    <td>{Pooja.mobile}</td>
                    <td>{Pooja.mobile}</td>
                    <td>
                      <button className='btn btn-primary' onClick={handleButtonClick}> View Full details </button>
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>
      </div>
      
    </div>
  )
}

export default Templepoojari
