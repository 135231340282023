import { React, useState, useEffect, useMemo } from "react";
import Header from "../../Header/Header";
import Sidebar from "../../Sidebar/Sidebar";
import PageHeader from "../../PageHeader/PageHeader";
import DataTable from "react-data-table-component";
import { GetAllReviewsDetails } from "../../API/TempleApis/reviewsApi";
const TempleReviews = () => {
const[getAllReviews,setAllReviews] = useState([])


const ReviewsDetails = async()=>{
  try{
    const response = await GetAllReviewsDetails()
    setAllReviews(response)
  }catch(error){
    console.log(error)
  }
}

useEffect(()=>{
  ReviewsDetails()
},[])


  const columns = useMemo(
    () => [
      {
        name: "S.No",
        selector: "slno",
        sortable: true,
        format: (row, rowIndex) => rowIndex + 1,
      },
      {
        name: "Name",
        selector: "user.name",
        sortable: true,
      },
      
      {
        name: "Temple Name",
        selector: "temple_name",
        sortable: true,
      },
      {
        name: "Status Rating",
        selector: "star_rating",
        sortable: true,
      },
      {
        name: "Reviewes",
        selector: "review",
        sortable: true,
      },
      // {
      //   name: "Mobile",
      //   selector: "user.mobile",
      //   // cell: (row) => formatDate(row.pandal_start_date),
      //   sortable: true,
      // },
      // {
      //   name: "Actions",
      //   cell: (row) => (
      //     <div>
      //       <button
      //         class="btn btn-sm btn-edit me-2"
      //           // onClick={() => clickPandalDetails(row)}
      //       >
      //       <i class="fa-solid fa-edit"></i>
      //       </button>
      //     </div>
      //   ),
      // },
    ],
    []
  );

  const customStyles = {
    rows: {
      style: {
        // border: '0.1px solid #ddd',
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        color: "black",
        fontSize: "14px",
        backgroundColor: "#ccc",
        fontFamily: "Poppins",
        fontWeight: "600",
        border: "0.1px solid #ddd",
        display:'flex',
        justifyContent:'center'
      },
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        border: "0.1px solid #ddd",
        display:'flex',
        justifyContent:'center'
      },
    },
  };

  return (
    <>
      <div className="main-content">
        <Header />
        <div className="app-menu navbar-menu" style={{ display: "flex" }}>
          <Sidebar />
        </div>
        <div className="page-content">
          <PageHeader HeaderName="Review's" />
          <div className="container-fluid pt-3">
            <div className="row mb-2">
              <div style={{ display: "flex", marginRight: "50px" }}>
               
                <div className="col-md-3 ms-auto mb-3">
                  {/* <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      name=""
                      placeholder="Search here"
                    />
                    <button type="submit" className="input-group-text">
                      <i className="fa fa-search"></i>
                    </button>
                  </div> */}
                </div>

             
              </div>
            </div>
            <div className="table-responsive registered-img bg-white">
              <DataTable
                  columns={columns}
                  data={getAllReviews}
                pagination
                customStyles={customStyles}
                highlightOnHover
                //   noDataComponent="Loading..."
              />
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default TempleReviews;
