import React, { useEffect, useState } from 'react'
import PageHeader from '../../PageHeader/PageHeader'
import DataTable from 'react-data-table-component'
import {
  createDesignation,
  deleteDesignation,
  getAllDesignations,
  updateDesignation,
} from '../../API/Api'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import { jsPDF } from 'jspdf'
import html2canvas from 'html2canvas'
import 'jspdf-autotable'
import * as XLSX from 'xlsx'
import Header from '../../Header/Header'
import Sidebar from '../../Sidebar/Sidebar'
import Footer from '../../Footer/Footer'
import { sortBy } from 'lodash'

function AddNewDesignation() {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm()

  const [designation, setDesignation] = useState([])
  const [selectedDesignation, setSelectedDesignation] = useState(null)
  const [selectedStatus, setSelectedStatus] = useState('')
  const [loading, setLoading] = useState(true)
  const user_id = localStorage.getItem('user_id')

  const fetchTableData = async () => {
    try {
      const data = await getAllDesignations()
      // const sortedData = sortBy(data, 'created_at').reverse();
      const sortedData = data.sort(
        (a, b) =>
          new Date(b.created_at.split('/').reverse().join('-')) -
          new Date(a.created_at.split('/').reverse().join('-')),
      )
      setDesignation(sortedData)
      // setDesignation(data)
      setLoading(false)
    } catch (error) {
      console.error('Error fetching table data:', error)
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchTableData()
  }, [])

  const customStyles = {
    rows: {
      style: {
        // border: '0.1px solid #ddd',
      },
    },
    headCells: {
      style: {
        paddingLeft: '8px',
        paddingRight: '8px',
        color: 'black',
        fontSize: '14px',
        backgroundColor: '#ccc',
        fontFamily: 'Poppins',
        fontWeight: '600',
        border: '0.1px solid #ddd',
        display: 'flex',
        justifyContent: 'center',
      },
    },
    cells: {
      style: {
        paddingLeft: '8px',
        paddingRight: '8px',
        border: '0.1px solid #ddd',
        display: 'flex',
        justifyContent: 'center',
      },
    },
  }

  const columns = [
    {
      name: 'Sl.No',
      selector: (row) => row['slno'],
      sortable: true,
      format: (row, rowIndex) => rowIndex + 1,
    },
    {
      name: 'Designation Name',
      selector: (row) => row['name'],
      sortable: true,
    },
    {
      name: 'Status',
      cell: (row) => (row.status === '1' ? 'Active' : 'Inactive'),
      sortable: true,
    },
    {
      name: 'Date Added',
      selector: (row) => row['created_at'],
      sortable: true,
    },
    {
      name: 'Actions',
      cell: (row) => (
        <div className="d-flex">
          <button
            className="btn btn-sm btn-edit me-2"
            onClick={() => EditDesignation(row)}
          >
            <i className="fa-regular fa-pen-to-square"></i>{' '}
          </button>
          <button
            className="btn btn-sm btn-delete"
            onClick={() => DeleteDesignation(row.id)}
          >
            <i className="fa-solid fa-trash-can"></i>
          </button>
        </div>
      ),
    },
  ]

  const addNewDesignation = async (data) => {
    try {
      const name = data.name || ''
      const status = data.status || ''
      const id = data.id || ''
      const created_by = user_id
      const updated_by = user_id
      const requestData = {
        name,
        status: selectedStatus,
        created_by,
        updated_by,
        id,
      }
      if (selectedDesignation) {
        await updateDesignation(selectedDesignation.id, requestData)
        const successMessage = 'Designation Updated'
        toast.success(successMessage, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
        reset()
        setSelectedStatus('')
        setSelectedDesignation(null)
        fetchTableData()
      } else {
        await createDesignation(requestData)
        const successMessage = 'New Designation Added'
        toast.success(successMessage, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
        reset()
        setSelectedStatus('')
        fetchTableData()
      }
    } catch (error) {
      // console.log(error.response, 'response')
      if (error.response.status === 422) {
        Object.values(error.response.data.errors).map((item) => {
          toast.error(item[0], {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        })
      } else {
        toast.error(error.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      }
    }
  }

  const EditDesignation = (designation) => {
    setSelectedDesignation(designation)
    setValue('name', designation.name)
    setValue('id', designation.id)
    setSelectedStatus(designation.status)
    console.log(designation, 'designation')
  }

  const DeleteDesignation = async (id) => {
    try {
      await deleteDesignation(id)
      const successMessage = 'Designation Deleted'
      toast.success(successMessage, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      fetchTableData()
    } catch (error) {
      toast.error(error.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  const handleKeyDown = (event, callback) => {
    if (event.key === 'Enter' && event.shiftKey === false) {
      event.preventDefault()
      callback(addNewDesignation)
    }
  }

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value)
  }

  //Downloading PDF Format
  const columnsForPDF = [
    {
      name: 'Sl.No',
      selector: (row) => row['slno'],
      sortable: true,
      format: (rowIndex) => rowIndex + 1,
    },
    {
      name: 'Designation Name',
      selector: (row) => row['name'],
      sortable: true,
    },
    {
      name: 'Status',
      cell: (row) => (row.status === '1' ? 'Active' : 'Inactive'),
      sortable: true,
    },
    {
      name: 'Date Added',
      selector: (row) => row['created_at'],
      sortable: true,
    },
  ]
  const handleDownloadPDF = () => {
    const table = document.querySelector('.table-responsive table')
    html2canvas(table).then((canvas) => {
      const pdf = new jsPDF()
      const pdfData = designation.map((designation, index) => [
        index + 1,
        designation['name'],
        designation['status'] === '1' ? 'Active' : 'Inactive',
        designation['created_at'],
      ])

      pdf.autoTable({
        head: [columnsForPDF.map((column) => column.name)],
        body: pdfData,
      })

      pdf.save('designations_data.pdf')
    })
  }

  //Downloading Excel Format
  const handleDownloadExcel = () => {
    const columnsWithoutActions = columns.filter(
      (column) => column.name !== 'Actions',
    )
    const tableData = designation.map((designation_new, index) => [
      index + 1,
      designation_new['name'],
      designation_new['status'] === '1' ? 'Active' : 'Inactive',
      designation_new['created_at'],
    ])
    const worksheet = XLSX.utils.aoa_to_sheet([
      columnsWithoutActions.map((column) => column.name),
      ...tableData,
    ])
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Table Data')
    const excelFile = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
    const blob = new Blob([excelFile], { type: 'application/octet-stream' })
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = 'designations_data.xlsx'
    a.click()
    URL.revokeObjectURL(url)
  }

  //Downloading CSV Format
  const handleDownloadCSV = () => {
    const columnsWithoutActions = columns.filter(
      (column) => column.name !== 'Actions',
    )
    const formatDate = (dateString) => {
      if (!dateString) {
        return ''
      }
      const dateParts = dateString.split('/')
      if (dateParts.length !== 3) {
        // console.log('Invalid date format:', dateString)
        return ''
      }
      const formattedDate = `${dateParts[1]}/${dateParts[0]}/${dateParts[2]}`
      const timestamp = Date.parse(formattedDate)
      if (isNaN(timestamp)) {
        // console.log('Invalid date:', dateString)
        return ''
      }
      const date = new Date(timestamp)
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' }
      return new Intl.DateTimeFormat('en-US', options).format(date)
    }
    const tableData = designation.map((designation_new, index) => [
      index + 1,
      designation_new['name'],
      designation_new['status'] === '1' ? 'Active' : 'Inactive',
      formatDate(designation_new['created_at']),
    ])
    const worksheet = XLSX.utils.aoa_to_sheet([
      columnsWithoutActions.map((column) => column.name),
      ...tableData,
    ])
    const columnWidths = columnsWithoutActions.map((column) => ({
      wch: column.name.length + 2,
    }))
    worksheet['!cols'] = columnWidths
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Table Data')
    const excelFile = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
    const blob = new Blob([excelFile], { type: 'application/octet-stream' })
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = 'designations_data.csv'
    a.click()
    URL.revokeObjectURL(url)
  }

  return (
    <>
      <div className="main-content">
        <Header />
        <div className="app-menu navbar-menu" style={{ display: 'flex' }}>
          <Sidebar />
        </div>
        <div className="page-content">
          <PageHeader HeaderName="Masters > Add New Designations" />

          <div className="container-fluid pt-3">
            <div className="row">
              <div className="col-xl-12 col-md-12">
                <div className="card">
                  <div className="card-body">
                    <form
                      onSubmit={handleSubmit(addNewDesignation)}
                      onKeyDown={(e) => {
                        handleKeyDown(e, handleSubmit)
                      }}
                    >
                      <div className="row">
                        <div className="col-md-3 col-6">
                          <div>
                            <small>Designation Name</small>
                            <input
                              type="text"
                              className="form-control"
                              name="name"
                              {...register('name')}
                            />
                          </div>
                        </div>
                        <div className="col-md-2 col-6">
                          <small>Add Status</small>
                          <select
                            name="status"
                            className="form-select"
                            {...register('status')}
                            onChange={handleStatusChange}
                            value={selectedStatus}
                          >
                            <option value="">Select Status</option>
                            <option value="1">Active</option>
                            <option value="2">InActive</option>
                          </select>
                        </div>
                        <div className="col-md-2 col-6">
                          <div>
                            <small style={{ display: 'none' }}>ID</small>
                            <input
                              type="text"
                              className="form-control"
                              name="id"
                              {...register('id')}
                              style={{ display: 'none' }}
                            />
                          </div>
                        </div>
                        <div className="col-md-2 col-6">
                          <small style={{ display: 'none' }}>Created By</small>
                          <input
                            style={{ display: 'none' }}
                            type="text"
                            className="form-control"
                            name="created_by"
                            {...register('created_by')}
                          />
                        </div>
                        {/* <div className="col-md-3 col-6"></div> */}
                        <div className="col-md-2 text-end">
                          <small>&nbsp;</small>
                          <div className="col-md-12 text-end">
                            <button
                              type="submit"
                              className="btn btn-add waves-effect waves-light w_100"
                            >
                              {selectedDesignation ? 'Update' : 'Add'}
                            </button>
                            <small>&nbsp;</small>
                            <button
                              type="reset"
                              className="btn btn-warning waves-effect waves-light w_100"
                              onClick={() => {
                                setSelectedDesignation(null)
                              }}
                            >
                              Clear
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                {loading ? (
                  <p>Loading...</p>
                ) : designation.length === 0 ? (
                  <p>Data Not Found</p>
                ) : (
                  <>
                    <div className="card">
                      <div className="table-responsive">
                        <table className="table table-bordered table-striped m-0">
                          <DataTable
                            columns={columns}
                            data={designation}
                            pagination
                            customStyles={customStyles}
                            highlightOnHover
                            // noDataComponent="Loading..."
                            noDataComponent={
                              loading ? 'Loading...' : 'No data available'
                            }
                          />
                        </table>
                      </div>
                    </div>
                    <div className="col-md-12 text-end">
                      <div className="button-group">
                        <button
                          title="Download Excel"
                          className="button-excel-format"
                          onClick={handleDownloadExcel}
                        >
                          Excel
                        </button>
                        <button
                          title="Download CSV"
                          className="button-csv-format"
                          onClick={handleDownloadCSV}
                        >
                          CSV
                        </button>
                        <button
                          title="Download PDF"
                          className="button-pdf-format"
                          onClick={handleDownloadPDF}
                        >
                          PDF
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default AddNewDesignation
