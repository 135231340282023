import React from 'react';
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import PageHeader from "../PageHeader/PageHeader";
import TempleDetailsTabs from './TempleDetailsTabs';
import ViewFullSevaDetailsComponent from './ViewFullSevaDetailsComponent';


function ViewFullSevadetails() {
  return (
    <div>
    <div className="main-content">
              <Header />
              <div className="app-menu navbar-menu" style={{ display: 'flex' }}>
              <Sidebar />
              </div>
              <div className="page-content">
                  <PageHeader HeaderName="Dashboard " />
                  <div className='container'>
                  <div className='row mt-3 justify-content-between'>
                      <div className='col-md-12'>
                          <h4>Seva Details</h4>
                      </div>
                  </div>

                 <ViewFullSevaDetailsComponent />
               
                  </div>
              </div>
          </div>
  </div>
  )
}

export default ViewFullSevadetails
