import axios from 'axios'
const token = localStorage.getItem('token')
const apiBaseUrl = process.env.REACT_APP_API_BASEURL
const api = axios.create({
  baseURL: apiBaseUrl,
  headers: {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  },
})
const getToken = () => {
  return localStorage.getItem('token')
}

  export const GetAllSevaDetails = async () => {
    try {
      // const response = await api.get('temple-seva/getAllTempleSeva')
      const response = await api.get('temple-seva/getSevaInTemple')
      return response.data.map((item) => ({
        ...item,
        created_at: new Date(item.created_at).toLocaleDateString('en-GB'),
      }))
    } catch (error) {
      throw error
    }
  }

  export const CreateNewSevaDetails = async (requestData) => {
    try {
      const response = await api.post('temple-seva/create', requestData,
      {
        headers: {
          // Authorization: `Bearer ${getToken()}`,
          "Content-Type": "multipart/form-data",
        },

      })
      return response.data
    } catch (error) {

      throw error
    }
  }

  export const GetPackIds = async () => {
    try {
      const response = await api.get('admin/samagriKitList'); 
      return response.data;
    } catch (error) {
      throw error;
    }
  };

export const UpdateSevaDetails = async (requestData) => {
  try {
    const response = await api.post(
      `temple-seva/edit`,
      requestData,
      {
        headers: {
          // Authorization: `Bearer ${getToken()}`,
          'Content-Type': 'multipart/form-data',
        },
      },
    )
    return response.data
  } catch (error) {
    throw error
  }
}

  export const DeleteSevaDetails = async (id) => {
    try {
      const response = await api.delete(`temple-seva/delete/${id}`)
      return response.data
    } catch (error) {

      throw error
    }
  }