import { React, useState, useEffect, useMemo } from 'react'
import { Link } from 'react-router-dom'
import PageHeader from '../PageHeader/PageHeader'
import { useLocation } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import { useNavigate } from 'react-router-dom'
import * as XLSX from 'xlsx'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import Papa from 'papaparse'
import Header from '../Header/Header'
import Sidebar from '../Sidebar/Sidebar'
import {
  getDonationsById,
  getPandalBankDetailsById,
  getPandalMemberById,
  getPandalPostById,
  getPandalSevaById,
} from '../API/Api'
import axios from 'axios'
import { toast } from 'react-toastify'
import Footer from '../Footer/Footer'

function PandalDetails() {
  const token = localStorage.getItem('token')
  const location = useLocation()
  const receivedData = location.state
  const [data, setData] = useState([])
  const [memberData, setMemberData] = useState([])
  const [donationData, setDonationData] = useState([])
  const [pandalPostData, setPandalPostData] = useState([])
  const [bankData, setBankData] = useState([])
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)

  const clickSevaDetails = (row) => {
    navigate(`/seva-details`, { state: row })
  }

  const onPostButton = (row) => {
    navigate('/view-post', { state: row })
  }
  const id = receivedData?.pandal_id
  // console.log(location, 'iddd')
  // console.log(receivedData, 'receivedData')
  const downloadAsPDF = () => {
    const columnsForPDF = [...columns3]
    columnsForPDF.pop()
    const doc = new jsPDF()
    const pdfData = []
    donationData.forEach((row, index) => {
      pdfData.push([
        index + 1,
        row.donation_name,
        row.description,
        row.mobile,
        row.date,
        row.donation_amount,

        // Add the image name to the row data
      ])
    })

    doc.autoTable({
      head: [columns3.map((column) => column.name)],
      body: pdfData,
      showHead: 'firstPage',
    })
    doc.save('table_data.pdf')
  }

  const downloadAsExcel = () => {
    const excelData = donationData.map((item, index) => ({
      'S.No': index + 1,
      'Donor Name': item.donation_name,
      Description: item.description,
      'Phone Number': item.mobile,
      'Date Of Donation': item.date,
      'Donation Amount': item.donation_amount,
      // Age: item.age,
    }))

    const worksheet = XLSX.utils.json_to_sheet(excelData)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
    XLSX.writeFile(workbook, 'table_data.xlsx')
  }

  const handleDownloadCSV = () => {
    const csvData = donationData.map((item, index) => ({
      'S.No': index + 1,
      'Donor Name': item.donation_name,
      Description: item.description,
      'Phone Number': item.mobile,
      'Date Of Donation': item.date,
      'Donation Amount': item.donation_amount,
    }))

    const csv = Papa.unparse(csvData)
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.setAttribute('href', url)
    link.setAttribute('download', 'table_data.csv')
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const sevaData = async () => {
    try {
      const data = await getPandalSevaById(id)
      setData(data)
      setLoading(false)
    } catch (error) {
      // console.error('Error fetching data:', error)
      setLoading(false)
    }
  }

  // const sevaData = async () => {
  //   try {
  //     const url =
  //       process.env.REACT_APP_API_BASEURL + `api/seva/getByPandalId/${id}`
  //     const headers = {
  //       Authorization: `Bearer ${token}`,
  //       'Content-Type': 'application/json',
  //     }

  //     const response = await fetch(url, { headers })
  //     const data = await response.json()
  //     setData(data)
  //   } catch (error) {
  //     console.error('Error fetching data:', error)
  //   }
  // }

  const membersData = async () => {
    try {
      const data = await getPandalMemberById(id)
      setMemberData(data)
      setLoading(false)
    } catch (error) {
      // console.error('Error fetching data:', error)
      setLoading(false)
    }
  }

  // const membersData = async () => {
  //   try {
  //     const url =
  //       process.env.REACT_APP_API_BASEURL + `api/member/getByPandalId/${id}`
  //     const headers = {
  //       Authorization: `Bearer ${token}`,
  //       'Content-Type': 'application/json',
  //     }

  //     const response = await fetch(url, { headers })
  //     const data = await response.json()
  //     setMemberData(data)
  //   } catch (error) {
  //     console.error('Error fetching data:', error)
  //   }
  // }

  const banksData = async () => {
    try {
      const data = await getPandalBankDetailsById(id)
      setBankData(data)
      setLoading(false)
    } catch (error) {
      // console.error('Error fetching data:', error)
      setLoading(false)
    }
  }

  // const banksData = async () => {
  //   try {
  //     const url =
  //       process.env.REACT_APP_API_BASEURL + `api/banks/getByPandalId/${id}`
  //     const headers = {
  //       Authorization: `Bearer ${token}`,
  //       'Content-Type': 'application/json',
  //     }

  //     const response = await fetch(url, { headers })
  //     const data = await response.json()
  //     setBankData(data)
  //   } catch (error) {
  //     console.error('Error fetching data:', error)
  //   }
  // }

  const pandalPostFetchData = async () => {
    try {
      const data = await getPandalPostById(id)
      setPandalPostData(data)
      setLoading(false)
    } catch (error) {
      // console.error('Error fetching data:', error)
      setLoading(false)
    }
  }

  // const pandalPostFetchData = async () => {
  //   try {
  //     const url =
  //       process.env.REACT_APP_API_BASEURL + `api/posts/getByPandalId/${id}`
  //     const headers = {
  //       Authorization: `Bearer ${token}`,
  //       'Content-Type': 'application/json',
  //     }

  //     const response = await fetch(url, { headers })
  //     const data = await response.json()
  //     setPandalPostData(data)
  //   } catch (error) {
  //     console.error('Error fetching data:', error)
  //   }
  // }

  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split('-')
    return `${day}-${month}-${year}`
  }

  const donationsData = async () => {
    try {
      const modifiedData = await getDonationsById(id)
      setDonationData(modifiedData)
      setLoading(false)
    } catch (error) {
      // console.error('Error fetching data:', error)
      setLoading(false)
    }
  }

  // const donationsData = async () => {
  //   try {
  //     const url =
  //       process.env.REACT_APP_API_BASEURL + `api/donation/getByPandalId/${id}`
  //     const headers = {
  //       Authorization: `Bearer ${token}`,
  //       'Content-Type': 'application/json',
  //     }

  //     const response = await fetch(url, { headers })
  //     const data = await response.json()

  //     const modifiedData = data.map((item) => ({
  //       ...item,
  //       // Assuming your API response has a 'date' property in ISO 8601 format (e.g., "2023-07-19T12:34:56.789Z")
  //       date: new Date(item?.created_at).toLocaleDateString('en-GB'),
  //     }))
  //     setDonationData(modifiedData)
  //   } catch (error) {
  //     console.error('Error fetching data:', error)
  //   }
  // }

  const onDeleteData = async (id) => {
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    }
    try {
      await axios.get(
        process.env.REACT_APP_API_BASEURL + `api/posts/softDelete/${id}`,
        { headers },
      )
      const successMessage = 'Post Deleted'
      toast.success(successMessage, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      pandalPostFetchData()
    } catch (error) {
      toast.error(error.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  const formatDate2 = (dateString) => {
    const [year, month, day] = dateString.split('-')
    return `${day}-${month}-${year}`
  }

  const columns = [
    {
      name: 'Sl.No',
      selector: 'slno',
      sortable: true,
      format: (row, rowIndex) => rowIndex + 1,
    },
    {
      name: 'Seva Name',
      selector: 'seva_name',
      sortable: true,
    },
    // {
    //   name: 'Image',
    //   cell: (row) =>
    //     <img key={row} src={row?.images?.image} alt={row.name} style={{ width: '50px', height: '50px', marginRight: '5px' }} />
    // },
    {
      name: 'Description',
      selector: 'about_seva',
      sortable: true,
    },
    // {
    //   name: 'Location',
    //   selector: 'pandal_address',
    //   sortable: true,
    // },
    {
      name: 'Date Of Event',
      // selector: 'save_start_date',
      cell: (row) => formatDate(row?.save_start_date),
      sortable: true,
    },
    {
      name: 'Actions',
      cell: (row) => (
        <div>
          <button
            class="btn btn-sm btn-edit me-2"
            onClick={() => clickSevaDetails(row)}
          >
            View Full Details
          </button>
        </div>
      ),
    },
  ]

  const downloadAsPDF2 = () => {
    const columnsForPDF = [...columns]
    columnsForPDF.pop()
    const doc = new jsPDF()
    const pdfData = []
    data.forEach((row, index) => {
      pdfData.push([
        index + 1,
        row.seva_name,
        row.about_seva,
        formatDate(row?.save_start_date),

        // Add the image name to the row data
      ])
    })

    doc.autoTable({
      head: [columnsForPDF.map((column) => column.name)],
      body: pdfData,
      showHead: 'firstPage',
    })
    doc.save('table_data.pdf')
  }

  const downloadAsExcel2 = () => {
    const excelData = data.map((item, index) => ({
      'S.No': index + 1,
      'Seva Name': item.seva_name,
      Description: item.about_seva,
      'Date Of Event': formatDate(item?.save_start_date),
    }))

    const worksheet = XLSX.utils.json_to_sheet(excelData)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
    XLSX.writeFile(workbook, 'table_data.xlsx')
  }

  const handleDownloadCSV2 = () => {
    const csvData = data.map((item, index) => ({
      'S.No': index + 1,
      'Seva Name': item.seva_name,
      Description: item.about_seva,
      'Date Of Event': formatDate(item?.save_start_date),
    }))

    const csv = Papa.unparse(csvData)
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.setAttribute('href', url)
    link.setAttribute('download', 'table_data.csv')
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const columns2 = [
    {
      name: 'Sl.No',
      selector: 'slno',
      sortable: true,
      format: (row, rowIndex) => rowIndex + 1,
    },
    {
      name: 'Name',
      selector: 'member_name',
      sortable: true,
    },
    {
      name: 'Designation',
      selector: 'about',
      sortable: true,

      // cell: (row) =>
      // <img key={row} src={row?.images[0]?.image} alt={row.name} style={{ width: '50px', height: '50px', marginRight: '5px' }} />
    },
    {
      name: 'Phone Number',
      selector: 'member_mobile_no',
      sortable: true,
    },
    // {
    //   name: 'Email ID',
    //   selector: 'pandal_address',
    //   sortable: true,
    // },
    // {
    //   name: 'Pandal Name',
    //   // selector: 'save_start_date',
    //   cell: (row) => formatDate(row.save_start_date),
    //   sortable: true,
    // },
    // {
    //   name: 'Address',
    //   cell: (row) => (
    //     <div>

    //       <button class="btn btn-sm btn-edit me-2" onClick={()=>(clickSevaDetails(row))}>
    //        View Full Details
    //       </button>

    //     </div>
    //   ),
    // },
  ]

  const downloadAsPDF4 = () => {
    const columnsForPDF = [...columns3]
    columnsForPDF.pop()
    const doc = new jsPDF()
    const pdfData = []
    memberData.forEach((row, index) => {
      pdfData.push([
        index + 1,
        row.member_name,
        row.about,
        row.member_mobile_no,

        // Add the image name to the row data
      ])
    })

    doc.autoTable({
      head: [columns2.map((column) => column.name)],
      body: pdfData,
      showHead: 'firstPage',
    })
    doc.save('table_data.pdf')
  }

  const downloadAsExcel4 = () => {
    const excelData = memberData.map((item, index) => ({
      'S.No': index + 1,
      Name: item.member_name,
      Designation: item.about,
      'Phone Number': item.member_mobile_no,
    }))

    const worksheet = XLSX.utils.json_to_sheet(excelData)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
    XLSX.writeFile(workbook, 'table_data.xlsx')
  }

  const handleDownloadCSV4 = () => {
    const csvData = memberData.map((item, index) => ({
      'S.No': index + 1,
      Name: item.member_name,
      Designation: item.about,
      'Phone Number': item.member_mobile_no,
    }))

    const csv = Papa.unparse(csvData)
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.setAttribute('href', url)
    link.setAttribute('download', 'table_data.csv')
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const columns3 = [
    {
      name: 'Sl.No',
      selector: 'slno',
      sortable: true,
      format: (row, rowIndex) => rowIndex + 1,
    },
    {
      name: 'Donor Name',
      selector: 'donation_name',
      sortable: true,
    },
    {
      name: 'Description',
      selector: 'description',
      sortable: true,

      // cell: (row) =>
      // <img key={row} src={row?.images[0]?.image} alt={row.name} style={{ width: '50px', height: '50px', marginRight: '5px' }} />
    },
    {
      name: 'Phone Number',
      selector: 'mobile',
      sortable: true,
    },
    {
      name: 'Date of Donation',
      selector: 'date',
      sortable: true,
    },
    {
      name: 'Donation Amount',
      selector: 'donation_amount',
      sortable: true,
    },
  ]
  const columns4 = [
    {
      name: 'Sl.No',
      selector: 'slno',
      sortable: true,
      format: (row, rowIndex) => rowIndex + 1,
    },
    {
      name: 'Post Name',
      selector: 'name',
      sortable: true,
    },
    {
      name: 'Post/Feed',
      selector: 'description',
      sortable: true,

      // cell: (row) =>
      // <img key={row} src={row?.images[0]?.image} alt={row.name} style={{ width: '50px', height: '50px', marginRight: '5px' }} />
    },
    {
      name: 'Actions',
      cell: (row) => (
        <div>
          <button
            class="btn btn-sm btn-edit me-2"
            onClick={() => onPostButton(row)}
          >
            <i className="fa-regular fa-eye"></i>{' '}
          </button>
          <button
            class="btn btn-sm btn-delete"
            onClick={() => onDeleteData(row.id)}
          >
            <i className="fa-solid fa-trash-can"></i>
          </button>
        </div>
      ),
    },
    //   {
    //   name: 'Image',
    //   cell: (row) =>
    //     <img key={row} src={row?.images[0]?.image} style={{ width: '50px', height: '50px', marginRight: '5px' }} />
    // },
  ]

  const downloadAsPDF3 = () => {
    const columnsForPDF = [...columns4]
    columnsForPDF.pop()
    const doc = new jsPDF()
    const pdfData = []
    pandalPostData.forEach((row, index) => {
      pdfData.push([
        index + 1,
        row.name,
        row.description,

        // Add the image name to the row data
      ])
    })

    doc.autoTable({
      head: [columnsForPDF.map((column) => column.name)],
      body: pdfData,
      showHead: 'firstPage',
    })
    doc.save('table_data.pdf')
  }

  const downloadAsExcel3 = () => {
    const excelData = pandalPostData.map((item, index) => ({
      'S.No': index + 1,
      'Post Name': item.name,
      'Post/Feed': item.description,
    }))

    const worksheet = XLSX.utils.json_to_sheet(excelData)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
    XLSX.writeFile(workbook, 'table_data.xlsx')
  }

  const handleDownloadCSV3 = () => {
    const csvData = pandalPostData.map((item, index) => ({
      'S.No': index + 1,
      'Post Name': item.name,
      'Post/Feed': item.description,
    }))

    const csv = Papa.unparse(csvData)
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.setAttribute('href', url)
    link.setAttribute('download', 'table_data.csv')
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const columns5 = [
    {
      name: 'Sl.No',
      selector: 'slno',
      sortable: true,
      format: (row, rowIndex) => rowIndex + 1,
    },
    {
      name: 'Bank Name',
      selector: 'bank_name',
      sortable: true,
    },
    {
      name: 'Account Name',
      selector: 'account_name',
      sortable: true,

      // cell: (row) =>
      // <img key={row} src={row?.images[0]?.image} alt={row.name} style={{ width: '50px', height: '50px', marginRight: '5px' }} />
    },
    {
      name: 'Account Number',
      selector: 'account_number',
      sortable: true,
    },
    {
      name: 'IFSC Code',
      selector: 'ifsc_code',
      sortable: true,
    },
    {
      name: 'Bank Address',
      selector: 'address',
      sortable: true,
    },
  ]

  const downloadAsPDF1 = () => {
    const columnsForPDF = [...columns3]
    columnsForPDF.pop()
    const doc = new jsPDF()
    const pdfData = []
    bankData.forEach((row, index) => {
      pdfData.push([
        index + 1,
        row.bank_name,
        row.account_name,
        row.account_number,
        row.ifsc_code,
        row.address,

        // Add the image name to the row data
      ])
    })

    doc.autoTable({
      head: [columns5.map((column) => column.name)],
      body: pdfData,
      showHead: 'firstPage',
    })
    doc.save('table_data.pdf')
  }

  const downloadAsExcel1 = () => {
    const excelData = bankData.map((item, index) => ({
      'S.No': index + 1,
      'Bank Name': item.bank_name,
      'Account Name': item.account_name,
      'Account Number': item.account_number,
      'IFSC Code': item.ifsc_code,
      'Bank Address': item.address,
    }))

    const worksheet = XLSX.utils.json_to_sheet(excelData)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
    XLSX.writeFile(workbook, 'table_data.xlsx')
  }

  const handleDownloadCSV1 = () => {
    const csvData = bankData.map((item, index) => ({
      'S.No': index + 1,
      'Bank Name': item.bank_name,
      'Account Name': item.account_name,
      'Account Number': item.account_number,
      'IFSC Code': item.ifsc_code,
      'Bank Address': item.address,
    }))

    const csv = Papa.unparse(csvData)
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.setAttribute('href', url)
    link.setAttribute('download', 'table_data.csv')
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const columns6 = [
    {
      name: 'Sl.No',
      selector: 'slno',
      sortable: true,
      format: (row, rowIndex) => rowIndex + 1,
    },
    {
      name: 'Name Of Pandal',
      selector: 'donation_name',
      sortable: true,
    },
    {
      name: 'Description',
      selector: 'description',
      sortable: true,

      // cell: (row) =>
      // <img key={row} src={row?.images[0]?.image} alt={row.name} style={{ width: '50px', height: '50px', marginRight: '5px' }} />
    },
    {
      name: 'Phone Number',
      selector: 'mobile',
      sortable: true,
    },
    {
      name: 'Date of Donation',
      selector: 'date',
      sortable: true,
    },
    {
      name: 'Donation Amount',
      selector: 'donation_amount',
      sortable: true,
    },
  ]

  const customStyles = {
    rows: {
      style: {
        // minHeight: '72px', // override the row height
        border: '0.1px solid #ddd',
      },
    },
    headCells: {
      style: {
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '8px',
        color: 'black',
        fontSize: '14px',
        backgroundColor: '#ccc',
        fontFamily: 'Poppins',
        fontWeight: '600',
        // fontSize:'10px'
        border: '0.1px solid #ddd',
      },
    },
    cells: {
      style: {
        paddingLeft: '8px', // override the cell padding for data cells
        paddingRight: '8px',
        border: '0.1px solid #ddd',
      },
    },
  }

  useEffect(() => {
    sevaData()
    membersData()
    donationsData()
    pandalPostFetchData()
    banksData()
  }, [])

  return (
    <>
      <div className="main-content">
        <Header />
        <div className="app-menu navbar-menu" style={{ display: 'flex' }}>
          <Sidebar />
        </div>
        <div className="page-content">
          <PageHeader HeaderName="Pandal Full Details" />
          <div className="container-fluid pt-3">
            <div className="row">
              <div className="col-xl-12 col-md-12">
                <div className="card">
                  <div className="card-body">
                    <ul className="nav nav-tabs">
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          data-bs-toggle="tab"
                          href="#one"
                        >
                          Pandals Details
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#two"
                        >
                          Bank Details Of Pandal
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#three"
                        >
                          Payments to Pandal
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#four"
                        >
                          Donations Received
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#five"
                        >
                          Sevas
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#six"
                        >
                          Pandal Posts
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#seven"
                        >
                          Members
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <h5 className="text-end">
                  Pandal Name : {receivedData?.pandal_name}
                </h5>
                {/* <!-- Tab panes --> */}
                <div className="tab-content">
                  <div className="tab-pane active" id="one">
                    <div className="card card-body">
                      <div className="row">
                        <div className="col-md-9 mb-3">
                          <div className="row">
                            <div className="col-md-6 mb-3">
                              <small>Pandal Name</small>
                              <input
                                type="text"
                                className="form-control"
                                name="pandal_name"
                                value={receivedData?.pandal_name}
                                id=""
                              />
                            </div>
                            <div className="col-md-6 mb-3">
                              <small>Pandal Created Date</small>
                              <input
                                type="date"
                                className="form-control"
                                value={receivedData?.pandal_start_date}
                                name=""
                                id=""
                              />
                            </div>
                            <div className="col-md-12 mb-3">
                              <small>Address</small>
                              <textarea
                                className="form-control"
                                rows="4"
                                value={receivedData?.pandal_address}
                              ></textarea>
                            </div>
                            <div className="col-md-12 mb-3">
                              <small>About Pandal</small>
                              <textarea
                                className="form-control"
                                rows="4"
                                value={receivedData?.pandal_about}
                              ></textarea>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 mb-3">
                          <img
                            src={receivedData?.images[0]?.image}
                            style={{ width: '100%' }}
                            className="img-fluid mt-3"
                          />
                        </div>
                        <div className="col-md-12 mb-3">
                          <small>History</small>
                          <textarea
                            className="form-control"
                            rows="6"
                            value={receivedData?.pandal_history}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="two">
                    <div className="card card-body">
                      <div className="row mb-3">
                        {loading ? (
                          <p>Loading...</p>
                        ) : bankData.length === 0 ? (
                          <p>Data Not Found</p>
                        ) : (
                          <>
                            <div className="col-md-12">
                              <DataTable
                                pagination
                                customStyles={customStyles}
                                data={bankData}
                                columns={columns5}
                                highlightOnHover
                                noDataComponent="Loading..."
                              />
                            </div>
                            <div className="col-md-12 text-end">
                              <div className="button-group">
                                <button
                                  title="Download Excel"
                                  className="button-excel-format"
                                  onClick={downloadAsExcel1}
                                >
                                  Excel
                                </button>
                                <button
                                  title="Download CSV"
                                  className="button-csv-format"
                                  onClick={handleDownloadCSV1}
                                >
                                  CSV
                                </button>
                                <button
                                  title="Download PDF"
                                  className="button-pdf-format"
                                  onClick={downloadAsPDF1}
                                >
                                  PDF
                                </button>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="three">
                    <div className="card card-body">
                      <div className="col-md-6 ms-auto text-end mb-3">
                        <Link to="/payment" className="btn btn-success ">
                          Make a Payment For This Pandal
                        </Link>
                      </div>
                      <div className="col-md-12 table-responsive">
                        <table className="table table-bordered table-striped">
                          <thead style={{ background: '#ccc' }}>
                            <tr>
                              <th>S.No</th>
                              <th>Name of Pandal</th>
                              <th>Payment Created for</th>
                              <th>Payment Created Date</th>
                              <th>Payment Amount</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>1</td>
                              <td>Ganesh</td>
                              <td>Ganesh Idol</td>
                              <td>15-06-2023</td>
                              <td>45000/-</td>
                              <td>
                                <div className="d-flex">
                                  <button className="btn btn-sm btn-edit me-2">
                                    <i className="fa-regular fa-pen-to-square"></i>{' '}
                                  </button>
                                  <button className="btn btn-sm btn-delete">
                                    <i className="fa-solid fa-trash-can"></i>
                                  </button>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>2</td>
                              <td>Ganesh</td>
                              <td>Ganesh Idol</td>
                              <td>15-06-2023</td>
                              <td>45000/-</td>
                              <td>
                                <div className="d-flex">
                                  <button className="btn btn-sm btn-edit me-2">
                                    <i className="fa-regular fa-pen-to-square"></i>{' '}
                                  </button>
                                  <button className="btn btn-sm btn-delete">
                                    <i className="fa-solid fa-trash-can"></i>
                                  </button>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>3</td>
                              <td>Ganesh</td>
                              <td>Ganesh Idol</td>
                              <td>15-06-2023</td>
                              <td>45000/-</td>
                              <td>
                                <div className="d-flex">
                                  <button className="btn btn-sm btn-edit me-2">
                                    <i className="fa-regular fa-pen-to-square"></i>{' '}
                                  </button>
                                  <button className="btn btn-sm btn-delete">
                                    <i className="fa-solid fa-trash-can"></i>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="four">
                    <div className="card card-body">
                      {loading ? (
                        <p>Loading...</p>
                      ) : donationData.length === 0 ? (
                        <p>Data Not Found</p>
                      ) : (
                        <>
                          <div className="col-md-12 table-responsive">
                            <DataTable
                              pagination
                              customStyles={customStyles}
                              data={donationData}
                              columns={columns3}
                              highlightOnHover
                              noDataComponent="Loading..."
                            />
                          </div>
                          <div className="col-md-12 text-end">
                            <div className="button-group">
                              <button
                                title="Download Excel"
                                className="button-excel-format"
                                onClick={downloadAsExcel}
                              >
                                Excel
                              </button>
                              <button
                                title="Download CSV"
                                className="button-csv-format"
                                onClick={handleDownloadCSV}
                              >
                                CSV
                              </button>
                              <button
                                title="Download PDF"
                                className="button-pdf-format"
                                onClick={handleDownloadCSV}
                              >
                                PDF
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="tab-pane fade" id="five">
                    <div className="card card-body">
                      {loading ? (
                        <p>Loading...</p>
                      ) : data.length === 0 ? (
                        <p>Data Not Found</p>
                      ) : (
                        <>
                          <div className="col-md-12 table-responsive">
                            <DataTable
                              columns={columns}
                              data={data}
                              pagination
                              customStyles={customStyles}
                              highlightOnHover
                              noDataComponent="Loading..."
                            />
                          </div>
                          <div className="col-md-12 text-end">
                            <div className="button-group">
                              <button
                                title="Download Excel"
                                className="button-excel-format"
                                onClick={downloadAsExcel2}
                              >
                                Excel
                              </button>
                              <button
                                title="Download CSV"
                                className="button-csv-format"
                                onClick={handleDownloadCSV2}
                              >
                                CSV
                              </button>
                              <button
                                title="Download PDF"
                                className="button-pdf-format"
                                onClick={downloadAsPDF2}
                              >
                                PDF
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="tab-pane fade" id="six">
                    <div className="card card-body">
                      {loading ? (
                        <p>Loading...</p>
                      ) : pandalPostData.length === 0 ? (
                        <p>Data Not Found</p>
                      ) : (
                        <>
                          <div className="col-md-12 table-responsive">
                            <DataTable
                              columns={columns4}
                              data={pandalPostData}
                              pagination
                              customStyles={customStyles}
                              highlightOnHover
                              noDataComponent="Loading..."
                            />
                          </div>
                          <div className="col-md-12 text-end">
                            <div className="button-group">
                              <button
                                title="Download Excel"
                                className="button-excel-format"
                                onClick={downloadAsExcel3}
                              >
                                Excel
                              </button>
                              <button
                                title="Download CSV"
                                className="button-csv-format"
                                onClick={handleDownloadCSV3}
                              >
                                CSV
                              </button>
                              <button
                                title="Download PDF"
                                className="button-pdf-format"
                                onClick={downloadAsPDF3}
                              >
                                PDF
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="tab-pane fade" id="seven">
                    <div className="card card-body">
                      {loading ? (
                        <p>Loading...</p>
                      ) : memberData.length === 0 ? (
                        <p>Data Not Found</p>
                      ) : (
                        <>
                          <div className="col-md-12 table-responsive">
                            <DataTable
                              pagination
                              customStyles={customStyles}
                              data={memberData}
                              columns={columns2}
                              highlightOnHover
                              noDataComponent="Loading..."
                            />
                            <div className="col-md-12 text-end">
                              <div className="button-group">
                                <button
                                  title="Download Excel"
                                  className="button-excel-format"
                                  onClick={downloadAsExcel4}
                                >
                                  Excel
                                </button>
                                <button
                                  title="Download CSV"
                                  className="button-csv-format"
                                  onClick={handleDownloadCSV4}
                                >
                                  CSV
                                </button>
                                <button
                                  title="Download PDF"
                                  className="button-pdf-format"
                                  onClick={downloadAsPDF4}
                                >
                                  PDF
                                </button>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
       
      </div>
      <Footer />
    </>
  )
}

export default PandalDetails
