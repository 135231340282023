import React from 'react'
import './SponsorDetail.css'
import PageHeader from '../PageHeader/PageHeader'
import { useLocation } from 'react-router-dom'
import Header from '../Header/Header'
import Sidebar from '../Sidebar/Sidebar'
import Footer from '../Footer/Footer'

function SponsorDetail() {
  const location = useLocation()
  const receivedData = location.state
  return (
    <>
      <div className="main-content">
        <Header />
        <div className="app-menu navbar-menu" style={{ display: 'flex' }}>
          <Sidebar />
        </div>
        <div className="page-content">
          <PageHeader HeaderName="Sponsors Full Details" />

          <div className="container-fluid pt-3">
            <h4>Sponsors Enquiry Received</h4>
            <div className="row">
              <div className="col-xl-12 col-md-12">
                <div className="tab-content">
                  <div className="tab-pane active" id="one">
                    <div className="card card-body">
                      <div className="row">
                        <div className="col-md-9 mb-3">
                          <div className="row">
                            <div className="col-md-6 mb-3">
                              <small>Sponsor Name</small>
                              <input
                                type="text"
                                className="form-control"
                                value={receivedData?.full_name}
                                readOnly
                              />
                            </div>
                            <div className="col-md-6 mb-3">
                              <small>Sponsor Company Name</small>
                              <input
                                type="text"
                                className="form-control"
                                value={receivedData?.brand_name}
                                readOnly
                              />
                            </div>
                            <div className="col-md-6 mb-3">
                              <small>Mobile Number </small>
                              <input
                                type="tel"
                                className="form-control"
                                value={receivedData?.mobile}
                                readOnly
                              />
                            </div>
                            <div className="col-md-6 mb-3">
                              <small>sample </small>
                              <input
                                type="tel"
                                className="form-control"
                                value={receivedData?.package}
                                readOnly
                              />
                            </div>

                            <div className="col-md-12 mb-3">
                              <small>Message From Sponsor</small>
                              <textarea
                                className="form-control"
                                rows="4"
                                value={receivedData?.enquiry_message}
                                readOnly
                              ></textarea>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 mb-3">
                          <small>Brand Logo Uploaded</small>
                          <div className="col-md-12">
                            <img
                              src={receivedData?.images[0].image}
                              className="img-fluid mt-2"
                              alt="pujam_pandal"
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default SponsorDetail
