import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Dropdown, DropdownButton } from 'react-bootstrap';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [username, setUsername] = useState('');
  const navigate = useNavigate();

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    // Reset errors
    setEmailError('');
    setPasswordError('');

    let isValid = true;

    // Validate email
    if (!email) {
      setEmailError('Email is required.');
      isValid = false;
    } else if (!validateEmail(email)) {
      setEmailError('Please enter a valid email address.');
      isValid = false;
    }

    // Validate password
    if (!password) {
      setPasswordError('Password is required.');
      isValid = false;
    } else if (password.length < 6) {
      setPasswordError('Password must be at least 6 characters long.');
      isValid = false;
    }

    if (!isValid) {
      return;
    }

    try {
      const url = 'https://gowinda.in/api/api/login-user';
      // const url = 'http://192.168.1.81:8000/api/login-user';
      const data = { email, password };
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });
      const responseData = await response.json();
      const { token, user_id, user_type } = responseData.data;

      if (token) {
        localStorage.setItem('token', token);
        localStorage.setItem('user_id', user_id);
        localStorage.setItem('user_type', user_type);

        const successMessage = 'LoggedIn Successfully';
        toast.success(successMessage, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        const userDetailsUrl = 'https://gowinda.in/api/api/user/user-details';
        const userDetailsResponse = await fetch(userDetailsUrl, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });
        const userDetailsData = await userDetailsResponse.json();
        setUsername(userDetailsData.data.username); // Assuming 'username' is the field name


        if (user_type === '5') navigate('/temple-dashboard');
        else if (user_type === '7') navigate('/pujari-dashboard');
        else navigate('/dashboard');
      } else {
        const errorMessage = responseData.data.error;
        if (errorMessage) {
          toast.error(errorMessage, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
    } catch (error) {
      console.error('Error during login:', error);
    }
  };

  const handleCreateAccount = (path) => {
    navigate(path);
  };

  return (
    <>
      <div id="main-wrapper" className="oxyy-login-register">
        <div className="container-fluid px-0">
          <div className="row g-0 min-vh-100">
            <div className="col-md-8 ">
              <div className="hero-wrap d-flex align-items-center h-100">
                <div className="hero-mask "></div>
                <div className="hero-bg hero-bg-scroll"></div>
                <div
                  className="hero-content mx-auto w-100 h-100 d-flex flex-column"
                  style={{
                    backgroundImage: "url('/assets/images/Rectangle1.jpg')",
                  }}
                ></div>
              </div>
            </div>

            <div className="col-md-4 d-flex flex-column ">
              <div className="container my-auto py-5">
                <div className="row mx-0">
                  <div className="col-11 col-lg-9 col-xl-8 mx-auto text-center">
                    <div className="logo mb-4">
                      <a href="index.html" title="Oxyy">
                        <img
                          src="/assets/images/pandal-logo.svg"
                          alt="pujam_pandal"
                          width="50%"
                        />
                      </a>
                    </div>
                    <form id="" method="post" onSubmit={handleLogin}>
                      <div className="vertical-input-group">
                        <div className="input-group mb-3">
                          <input
                            type="email"
                            className="form-control"
                            id="emailAddress"
                            required=""
                            placeholder="Email or Username"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                        {emailError && <p className="text-danger">{emailError}</p>}
                        <div className="input-group mb-3">
                          <input
                            type="password"
                            className="form-control"
                            id="loginPassword"
                            required=""
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </div>
                        {passwordError && <p className="text-danger">{passwordError}</p>}
                      </div>
                      <div className="d-grid my-4">
                        <button
                          style={{
                            backgroundColor: '#8b0502',
                            height: '50px',
                            color: '#fff',
                            border: 'none',
                            borderRadius: '5px',
                            fontFamily: 'Roboto',
                          }}
                          type="submit"
                        >
                          LOGIN
                        </button>
                        {username && (
                          <p className="mt-3">
                            Welcome, <strong>{username}</strong>!
                          </p>
                        )}
                        <div
                          className="mb-3"
                          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        >
                          <Link
                            style={{
                              color: 'black',
                              textDecoration: 'underline',
                              cursor: 'pointer',
                              fontFamily: 'Roboto',
                            }}
                          >
                            NEW USER?
                          </Link>
                          {' '}|{' '}
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="link"
                              id="dropdown-link"
                              style={{
                                color: 'blue',
                                textDecoration: 'underline',
                                cursor: 'pointer',
                                fontFamily: 'Roboto',
                              }}
                            >
                              Register here
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item onClick={() => handleCreateAccount('/temple-registration')}>
                                Temple Registration
                              </Dropdown.Item>
                              <Dropdown.Item onClick={() => handleCreateAccount('/pujariRegisteration')}>
                                Pujari Registration
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
