import { useEffect, useState } from 'react'
import PageHeader from '../PageHeader/PageHeader'
import { useLocation, useNavigate } from 'react-router-dom'
import Modal from 'react-modal'
import './validation.css'
import Header from '../Header/Header'
import Sidebar from '../Sidebar/Sidebar'
import {
  acceptValidationDetail,
  getValidationPermissionDataById,
  getValidationRejectData,
} from '../API/Api'
import { toast } from 'react-toastify'
import Footer from '../Footer/Footer'
 
function ValidationDetail() {
  const location = useLocation()
  const receivedData = location.state
  const id = receivedData?.pandal_id
  const newImage = receivedData?.images?.[0]?.image;

  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [open1, setOpen1] = useState(false)
  const [open2, setOpen2] = useState(false)
  const [open3, setOpen3] = useState(false)
  const [open4, setOpen4] = useState(false)
  const [reject, setReject] = useState(false)
  const [error, setError] = useState({})
  const [permissionData, setPandalPermissionData] = useState([])
  const [text, setText] = useState('')
  const policeLetter = permissionData?.police_letter
  const presidentId = permissionData?.president_id
  const vicePresident = permissionData?.vice_president_id
  const financeHead = permissionData?.finance_head_id
  const [startDate, setStartDate] = useState(receivedData?.pandal_start_date);
  const [endDate, setEndDate] = useState(receivedData?.pandal_end_date);

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClickOpen1 = () => {
    setOpen1(true)
  }
  const handleClickOpen2 = () => {
    setOpen2(true)
  }
  const handleClickOpen3 = () => {
    setOpen3(true)
  }
  const handleClickOpen4 = () => {
    setOpen4(true)
  }

  const onApproveData = async (id) => {
    // const headers = {
    //   Authorization: Bearer ${token},
    //   'Content-Type': 'application/json',
    // }
    try {
      await acceptValidationDetail(id)
      // await axios.get(
      //   process.env.REACT_APP_API_BASEURL + api/pandal/approvePandal/${id},
      //   { headers },
      // )
      pandalPermissionData()
      navigate('/validation')
    } catch (error) {
      console.error('Error deleting data:', error)
    }
  }

  const handleChangeStartDate = (date) => {
    setStartDate(date);
  };

  const handleChangeEndDate = (date) => {
    setEndDate(date);
  };


  function closeModal() {
    setOpen(false)
    setOpen1(false)
    setOpen2(false)
    setOpen3(false)
    setOpen4(false)
  }

  const pandalPermissionData = async () => {
    try {
      const data = await getValidationPermissionDataById(id)
      console.log(data, 'data from validation details')
      setPandalPermissionData(data)
    } catch (error) {
      console.error('Error fetching table data:', error)
    }
  }
  // const pandalPermissionData = async () => {
  //   try {
  //     const url =
  //       process.env.REACT_APP_API_BASEURL +
  //       api/pandalpermission/getByPandalId/${id}
  //     const headers = {
  //       Authorization: Bearer ${token},
  //       'Content-Type': 'application/json',
  //     }

  //     const response = await fetch(url, { headers })
  //     const data = await response.json()
  //     console.log(data)
  //     setPandalPermissionData(data)
  //   } catch (error) {
  //     console.error('Error fetching data:', error)
  //   }
  // }

  const handleChange = (event) => {
    const { value } = event.target
    setText(value)
  }

  const backPage = () => {
    navigate('/validation')
  }

  const onRejectData = async () => {
    try {
      const bookingData = {
        pandal_id: id,
        reject_reason: text,
      }
      await getValidationRejectData(bookingData)
      pandalPermissionData()
      navigate('/validation')
    } catch (error) {
      console.error('Error Rejecting data:', error)
      if (error.response.status === 422) {
        Object.values(error.response.data.errors).map((item) => {
          toast.error(item[0], {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        })
      } else {
        toast.error(error.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      }
    }
    setReject(true)
  }

  // const onRejectData = async () => {
  //   const bookingData = {
  //     pandal_id: id,
  //     reject_reason: text,
  //   }
  //   const headers = {
  //     Authorization: Bearer ${token},
  //     'Content-Type': 'application/json',
  //   }
  //   try {
  //     await axios.post(
  //       process.env.REACT_APP_API_BASEURL + api/pandal/rejectPandal,
  //       bookingData,
  //       { headers },
  //     )
  //     pandalPermissionData()
  //   } catch (error) {
  //     console.error('Error deleting data:', error)
  //   }

  //   setReject(true)
  // }

  const onSendReason = (e) => {
    e.preventDefault()
    const validationErrors = {}
    !text
      ? (validationErrors.text = 'Reason Required')
      : delete validationErrors['text']
    !Object.keys(validationErrors).length && onRejectData() && backPage()
    setError(validationErrors)
  }

  useEffect(() => {
    pandalPermissionData()
  }, [])

  return (
    <>
      <div className="main-content">
        <Header />
        <div className="app-menu navbar-menu" style={{ display: 'flex' }}>
          <Sidebar />
        </div>
        <div className="page-content">
          <PageHeader HeaderName="Validation Full Details" />
          <div className="container-fluid pt-3">
            <div className="card card-body">
              <div className="row mb-5">
                <div className="col-md-6 mb-4">
                  <small>Pandal Title</small>
                  <input
                    type="text"
                    name=""
                    className="form-control"
                    value={receivedData?.pandal_title}
                    readOnly
                  />
                </div>
                <div className="col-md-6 mb-4">
                  <small>Pandal Name</small>
                  <input
                    type="text"
                    className="form-control"
                    value={receivedData?.pandal_name}
                    readOnly
                  />
                </div>
                <div className="row mb-4">
                  <div className="col-md-6 ">
                    <small>Pandal Image</small>
                    <img
                      src={newImage}
                      alt="Pandal"
                      className="form-control"
                    // style={{ height: '200px', width: 'auto' }}
                    />

                    <button
                      className="from-icon form-btn btn-sm btn btn-info"
                      onClick={handleClickOpen}
                    >
                      View
                    </button>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-md-6 ">
                    <small>From Date</small>
                    <input
                      type="date"
                      className="form-control"
                      value={receivedData?.pandal_start_date}
                      onChange={(e) => handleChangeStartDate(e.target.value)}
                    />
                  </div>
                  <div className="col-md-6 ">
                    <small>To Date</small>
                    <input
                      type="date"
                      className="form-control"
                      value={receivedData?.pandal_end_date}
                      onChange={(e) => handleChangeEndDate(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-md-12 ">
                    <small>Pandal Location</small>
                    <textarea
                      className="form-control"
                      rows="3"
                      value={receivedData?.pandal_address}
                      readOnly
                    ></textarea>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-md-12 ">
                    <small>About Pandal</small>
                    <textarea
                      className="form-control"
                      rows="3"
                      value={receivedData?.pandal_about}
                      readOnly
                    ></textarea>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-md-12 ">
                    <small>History of Pandal</small>
                    <textarea
                      className="form-control"
                      rows="3"
                      readOnly
                      value={receivedData?.pandal_history}
                    ></textarea>
                  </div>
                </div>
                <div className="row mb-4">

                  <div className="col-md-6 mb-3">


                    <small>Police Permission Letter</small>
                    <img
                      src={policeLetter}
                      alt="Police Letter"
                      className="form-control"

                    />
                    <button className="from-icon form-btn btn-sm btn btn-info"
                      onClick={handleClickOpen} >
                      View
                    </button>
                  </div>

                  <div className="col-md-6">
                    <small>President ID</small>
                    <img
                      src={presidentId}
                      alt="President ID"
                      className="form-control"

                    />
                    <button
                      className="from-icon form-btn btn-sm btn btn-info"
                      onClick={handleClickOpen}
                    >
                      View
                    </button>
                  </div>
                </div>
                <div className="row mb-4">

                  <div className="col-md-6 ">
                    <small>Vice-President ID</small>
                    <img
                      src={vicePresident}
                      alt="Vice-President ID"
                      className="form-control"

                    />
                    <button
                      className="from-icon form-btn btn-sm btn btn-info"
                      onClick={handleClickOpen}
                    >
                      View
                    </button>

                  </div>
                  <div className="col-md-6 mb-3">
                    <small>Finance Head ID</small>
                    <img
                      src={financeHead}
                      alt="Finance Head ID"
                      className="form-control"

                    />
                    <button
                      className="from-icon form-btn btn-sm btn btn-info"
                      onClick={handleClickOpen}
                    >
                      View
                    </button>
                  </div>

                </div>

                <div className="col-md-12 mb-3  text-center">
                <button
                    className="btn btn-success"
                    onClick={() => onApproveData(id)}
                    style={{  width: '140px',marginRight: '28px' }}
                  >
                    &nbsp; Approve
                  </button>
                  <button
                    className="btn btn-danger"
                    type="submit"
                    onClick={onRejectData}
                   
                    style={{  width: '140px',marginRight: '28px' }}
                  >
                    &nbsp; Reject
                  </button>
                 
                </div>
                {reject ? (
                  <div>
                    <div className="col-md-12 mb-3">
                      <small>Rejection Reason </small>
                      <textarea
                        className="form-control"
                        rows="3"
                        onChange={handleChange}
                      >
                        {text}
                      </textarea>
                      {error.text && (
                        <div style={{ color: 'red' }}>{error.text}</div>
                      )}
                    </div>

                    <div className="col-md-12 mb-3 text-end">
                      <button
                        className="btn btn-warning"
                        onClick={onSendReason}
                      >
                        <i className="far fa-paper-plane"></i>&nbsp; Send
                      </button>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
          {/* <Modal
        isOpen={open}
        onRequestClose={handleCloseModal}
        contentLabel="Image Popup"
        style={{border:'none'}}
      >
        <img src={newImage} alt="Popup Image" style={{height:'300px',width:'50%'}}/>
        <button onClick={handleCloseModal}>Close</button>
      </Modal> */}
          <Modal isOpen={open} onRequestClose={closeModal} style={customStyles}>
            <img
              src={newImage}
              alt="pujam_pandal"
              style={{ width: '70%', height: '330px', borderRadius: '0px' }}
            />
            {/* <button onClick={closeModal}>close</button> */}
          </Modal>
          <Modal
            isOpen={open1}
            onRequestClose={closeModal}
            style={customStyles}
          >
            <img
              src={policeLetter}
              alt="pujam_pandal"
              style={{ width: '70%', height: '330px', borderRadius: '0px' }}
            />
          </Modal>
          <Modal
            isOpen={open2}
            onRequestClose={closeModal}
            style={customStyles}
          >
            <img
              src={presidentId}
              alt="pujam_pandal"
              style={{ width: '70%', height: '330px', borderRadius: '0px' }}
            />
          </Modal>
          <Modal
            isOpen={open3}
            onRequestClose={closeModal}
            style={customStyles}
          >
            <img
              alt="pujam_pandal"
              src={vicePresident}
              style={{ width: '70%', height: '330px', borderRadius: '0px' }}
            />
          </Modal>
          <Modal
            isOpen={open4}
            onRequestClose={closeModal}
            style={customStyles}
          >
            <img
              alt="pujam_pandal"
              src={financeHead}
              style={{ width: '70%', height: '330px', borderRadius: '0px' }}
            />
          </Modal> 
        </div>
      </div>
      <Footer />
    </>
  )
}

export default ValidationDetail

const customStyles = {
  content: {
    position: 'absolute',
    inset: '40% 40px 50% 30%',
    height: '400px',
    border: 'none',
    backgroundColor: 'none',
    overflow: 'hidden',
    borderRadius: '4px',
    padding: '20px',
    width: '40%',
    /* margin-left: 30%; */
    transform: 'translate(-60%, -50%)',
  },
}