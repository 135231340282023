import React, { useState, useEffect } from 'react'
import { fetchSevaTemples } from '../API/FetchAPI'
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import PageHeader from "../PageHeader/PageHeader";

const TemplepoojariViewdetails = () => {
    const [detail, setDetails] = useState([]);

    async function loadTemplesData() {
        try {
            const data = await fetchSevaTemples('pujari/getByTempleId/1');
            console.log('Data for TemplePoojari:', data);
            setDetails(data);
            // Handle the data as needed
        } catch (error) {
            console.error('Error loading temples data:', error);
        }
    }

    useEffect(() => {
        loadTemplesData();
    }, [])
    return (
        <div>
             <div className="main-content">
              <Header />
              <div className="app-menu navbar-menu" style={{ display: 'flex' }}>
              <Sidebar />
              </div>
              <div className="page-content">
                  <PageHeader HeaderName="Dashboard " />
                  <div className='container'>
                  <div className='row mt-3 justify-content-between'>
                      <div className='col-md-12'>
                          <h4>poojari's Details</h4>
                      </div>
                  </div>

                  <div className='Card_form'>
                <form>
                    <div className='row'>
                        <div className='col-md-9'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className='form-group mb-3'>
                                        <label>Poojari's Name</label>
                                        <input type='text' value={detail.name} placeholder='' className='form-control' readOnly />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='form-group mb-3'>
                                        <label>Poojari Registered Date</label>
                                        <input type='text' value={detail.temple_name} placeholder='' className='form-control' readOnly />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='form-group mb-3'>
                                        <label>Gender</label>
                                        <input type='text' value={detail.gender} placeholder='' className='form-control' readOnly />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='form-group mb-3'>
                                        <label>Age</label>
                                        <input type='text' value={detail.dob} placeholder='' className='form-control' readOnly />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='form-group mb-3'>
                                        <label>Experience</label>
                                        <input type='text' value={detail.experience} placeholder='' className='form-control' readOnly />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='form-group mb-3'>
                                        <label>Language</label>
                                        <input type='text' value={detail.languages} placeholder='' className='form-control' readOnly />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='form-group mb-3'>
                                        <label>Qualification</label>
                                        <input type='text' value={detail.temple_name} placeholder='' className='form-control' readOnly />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='form-group mb-3'>
                                        <label>Awards</label>
                                        <input type='text' value={detail.award_name} placeholder='' className='form-control' readOnly />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className='form-group mb-3'>
                                <img src='/public/assets/images/templeformImg.png' className='img-fluid' alt='' />
                            </div>
                        </div>
                        <div className='col-md-12'>
                            <div className='form-group mb-3'>
                                <label>Description</label>
                                <textarea rows={3} cols={12} value={detail.description} placeholder='Khairtabad, Hyderabad.' className='form-control' readOnly />
                            </div>
                        </div>
                        <div className='col-md-12'>
                            <div className='form-group mb-3'>
                                <label>Adress</label>
                                <textarea rows={3} cols={12} value={detail.Address} className='form-control' readOnly />
                            </div>
                        </div>

                    </div>
                </form>
            </div>
               
                  </div>
              </div>
          </div>
        </div>
    )
}

export default TemplepoojariViewdetails
