import React, { useEffect, useState } from 'react'
import PageHeader from '../PageHeader/PageHeader'
import Header from '../Header/Header'
import Sidebar from '../Sidebar/Sidebar'
import { OngoingAuctions, UpComingAuctions, closedAuctions } from '../API/Api'
import DataTable from 'react-data-table-component'
import { useNavigate } from 'react-router-dom'
import { jsPDF } from 'jspdf'
import html2canvas from 'html2canvas'
import 'jspdf-autotable'
import * as XLSX from 'xlsx'
import Footer from '../Footer/Footer'

function Auction() {
  const [onGoingAuction, setOnGoingAuction] = useState([])
  const [upComingAuction, setUpComingAuction] = useState([])
  const [closedAuction, setClosedAuction] = useState([])
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()

  const fetchOngoingAuction = async () => {
    try {
      const data = await OngoingAuctions()
      const sortedData = data.sort(
        (a, b) =>
          new Date(b.created_at.split('/').reverse().join('-')) -
          new Date(a.created_at.split('/').reverse().join('-')),
      )
      setOnGoingAuction(sortedData)
      setLoading(false)
    } catch (error) {
      console.error('Error fetching table data:', error)
      setLoading(false)
    }
  }

  const fetchUpComingAuction = async () => {
    try {
      const data = await UpComingAuctions()
      const sortedData = data.sort(
        (a, b) =>
          new Date(b.created_at.split('/').reverse().join('-')) -
          new Date(a.created_at.split('/').reverse().join('-')),
      )
      setUpComingAuction(sortedData)
      setLoading(false)
    } catch (error) {
      console.error('Error fetching table data: ', error)
      setLoading(false)
    }
  }

  const fetchClosedAuction = async () => {
    try {
      const data = await closedAuctions()
      const sortedData = data.sort(
        (a, b) =>
          new Date(b.created_at.split('/').reverse().join('-')) -
          new Date(a.created_at.split('/').reverse().join('-')),
      )
      setClosedAuction(sortedData)
      setLoading(false)
    } catch (error) {
      console.error('Error fetching table data: ', error)
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchOngoingAuction()
    fetchUpComingAuction()
    fetchClosedAuction()
  }, [])

  const customStyles = {
    rows: {
      style: {},
    },
    headCells: {
      style: {
        paddingLeft: '8px',
        paddingRight: '8px',
        color: 'black',
        backgroundColor: 'rgb(204, 204, 204)',
        fontFamily: 'Poppins',
        fontWeight: '600',
      },
    },
    cells: {
      style: {
        paddingLeft: '8px',
        paddingRight: '8px',
      },
    },
  }
  const onGoingAuctionColums = [
    {
      name: 'Sl.No',
      selector: (row) => row['slno'],
      sortable: true,
      format: (row, rowIndex) => rowIndex + 1,
    },
    {
      name: 'Auction Name',
      selector: (row) => row['auction_name'],
      sortable: true,
    },
    {
      name: 'Date Of Auction',
      selector: (row) => row['created_at'],
      sortable: true,
    },
    {
      name: 'Status',
      cell: (row) => (row.status === '1' ? 'Active' : 'Inactive'),
      // selector: (row) => row['status'],
      sortable: true,
    },
    {
      name: 'Actions',
      cell: (row) => (
        <div className="d-flex">
          <button
            className="btn btn-sm btn-edit me-2"
            onClick={() => handleGetOneView(row)}
          >
            {' '}
            View Full details{' '}
          </button>
        </div>
      ),
    },
  ]
  const handleGetOneView = (row) => {
    navigate(
      '/auction-one-view',
      { state: row },
      // { state: { ...row } },
    )
    // console.log(row, 'handleGetOneView')
  }

  const upComingAuctionColums = [
    {
      name: 'Sl.No',
      selector: (row) => row['slno'],
      sortable: true,
      format: (row, rowIndex) => rowIndex + 1,
    },
    {
      name: 'Auction Name',
      selector: (row) => row['auction_name'],
      sortable: true,
    },
    {
      name: 'Date Of Auction',
      selector: (row) => row['created_at'],
      sortable: true,
    },
    {
      name: 'Status',
      cell: (row) => (row.status === '1' ? 'Active' : 'Inactive'),
      // selector: (row) => row['status'],
      sortable: true,
    },
    {
      name: 'Actions',
      cell: (row) => (
        <div className="d-flex">
          <button
            className="btn btn-sm btn-edit me-2"
            onClick={() => handleGetTwoView(row)}
          >
            {' '}
            View Full details{' '}
          </button>
        </div>
      ),
    },
  ]
  const handleGetTwoView = (row) => {
    navigate('/auction-two-view', { state: row })
    // console.log(row, 'handleGetTwoView')
  }
 
  const closedAuctionColums = [
    {
      name: 'Sl.No',
      selector: (row) => row['slno'],
      sortable: true,
      format: (row, rowIndex) => rowIndex + 1,
    },
    {
      name: 'Auction Name',
      selector: (row) => row['auction_name'],
      sortable: true,
    },
    {
      name: 'Date Of Auction',
      selector: (row) => row['created_at'],
      sortable: true, 
    },
    {
      name: 'Status',
      cell: (row) => (row.status === '1' ? 'Active' : 'Inactive'),
      // selector: (row) => row['status'],
      sortable: true,
    },
    {
      name: 'Actions',
      cell: (row) => (
        <div className="d-flex">
          <button
            className="btn btn-sm btn-edit me-2"
            onClick={() => handleGetThreeView(row)}
          >
            {' '}
            View Full details{' '}
          </button>
        </div>
      ),
    },
  ]
  const handleGetThreeView = (row) => {
    navigate('/auction-three-view', { state: row })
    // console.log(row, 'handleGetThreeView')
  }

  //Downloading PDF Format for Ongoing columns
  const ongoingColumnsForPDF = [
    {
      name: 'Sl.No',
      selector: (row) => row['slno'],
      sortable: true,
      format: (rowIndex) => rowIndex + 1,
    },
    {
      name: 'Auction Name',
      selector: (row) => row['auction_name'],
      sortable: true,
    },
    {
      name: 'Status',
      cell: (row) => (row.status === '1' ? 'Active' : 'Inactive'),
      sortable: true,
    },
    {
      name: 'Date Of Auction',
      selector: (row) => row['created_at'],
      sortable: true,
    },
  ]
  const handleDownloadOnGoingPDF = () => {
    const table = document.querySelector('.table-responsive table')
    html2canvas(table).then((canvas) => {
      const pdf = new jsPDF()
      const pdfData = onGoingAuction.map((onGoingAuction_detail, index) => [
        index + 1,
        onGoingAuction_detail['auction_name'],
        onGoingAuction_detail['status'] === '1' ? 'Active' : 'Inactive',
        onGoingAuction_detail['created_at'],
      ])

      pdf.autoTable({
        head: [ongoingColumnsForPDF.map((column) => column.name)],
        body: pdfData,
      })

      pdf.save('onGoingAuction_data.pdf')
    })
  }
  //Downloading Excel Format Ongoing columns
  const handleDownloadOnGoingExcel = () => {
    const columnsWithoutActions = ongoingColumnsForPDF.filter(
      (column) => column.name !== 'Actions',
    )
    const tableData = onGoingAuction.map((onGoingAuction_detail, index) => [
      index + 1,
      onGoingAuction_detail['auction_name'],
      onGoingAuction_detail['status'] === '1' ? 'Active' : 'Inactive',
      onGoingAuction_detail['created_at'],
    ])
    const worksheet = XLSX.utils.aoa_to_sheet([
      columnsWithoutActions.map((column) => column.name),
      ...tableData,
    ])
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Table Data')
    const excelFile = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
    const blob = new Blob([excelFile], { type: 'application/octet-stream' })
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = 'onGoingAuction_data.xlsx'
    a.click()
    URL.revokeObjectURL(url)
  }

  //Downloading CSV Format Ongoing columns
  const handleDownloadOnGoingCSV = () => {
    const columnsWithoutActions = ongoingColumnsForPDF.filter(
      (column) => column.name !== 'Actions',
    )
    const formatDate = (dateString) => {
      if (!dateString) {
        return ''
      }
      const dateParts = dateString.split('/')
      if (dateParts.length !== 3) {
        // console.log('Invalid date format:', dateString)
        return ''
      }
      const formattedDate = `${dateParts[1]}/${dateParts[0]}/${dateParts[2]}`
      const timestamp = Date.parse(formattedDate)
      if (isNaN(timestamp)) {
        // console.log('Invalid date:', dateString)
        return ''
      }
      const date = new Date(timestamp)
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' }
      return new Intl.DateTimeFormat('en-US', options).format(date)
    }
    const tableData = onGoingAuction.map((onGoingAuction_detail, index) => [
      index + 1,
      onGoingAuction_detail['auction_name'],
      onGoingAuction_detail['status'] === '1' ? 'Active' : 'Inactive',
      formatDate(onGoingAuction_detail['created_at']),
    ])
    const worksheet = XLSX.utils.aoa_to_sheet([
      columnsWithoutActions.map((column) => column.name),
      ...tableData,
    ])
    const columnWidths = columnsWithoutActions.map((column) => ({
      wch: column.name.length + 2,
    }))
    worksheet['!cols'] = columnWidths
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Table Data')
    const excelFile = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
    const blob = new Blob([excelFile], { type: 'application/octet-stream' })
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = 'designations_data.csv'
    a.click()
    URL.revokeObjectURL(url)
  }

  //Downloading PDF Format Ongoing columns
  const upcomingColumnsForPDF = [
    {
      name: 'Sl.No',
      selector: (row) => row['slno'],
      sortable: true,
      format: (rowIndex) => rowIndex + 1,
    },
    {
      name: 'Auction Name',
      selector: (row) => row['auction_name'],
      sortable: true,
    },
    {
      name: 'Status',
      cell: (row) => (row.status === '1' ? 'Active' : 'Inactive'),
      sortable: true,
    },
    {
      name: 'Date Of Auction',
      selector: (row) => row['created_at'],
      sortable: true,
    },
  ]
  const handleDownloadupComingPDF = () => {
    const table = document.querySelector('.table-responsive table')
    html2canvas(table).then((canvas) => {
      const pdf = new jsPDF()
      const pdfData = upComingAuction.map((upComingAuction_detail, index) => [
        index + 1,
        upComingAuction_detail['auction_name'],
        upComingAuction_detail['status'] === '1' ? 'Active' : 'Inactive',
        upComingAuction_detail['created_at'],
      ])

      pdf.autoTable({
        head: [upcomingColumnsForPDF.map((column) => column.name)],
        body: pdfData,
      })

      pdf.save('upcomingauction_data.pdf')
    })
  }
  //Downloading Excel Format for upcoming columns
  const handleDownloadupComingExcel = () => {
    const columnsWithoutActions = upcomingColumnsForPDF.filter(
      (column) => column.name !== 'Actions',
    )
    const tableData = upComingAuction.map((upComingAuction_detail, index) => [
      index + 1,
      upComingAuction_detail['auction_name'],
      upComingAuction_detail['status'] === '1' ? 'Active' : 'Inactive',
      upComingAuction_detail['created_at'],
    ])
    const worksheet = XLSX.utils.aoa_to_sheet([
      columnsWithoutActions.map((column) => column.name),
      ...tableData,
    ])
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Table Data')
    const excelFile = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
    const blob = new Blob([excelFile], { type: 'application/octet-stream' })
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = 'upComingAuction_data.xlsx'
    a.click()
    URL.revokeObjectURL(url)
  }
  //Downloading CSV Format for upcoming columns
  const handleDownloadupComingCSV = () => {
    const columnsWithoutActions = upcomingColumnsForPDF.filter(
      (column) => column.name !== 'Actions',
    )
    const formatDate = (dateString) => {
      if (!dateString) {
        return ''
      }
      const dateParts = dateString.split('/')
      if (dateParts.length !== 3) {
        // console.log('Invalid date format:', dateString)
        return ''
      }
      const formattedDate = `${dateParts[1]}/${dateParts[0]}/${dateParts[2]}`
      const timestamp = Date.parse(formattedDate)
      if (isNaN(timestamp)) {
        // console.log('Invalid date:', dateString)
        return ''
      }
      const date = new Date(timestamp)
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' }
      return new Intl.DateTimeFormat('en-US', options).format(date)
    }
    const tableData = upComingAuction.map((upComingAuction_detail, index) => [
      index + 1,
      upComingAuction_detail['auction_name'],
      upComingAuction_detail['status'] === '1' ? 'Active' : 'Inactive',
      formatDate(upComingAuction_detail['created_at']),
    ])
    const worksheet = XLSX.utils.aoa_to_sheet([
      columnsWithoutActions.map((column) => column.name),
      ...tableData,
    ])
    const columnWidths = columnsWithoutActions.map((column) => ({
      wch: column.name.length + 2,
    }))
    worksheet['!cols'] = columnWidths
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Table Data')
    const excelFile = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
    const blob = new Blob([excelFile], { type: 'application/octet-stream' })
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = 'upComingAuction_data.csv'
    a.click()
    URL.revokeObjectURL(url)
  }

  //Downloading PDF Format closed columns
  const closedColumnsForPDF = [
    {
      name: 'Sl.No',
      selector: (row) => row['slno'],
      sortable: true,
      format: (rowIndex) => rowIndex + 1,
    },
    {
      name: 'Auction Name',
      selector: (row) => row['auction_name'],
      sortable: true,
    },
    {
      name: 'Status',
      cell: (row) => (row.status === '1' ? 'Active' : 'Inactive'),
      sortable: true,
    },
    {
      name: 'Date Of Auction',
      selector: (row) => row['created_at'],
      sortable: true,
    },
  ]
  const handleDownloadClosedPDF = () => {
    const table = document.querySelector('.table-responsive table')
    html2canvas(table).then((canvas) => {
      const pdf = new jsPDF()
      const pdfData = closedAuction.map((closedAuction_detail, index) => [
        index + 1,
        closedAuction_detail['auction_name'],
        closedAuction_detail['status'] === '1' ? 'Active' : 'Inactive',
        closedAuction_detail['created_at'],
      ])

      pdf.autoTable({
        head: [closedColumnsForPDF.map((column) => column.name)],
        body: pdfData,
      })

      pdf.save('closedAuction_data.pdf')
    })
  }
  //Downloading Excel Format for closed columns
  const handleDownloadClosedExcel = () => {
    const columnsWithoutActions = closedColumnsForPDF.filter(
      (column) => column.name !== 'Actions',
    )
    const tableData = closedAuction.map((closedAuction_detail, index) => [
      index + 1,
      closedAuction_detail['auction_name'],
      closedAuction_detail['status'] === '1' ? 'Active' : 'Inactive',
      closedAuction_detail['created_at'],
    ])
    const worksheet = XLSX.utils.aoa_to_sheet([
      columnsWithoutActions.map((column) => column.name),
      ...tableData,
    ])
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Table Data')
    const excelFile = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
    const blob = new Blob([excelFile], { type: 'application/octet-stream' })
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = 'closedAuction_data.xlsx'
    a.click()
    URL.revokeObjectURL(url)
  }
  //Downloading CSV Format for closed columns
  const handleDownloadClosedCSV = () => {
    const columnsWithoutActions = closedColumnsForPDF.filter(
      (column) => column.name !== 'Actions',
    )
    const formatDate = (dateString) => {
      if (!dateString) {
        return ''
      }
      const dateParts = dateString.split('/')
      if (dateParts.length !== 3) {
        // console.log('Invalid date format:', dateString)
        return ''
      }
      const formattedDate = `${dateParts[1]}/${dateParts[0]}/${dateParts[2]}`
      const timestamp = Date.parse(formattedDate)
      if (isNaN(timestamp)) {
        // console.log('Invalid date:', dateString)
        return ''
      }
      const date = new Date(timestamp)
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' }
      return new Intl.DateTimeFormat('en-US', options).format(date)
    }
    const tableData = closedAuction.map((closedAuction_detail, index) => [
      index + 1,
      closedAuction_detail['auction_name'],
      closedAuction_detail['status'] === '1' ? 'Active' : 'Inactive',
      formatDate(closedAuction_detail['created_at']),
    ])
    const worksheet = XLSX.utils.aoa_to_sheet([
      columnsWithoutActions.map((column) => column.name),
      ...tableData,
    ])
    const columnWidths = columnsWithoutActions.map((column) => ({
      wch: column.name.length + 2,
    }))
    worksheet['!cols'] = columnWidths
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Table Data')
    const excelFile = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
    const blob = new Blob([excelFile], { type: 'application/octet-stream' })
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = 'closedAuction_data.csv'
    a.click()
    URL.revokeObjectURL(url)
  }

  return (
    <>
      <div className="main-content">
        <Header />
        <div className="app-menu navbar-menu" style={{ display: 'flex' }}>
          <Sidebar />
        </div>
        <div className="page-content">
          <PageHeader HeaderName="Auctions" />
          <div className="container-fluid pt-3">
            <div className="row mb-5">
              <div className="col-xl-12 col-md-12">
                <div className="card">
                  <div className="card-body">
                    <ul className="nav nav-tabs" role="tablist">
                      <li className="nav-item" role="presentation">
                        <a
                          className="nav-link active"
                          data-bs-toggle="tab"
                          href="#one"
                          aria-selected="true"
                          role="tab"
                        >
                          Ongoing Auctions
                        </a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#two"
                          aria-selected="false"
                          tabIndex="-1"
                          role="tab"
                        >
                          Upcoming Auctions
                        </a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#three"
                          aria-selected="false"
                          tabIndex="-1"
                          role="tab"
                        >
                          Closed Auctions
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <!-- Tab panes --> */}
                <div className="tab-content">
                  <div className="tab-pane active" id="one" role="tabpanel">
                    <div className="card card-body">
                      <div className="row">
                        {loading ? (
                          <p>Loading...</p>
                        ) : onGoingAuction.length === 0 ? (
                          <p>Data Not Found</p>
                        ) : (
                          <>
                            <div className="col-md-12 table-responsive">
                              <table className="table table-bordered table-striped text-center m-0">
                                <DataTable
                                  columns={onGoingAuctionColums}
                                  data={onGoingAuction}
                                  pagination
                                  customStyles={customStyles}
                                  highlightOnHover
                                  noDataComponent="Loading..."
                                />
                              </table>
                            </div>
                            <div className="col-md-12 text-end">
                              <div className="button-group">
                                <button
                                  title="Download Excel"
                                  className="button-excel-format"
                                  onClick={handleDownloadOnGoingExcel}
                                >
                                  Excel
                                </button>
                                <button
                                  title="Download CSV"
                                  className="button-csv-format"
                                  onClick={handleDownloadOnGoingCSV}
                                >
                                  CSV
                                </button>
                                <button
                                  title="Download PDF"
                                  className="button-pdf-format"
                                  onClick={handleDownloadOnGoingPDF}
                                >
                                  PDF
                                </button>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="two" role="tabpanel">
                    <div className="card card-body">
                      <div className="row">
                        {loading ? (
                          <p>Loading...</p>
                        ) : upComingAuction.length === 0 ? (
                          <p>Data Not Found</p>
                        ) : (
                          <>
                            <div className="col-md-12 table-responsive">
                              <table className="table table-bordered table-striped text-center m-0">
                                <DataTable
                                  columns={upComingAuctionColums}
                                  data={upComingAuction}
                                  pagination
                                  customStyles={customStyles}
                                  highlightOnHover
                                  noDataComponent="Loading..."
                                />
                              </table>
                            </div>
                            <div className="col-md-12 text-end">
                              <div className="button-group">
                                <button
                                  title="Download Excel"
                                  className="button-excel-format"
                                  onClick={handleDownloadupComingExcel}
                                >
                                  Excel
                                </button>
                                <button
                                  title="Download CSV"
                                  className="button-csv-format"
                                  onClick={handleDownloadupComingCSV}
                                >
                                  CSV
                                </button>
                                <button
                                  title="Download PDF"
                                  className="button-pdf-format"
                                  onClick={handleDownloadupComingPDF}
                                >
                                  PDF
                                </button>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="three" role="tabpanel">
                    <div className="card card-body">
                      <div className="row">
                        {loading ? (
                          <p>Loading...</p>
                        ) : closedAuction.length === 0 ? (
                          <p>Data Not Found</p>
                        ) : (
                          <>
                            <div className="col-md-12 table-responsive">
                              <table className="table table-bordered table-striped text-center m-0">
                                <DataTable
                                  columns={closedAuctionColums}
                                  data={closedAuction}
                                  pagination
                                  customStyles={customStyles}
                                  highlightOnHover
                                  noDataComponent="Loading..."
                                />
                              </table>
                            </div>
                            <div className="col-md-12 text-end">
                              <div className="button-group">
                                <button
                                  title="Download Excel"
                                  className="button-excel-format"
                                  onClick={handleDownloadClosedExcel}
                                >
                                  Excel
                                </button>
                                <button
                                  title="Download CSV"
                                  className="button-csv-format"
                                  onClick={handleDownloadClosedCSV}
                                >
                                  CSV
                                </button>
                                <button
                                  title="Download PDF"
                                  className="button-pdf-format"
                                  onClick={handleDownloadClosedPDF}
                                >
                                  PDF
                                </button>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Auction
