import React from 'react'
import Header from '../Header/Header'
import PageHeader from '../PageHeader/PageHeader'
import PujariSidebar from './PujariSidebar'
import Footer from '../Footer/Footer'

function PujariDashboard() {
  return (
    <>
    <div className="main-content">
        <Header />
        <div className="app-menu navbar-menu" style={{ display: 'flex' }}>
            <PujariSidebar />
        </div>
        <div className="page-content">
        <PageHeader HeaderName="Pujari Dashboard" />
          <div className="container-fluid pt-3">
            <div className="row dashboardMain">
                <div className="col-md-4">
                <div className="card card4">
                  <div className="card-body">
                    <div className="">
                      <p>
                        Total Calls Received
                      </p>
                      <p>{5}</p>
                      <img
                        alt="pujam_pandal"
                        src="/assets/images/user1.png"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card card2">
                  <div className="card-body">
                    <div className="">
                      <p>
                        Number of Pooja's
                      </p>
                      <p>{2}</p>
                      <img
                        src="/assets/images/group.png"
                        alt="pujam_pandal"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card card3">
                  <div className="card-body">
                    <div className="">
                      <p>
                        Number of Reviews
                      </p>
                      <p>{3}</p>
                      <img
                        alt="pujam_pandal"
                        src="/assets/images/save-money.png"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
    {/* <Footer /> */}
    </>
  )
}

export default PujariDashboard