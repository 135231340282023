import React, { useState, useEffect } from 'react';
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane
} from 'mdb-react-ui-kit';
import { useNavigate } from 'react-router-dom';
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import PageHeader from "../PageHeader/PageHeader";
import { fetchTemples, fetchPujaris } from '../API/Api';
import Partners from "../Partners/Partners";

function PartnerTabs() {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/viewfulldetails');
  };
  const handleButtonClick1 = () => {
    navigate('/PoojariViewFullDetails');
  };

  const handleButtonClick2 = () => {
    navigate('/viewfulldetails');
  };
  const [basicActive, setBasicActive] = useState('tab1');
  const [temples, setTemples] = useState([]);
  const [pujari, setPujari] = useState([]);

  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }
    setBasicActive(value);
  };


  const fetchData = async () => {
    const data = await fetchTemples();
    console.log(data)
    setTemples(data);
  }
  // setTemples(data)
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData1 = async () => {
    try {
      const data = await fetchPujaris();
      console.log('Pujaris:', data);
      setPujari(Array.isArray(data) ? data : []);
    } catch (error) {
      console.error('Error fetching pujari:', error);
      setPujari([]);
    }
  };
  useEffect(() => {
    fetchData1();
  }, []);



  return (
    <div>

      <div className="main-content">
        <Header />
        <div className="app-menu navbar-menu" style={{ display: 'flex' }}>
          <Sidebar />
        </div>
        <div className="page-content">
          <PageHeader HeaderName="Dashboard " />
          <div className='container'>
            <div className='row mt-3 justify-content-between'>
              <div className='col-md-6'>
                <h4>List of all Registered</h4>
              </div>
              {/* <div className='col-md-3  '>
                <div class="input-group">
                  <input type="text" class="form-control" name="" placeholder="" />
                  <button type="submit" class="input-group-text"><i class="fa fa-search"></i></button>
                </div>
              </div> */}
            </div>

            <MDBTabs className='mb-3 list_partners'>
              <MDBTabsItem className=''>
                <MDBTabsLink onClick={() => handleBasicClick('tab1')} active={basicActive === 'tab1'}>
                  Temples
                </MDBTabsLink>
              </MDBTabsItem>
              <MDBTabsItem>
                <MDBTabsLink onClick={() => handleBasicClick('tab2')} active={basicActive === 'tab2'}>
                  Poojari
                </MDBTabsLink>
              </MDBTabsItem>
              <MDBTabsItem>
                <MDBTabsLink onClick={() => handleBasicClick('tab3')} active={basicActive === 'tab3'}>
                  Pandals
                </MDBTabsLink>
              </MDBTabsItem>
            </MDBTabs>

            <MDBTabsContent>
              <MDBTabsPane open={basicActive === 'tab1'}>
                <table className='table table-bordered table-hover' style={{
                  backgroundColor: 'white',
                  borderRadius: '2px',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                  padding: '20px',
                  borderCollapse: 'separate',
                  borderSpacing: '0'
                }}>
                  <thead>
                    <tr className='table-primary'>
                      <th style={{ textAlign: 'center' }}>Sl.No</th>
                      <th style={{ textAlign: 'center' }}>Temple Name</th>
                      <th style={{ textAlign: 'center' }}>Image</th>
                      <th style={{ textAlign: 'center' }}>Dities</th>
                      <th style={{ textAlign: 'center' }}>Location</th>
                      <th style={{ textAlign: 'center' }}>Registered Date</th>
                      <th style={{ textAlign: 'center' }}>Action</th>
                    </tr>
                  </thead>
                  <tbody className='table_tbody'>
                    {temples.map((temple, index) => (
                      <tr key={index}>
                        <td>{temple.id}</td>
                        <td>{temple.temple_name}</td>
                        <td>
                          <img src={temple.image} alt="Temple" />
                        </td>
                        <td>{temple.dities_id}</td>
                        <td>
                          {temple.location}
                        </td>
                        <td>{temple.created_at}</td>
                        <td>
                          <button className='btn btn-primary' onClick={handleButtonClick}> View Full details </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </MDBTabsPane>
              <MDBTabsPane open={basicActive === 'tab2'}>
                <table className='table table-bordered table-hover' style={{
                  backgroundColor: 'white',
                  borderRadius: '2px',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                  padding: '20px',
                  borderCollapse: 'separate',
                  borderSpacing: '0'
                }}>
                  <thead>
                    <tr className='table-primary'>
                      <th style={{ textAlign: 'center' }}>Sl.No</th>
                      <th style={{ textAlign: 'center' }}>Poojari Name</th>
                      <th style={{ textAlign: 'center' }}>Image</th>
                      <th style={{ textAlign: 'center' }}>Category</th>
                      <th style={{ textAlign: 'center' }}>Location</th>
                      <th style={{ textAlign: 'center' }}>Registered Date</th>
                      <th style={{ textAlign: 'center' }}>Action</th>
                    </tr>
                  </thead>
                  <tbody className='table_tbody'>
                    {/* <tr>
                        <td>{pujari.id}</td>
                        <td>{pujari.name}</td>
                        <td>
                          <img src={pujari.image} alt="" />
                        </td>
                        <td>{pujari.category}</td>
                        <td>
                          {pujari.location}
                        </td>
                        <td>{pujari.created_at}</td>
                        <td>
                          <button className='btn btn-primary' onClick={handleButtonClick1}> View Full details </button>
                        </td>
                      </tr> */}
                    {pujari.map((pujari, index) => (
                      <tr key={index}>
                        <td>{pujari.id}</td>
                        <td>{pujari.name}</td>
                        <td>
                          <img src={pujari.image} alt="" />
                        </td>
                        <td>{pujari.category}</td>
                        <td>
                          {pujari.location}
                        </td>
                        <td>{pujari.created_at}</td>
                        <td>
                          <button className='btn btn-primary' onClick={handleButtonClick1}> View Full details </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

              </MDBTabsPane>
              <MDBTabsPane open={basicActive === 'tab3'}>
                < Partners />
                {/* <table className='table table-bordered table-hover'>
                  <thead>
                    <tr className='table-primary'>
                      <th style={{ textAlign: 'center' }}>Sl.No</th>
                      <th style={{ textAlign: 'center' }}>Pandal Name</th>
                      <th style={{ textAlign: 'center' }}>Image</th>
                      <th style={{ textAlign: 'center' }}>View Pandal Details</th>
                      <th style={{ textAlign: 'center' }}>Location</th>
                      <th style={{ textAlign: 'center' }}>Pandal Created Date</th>
                      <th style={{ textAlign: 'center' }}>Action</th>
                    </tr>
                  </thead>
                  <tbody className='table_tbody'>
                    {temples.map((temple, index) => (
                      <tr key={index}>
                        <td>{temple.id}</td>
                        <td>{temple.temple_name}</td>
                        <td>
                          <img src={temple.image} alt="Temple" />
                        </td>
                        <td>{temple.dities_id}</td>
                        <td>
                          {temple.location}
                        </td>
                        <td>{temple.created_at}</td>
                        <td>
                          <button className='btn btn-primary' onClick={handleButtonClick2}> View Full details </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table> */}

              </MDBTabsPane>
            </MDBTabsContent>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PartnerTabs
