import axios from 'axios'
const token = localStorage.getItem('token')
const apiBaseUrl = process.env.REACT_APP_API_BASEURL
const api = axios.create({
    baseURL: apiBaseUrl,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })
const getToken = () => {
    return localStorage.getItem("token");
  };


  export const GetAllAuctionsDetails = async () => {
    try {
      const response = await api.get('templeAuction/listView')
      console.log(response)
      return response.data.data.map((item) => ({
        ...item,
        created_at: new Date(item.created_at).toLocaleDateString('en-GB'),
      }))
    } catch (error) {
      throw error
    }
  }

  export const CreateAuctiontDetails = async (requestData) => {
    try {
      const response = await api.post('templeAuction/create', requestData,
      {
        headers: {
          // Authorization: `Bearer ${getToken()}`,
          "Content-Type": "multipart/form-data",
        },

      })
      return response.data
    } catch (error) {

      throw error
    }
  }

  export const UpdateAuctiontDetails = async (requestData,) => {
    try {
      const response = await api.post("templeAuction/update", requestData,
      {
        headers: {
          // Authorization: `Bearer ${getToken()}`,
          "Content-Type": "multipart/form-data",
        },

      })
      return response.data
    } catch (error) {

      throw error
    }
  }

  export const DeleteAuctionDetails = async (id) => {
    try {
      const response = await api.post(`templeAuction/delete`,id)
      return response.data
    } catch (error) {

      throw error
    }
  }