import React, { useEffect, useState } from 'react';
import Header from '../Header/Header';
import PujariSidebar from './PujariSidebar';
import PageHeader from '../PageHeader/PageHeader';
import { getApiData } from './API';
import { PujariPujaBookingsData } from './Constants';
import { showToast } from './Toast';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { parse } from 'date-fns';

function PujariDashboard() {
    const [bookingData, setBookingData] = useState([]);
    const [upcomingBookings, setUpcomingBookings] = useState([]);
    const [completedBookings, setCompletedBookings] = useState([]);
    const [cancelledBookings, setCancelledBookings] = useState([]);
    const currentDate = new Date();

    const getBookingDetails = async () => {
        try {
            const data = await getApiData(PujariPujaBookingsData);
            console.log("Raw API Data: ", data);

            const dateFormat = "dd-MM-yyyy HH:mm:ss";
            const upcoming = [];
            const completed = [];
            const cancelled = [];

            data.forEach((item) => {
                const bookingDate = parse(item.date_time, dateFormat, new Date());
                if (item.status) {
                    if (bookingDate >= currentDate) {
                        upcoming.push(item);
                    } else {
                        completed.push(item);
                    }
                } else if (item.status === 0) {
                    cancelled.push(item);
                }
            });

            console.log("Upcoming Bookings: ", upcoming);
            console.log("Completed Bookings: ", completed);
            console.log("Cancelled Bookings: ", cancelled);

            setUpcomingBookings(upcoming);
            setCompletedBookings(completed);
            setCancelledBookings(cancelled);
        } catch (error) {
            if (error.response) {
                if (error.response.status === 429) {
                    showToast('too many requests', 'error');
                } else if (error.response.status === 404) {
                    showToast('not found', 'error');
                } else {
                    showToast('something went wrong', 'error');
                }
            } else {
                showToast('something went wrong', 'error');
            }
        }
    };

    useEffect(() => {
        getBookingDetails();
    }, []);

    return (
        <div className="main-content">
            <Header />
            <div className="app-menu navbar-menu" style={{ display: 'flex' }}>
                <PujariSidebar />
            </div>
            <div className="page-content">
                <PageHeader HeaderName="Pooja Bookings" />
                <div className='container'>
                    <div className='row mt-3'>
                        <div className='col'>
                            <Tabs defaultActiveKey="Upcoming" id="justify-tab-example" className="mb-3" justify>
                                <Tab eventKey="Upcoming" title="Upcoming">
                                    <table className='table table-bordered table-striped'>
                                        <thead className='bg-warning'>
                                            <tr>
                                                <th>SNo</th>
                                                <th>Customer Name</th>
                                                <th>Mobile</th>
                                                <th>Pooja</th>
                                                <th>Slot</th>
                                                <th>Address</th>
                                                {/* <th>Action</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {upcomingBookings.length > 0 ? (
                                                upcomingBookings.map((booking, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{booking.customer_name}</td>
                                                        <td>{booking.mobile}</td>
                                                        <td>{booking.pooja_name}</td>
                                                        <td>{booking.date_time}</td>
                                                        <td>{booking.address}</td>
                                                        {/* <td></td> */}
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan="7" className="text-center">No Upcoming Bookings</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </Tab>
                                <Tab eventKey="Completed" title="Completed">
                                    <table className='table table-bordered table-striped'>
                                        <thead className='bg-warning'>
                                            <tr>
                                                <th>SNo</th>
                                                <th>Customer Name</th>
                                                <th>Mobile</th>
                                                <th>Pooja</th>
                                                <th>Slot</th>
                                                <th>Address</th>
                                                {/* <th>Action</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {completedBookings.length > 0 ? (
                                                completedBookings.map((booking, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{booking.customer_name}</td>
                                                        <td>{booking.mobile}</td>
                                                        <td>{booking.pooja_name}</td>
                                                        <td>{booking.date_time}</td>
                                                        <td>{booking.address}</td>
                                                        {/* <td></td> */}
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan="7" className="text-center">No Completed Bookings</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </Tab>
                                <Tab eventKey="Cancelled" title="Cancelled">
                                    <table className='table table-bordered table-striped'>
                                        <thead className='bg-warning'>
                                            <tr>
                                                <th>SNo</th>
                                                <th>Customer Name</th>
                                                <th>Mobile</th>
                                                <th>Pooja</th>
                                                <th>Slot</th>
                                                <th>Address</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {cancelledBookings.length > 0 ? (
                                                cancelledBookings.map((booking, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{booking.customer_name}</td>
                                                        <td>{booking.mobile}</td>
                                                        <td>{booking.pooja_name}</td>
                                                        <td>{booking.date_time}</td>
                                                        <td>{booking.address}</td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan="6" className="text-center">No Cancelled Bookings</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PujariDashboard;
