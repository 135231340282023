import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import TextField from '@mui/material/TextField'
import OtpInput from 'react-otp-input'
import './registerPage.css'

const RegisterPage = () => {
  const navigate = useNavigate()
  const [otp, setOtp] = useState('')
  const [forgotPassword, setForgotPassword] = useState(false)
  const [otpSent, setOtpSent] = useState(false)
  const [otpVerified, setOtpVerified] = useState(false) // State to track if OTP is verified
  const [passwordResetSuccess, setPasswordResetSuccess] = useState(false)
  const handleSubmit = (event) => {
    event.preventDefault()
    // Add your form submission logic here
  }

  const handleForgotPassword = () => {
    setForgotPassword(true)
  }

  const handleBackToLogin = () => {
    setForgotPassword(false)
  }

  const handleSendOtp = () => {
    setOtpSent(true)
    // You can add your logic here to send the OTP
  }

  const handleVerifyOtp = () => {
    // Here, you should implement your logic to verify the OTP
    // For now, let's assume OTP verification is successful
    setOtpVerified(true)
  }

  const handleResetPassword = () => {
    setPasswordResetSuccess(true)
  }

  return (
    <>
      <div id="main-wrapper" className="oxyy-login-register">
        <div
          className="hero-content mx-auto w-100 h-100 "
          style={{
            backgroundImage: "url('/assets/images/entrance1.png')",
            backgroundSize: '100% 100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
              paddingTop: '40px',
              height: '100vh',
            }}
          >
            <img src={'/assets/images/title 1.png'} alt="Title" />
            {!passwordResetSuccess ? (
              <form id="" method="post" onSubmit={handleSubmit}>
                {!otpSent && (
                  <>
                    <div
                      style={{
                        padding: '30px',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <TextField
                        id="outlined-basic"
                        label="Enter Your Phone Number/E-mail Id"
                        variant="outlined"
                        style={{
                          width: '350px',
                          marginBottom: '25px',
                          height: '60px',
                        }}
                      />
                      {!forgotPassword && (
                        <TextField
                          id="outlined-basic"
                          label="Password"
                          variant="outlined"
                          style={{ width: '350px', height: '50px' }}
                        />
                      )}
                      {!forgotPassword && (
                        <p
                          style={{
                            color: '#A90000',
                            textAlign: 'right',
                            marginTop: '10px',
                          }}
                        >
                          <button
                            type="button"
                            onClick={handleForgotPassword}
                            style={{
                              border: 'none',
                              background: 'none',
                              color: '#A90000',
                              cursor: 'pointer',
                            }}
                          >
                            Forgot Password?
                          </button>
                        </p>
                      )}
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      {!forgotPassword ? (
                        <button
                          type="submit"
                          className="button-container"
                          // style={{
                          //   backgroundColor: "#A90000",
                          //   color: "#fff",
                          //   width: "350px",
                          //   marginTop: "-35px",
                          //   display:'flex',
                          //   justifyContent:'center',
                          //   alignItems:'center'
                          // }}
                        >
                          Login
                        </button>
                      ) : (
                        <>
                          <button
                            type="button"
                            onClick={handleSendOtp}
                            className="button-container"
                            // style={{
                            //   backgroundColor: "#A90000",
                            //   color: "#fff",
                            //   width: "350px",
                            //   marginTop: "-35px",
                            // }}
                          >
                            Send OTP
                          </button>
                          <p className="mt-4">
                            <button
                              type="button"
                              onClick={handleBackToLogin}
                              style={{
                                border: 'none',
                                background: 'none',
                                cursor: 'pointer',
                              }}
                            >
                              Don't have an account yet?
                              <span style={{ color: '#A90000' }}>
                                {' '}
                                Register
                              </span>
                            </button>
                          </p>
                        </>
                      )}
                    </div>
                  </>
                )}
                {otpSent &&
                !otpVerified && ( // Render OTP input only if it's sent and not verified
                    <>
                      <p className="text-center">
                        A 4 digit OTP has been sent to your registered phone
                        number & E-mail Id <br />
                        +91 96666****** & vmax123***@gmail.com
                      </p>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                        }}
                      >
                        <OtpInput
                          value={otp}
                          onChange={setOtp}
                          numInputs={4}
                          renderInput={(props) => (
                            <input
                              {...props}
                              style={{
                                width: '40px',
                                height: '40px',
                                margin: '5px',
                                textAlign: 'center',
                                backgroundColor: '#D9D9D9',
                                border: 'none',
                                marginBottom: '30px',
                              }}
                            />
                          )}
                        />
                        <button
                          type="button" // Change type to button
                          onClick={handleVerifyOtp} // Call handleVerifyOtp function
                          className="button-container"
                          style={{ marginTop: '10px' }}
                        >
                          Verify OTP
                        </button>
                        <p className="mt-4">
                          <button
                            type="button"
                            onClick={handleBackToLogin}
                            style={{
                              border: 'none',
                              background: 'none',
                              cursor: 'pointer',
                            }}
                          >
                            OTP not Received!
                            <span style={{ color: '#A90000' }}> Resend</span>
                          </button>
                        </p>
                      </div>
                    </>
                  )}
                {otpVerified && ( // Render password reset fields only if OTP is verified
                  <div
                    style={{
                      padding: '30px',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <TextField
                      id="outlined-basic"
                      label="New Password"
                      variant="outlined"
                      style={{
                        width: '350px',
                        marginBottom: '25px',
                        height: '50px',
                      }}
                    />

                    <TextField
                      id="outlined-basic"
                      label="Confirm New Password"
                      variant="outlined"
                      style={{ width: '350px', height: '50px' }}
                    />
                    <button
                      type="submit"
                      className="button-container"
                      onClick={handleResetPassword}
                      style={{
                        marginTop: '20px',
                      }}
                    >
                      Reset Password
                    </button>
                  </div>
                )}
              </form>
            ) : (
              <>
                <img
                  src={'/assets/images/vector.png'}
                  style={{ width: '70px', height: '70px', marginTop: '20px' }}
                  alt="Title"
                />
                <p className="text-center mt-4">
                  Your password has been changed successfully <br />
                  navigate to{' '}
                  <span style={{ color: '#A90000' }}>home page</span>
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default RegisterPage
