import { React, useState, useEffect, useMemo } from 'react'
// import PageHeader from '../PageHeader/PageHeader'
import * as XLSX from 'xlsx'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import Papa from 'papaparse'
import DataTable from 'react-data-table-component'
import { useNavigate } from 'react-router-dom'
import Header from "../Header/Header";
import Sidebar from '../Sidebar/Sidebar'
import { getAllPartners } from '../API/Api'
// import Footer from '../Footer/Footer'

function Partners() {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const navigate = useNavigate()

  const clickPandalDetails = (row1) => {
    navigate(`/pandal-full-details/${row1.pandal_id}`, { state: row1 })
    // console.log(row)
  }

  const fetchData = async () => {
    try {
      const data = await getAllPartners()
      const sortedData = data.sort(
        (a, b) =>
          new Date(b.pandal_start_date.split('/').reverse().join('-')) -
          new Date(a.pandal_start_date.split('/').reverse().join('-')),
      )
      setData(sortedData)
      setLoading(false)
    } catch (error) {
      console.error('Error fetching table data:', error)
      setLoading(false)
    }
  }
  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split('-')
    return `${day}-${month}-${year}`
  }

  const columns = useMemo(
    () => [
      {
        name: 'Sl.No',
        selector: 'slno',
        sortable: true,
        format: (row, rowIndex) => rowIndex + 1,
      },
      {
        name: 'Pandal Name',
        selector: 'pandal_name',
        sortable: true,
      },
      {
        name: 'Pandal Details',
        selector: 'pandal_about',
        sortable: true,
      },
      {
        name: 'Location',
        selector: 'pandal_address',
        sortable: true,
      },
      {
        name: 'Pandal Create At',
        selector: 'pandal_start_date',
        cell: (row) => formatDate(row.pandal_start_date),
        sortable: true,
      },
      {
        name: 'Actions',
        cell: (row) => (
          <div>
            <button
              class="btn btn-sm btn-edit me-2"
              onClick={() => clickPandalDetails(row)}
            >
              Full Details
            </button>
          </div>
        ),
      },
    ],
    [],
  )

  const downloadAsPDF = () => {
    const columnsForPDF = [...columns]
    columnsForPDF.pop()
    const doc = new jsPDF()
    const pdfData = []
    data.forEach((row, index) => {
      pdfData.push([
        index + 1,
        row.pandal_name,
        row.pandal_about,
        row.pandal_address,
        row.pandal_start_date,
      ])
    })

    doc.autoTable({
      head: [columnsForPDF.map((column) => column.name)],
      body: pdfData,
      showHead: 'firstPage',
    })
    doc.save('table_data.pdf')
  }

  const downloadAsExcel = () => {
    const excelData = data.map((item, index) => ({
      'S.No': index + 1,
      PandalName: item.pandal_name,
      'Pandal Details': item.pandal_about,
      Location: item.pandal_address,
      'Pandal Create At': item.pandal_start_date,
    }))

    const worksheet = XLSX.utils.json_to_sheet(excelData)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
    XLSX.writeFile(workbook, 'table_data.xlsx')
  }

  const handleDownloadCSV = () => {
    const csvData = data.map((item, index) => ({
      'S.No': index + 1,
      'Pandal Name': item.pandal_name,
      'Pandal Details': item.pandal_about,
      Location: item.pandal_address,
      'Pandal Create At': item.pandal_start_date,
    }))

    const csv = Papa.unparse(csvData)
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.setAttribute('href', url)
    link.setAttribute('download', 'table_data.csv')
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const customStyles = {
    rows: {
      style: {
        // border: '0.1px solid #ddd',
      },
    },
    headCells: {
      style: {
        paddingLeft: '8px',
        paddingRight: '8px',
        color: 'black',
        fontSize: '14px',
        backgroundColor: '#ccc',
        fontFamily: 'Poppins',
        fontWeight: '600',
        border: '0.1px solid #ddd',
      },
    },
    cells: {
      style: {
        paddingLeft: '8px',
        paddingRight: '8px',
        border: '0.1px solid #ddd',
      },
    },
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
                <div className="Card_form ">
                  {loading ? (
                    <p>Loading...</p>
                  ) : columns.length === 0 ? (
                    <p>Data Not Found</p>
                  ) : (
                    <>
                      <div className="table-responsive registered-img bg-white">
                        <DataTable
                          columns={columns}
                          data={data}
                          pagination
                          customStyles={customStyles}
                          highlightOnHover
                          noDataComponent="Loading..."
                        />
                      </div>
                      <div className="col-md-12 text-end">
                        <div className="button-group">
                          <button
                            title="Download Excel"
                            className="button-excel-format"
                            onClick={downloadAsExcel}
                          >
                            Excel
                          </button>
                          <button
                            title="Download CSV"
                            className="button-csv-format"
                            onClick={handleDownloadCSV}
                          >
                            CSV
                          </button>
                          <button
                            title="Download PDF"
                            className="button-pdf-format"
                            onClick={downloadAsPDF}
                          >
                            PDF
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              
    </>
  )
}

export default Partners
