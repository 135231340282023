import React from 'react'

function Footer() {
  return (
    <>
      <div className="page-content" style={{ position: 'sticky', bottom: '0' }}>
        <footer className="footer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">© 2023 Pujam Pandal.</div>
              <div className="col-sm-6">
                <div className="text-sm-end d-sm-block">
                  Design & Develop by{' '}
                  <a href="https://vmaxindia.com/">
                    <b className="text-dark">VMAX</b>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  )
}

export default Footer
