import {  React } from 'react'
import {  useNavigate } from 'react-router-dom'
import PageHeader from '../PageHeader/PageHeader'
import { useLocation } from 'react-router-dom'
import Header from '../Header/Header'
import Sidebar from '../Sidebar/Sidebar' 
import Footer from '../Footer/Footer'
function SevaDetails() {
  const location = useLocation()
  const location1 = useLocation()
  const receivedData = location.state
  const receivedData1 = location1.state
  console.log(receivedData1)
  const navigate = useNavigate()
  const status = receivedData.donation_needed === '1' ? 'Yes' : 'No'

  const clickSevaDetails = (data1, data2) => {
    navigate(`/pandal-full-details/${receivedData.pandal_id}`, {
      state: receivedData1,
    })
  }

  // const fetchData = async () => {
  //   try {
  //     const data = await getAllPartners()
  //     setData(data)
  //     setLoading(false)
  //   } catch (error) {
  //     console.error('Error fetching table data:', error)
  //     setLoading(false)
  //   }
  // }

  // useEffect(()=>{
  //   fetchData()
  // },[])
  
  return (
    <>
      <div className="main-content">
        <Header />
        <div className="app-menu navbar-menu" style={{ display: 'flex' }}>
          <Sidebar />
        </div>
        <div className="page-content">
          <PageHeader HeaderName="Seva Details" />

          <div className="container-fluid pt-3">
            <div className="row">
              <div className="col-xl-12 col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="col-md-3 mb-3">
                      <button
                        onClick={() => clickSevaDetails(receivedData)}
                        className="btn btn-info"
                      >
                        Back
                      </button>
                    </div>
                    <form method="">
                      <div className="row">
                        <div className="col-md-9 mb-3">
                          <div className="row">
                            <div className="col-12">
                              <div className="col-md-8 mb-3">
                                <small>Seva Name</small>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={receivedData?.seva_name} 
                                />
                              </div>

                              <div className="col-md-8 mb-3">
                                <small>Seva Type</small>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={receivedData?.seva_type} 
                                />
                              </div>
                              <div className="col-md-8 mb-3">
                                <small>Description</small>
                                <textarea
                                  className="form-control"
                                  rows="4"
                                  value={receivedData.about_seva}
                                ></textarea>
                              </div>
                            </div>
                            <div className="col-md-6 mb-3">
                              <small>Seva Start Date</small>
                              <input
                                type="date"
                                className="form-control"
                                value={receivedData?.save_start_date} 
                              />
                            </div>
                            <div className="col-md-6 mb-3">
                              <small>Seva End Date</small>
                              <input
                                type="date"
                                className="form-control"
                                value={receivedData?.seva_end_date} 
                              />
                            </div>
                            <div className="col-md-6 mb-3">
                              <small>Seva Start Time</small>
                              <input
                                type="time"
                                className="form-control"
                                value={receivedData?.seva_start_time} 
                              />
                            </div>
                            <div className="col-md-6 mb-3">
                              <small>Seva end Date</small>
                              <input
                                type="time"
                                className="form-control"
                                value={receivedData?.seva_end_time} 
                              />
                            </div>
                            <div className="col-md-6 mb-3">
                              <small>Donations Accepted</small>
                              {/* <textarea className="form-control" rows="4"></textarea> */}
                              <input
                                type="text"
                                className="form-control"
                                value={status} 
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 mb-3">
                          <img
                            src={receivedData?.images[0]?.image}
                            alt='pujam_pandal'
                            className="img-fluid mt-3"
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default SevaDetails
