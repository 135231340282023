import { React, useState, useEffect, useMemo } from "react";
import Header from "../../Header/Header";
import Sidebar from "../../Sidebar/Sidebar";
import PageHeader from "../../PageHeader/PageHeader";
import DataTable from "react-data-table-component";

const   Temple = () => {
  const data = [
    {
      id: 1,
      name: "Pooja 1",
      duration: "2 hours",
      dakshina: "$50",
      samagri: "Flowers",
      samagriPrice: "$20",
    },
    {
      id: 2,
      name: "Pooja 2",
      duration: "1 hour",
      dakshina: "$30",
      samagri: "Incense sticks",
      samagriPrice: "$10",
    },
    // Add more sample data as needed
  ];
  const columns = useMemo(
    () => [
      {
        name: "Sl.No",
        // selector: "slno",
        sortable: true,
        format: (row, rowIndex) => rowIndex + 1,
      },
      {
        name: "Pooja Name",
        // selector: "pandal_name",
        sortable: true,
      },
      {
        name: "Duration",
        // selector: "pandal_about",
        sortable: true,
      },
      {
        name: "Dakshina",
        // selector: "pandal_address",
        sortable: true,
      },
      {
        name: "Samagri",
        // selector: "pandal_start_date",
        // cell: (row) => formatDate(row.pandal_start_date),
        sortable: true,
      },
      {
        name: "Samagri Price",
        // selector: "pandal_start_date",
        // cell: (row) => formatDate(row.pandal_start_date),
        sortable: true,
      },
      {
        name: "Actions",
        cell: (row) => (
          <div>
            <button
              class="btn btn-sm btn-edit me-2"
              //   onClick={() => clickPandalDetails(row)}
            >
              Full Details
            </button>
          </div>
        ),
      },
    ],
    []
  );

  const customStyles = {
    rows: {
      style: {
        // border: '0.1px solid #ddd',
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        color: "black",
        fontSize: "14px",
        backgroundColor: "#ccc",
        fontFamily: "Poppins",
        fontWeight: "600",
        border: "0.1px solid #ddd",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        border: "0.1px solid #ddd",
      },
    },
  };

  return (
    <>
      <div className="main-content">
        <Header />
        <div className="app-menu navbar-menu" style={{ display: "flex" }}>
          <Sidebar />
        </div>
        <div className="page-content">
          <PageHeader HeaderName="Temples" />
          <div className="container-fluid pt-3">
            <div className="row mb-2">
              <div style={{ display: "flex", marginRight: "50px" }}>
                <h5 style={{ color: "#A90000" }}>Temples</h5>
                <div className="col-md-3 ms-auto mb-3"></div>
                <button
                  className="button-container"
                  style={{
                    marginTop: "0px",
                    height: "35px",
                    width: "150px",
                    marginLeft: "30px",
                  }}
                >
                 Add Temple
                </button>
              </div>
            </div>
        
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default Temple;
