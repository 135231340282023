import React, { useEffect, useState } from 'react';
import Header from '../Header/Header';
import PujariSidebar from './PujariSidebar';
import { getApiData } from './API';
import { PujariProfileInfo, imgUrl } from './Constants';
import { showToast } from './Toast';
import PageHeader from '../PageHeader/PageHeader';

function PujariProfile() {
    const [profile, setProfile] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [retryCount, setRetryCount] = useState(0);
    const maxRetries = 3;
    const retryDelay = 1000;

    const getProfileInfo = async () => {
        try {
            const data = await getApiData(PujariProfileInfo);
            console.log(data);
            setProfile(data);
            setRetryCount(0); // Reset retry count on success
        } catch (error) {
            if (error.response.status === 429) {
                showToast('Too many requests', 'error');
            } else if (error.response.status === 404) {
                showToast('Not Found', 'error');
                if (retryCount < maxRetries) {
                    setRetryCount(retryCount + 1);
                    setTimeout(getProfileInfo, retryDelay);
                }
            } else {
                showToast('Something went wrong', 'info');
            }
        }
    };

    const handleEditClick = () => {
        setIsEditing(!isEditing);
    };

    useEffect(() => {
        getProfileInfo();
    }, []);

    return (
        <>
            <div className="main-content">
                <Header />
                <div className="app-menu navbar-menu" style={{ display: 'flex' }}>
                    <PujariSidebar />
                </div>
                <div className="page-content">
                    <PageHeader HeaderName="Pujari Profile" />
                    <div className="container mt-5">
                        {/* <div className='row mt-3'>
                             <div className='col-md-12  text-right'>
                                <button className="btn btn-primary" onClick={handleEditClick}>
                                    {isEditing ? 'Cancel Edit' : 'Edit Profile'}
                                </button>
                            </div> 
                        </div>  */}
                        <div className='row'>
                            <div className='col-md-3'>
                                <img src={imgUrl + profile.image} alt="pandit-image" />
                            </div>
                            <div className='col-md-6'>
                                <table style={{ lineHeight: '35px', fontSize: '18px' }}>
                                    <tbody>
                                        <tr>
                                            <th>User Name</th>
                                            <th style={{ paddingLeft: '10px' }}> : </th>
                                            <th style={{ fontSize: '15px', color: 'black', fontWeight: '400', paddingLeft: '15px' }}> {profile.name}</th>
                                        </tr>
                                        <tr>
                                            <th>Phone Number</th>
                                            <th style={{ paddingLeft: '10px' }}> : </th>
                                            <th style={{ fontSize: '15px', color: 'black', fontWeight: '400', paddingLeft: '15px' }}> {profile.mobile}</th>
                                        </tr>
                                        <tr>
                                            <th>Email</th>
                                            <th style={{ paddingLeft: '10px' }}> : </th>
                                            <th style={{ fontSize: '15px', color: 'black', fontWeight: '400', paddingLeft: '15px' }}> {profile.email}</th>
                                        </tr>
                                        <tr>
                                            <th>Category</th>
                                            <th style={{ paddingLeft: '10px' }}> : </th>
                                            <th style={{ fontSize: '15px', color: 'black', fontWeight: '400', paddingLeft: '15px' }}>{profile.category}</th>
                                        </tr>
                                        <tr>
                                            <th>Address</th>
                                            <th style={{ paddingLeft: '10px' }}> : </th>
                                            <th style={{ fontSize: '15px', color: 'black', fontWeight: '400', paddingLeft: '15px' }}>{profile.Address}</th>
                                        </tr>
                                        <tr>
                                            <th>Temples</th>
                                            <th style={{ paddingLeft: '10px' }}> : </th>
                                            <th style={{ fontSize: '15px', color: 'black', fontWeight: '400', paddingLeft: '15px' }}>{profile.temple_name}</th>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PujariProfile;
