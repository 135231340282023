import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { React } from 'react'
import AddNewPackage from "./Components/Masters/AddNewPackage/AddNewPackage";
import AddNewDesignation from "./Components/Masters/AddNewDesignation/AddNewDesignation";
import AddNewLocation from "./Components/Masters/AddNewLocation/AddNewLocation";
import AddNewSevaType from "./Components/Masters/AddNewSevaType/AddNewSevaType";
import AddNewExpenseType from "./Components/Masters/AddNewExpenseType/AddNewExpenseType";
import Auction from "./Components/Auction/Auction";
import Dashboard from "./Components/Dashboard/Dashboard";
import Employee from "./Components/Employee/Employee";
import Partners from "./Components/Partners/Partners";

import Sponsors from "./Components/Sponsors/Sponsors";
import Users from "./Components/Users/Users";
import Validation from "./Components/Validation/Validation";
import Review from "./Components/Review/Review";
import Notification from "./Components/Notification/Notification";
import AuctionOneView from "./Components/Auction/AuctionOneView";
import AuctionThreeView from "./Components/Auction/AuctionThreeView";
import AuctionTwoView from "./Components/Auction/AuctionTwoView";
import AuctionProfile from "./Components/Auction/AuctionProfile";
import PandalDetails from "./Components/Details/PandalDetails";
import UserReview from "./Components/Review/UserReview";
import SponsorDetail from "./Components/Sponsors/SponsorDetail";
import UserDetail from "./Components/Users/UserDetail";
import ValidationDetail from "./Components/Validation/ValidationDetail";
import Payment from "./Components/Others/Payment";
import SevaDetails from "./Components/Details/SevaDetails";
import ViewPost from "./Components/Others/ViewPost";
import Login from "./Components/Login/Login";
import RegisterSponsorDetails from "./Components/Sponsors/registerSponsorDetails";
import { ToastContainer } from "react-toastify";
import { PrivateRoute } from "./Components/PrivateRoute";
import NotFound from "./NotFound/NotFound";
import "react-toastify/dist/ReactToastify.css";
import EmployeeDetail from "./Components/Employee/EmployeeDetail";
import RegisterPage from "./Components/RegisterPage/registerPage";
import CalendarDetails from "./Components/Pujari/Calendar/calendar";
import PoojaServices from "./Components/Pages/PoojaServices/poojaServices";
import MyBookings from "./Components/Pages/MyBookings/myBookings";
import Temple from "./Components/Pages/Temples/temples";
import Sevas from "./Components/Pages/Sevas/sevas";
import Poojari from "./Components/Pages/Poojari/poojari";
import Darshanam from "./Components/Pages/Darshanam/darshanam";
import TempleAuction from "./Components/Pages/TempleAuction/templeAuction";
import TempleProducts from "./Components/Pages/Products/products";
import TempleDonations from "./Components/Pages/Donations/donations";
import TempleReviews from "./Components/Pages/TempleReviews/templeReviews";
import TempleDashboard from "./Components/Pages/TempleDashboard/templeDashboard";
import TemplePhotos from "./Components/Pages/TemplePhotos/templePhotos";
import PujariDashboard from "./Components/Pujari/PujariDashboard";
import PujariProfile from "./Components/Pujari/PujariProfile";
import PujariPoojaService from "./Components/Pujari/PujariPoojaService";
import PujariReviews from "./Components/Pujari/PujariReviews";
import PujariPoojaBookings from "./Components/Pujari/PujariPoojaBookings";
import PujariImages from "./Components/Pujari/PujariImages";
import PujariTemple from "./Components/Pujari/PujariTemple";
import TempleProfile from "./Components/Pages/TempleProfile/templeProfile";
import PujariRegister from "./Components/Pujari/PujariRegister";
import PujariCalender from "./Components/Pujari/PujariCalender";
import Banners from "./Components/Banners/Banners";
import SamagriKit from "./Components/SamagriKit/SamagriKit";
import PartnerTabs from "./Components/Partners/PartnerTabs";
import ViewFulldetails from "./Components/Partners/ViewFulldetails";
import ViewFullSevadetails from "./Components/Partners/ViewFullSevadetails";
import ValidationDetail1 from "./Components/Validation/ValidationDetail1";
import ValidationDetail2 from "./Components/Validation/ValidationDetail2";
import PoojariViewFullDetails from "./Components/Partners/PoojariViewFullDetails";
import PujariPoojaViewDetails from "./Components/Partners/PujariPoojaViewDetails";
import TemplepoojariViewdetails from "./Components/Partners/TemplepoojariViewdetails";
import TempleDharshanamViewDetails from "./Components/Partners/TempleDharshanamViewDetails";
import TempleRegistration from "./Components/Pages/TempleRegistration";
import BankDetailsTemple from "./Components/Pages/BankDetailsTemple";
import BankDetailsModalTemple from "./Components/Pages/BankDetailsModalTemple";
import BankDetailsPujari from "./Components/Pujari/BankDetailsPujari";
import BankDetailsModalPujari from "./Components/Pujari/BankDetailsModalPujari";






function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/register-page" element={<RegisterPage />} />
          <Route path="/dashboard" element={<PrivateRoute element={Dashboard} />} />
          <Route path="/addnewlocations" element={<PrivateRoute element={AddNewLocation} />} />
          <Route path="/addnewpackages" element={<PrivateRoute element={AddNewPackage} />} />
          <Route path="/addnewdesignations" element={<PrivateRoute element={AddNewDesignation} />} />
          <Route path="/addnewsevatype" element={<PrivateRoute element={AddNewSevaType} />} />
          <Route path="/addnewexpensetype" element={<PrivateRoute element={AddNewExpenseType} />} />
          <Route path="/employee" element={<PrivateRoute element={Employee} />} />
          <Route path="/list-of-all-pandals-rigistered" element={<PrivateRoute element={Partners} />} />
          <Route path="/sponsors" element={<PrivateRoute element={Sponsors} />} />
          <Route path="/partnertabs" element={<PrivateRoute element={PartnerTabs} />} />
          <Route path="/list-of-all-users-rigistered" element={<PrivateRoute element={Users} />} />
          <Route path="/auctions" element={<PrivateRoute element={Auction} />} />
          <Route path="/validation" element={<PrivateRoute element={Validation} />} />
          <Route path="/reviews" element={<PrivateRoute element={Review} />} />
          <Route path="/notifications" element={<PrivateRoute element={Notification} />} />
          <Route path="/auction-one-view" element={<PrivateRoute element={AuctionOneView} />} />
          <Route path="/auction-three-view" element={<PrivateRoute element={AuctionThreeView} />} />
          <Route path="/auction-two-view" element={<PrivateRoute element={AuctionTwoView} />} />
          <Route path="/auction-user-profile" element={<PrivateRoute element={AuctionProfile} />} />
          <Route path="/pandal-full-details/:id" element={<PrivateRoute element={PandalDetails} />} />
          <Route path="/user-reviews" element={<PrivateRoute element={UserReview} />} />
          <Route path="/sponsor-full-details" element={<PrivateRoute element={SponsorDetail} />} />
          <Route path="/register-sponsor-full-details" element={<PrivateRoute element={RegisterSponsorDetails} />} />
          <Route path="/users-full-details" element={<PrivateRoute element={UserDetail} />} />
          <Route path="/validations-details" element={<PrivateRoute element={ValidationDetail} />} />
          <Route path="/validations-details1" element={<PrivateRoute element={ValidationDetail1} />} />
          <Route path="/validations-details2" element={<PrivateRoute element={ValidationDetail2} />} />
          <Route path="/payment" element={<PrivateRoute element={Payment} />} />
          <Route path="/seva-details" element={<PrivateRoute element={SevaDetails} />} />
          <Route path="/view-post" element={<PrivateRoute element={ViewPost} />} />
          <Route path="/employee-detail" element={<PrivateRoute element={EmployeeDetail} />} />
          <Route path="/pooja-services" element={<PrivateRoute element={PoojaServices} />} />
          <Route path="/my-bookings" element={<PrivateRoute element={MyBookings} />} />
          <Route path="/temples" element={<PrivateRoute element={Temple} />} />
          <Route path="/sevas" element={<PrivateRoute element={Sevas} />} />
          <Route path="/pujari" element={<PrivateRoute element={Poojari} />} />
          <Route path="/darshanam" element={<PrivateRoute element={Darshanam} />} />
          <Route path="/temple-auction" element={<PrivateRoute element={TempleAuction} />} />
          <Route path="/temple-products" element={<PrivateRoute element={TempleProducts} />} />
          <Route path="/temple-donations" element={<PrivateRoute element={TempleDonations} />} />
          <Route path="/temple-reviews" element={<PrivateRoute element={TempleReviews} />} />
          <Route path="/temple-bankdetails" element={<PrivateRoute element={BankDetailsTemple} />} />
          <Route path="/temple-dashboard" element={<PrivateRoute element={TempleDashboard} />} />
          <Route path="/temple-photos" element={<PrivateRoute element={TemplePhotos} />} />
          <Route path="/temple-profile" element={<PrivateRoute element={TempleProfile} />} />
          <Route path="/addbanners" element={<PrivateRoute element={Banners} />} />
          <Route path="/samagrikit" element={<PrivateRoute element={SamagriKit} />} />
          {/* Pujari Dashboard */}
          <Route path="/pujari-dashboard" element={<PrivateRoute element={PujariDashboard} />} />
          <Route path="/pujari-Profile" element={<PrivateRoute element={PujariProfile} />} />
          <Route path="/poojaService" element={<PrivateRoute element={PujariPoojaService} />} />
          <Route path="/pujariReviews" element={<PrivateRoute element={PujariReviews} />} />
          <Route path="/poojaBookings" element={<PrivateRoute element={PujariPoojaBookings} />} />
          <Route path="/calendar" element={<PrivateRoute element={CalendarDetails} />} />
          <Route path="/pujariImages" element={<PrivateRoute element={PujariImages} />} />
          <Route path="/viewfulldetails" element={<PrivateRoute element={ViewFulldetails} />} />
          <Route path="/viewfullSevadetails" element={<PrivateRoute element={ViewFullSevadetails} />} />

          <Route path="/poojaTemple" element={<PrivateRoute element={PujariTemple} />} />
          
          <Route path="/poojaCalender" element={<PrivateRoute element={PujariCalender} />} />
          <Route path="/poojariImages" element={<PrivateRoute element={PujariImages} />} />
          <Route path="/PoojariViewFullDetails" element={<PrivateRoute element={PoojariViewFullDetails} />} />
          <Route path="/PujariPoojaViewDetails" element={<PrivateRoute element={PujariPoojaViewDetails} />} />

          <Route path="/TemplepoojariViewdetails" element={<PrivateRoute element={TemplepoojariViewdetails } />} />
          <Route path="/TempleDharshanamViewDetails" element={<PrivateRoute element={TempleDharshanamViewDetails } />} />
          <Route path="/temple-registration" element={<TempleRegistration />} />
          <Route path="/pujariRegisteration" element={<PujariRegister />} />
          <Route path="/Bankdetailsmodeltemple" element={<BankDetailsModalTemple />} />
          <Route path="/pujari-bankdetails" element={<PrivateRoute element={BankDetailsPujari} />} />
          <Route path="/Bankdetailsmodalpujari" element={<BankDetailsModalPujari />} />
          {/* <Route path="/pujari-registration" element={<PrivateRoute element={PujariRegister} />} /> */}

          <Route path="/*" element={<NotFound />} />
        </Routes>
      </Router>
      <ToastContainer />
      {/* {window.location.pathname == '/' ? '' :
        <div style={{ display: 'flex', justifyContent: 'flex-end', position: "sticky", bottom: '0' }}>
          <Footer />
        </div>
      } */}
    </>
  );
}
export default App;
