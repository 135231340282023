import React, { useEffect, useState } from 'react'
import Header from '../Header/Header'
import PujariSidebar from './PujariSidebar'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PageHeader from '../PageHeader/PageHeader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { fetchSevaTemples } from '../API/FetchAPI';
import BankDetailsModalPujari from './BankDetailsModalPujari'; 


function BankDetailsPujari() {
    const [bankdetails, setBankDetails] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
   
  
  
  //   const loadBankDetails = async () => {
  //     try {
  //       console.log('Fetching bank details...');
  //         const response = await fetchSevaTemples('pujari/createBank/getBankById');
  //         console.log('Response:', response);
  //         if (!response.ok) {
  //             throw new Error(`Failed to fetch: ${response.status} ${response.statusText}`);
  //         }

  //         const data = await response.json();
  //         console.log('Data:', data);
  //         if (data.data) {
  //             setBankDetails(data.data);
  //         } else {
  //             throw new Error('Invalid response structure');
  //         }
  //     } catch (error) {
  //         console.error('Error loading bank details:', error);
  //         toast.error('Failed to load bank details. Please try again.');
  //     }
  // };

  // useEffect(() => {
  //     loadBankDetails();
  // }, []);


  const loadBankDetails = async () => {
    try {
      const response = await fetchSevaTemples('pujari/getBankById');
      
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const data = await response.json(); // Wait for the JSON parsing to complete
      console.log('Data:', data); // Log the data to verify
  
      if (data) {
        setBankDetails(data); // Update state with the fetched data
      } else {
        throw new Error('Invalid data structure');
      }
    } catch (error) {
      console.error('Error loading bank details:', error);
      toast.error('Failed to load bank details. Please try again.');
    }
  };
  
  useEffect(() => {
    loadBankDetails();
  }, []);
  
    const handleCreateClick = () => {
      setIsEditMode(false);
      setIsModalOpen(true);
    };
  
    const handleEditClick = () => {
      setIsEditMode(true);
      setIsModalOpen(true);
    };
  
    const handleSave = async (details) => {
    try {
      const apiurl = 'pujari/createBank';
      const method = 'POST'; 
      const response = await fetchSevaTemples(apiurl, method, details);
  
      if (response.ok) {
        toast.success(`${isEditMode ? 'Bank details updated' : 'Bank details created'} successfully`);
        setIsModalOpen(false);
        loadBankDetails(); 
      } else {
        const errorText = await response.text();
        toast.error(`Failed to save bank details: ${errorText}`);
      }
    } catch (error) {
      console.error('Error saving bank details:', error);
      toast.error('Failed to save bank details. Please try again.');
    }
  };
  
  
    return (
      <>
        <div className="main-content">
          <Header />
          <div className="app-menu navbar-menu" style={{ display: "flex" }}>
            <PujariSidebar />
            </div>
          <div className="page-content">
          <PageHeader HeaderName="Bank Details" />
            <div>
              <div className='Card_form Bank_deatils_main'>
                  <div className='text-end'>
                      <button className="btn btn-primary me-2" onClick={handleCreateClick}>Create</button>
                      <button className="btn btn-secondary" onClick={handleEditClick}>
                      <FontAwesomeIcon icon={faPencilAlt} /></button>
                  </div>
              
                <div className='bank_img_main'>
                  <img src="../../../assets/images/bank.png" className='img-fluid' alt='' style={{width: '110px', marginBottom: '35px'}} />
                </div>
               
                <div className='row' style={{fontSize: '17px'}}>
                  <div className='col-md-2 ms-auto position_relative'>
                    <p><b>Full Name </b> <span className='span_p'>:</span></p>
                    <p><b>Bank Name </b> <span className='span_p'>:</span></p>
                    <p><b>Account No </b> <span className='span_p'>:</span></p>
                    <p><b>IFSC Code </b> <span className='span_p'>:</span></p>
                    <p><b>Address </b> <span className='span_p'>:</span></p>
                  </div>
                <div className="col-md-3 me-auto position_relative">
                    <p>{bankdetails.name }</p>
                    <p>{bankdetails.bank_name}</p>
                    <p>{bankdetails.account_no}</p>
                    <p>{bankdetails.ifsc}</p>
                    <p>{bankdetails.address}</p> 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
        {isModalOpen && (
          <BankDetailsModalPujari
            isEditMode={isEditMode}
            bankdetails={isEditMode ? bankdetails : {}}
            onSave={handleSave}
            onClose={() => setIsModalOpen(false)}
          />
        )}
      </>
    );
  }
  

export default BankDetailsPujari
