import React, { useEffect, useState } from 'react'
import Header from '../Header/Header'
import PujariSidebar from './PujariSidebar'
import PageHeader from '../PageHeader/PageHeader'
import { deleteApiResponse, getApiData } from './API'
import CreatePoojaModel from './CreatePoojaModel'
import { DeletePooja, GetAllPooja, GetSinglePooja } from './Constants'
import { showToast } from './Toast'

function PujariPoojaService() {
    const[poojaDetails,setPoojaDetails] = useState([]);
    const[openModal,setOpenModal] = useState(false)
    const [editId,setEditId] = useState('');
    const[formInput,setFormInput] = useState({
        pooja_name : '',
        description : '',
        duration : '',
        dakshina : '',
        samagri : '',
        samagri_price : '',
    });

    const GetPoojaDetails = async () => {
        const data = await getApiData(GetAllPooja);
        setPoojaDetails(data);
    }

    const handleOpen = () => {
        setOpenModal(true);
        setEditId('');
        setFormInput({
                pooja_name : '',
                description : '',
                duration : '',
                dakshina : '',
                samagri : '',
                samagri_price : '',
            });

    }

    const editDetails = async (pooja_id) => {
        const PoojaById = await getApiData(GetSinglePooja + pooja_id);
        setFormInput({
          pooja_name: PoojaById.pooja_name,
          description: PoojaById.description,
          duration: PoojaById.duration,
          dakshina: PoojaById.dakshina,
          pack_id: PoojaById.pack_id,
          samagri_price: PoojaById.samagri_price,
          process :PoojaById.process ,
          benfits :PoojaById.benfits ,
         
        });
        setEditId(pooja_id);
        setOpenModal(true);
      };

    const deleteModel = async (id,name) => {
        let text = "Are you sure want to delete "+name;
        if (window.confirm(text) == true) {
            const res = await deleteApiResponse(DeletePooja+id);
            if(res.status === 200){
                showToast(name+" was Deleted Succesfully",'success');
                GetPoojaDetails();
            }else{
                showToast("Something went wrong",'success');
            }
        } else {
            showToast("changes are not saved",'info');
        }
    }

    const propDetails = {
        openModal:openModal,
        setOpenModal:setOpenModal,
        GetPoojaDetails:GetPoojaDetails,
        formInput:formInput,
        setFormInput:setFormInput,
        editId:editId,
    }
    
    useEffect(()=>{
        GetPoojaDetails();
    },[])

  return (
    <>
        <div className="main-content">
            <Header />
            <div className="app-menu navbar-menu" style={{ display: 'flex' }}>
                <PujariSidebar />
            </div>
            <div className="page-content">
                <PageHeader HeaderName="Pooja/Service" />
                <div className='container'>
                    <div className='row mt-3'>
                        <div className='col'>
                            <h4>Pooja</h4>
                        </div>
                        <div className='col d-flex justify-content-end'>
                            <button className='btn btn-sm btn-danger' onClick={()=>handleOpen()}>Create Pooja</button>
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className='col'>
                            <table className='table table-bordered table-bordered'>
                                <thead className='bg-warning'>
                                    <tr>
                                        <th>SNo</th>
                                        <th>Pooja Name</th>
                                        <th>Description</th>
                                        <th>Duration</th>
                                        <th>Daksina</th>
                                        <th>Samagri</th>
                                        <th>Samagri Price</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                    poojaDetails.map((pooja,index)=>(
                                        <tr key={pooja.id}>
                                            <td>{index + 1}</td>
                                            <td>{pooja.pooja_name}</td>
                                            <td>{pooja.description}</td>
                                            <td>{pooja.duration}</td>
                                            <td>{pooja.dakshina}</td>
                                            <td>{pooja.pack_id}</td>
                                            <td>{pooja.samagri_price}</td>
                                            <td>
                                                <i class="fa-solid fa-edit" style={{ fontSize: "16px", color: "blue" }} onClick={()=>editDetails(pooja.id)}></i>
                                                <i class="fa-solid fa-trash-can" style={{ fontSize: "16px", color: "red", marginLeft: '13px' }} onClick={()=>deleteModel(pooja.id,pooja.pooja_name)}></i>
                                            </td>   
                                        </tr>
                                    ))
                                    }
                                    
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <CreatePoojaModel {...propDetails}/>
            </div>
        </div>
    </>
  )
}

export default PujariPoojaService