import React, { useState } from 'react';
import Modal from "react-bootstrap/Modal";
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { postApiResponse } from '../Pujari/API';
import { PostAllSamagries } from '../Pujari/Constants';



const PostAllSamagriKit = async () => {

    const formData = new FormData();
    // formData.append('image', image);

    try {
      const response = await fetch('http://192.168.1.66:8000/api/admin/samagriKit', {
        method: 'POST',
        body: formData,
        headers:{
          Authorization : `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiMzMzODU4NDVjYWFiOTkzNzEwYTA3MDMzMzkyNzIxMmI5YjNiMGQ3ZjQzZWI5OWNjM2JjY2JiYWVmODgxYzM3MTM1ZWQ1Y2VmZGY5ZWQ5ZDIiLCJpYXQiOjE3MTUyNTI2MzguNjAzNzQ2LCJuYmYiOjE3MTUyNTI2MzguNjAzNzQ2LCJleHAiOjE3NDY3ODg2MzguNjAwODg5LCJzdWIiOiI4MjAiLCJzY29wZXMiOltdfQ.xewVNyrpiVRlLosZPedoHCP8C2bI0rY7tLzG3Rse30iGR9gFN-mcFKEYKrjrpSnezwT7QpFXFYBHDD_vpvWZwxX_DYQHQL5X-VfliDrJIdqra-BEHV4NeeiZiJ69oaW2WsyMWjHMhyIfHnzgooTp3JuPTRedbzHkIY1YirvE7jMxbe86mbfFnWYW36R6VICdGjftDGMOoS9Lz6fb2MbnPR1cfYE-PMzahzwn8NKf4uMiObY2APelAeru0RpqPWhFKkxewxZy77L4QtzmHOPrqyu9T0hP7Uhie2nGHBxaXwd09-uk53U2Hyb-kcPklEuEyxLMHcS3im7c3pzuDzJM6H-In1wANjVuGGymp99TiVjJcjE7upycMhTZubP4HR31vCp-1vQdfiEC98Rx7txdGgrvl7MLxJBRhavL6Dm4Wpe-9rCK0d3l8XCrSxs84D95Dwl9igIOL7lCXyVBCfZpA8MfqOnadckCyIBNVHspUxk0E8yaKxvkbBtKjCI4rF8Eb9s-uNZwSi2zjsCHbkPeU8S6NeiH4sQOOZRV0VKHVSZzNwje-kRFirqlXdlUMeZyr3whGGopfvymKcgBH5yzxFs7YaYvenp0eEyxasezazMBkZnFLitzHwlM4RZvTnP0-AMtipyLu3yotI1QBHVPl1mGMPHn3O5kW6V1OzDNx9E`, 
          Accept : 'application/json',
        }
      });

      if (response.ok) {
        console.log('Image uploaded successfully');
        // Handle success, e.g., show a success message
      } else {
        console.error('Failed to upload image');
        // Handle failure, e.g., show an error message
      }
    } catch (error) {
      console.error('Error uploading image:', error);
      // Handle error, e.g., show an error message
    } 
  };



 
const SamagriKitModal = (props) => {
    const { openModal, setOpenModal, kitItems, setKitItems, editId, fetchSamagriKits } = props;
    const [errors, setErrors] = useState({});
    const [formInput, setFormInput] = useState({
        packName: '',
        finalAmount: '',
        quantity: [],
        image: [],
        samagriName: []
    });
 
    const closeModal = () => {
        setOpenModal(false);
    };
 
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormInput(prevData => ({ ...prevData, [name]: value }));
    };
 
     
 
    const handleInputQuantityChange = (index, value) => {
        const quantity = [...formInput.quantity];
        quantity[index] = value;
        setFormInput(prevData => ({ ...prevData, quantity }));
    };
 
    const handleImageChange = (index, e) => {
        const files = Array.from(e.target.files);
        const updatedImages = [...formInput.image];
        updatedImages[index] = files;
        setFormInput(prevData => ({ ...prevData, image: updatedImages }));
      };
 
   
   
   
 
    const handleSamagriNameChange = (index, value) => {
        const samagriName = [...formInput.samagriName];
        samagriName[index] = value;
        setFormInput(prevData => ({ ...prevData, samagriName }));
    };
 
    const addItem = () => {
        const newItem = { samagriName: '', quantity: '', image: null };
        setKitItems([...kitItems, newItem]);
        setFormInput(prevData => ({ ...prevData, quantity: [...prevData.quantity, ''] }));
    };
 
    const validateForm = () => {
        // Your validation logic here
    };
 
    const saveKit = async (e) => {
        e.preventDefault();
        try {
           
            const formData = new FormData();
    formData.append('packName', formInput.packName);
    formData.append('finalPrice', formInput.finalPrice);

    formInput.quantity.forEach((quantity, index) => {
      formData.append(`quantity[${index}]`, quantity);
    });

    formInput.samagriName.forEach((samagriName, index) => {
      formData.append(`samagriName[${index}]`, samagriName);
    });

    formInput.image.forEach((imageArray, index) => {
      imageArray.forEach((image, imgIndex) => {
        formData.append(`image[${index}]`, image);
      });
    });
 
   
            if (editId) {
                // Handle update case
            } else {
                const response = await postApiResponse(PostAllSamagries, formData);
                console.log(response, 'response from submit');
            }
            fetchSamagriKits()
            closeModal();
        } catch (error) {
            console.error('Error saving kit:', error);
            
        }
    };
   
 
    return (
        <Modal
            show={openModal}
            onHide={closeModal}
            size="lg"
            aria-labelledby="example-modal-sizes-title-lg"
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header>
                <Modal.Title
                    id="example-modal-sizes-title-lg"
                    style={{ color: "#A90000" }}
                >
                    {editId ? "Update" : "Create"} Samagri Kit
                </Modal.Title>
                <button type="button" className="btn-close" aria-label="Close" onClick={closeModal}></button>
            </Modal.Header>
            <hr />
            <Modal.Body style={{ marginTop: "-20px" }}>
                <form onSubmit={saveKit} id="samagriKitForm">
                    <div style={{ marginBottom: '20px' }}>
                        <h4 style={{ marginBottom: '20px', color: 'red', fontSize: '18px' }}>Kit Name</h4>
                        <div style={{ display: 'flex', marginBottom: '10px' }}>
                            <div style={{ marginRight: '135px' }}>
                                <h5 style={{ fontSize: '14px' }}>Samagri Kit Name</h5>
                                <input
                                    type="text"
                                    placeholder="Enter Samagri Kit Name"
                                    name='packName'
                                    // value={formInput.packName}
                                    style={{ padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <h5 style={{ fontSize: '14px' }}>Enter Amount</h5>
                                <input
                                    type="text"
                                    placeholder="Enter Amount"
                                    name='finalPrice'
                                    // value={formInput.finalAmount}
                                    style={{ padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
                                    pattern="[0-9]*"
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                    </div>
 
                    <div style={{ marginBottom: '20px' }}>
                        <h3 style={{ marginBottom: '20px', color: 'red', fontSize: '18px' }}>Add Items</h3>
                        <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', marginBottom: '10px' }}>
                            {kitItems.map((item, index) => (
                                <div key={index} style={{ display: 'flex', marginBottom: '10px' }}>
                                    <div style={{ marginRight: '10px' }}>
                                        <h5 style={{ fontSize: '14px' }}>Add Item</h5>
                                        <input
                                            type="text"
                                            placeholder="Enter Item Name"
                                            name="samagriName"
                                            style={{ padding: '8px', borderRadius: '4px', border: '1px solid #ccc', flex: '1' }}
                                            value={formInput.samagriName[index] || ''}
                                            onChange={(e) => handleSamagriNameChange(index, e.target.value)}
                                        />
                                    </div>
                                    <div style={{ marginRight: '10px' }}>
                                        <h5 style={{ fontSize: '14px' }}>Enter Quantity</h5>
                                        <input
                                            type="text"
                                            placeholder="Enter Quantity"
                                            name='quantity'
                                            value={formInput.quantity[index] || ''}
                                            style={{ padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
                                            onChange={(e) => handleInputQuantityChange(index, e.target.value)}
                                        />
                                    </div>
                                    <div>
                                        <h5 style={{ fontSize: '14px' }}>Upload Item Image</h5>
                                        <input
                                            type="file"
                                            name='image'
                                            style={{ padding: '8px', borderRadius: '4px', border: '1px solid #ccc', width: '205px' }}
                                            // multiple  // Add this attribute to allow multiple file selection
                                            onChange={(e) => handleImageChange(index, e)}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                        <button type="button" onClick={addItem} style={{ padding: '8px 16px', borderRadius: '4px', backgroundColor: '#007bff', color: '#fff', border: 'none', cursor: 'pointer', marginRight: '10px', marginTop: '25px' }}>
                            <FontAwesomeIcon icon={faPlus} style={{ marginRight: '5px' }} />
                        </button>
                    </div>
                    <button type="submit" style={{ padding: '8px 16px', borderRadius: '4px', backgroundColor: '#28a745', color: '#fff', border: 'none', cursor: 'pointer', marginBottom: '10px' }}>{editId ? "Update" : "Save"}</button>

                </form>
            </Modal.Body>
        </Modal>
    );
};
 
export default SamagriKitModal;