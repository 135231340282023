import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import './PujariRegister.css'
import { useForm } from 'react-hook-form'
import { PujariCreate, getAllAwardDoc, getAllCategory, getAllEduDoc, getAllKycDoc, getAllLanguage, getAllSunSign } from '../API/PujariApis/RegisterApi'
import Header from '../Header/Header'

function PujariRegister() {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm()

  const [category, setCategory] = useState([])
  const [language, setLanguage] = useState([])
  const [gender, setGender] = useState([])
  const [sunSign, setSunSign] = useState([])
  const [kycDoc, setKycDoc] = useState([])
  const [eduDoc, setEduDoc] = useState([])
  const [awardDoc, setAwardDoc] = useState([])

  const fetchCategory = async () => {
    try {
      const response = await getAllCategory()
      setCategory(response)
      // console.log(response, 'response for category')
    } catch (error) {
      throw error
    }
  }

  const fetchLanguage = async () => {
    try {
      const response = await getAllLanguage()
      setLanguage(response)
      // console.log(response, 'response for language')
    } catch (error) {
      throw error
    }
  }

  const fetchSunSign = async () => {
    try {
      const response = await getAllSunSign()
      setSunSign(response)
      // console.log(response, 'response for SunSign')
    } catch (error) {
      throw error
    }
  }

  const fetchKycDoc = async () => {
    try {
      const response = await getAllKycDoc()
      setKycDoc(response)
      // console.log(response, 'response for KycDoc')
    } catch (error) {
      throw error
    }
  }

  const fetchEduDoc = async () => {
    try {
      const response = await getAllEduDoc()
      setEduDoc(response)
      // console.log(response, 'response for EduDoc')
    } catch (error) {
      throw error
    }
  }

  const fetchAwardDoc = async () => {
    try {
      const response = await getAllAwardDoc()
      setAwardDoc(response)
      // console.log(response, 'response for awardDoc')
    } catch (error) {
      throw error
    }
  }

  useEffect(() => {
    fetchCategory()
    fetchLanguage()
    fetchSunSign()
    fetchKycDoc()
    fetchEduDoc()
    fetchAwardDoc()
  }, [])

  const [selectedImage, setSelectedImage] = useState(null)
  const handleImageChange = (event) => {
    const file = event.target.files[0]
    setSelectedImage(file)
  }

  const [selectedKycDoc, setSelectedKycDoc] = useState(null)
  const handleKycDocChange = (event) => {
    const file = event.target.files[0]
    setSelectedKycDoc(file)
  }

  const [selectedEduDoc, setSelectedEduDoc] = useState(null)
  const handleEduDocChange = (event) => {
    const file = event.target.files[0]
    setSelectedEduDoc(file)
  }

  const [selectedAwardPic, setSelectedAwardPic] = useState(null)
  const handleAwardPicChange = (event) => {
    const file = event.target.files[0]
    setSelectedAwardPic(file)
  }

  const [addressType, setAddressType] = useState('')
  const handleAddressTypeChange = (event) => {
    setAddressType(event.target.value)
  }

  const pujariRegistration = async (data) => {
    try {
      console.log(data, 'data')
      const formData = new FormData()
      const name = data.name || ''
      const email = data.email || ''
      const mobile = data.mobile || ''
      const password = data.password || ''
      const dob = data.dob || ''
      const sun_sign = data.sun_sign || ''
      const gothram = data.gothram || ''
      const category = data.category || []
      const languages = data.languages || []
      const gender = data.gender || ''
      const hno = data.hno || ''
      const address_type = data.address_type || ''
      const Address = data.Address || ''
      const latitude = data.latitude || ''
      const longitude = data.longitude || ''
      const kyc_doc_type = data.kyc_doc_type || ''
      const educnal_doc_type = data.educnal_doc_type || ''
      const award_name = data.award_name || ''
      const experiance = data.experiance || ''
      const status = data?.status || ''

      formData.append('name', name)
      formData.append('email', email)
      formData.append('mobile', mobile)
      formData.append('password', password)
      formData.append('dob', dob)
      formData.append('sun_sign', sun_sign)
      formData.append('gothram', gothram)
      languages.forEach((language) => formData.append('languages[]', language))
      formData.append('gender', gender)
      formData.append('hno', hno)
      formData.append('address_type', address_type)
      formData.append('Address', Address)
      formData.append('latitude', latitude)
      formData.append('longitude', longitude)
      formData.append('category', category)
      formData.append('kyc_doc_type', kyc_doc_type)
      formData.append('educnal_doc_type', educnal_doc_type)
      formData.append('award_name', award_name)
      formData.append('experiance', experiance)
      formData.append('status', 1)

      if (selectedImage) {
        formData.append('image', selectedImage)
      }
      if (selectedKycDoc) {
        formData.append('kyc_document', selectedKycDoc)
      }
      if (selectedEduDoc) {
        formData.append('educnal_doc', selectedEduDoc)
      }
      if (selectedAwardPic) {
        formData.append('award_picture', selectedAwardPic)
      }

      await PujariCreate(formData)

      const successMessage = 'Pujari Created'
      toast.success(successMessage, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      setSelectedImage(null)
      reset()
    } catch (error) {
      if (error.response?.status === 422) {
        Object.values(error.response.data.errors).map((item) => {
          toast.error(item[0], {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        })
      } else {
        toast.error(error.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      }
    }
  }

  const handleKeyDown = (event, callback) => {
    if (event.key === 'Enter' && event.shiftKey === false) {
      event.preventDefault()
      // callback(pujariRegistration)
    }
  }

  return (
    <>
      <Header />
      <div id="main-wrapper" className="oxyy-login-register mt-5 pt-3 pb-5">

        <div className="hero-content mx-auto">
          <div className="container m-5">
            <form
              onSubmit={handleSubmit(pujariRegistration)}
              onKeyDown={(e) => {
                handleKeyDown(e, handleSubmit)
              }}
            >
              <div className='mb-3' style={{ backgroundColor: "#e38695", padding: "1px" }}>
                <h5 className="m-2 text-white">PERSONAL DETAILS</h5>
              </div>
              <div className="row mt-3">
                <div className="col-md-10">

                  <div className="row">
                    <div className="col-md-4 col-6 mb-3">
                      <div>
                        <small>Full Name</small>
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          {...register('name', { required: 'Full Name is required' })}
                        />
                        {errors.name && <span style={{ color: 'red' }}>{errors.name.message}</span>}

                      </div>
                    </div>
                    <div className="col-md-4 col-6 mb-3">
                      <div>
                        <small>Phone Number</small>
                        <input
                          type="number"
                          className="form-control"
                          name="mobile"
                          {...register('mobile', { required: 'Phone Number is required' })}
                        />
                        {errors.mobile && <span style={{ color: 'red' }}>{errors.mobile.message}</span>}

                      </div>
                    </div>
                    <div className="col-md-4 col-6 mb-3">
                      <div>
                        <small>Date of Birth</small>
                        <input
                          type="date"
                          className="form-control"
                          name="dob"
                          {...register('dob', { required: 'Date of Birth  is required' })}
                        />
                        {errors.dob && <span style={{ color: 'red' }}>{errors.dob.message}</span>}
                      </div>
                    </div>
                    <div className="col-md-4 col-6 mb-3">
                      <div>
                        <small>Password</small>
                        <input
                          type="text"
                          className="form-control"
                          name="password"
                          {...register('password', { required: 'Password  is required' })}
                        />
                        {errors.password && <span style={{ color: 'red' }}>{errors.password.message}</span>}
                      </div>
                    </div>
                    <div className="col-md-4 col-6 mb-3">
                      <div>
                        <small>Sun Sign</small>
                        <select
                          name="sun_sign"
                          id="sun_sign"
                          className="form-select"
                          {...register('sun_sign', { required: 'Sun Sign is required' })}
                        >

                          <option value="">--select--</option>
                          {sunSign.map((sun) => (
                            <option key={sun.id} value={sun.id}>
                              {sun.name}
                            </option>
                          ))}
                        </select>
                        {errors.sun_sign && <span style={{ color: 'red' }}>{errors.sun_sign.message}</span>}
                      </div>
                    </div>
                    <div className="col-md-4 col-6 mb-3">
                      <div>
                        <small>Gothram</small>
                        <input
                          type="text"
                          className="form-control"
                          name="gothram"
                          {...register('gothram', { required: 'Gothram is required' })}
                        />
                        {errors.gothram && <span style={{ color: 'red' }}>{errors.gothram.message}</span>}
                      </div>
                    </div>
                    <div className="col-md-4 col-6 mb-3">
                      <div>
                        <small>Category</small>
                        <select
                          name="category"
                          id="category"
                          className="form-select"
                          {...register('category', { required: 'Category is required' })}
                        >
                          <option value="">--select--</option>
                          {category.map((cat) => (
                            <option key={cat.id} value={cat.id}>
                              {cat.name}
                            </option>
                          ))}
                        </select>
                        {errors.category && <span style={{ color: 'red' }}>{errors.category.message}</span>}
                      </div>
                    </div>
                    <div className="col-md-4 col-6 mb-3">
                      <div>
                        <small>Latitude</small>
                        <input
                          type="text"
                          className="form-control"
                          name="latitude"
                          {...register('latitude', { required: 'Latitude is required' })}
                        />
                        {errors.latitude && <span style={{ color: 'red' }}>{errors.latitude.message}</span>}
                      </div>
                    </div>
                    <div className="col-md-4 col-6 mb-3">
                      <div>
                        <small>Longitude</small>
                        <input
                          type="text"
                          className="form-control"
                          name="longitude"
                          {...register('longitude', { required: 'Longitude is required' })}
                        />
                        {errors.longitude && <span style={{ color: 'red' }}>{errors.longitude.message}</span>}
                      </div>
                    </div>

                  </div>
                  <div className="d-flex flex-wrap">
                    <div className="col-md-4 col-12 mb-8">
                      <small>Languages</small>
                      <div className="d-flex align-items-center mt-2">
                        {language.map((lang) => (
                          <div key={lang.id} className="d-flex align-items-center mr-4">
                            <input
                              type="checkbox"
                              id={lang.id}
                              value={lang.id}
                              name="languages"
                              {...register('languages', { required: 'Languages is required' })}
                              style={{ marginRight: '5px' }}
                            />
                            <label htmlFor={lang.id} className="mt-2" style={{ marginRight: '10px' }} >
                              {lang.name}
                            </label>

                          </div>
                        ))}

                        {errors.languages && <span style={{ color: 'red' }}>{errors.languages.message}</span>}

                      </div>
                    </div>
                    <div className="col-md-4 col-12 ">
                      <small>Gender</small>
                      <div className="d-flex align-items-center mt-2">
                        <input
                          type="radio"
                          id="home"
                          name="gender"
                          value="1"
                          {...register('gender', { required: 'Gender is required' })}
                          style={{ marginRight: '5px' }}
                        />

                        <label htmlFor="gender" className="mt-2" style={{ marginRight: '8px' }}  >

                          Male
                        </label>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <input
                          type="radio"
                          id="work"
                          name="gender"
                          value="2"
                          {...register('gender')}
                          style={{ marginRight: '5px' }}
                        />
                        <label htmlFor="work" className="mt-2">
                          Female
                        </label>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {errors.gender && <span style={{ color: 'red' }}>{errors.gender.message}</span>}

                      </div>
                    </div>
                    <div className="col-md-4 col-12 ml-5 ">
                      <small>Experience</small>
                      <div>

                        <input
                          type="number"
                          className="form-control"
                          name="experiance"
                          {...register('experiance', { required: 'Experiance is required' })}
                        />
                        {errors.experiance && <span style={{ color: 'red' }}>{errors.experiance.message}</span>}
                      </div>
                    </div>
                  </div>

                  <div className='mb-3 mt-2' style={{ backgroundColor: "#e38695", padding: "1px" }}>
                    <h5 className="m-2 text-white">ADDRESS </h5>
                  </div>
                  <div className="row">

                    <div className="col-md-4 col-6 mb-2 ">
                      <div>
                        <small>Address Type</small>
                        <div className="d-flex align-items-center mt-2">
                          <input
                            type="radio"
                            id="home"
                            name="address_type"
                            value="1"
                            {...register('address_type', { required: 'Address Type is required' })}
                          />
                          <label for="home" className="mt-2">
                            Home
                          </label>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <input
                            type="radio"
                            id="work"
                            name="address_type"
                            value="2"
                            {...register('address_type')}
                          />
                          <label for="work" className="mt-2">
                            Work
                          </label>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <input
                            type="radio"
                            id="other"
                            name="address_type"
                            value="3"
                            {...register('address_type')}
                          />
                          <label for="other" className="mt-2">
                            Other
                          </label>
                          {errors.address_type && <span style={{ color: 'red' }}>{errors.address_type.message}</span>}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-6 mb-3">
                      <div>
                        <small>House Number</small>
                        <input
                          type="text"
                          className="form-control"
                          name="hno"
                          {...register('hno', { required: 'H.No is required' })}

                        />
                        {errors.hno && <span style={{ color: 'red' }}>{errors.hno.message}</span>}

                      </div>
                    </div>
                    <div className="col-md-4 col-6 mb-3">
                      <div>
                        <small>Email-Id</small>
                        <input
                          type="text"
                          className="form-control"
                          name="email"
                          {...register('email', { required: 'Email is required' })}
                        />
                        {errors.email && <span style={{ color: 'red' }}>{errors.email.message}</span>}
                      </div>
                    </div>
                    <div className="col-md-12 col-6">
                      <small>
                        <label for="address_area">Address</label>
                      </small>
                      <textarea
                        id="address_area"
                        name="Address"
                        {...register('Address', { required: 'Address is required' })}
                        rows="5"
                        cols="164"
                        className="form-control"
                      ></textarea>
                      {errors.Address && <span style={{ color: 'red' }}>{errors.Address.message}</span>}
                    </div>
                  </div>

                  <div className=' mt-3' style={{ backgroundColor: "#e38695", padding: "1px" }}>
                    <h5 className="m-2 text-white">KYC DOCUMENTS </h5>
                  </div>
                  <div className="row">

                    <div className="col-md-4 col-6 mb-3 mt-3">
                      <div>
                        <small>Select Document</small>
                        <select
                          name="kyc_doc_type"
                          id="kyc_doc_type"
                          className="form-select"
                          {...register('kyc_doc_type', { required: 'kyc_doc_type is required' })}
                        >
                          <option value="">--select--</option>
                          {kycDoc.map((kyc) => (
                            <option key={kyc.id} value={kyc.id}>
                              {kyc.name}
                            </option>
                          ))}
                        </select>
                        {errors.kyc_doc_type && <span style={{ color: 'red' }}>{errors.kyc_doc_type.message}</span>}
                      </div>
                    </div>
                    <div className="col-md-4 col-6 mb-3 mt-3">
                      <div>
                        <small>Upload Document</small>
                        <input
                          type="file"
                          className="form-control"
                          name="kyc_document"
                          {...register('kyc_document', { required: 'kyc_document is required' })}
                          onChange={handleKycDocChange}
                        />
                        {selectedKycDoc && (
                          <div>
                            <img
                              className="img-fluid"
                              src={URL.createObjectURL(selectedKycDoc)}
                              alt="pujam_pandal"
                            />
                          </div>
                        )}
                        {errors.kyc_document && <span style={{ color: 'red' }}>{errors.kyc_document.message}</span>}
                      </div>
                    </div>
                  </div>

                  <div className=' mt-3' style={{ backgroundColor: "#e38695", padding: "1px" }}>
                    <h5 className="m-2 text-white">EDUCATIONAL DETAILS </h5>
                  </div>
                  <div className="row">

                    <div className="col-md-4 col-6 mb-3 mt-3">
                      <div>
                        <small>Select Document</small>
                        <select

                          id="educnal_doc_type"
                          className="form-select"
                          {...register('educnal_doc_type', { required: 'Education doc is required' })}
                        >
                          <option value="">--select--</option>
                          {eduDoc.map((edu) => (
                            <option key={edu.id} value={edu.id}>
                              {edu.name}
                            </option>
                          ))}
                        </select>
                        {errors.educnal_doc_type && <span style={{ color: 'red' }}>{errors.educnal_doc_type.message}</span>}

                      </div>
                    </div>
                    <div className="col-md-4 col-6 mb-3 mt-3">
                      <div>
                        <small>Upload Document</small>
                        <input
                          type="file"
                          className="form-control"
                          name="educnal_doc"
                          {...register('educnal_doc', { required: 'Education doc is required' })}
                          onChange={handleEduDocChange}
                        />


                        {selectedEduDoc && (
                          <div>
                            <img
                              className="img-fluid"
                              src={URL.createObjectURL(selectedEduDoc)}
                              alt="pujam_pandal"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className=' mt-3' style={{ backgroundColor: "#e38695", padding: "1px" }}>
                    <h5 className="m-2 text-white">AWARDS</h5>
                  </div>
                  <div className="row mb-5">

                    <div className="col-md-4 col-6 mb-3 mt-3">
                      <div>
                        <small>Select Document</small>
                        <select
                          name="award_name"
                          id="award_name"
                          className="form-select"
                          {...register('award_name')}
                        >
                          <option value="">--select--</option>
                          {awardDoc.map((award) => (
                            <option key={award.id} value={award.id}>
                              {award.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-4 col-6 mb-3 mt-3">
                      <div>
                        <small>Upload Document</small>
                        <input
                          type="file"
                          className="form-control"
                          name="award_picture"
                          {...register('award_picture')}
                          onChange={handleAwardPicChange}
                        />
                        {selectedAwardPic && (
                          <div>
                            <img
                              className="img-fluid"
                              src={URL.createObjectURL(selectedAwardPic)}
                              alt="pujam_pandal"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-2">

                  <h5>User Image</h5>
                  {selectedImage && (
                    <div>
                      <img
                        className="img-fluid"
                        src={URL.createObjectURL(selectedImage)}
                        alt="pujam_pandal"
                      />
                    </div>
                  )}


                  <input
                    id="imageInput"
                    type="file"
                    name="image"
                    {...register('image')}
                    onChange={handleImageChange}
                    style={{ display: 'none' }}
                  />

                  <label htmlFor="image" className="custom-file-upload mt-2">
                    <button className='btn btn-success btn-sm' type="button" onClick={() => document.getElementById('imageInput').click()}>
                      Upload Image
                    </button>
                  </label>




                </div>
              </div>
              <div className="row">
                <div className="col-md-12 text-center">
                  <button type="submit" className="btn btn-danger">
                    REGISTER PUJARI
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default PujariRegister
