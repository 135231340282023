import React, { useEffect, useState } from 'react'
import Header from '../Header/Header'
import PujariSidebar from './PujariSidebar'
import PageHeader from '../PageHeader/PageHeader'
import CreatePoojaTempleModel from './CreatePoojaTempleModel'

function PujariTemple() {
    const [openModal, setOpenModal] = useState(false)
    const [editId, setEditId] = useState('');
    const [formInput, setFormInput] = useState({
        Add_Temple: '',
    });
    const handleOpen = () => {
        setOpenModal(true);
        setEditId('');
        setFormInput({
            Add_Temple: '',
        })
    }

    const propDetails = {
        openModal: openModal,
        setOpenModal: setOpenModal,
        formInput: formInput,
        setFormInput: setFormInput,
        editId: editId,
    }

    return (
        <>
            <div className="main-content">
                <Header />
                <div className="app-menu navbar-menu" style={{ display: 'flex' }}>
                    <PujariSidebar />
                </div>
                <div className="page-content">
                    <PageHeader HeaderName="Temple" />
                    <div className='container'>
                        <div className='row mt-3'>
                            <div className='row mt-3'>
                                <div className='col'>
                                    <h4 style={{ color: '#A90000' }}>Temples</h4>
                                </div>
                                <div className='col d-flex justify-content-end mt-3'>
                                    <button className="button-container"
                                        style={{
                                            height: "35px",
                                            width: "150px",
                                        }}
                                        onClick={() => handleOpen()}>Add Temple</button>
                                </div>
                            </div>
                            <div className='col-md-4 mt-5'>
                                <img src="" alt="" />
                            </div>
                            <div className='col-md-8 mt-3'>
                                <div className="row">
                                    <div className="col-md-3">
                                        <p>
                                            {' '}
                                            <strong style={{ fontSize: '15px' }}>Temple : </strong>{' '}
                                        </p>
                                        <p>
                                            {' '}
                                            <strong style={{ fontSize: '15px' }}>Idol : </strong>{' '}
                                        </p>
                                        <p>
                                            {' '}
                                            <strong style={{ fontSize: '15px' }}>Address : </strong>{' '}
                                        </p>
                                        <p>
                                            {' '}
                                            <strong style={{ fontSize: '15px' }}>Availability : </strong>
                                        </p>
                                    </div>
                                    <div className="col-md-9">
                                        <p> { }</p>
                                        <p> { }</p>
                                        <p> { }</p>
                                        <div className="row text-end">
                                            <div className="col text-end">
                                                <p className="button-container" style={{
                                                    marginTop: "0px",
                                                    height: "35px",
                                                    width: "40px",
                                                    marginLeft: "410px",
                                                }} onClick={() => handleOpen()}>
                                                    <i className='fa fa-pencil'></i>&nbsp;
                                                    </p>
                                            </div>
                                        </div>
                                        <p> { }</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col-md-4'>
                                <p>
                                    {' '}
                                    <strong>Description : </strong>{' '}
                                </p>
                            </div>
                            <div className='col-md-8'>
                                <p> { }</p>
                            </div>
                        </div>
                    </div>
                    <CreatePoojaTempleModel {...propDetails} />
                </div>
            </div>
        </>
    )
}

export default PujariTemple