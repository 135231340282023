import React, { useEffect, useState, useMemo } from 'react'
import PageHeader from '../PageHeader/PageHeader'
import DataTable from 'react-data-table-component'
import { useNavigate } from 'react-router-dom'
import * as XLSX from 'xlsx'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import Papa from 'papaparse'
import Header from '../Header/Header'
import Sidebar from '../Sidebar/Sidebar'
import axios from 'axios'
import {
  createSponsor,
  getSponsorEnquired,
  getSponsorLocation,
  getSponsorPackage,
  getSponsorRegistered,
} from '../API/Api'
import { toast } from 'react-toastify'
import Footer from '../Footer/Footer'
import MapContainer from '../Details/GoogleMap'

function Sponsors(onSelectLocation ) {
  const navigate = useNavigate()
  // const token = localStorage.getItem('token')
  const [sponsorsData, setSponsorsData] = useState([])
  const [selectedLocation, setSelectedLocation] = useState('');
  const apiKey = 'AIzaSyCN_4DMfPdC0fH9UFcRx0nv3ay7KUy60lk';
  const [registerSponsorsData, setRegisterSponsorsData] = useState([])
  const [packagesData, setPackagesData] = useState([])
  const [locationsData, setLocationsData] = useState([])
  const [changeName, setChangeName] = useState('')
  const [changeComapnyName, setChangeComapnyName] = useState('')
  const [mobileNumber, setMobileNumber] = useState('')
  const [changePackage, setChangePackage] = useState('')
  const [createLocation, setLocation] = useState('')
  const [createDate, setCreateDate] = useState('')
  const [createToDate, setcreateToDate] = useState('')
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(true)
  const [searchText, setSearchText] = useState('')
  // const [selectedImage, setSelectedImage] = useState(null)
  const [file, setFile] = useState([])
  const [show, setShow] = useState(true)

  const handleSearch = (event) => {
    setSearchText(event.target.value)
  }

  const clickSponsorDetails = (row) => {
    navigate('/sponsor-full-details', { state: row })
  }

  const onChangeName = (e) => {
    setChangeName(e.target.value)
  }
  const onChangeCompanyName = (e) => {
    setChangeComapnyName(e.target.value)
  }
  const onChangeNumber = (e) => {
    setMobileNumber(e.target.value)
  }
  const changePackages = (e) => {
    setChangePackage(e.target.value)
  }
  const setLocationData = async(e) => {
    setLocation(e.target.value)
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(createLocation)}&key=${apiKey}`
      );

      const { lat, lng } = response.data.results[0].geometry.location;
      console.log(lat)
      onSelectLocation({ lat, lng });
    } catch (error) {
      console.error('Error fetching coordinates:', error);
    }
  }

  const fromDate = (e) => {
    setCreateDate(e.target.value)
  }

  const toDate = (e) => {
    setcreateToDate(e.target.value)
  }

  const sponsorsFetchData = async () => {
    try {
      const data = await getSponsorEnquired()
      // setSponsorsData(data)
      // setLoading(false)
      if (data) {
        setSponsorsData(data)
      }
      setLoading(false)
    } catch (error) {
      console.error('Error fetching table data:', error)
      setLoading(false)
    }
  }

  // const sponsorsFetchData = async () => {
  //   try {
  //     const url = process.env.REACT_APP_API_BASEURL + `api/brand/getAllBrands`
  //     const headers = {
  //       Authorization: `Bearer ${token}`,
  //       'Content-Type': 'application/json',
  //     }

  //     const response = await fetch(url, { headers })
  //     const data = await response.json()
  //     setSponsorsData(data)
  //   } catch (error) {
  //     console.error('Error fetching data:', error)
  //   }
  // }

  const registerSponsorsFetchData = async () => {
    try {
      const data = await getSponsorRegistered()
      setRegisterSponsorsData(data)
      setLoading(false)
    } catch (error) {
      console.error('Error fetching table data:', error)
      setLoading(false)
    }
  }

  // const registerSponsorsFetchData = async () => {
  //   try {
  //     const url =
  //       process.env.REACT_APP_API_BASEURL + `api/sponsor/getAllsponsors`
  //     const headers = {
  //       Authorization: `Bearer ${token}`,
  //       'Content-Type': 'application/json',
  //     }

  //     const response = await fetch(url, { headers })
  //     const data = await response.json()
  //     setRegisterSponsorsData(data)

  //     // await fetch()
  //   } catch (error) {
  //     console.error('Error fetching data:', error)
  //   }
  // }

  const fetchPackageStatus = async () => {
    try {
      const data = await getSponsorPackage()
      setPackagesData(data)
      setLoading(false)
    } catch (error) {
      console.error('Error fetching table data:', error)
      setLoading(false)
    }
  }

  // const fetchPackageStatus = async () => {
  //   try {
  //     const url = process.env.REACT_APP_API_BASEURL + `api/package/getAllActive`
  //     const headers = {
  //       Authorization: `Bearer ${token}`,
  //       'Content-Type': 'application/json',
  //     }

  //     const response = await fetch(url, { headers })
  //     const data = await response.json()
  //     setPackagesData(data)
  //     console.log(data)
  //   } catch (error) {
  //     console.error('Error fetching data:', error)
  //   }
  // }

  const fetchLocationData = async () => {
    try {
      const data = await getSponsorLocation()
      setLocationsData(data)
      setLoading(false)
    } catch (error) {
      console.error('Error fetching table data:', error)
      setLoading(false)
    }
  }

  // const fetchLocationData = async () => {
  //   try {
  //     const url =
  //       process.env.REACT_APP_API_BASEURL + `api/location/getAllActive`
  //     const headers = {
  //       Authorization: `Bearer ${token}`,
  //       'Content-Type': 'application/json',
  //     }

  //     const response = await fetch(url, { headers })
  //     const data = await response.json()
  //     setLocationsData(data)
  //     console.log(data)
  //   } catch (error) {
  //     console.error('Error fetching data:', error)
  //   }
  // }

  // const onCreate = async () => {
  //   try {
  //     const formData = new FormData()
  //     const bookingData = {
  //       sponsor_name: changeName,
  //       sponsor_company_name: changeComapnyName,
  //       mobile_number: parseInt(mobileNumber),
  //       created_by: 1,
  //       updated_by: 2,
  //       sponsor_package: changePackage,
  //       sponsor_location: createLocation,
  //       status: '1',
  //       from_date: createDate,
  //       to_date: createToDate,
  //       sponsor_images:selectedImage
  //       // if (selectedImage) {
  //       //   formData.append('image', selectedImage)
  //       // }
  //     }
  //     await createSponsor(bookingData, formData)
  //     const successMessage = 'Sponsor Created'
  //     toast.success(successMessage, {
  //       position: 'top-right',
  //       autoClose: 3000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //     })
  //     setChangeName('')
  //     setChangeComapnyName('')
  //     setMobileNumber('')
  //     setChangePackage('')
  //     setLocation('')
  //     setCreateDate('')
  //     setcreateToDate('')
  //     registerSponsorsFetchData()
  //   } catch (error) {
  //     if (error.response.status === 422) {
  //       Object.values(error.response.data.errors).map((item) => {
  //         toast.error(item[0], {
  //           position: 'top-right',
  //           autoClose: 3000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //         })
  //       })
  //     } else {
  //       toast.error(error.message, {
  //         position: 'top-right',
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //       })
  //     }
  //   }
  // }

  const handleFile = (e) => {
    setFile(URL.createObjectURL(e.target.files[0]))
    setShow(false)
    // const formData = new FormData();
    // formData.append("sponsor_images", event.target.files[0]);
    // console.log(formData)
  }

  const onCreate = async () => {
    try {
      const formData = new FormData()
      formData.append('sponsor_images', file)
      const bookingData = {
        sponsor_name: changeName,
        sponsor_company_name: changeComapnyName,
        mobile_number: parseInt(mobileNumber),
        created_by: 1,
        updated_by: 2,
        sponsor_package: changePackage,
        sponsor_location: createLocation,
        status: '1',
        from_date: createDate,
        to_date: createToDate,
        sponsor_images: file,
      }
      await createSponsor(bookingData)
      const successMessage = 'Sponsor Created'
      toast.success(successMessage, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      setChangeName('')
      setChangeComapnyName('')
      setMobileNumber('')
      setChangePackage('')
      setLocation('')
      setCreateDate('')
      setcreateToDate('')
      setFile([])
      setShow(true)
      registerSponsorsFetchData()
    } catch (error) {
      if (error.response.status === 422) {
        Object.values(error.response.data.errors).map((item) => {
          toast.error(item[0], {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        })
      } else {
        toast.error(error.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      }
    }
  }

  // const onCreate = async () => {
  //   const formData = new FormData()

  //   formData.append('sponsor_images', file)
  //   // console.log(formData)
  //   const bookingData = {
  //     sponsor_name: changeName,
  //     sponsor_company_name: changeComapnyName,
  //     mobile_number: parseInt(mobileNumber),
  //     created_by: 1,
  //     updated_by: 2,
  //     sponsor_package: changePackage,
  //     sponsor_location: createLocation,
  //     status: '1',
  //     from_date: createDate,
  //     to_date: createToDate,
  //     sponsor_images: file,
  //   }
  //   const headers = {
  //     Authorization: `Bearer ${token}`,
  //     'Content-Type': 'application/json',
  //   }
  //   await axios
  //     .post(
  //       process.env.REACT_APP_API_BASEURL + `api/sponsor/create`,
  //       bookingData,
  //       { headers },
  //     )

  //     .then(async (res) => {
  //       const successMessage = 'Sponsor Created'
  //       toast.success(successMessage, {
  //         position: 'top-right',
  //         autoClose: 3000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //       })
  //       // console.log(res.data)
  //       setChangeName('')
  //       setChangeComapnyName('')
  //       setMobileNumber('')
  //       setChangePackage('')
  //       setLocation('')
  //       setCreateDate('')
  //       setcreateToDate('')
  //       registerSponsorsFetchData()
  //       // onCreate()
  //       await fetch()
  //     })

  //     .catch((error) => {
  //       // console.log(error)
  //     })
  // }

  const editDetails = (row) => {
    navigate('/register-sponsor-full-details', { state: row })
    // console.log(row)
  }

  const filteredData = sponsorsData.filter((item) =>
    item.full_name.toLowerCase().includes(searchText.toLowerCase()),
  )

  const handleSubmit = (e) => {
    e.preventDefault()
    const validationErrors = {}
    // Validate name field
    !changeName
      ? (validationErrors.changeName = 'Required')
      : delete validationErrors['changeName']

    !changeComapnyName
      ? (validationErrors.changeComapnyName = 'Required')
      : delete validationErrors['changeComapnyName']

    !mobileNumber
      ? (validationErrors.mobileNumber = 'Required')
      : delete validationErrors['mobileNumber']
    !changePackage
      ? (validationErrors.changePackage = 'Required')
      : delete validationErrors['changePackage']
    !createLocation
      ? (validationErrors.createLocation = 'Required')
      : delete validationErrors['createLocation']
    !createDate
      ? (validationErrors.createDate = 'Required')
      : delete validationErrors['createDate']
    !createToDate
      ? (validationErrors.createToDate = 'Required')
      : delete validationErrors['createToDate']
    setErrors(validationErrors)
    !Object.keys(validationErrors).length && onCreate()
  }

  const columns = useMemo(
    () => [
      {
        name: 'Sl.No',
        selector: 'slno',
        sortable: true,
        format: (row, rowIndex) => rowIndex + 1,
      },
      {
        name: 'Sponsor Name',
        selector: 'full_name',
        sortable: true,
        // customFilter: (row, searchTerm) => {
        //   return row.full_name.toLowerCase().includes(searchTerm.toLowerCase());
        // },
      },
      {
        name: 'Company Name',
        selector: 'brand_name',
        sortable: true,
      },
      {
        name: 'Mobile Number',
        selector: 'mobile',
        sortable: true,
      },
      {
        name: 'Package',
        selector: 'package',
        sortable: true,
      },
      {
        name: 'Details',
        cell: (row) => (
          <div>
            <button
              class="btn btn-sm btn-edit me-2"
              onClick={() => clickSponsorDetails(row)}
            >
              View Full Details
            </button>
          </div>
        ),
      },
    ],
    [],
  )

  const downloadAsPDF = () => {
    const columnsForPDF = [...columns]
    columnsForPDF.pop()
    const doc = new jsPDF()
    const pdfData = []
    filteredData.forEach((row, index) => {
      pdfData.push([
        index + 1,
        row.full_name,
        row.brand_name,
        row.mobile,
        row.package,

        // Add the image name to the row data
      ])
    })

    doc.autoTable({
      head: [columnsForPDF.map((column) => column.name)],
      body: pdfData,
      showHead: 'firstPage',
    })
    doc.save('table_data.pdf')
  }

  const downloadAsExcel = () => {
    const excelData = filteredData.map((item, index) => ({
      'S.No': index + 1,
      'Sponsor Name': item.full_name,
      'Company Name': item.brand_name,
      'Mobile Number': item.mobile,
      Package: item.package,

      // Age: item.age,
    }))

    const worksheet = XLSX.utils.json_to_sheet(excelData)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
    XLSX.writeFile(workbook, 'table_data.xlsx')
  }

  const handleDownloadCSV = () => {
    const csvData = filteredData.map((item, index) => ({
      'S.No': index + 1,
      'Sponsor Name': item.full_name,
      'Company Name': item.brand_name,
      'Mobile Number': item.mobile,
      Package: item.package,
    }))

    const csv = Papa.unparse(csvData)
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.setAttribute('href', url)
    link.setAttribute('download', 'table_data.csv')
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
  const columns2 = [
    {
      name: 'Sl.No',
      selector: 'slno',
      sortable: true,
      format: (row, rowIndex) => rowIndex + 1,
    },
    {
      name: 'Sponsor Name',
      selector: 'sponsor_name',
      sortable: true,
    },
    // {
    //   name: 'Image',
    //   cell: (row) =>
    //     <img key={row} src={row?.images[0]?.image} alt={row.name} style={{ width: '50px', height: '50px', marginRight: '5px' }} />
    // },
    {
      name: 'Company Name',
      selector: 'sponsor_company_name',
      sortable: true,
    },
    {
      name: 'Mobile Number',
      selector: 'mobile_number',
      sortable: true,
    },
    {
      name: 'Package',
      selector: 'sponsor_package',
      sortable: true,
    },
    {
      name: 'Details',
      cell: (row) => (
        <div>
          <button
            class="btn btn-sm btn-edit me-2"
            onClick={() => editDetails(row)}
          >
            View Full Details
          </button>
        </div>
      ),
    },
  ]

  const downloadAsPDF1 = () => {
    const columnsForPDF = [...columns2]
    columnsForPDF.pop()
    const doc = new jsPDF()
    const pdfData = []
    registerSponsorsData.forEach((row, index) => {
      pdfData.push([
        index + 1,
        row.sponsor_name,
        row.sponsor_company_name,
        row.mobile_number,
        row.sponsor_package,

        // Add the image name to the row data
      ])
    })

    doc.autoTable({
      head: [columnsForPDF.map((column) => column.name)],
      body: pdfData,
      showHead: 'firstPage',
    })
    doc.save('table_data.pdf')
  }

  const downloadAsExcel1 = () => {
    const excelData = registerSponsorsData.map((item, index) => ({
      'S.No': index + 1,
      'Sponsor Name': item.sponsor_name,
      'Company Name': item.sponsor_company_name,
      'Mobile Number': item.mobile_number,
      Package: item.sponsor_package,

      // Age: item.age,
    }))

    const worksheet = XLSX.utils.json_to_sheet(excelData)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
    XLSX.writeFile(workbook, 'table_data.xlsx')
  }

  const handleDownloadCSV1 = () => {
    const csvData = registerSponsorsData.map((item, index) => ({
      'S.No': index + 1,
      'Sponsor Name': item.sponsor_name,
      'Company Name': item.sponsor_company_name,
      'Mobile Number': item.mobile_number,
      Package: item.sponsor_package,
    }))

    const csv = Papa.unparse(csvData)
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.setAttribute('href', url)
    link.setAttribute('download', 'table_data.csv')
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const customStyles = {
    rows: {
      style: {
        // minHeight: '72px', // override the row height
        border: '0.1px solid #ddd',
      },
    },
    headCells: {
      style: {
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '8px',
        color: 'black',
        fontSize: '14px',
        backgroundColor: '#ccc',
        fontFamily: 'Poppins',
        fontWeight: '600',
        // fontSize:'10px'
        border: '0.1px solid #ddd',
      },
    },
    cells: {
      style: {
        paddingLeft: '8px', // override the cell padding for data cells
        paddingRight: '8px',
        border: '0.1px solid #ddd',
      },
    },
  }

  useEffect(() => {
    sponsorsFetchData()
    registerSponsorsFetchData()
    fetchPackageStatus()
    fetchLocationData()
  }, [])

  return (
    <>
      <div className="main-content">
        <Header />
        <div className="app-menu navbar-menu" style={{ display: 'flex' }}>
          <Sidebar />
        </div>
        <div className="page-content">
          <PageHeader HeaderName="Sponsors Enquiries Received" />
          <div className="container-fluid pt-3">
            <div className="card">
              <div className="card-body">
                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      data-bs-toggle="tab"
                      href="#one"
                    >
                      Sponsor Enquiries Received
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" data-bs-toggle="tab" href="#two">
                      All Registered Sponsors
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" data-bs-toggle="tab" href="#three">
                      Create a sponsorship
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="tab-content">
              <div className="tab-pane active" id="one">
                {/* <div className="col-md-3 ms-auto mb-3">
                  <input
                    type="text"
                    className="form-control"
                    name=""
                    placeholder="Search..."
                    value={searchText}
                    onChange={handleSearch}
                  />
                  <button type="submit" className="from-icon btn">
                    <i className="fa fa-search"></i>
                  </button>
                </div> */}
                <div>
                  <div className="card card-body">
                    {/* <div className="card card-body"> */}
                    {loading ? (
                      <p>Loading...</p>
                    ) : filteredData.length === 0 ? (
                      <p>Data Not Found</p>
                    ) : (
                      <div className="row mb-3">
                        <div className="col-md-12 table-responsive">
                          <DataTable
                            pagination
                            customStyles={customStyles}
                            data={filteredData}
                            columns={columns}
                            highlightOnHover
                            noDataComponent="Loading..."
                          />
                        </div>
                        <div className="col-md-12 text-end">
                          <div className="button-group">
                            <button
                              title="Download Excel"
                              className="button-excel-format"
                              onClick={downloadAsExcel}
                            >
                              Excel
                            </button>
                            <button
                              title="Download CSV"
                              className="button-csv-format"
                              onClick={handleDownloadCSV}
                            >
                              CSV
                            </button>
                            <button
                              title="Download PDF"
                              className="button-pdf-format"
                              onClick={downloadAsPDF}
                            >
                              PDF
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-content">
              <div className="tab-pane fade" id="two">
                <div className="col-md-3 ms-auto mb-3">
                  <input
                    type="text"
                    className="form-control"
                    name=""
                    placeholder="Search..."
                  />
                  <button type="submit" className="from-icon btn">
                    <i className="fa fa-search"></i>
                  </button>
                </div>
                <div>
                  <div className="card card-body">
                    <div className="col-xl-12 col-md-12">
                      {loading ? (
                        <p>Loading...</p>
                      ) : registerSponsorsData.length === 0 ? (
                        <p>Data Not Found</p>
                      ) : (
                        <>
                          <div className="table-responsive registered-img bg-white">
                            <DataTable
                              pagination
                              customStyles={customStyles}
                              data={registerSponsorsData}
                              columns={columns2}
                              highlightOnHover
                              searchable
                              noDataComponent="Loading..."
                            />
                          </div>
                          <div className="col-md-12 text-end">
                            <div className="button-group">
                              <button
                                title="Download Excel"
                                className="button-excel-format"
                                onClick={downloadAsExcel1}
                              >
                                Excel
                              </button>
                              <button
                                title="Download CSV"
                                className="button-csv-format"
                                onClick={handleDownloadCSV1}
                              >
                                CSV
                              </button>
                              <button
                                title="Download PDF"
                                className="button-pdf-format"
                                onClick={downloadAsPDF1}
                              >
                                PDF
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="three">
                <div className="card card-body">
                  <form method="" onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-9 mb-3">
                        <div className="row">
                          <div className="col-md-6 mb-3">
                            <small>Sponsor Name</small>
                            <input
                              type="text"
                              className="form-control"
                              onChange={onChangeName}
                              value={changeName}
                              name="sponsors_name"
                              id=""
                            />
                            {errors.changeName && (
                              <span style={{ color: 'red' }}>
                                {errors.changeName}
                              </span>
                            )}
                          </div>
                          <div className="col-md-6 mb-3">
                            <small>Sponsor Company Name</small>
                            <input
                              type="text"
                              className="form-control"
                              onChange={onChangeCompanyName}
                              value={changeComapnyName}
                              name="sponsor_company_name"
                              id=""
                            />
                            {errors.changeComapnyName && (
                              <span style={{ color: 'red' }}>
                                {errors.changeComapnyName}
                              </span>
                            )}
                          </div>
                          <div className="col-md-6 mb-3">
                            <small>Mobile Number</small>
                            <input
                              type="number"
                              id="phone"
                              pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                              className="form-control"
                              onChange={onChangeNumber}
                              value={mobileNumber}
                              name="mobile_number"
                            />
                            {errors.mobileNumber && (
                              <span style={{ color: 'red' }}>
                                {errors.mobileNumber}
                              </span>
                            )}
                          </div>
                          <div className="col-md-6 mb-3">
                            <small>Sponsor package</small>
                            <select
                              className="form-select"
                              onChange={changePackages}
                              name="sponsor_package"
                              value={changePackage}
                            >
                              <option value="">Select an option</option>
                              {packagesData.map((packages) => (
                                <option key={packages.id} value={packages.id}>
                                  {packages.package_name}
                                </option>
                              ))}
                            </select>
                            {errors.changePackage && (
                              <span style={{ color: 'red' }}>
                                {errors.changePackage}
                              </span>
                            )}
                          </div>
                          <div className="col-md-12 mb-3">
                            <small>
                              Sponsor Location for Sponsorship in App
                            </small>
                            <select
                              className="form-select"
                              onChange={setLocationData}
                              value={createLocation}
                              name="sponsor_location"
                            >
                              <option value="">Select an option</option>
                              {locationsData.map((location) => (
                                <option key={location.id} value={location.id}>
                                  {location.area_name}
                                </option>
                              ))}
                            </select>
                            <MapContainer/>
                            {errors.createLocation && (
                              <span style={{ color: 'red' }}>
                                {errors.createLocation}
                              </span>
                            )}
                          </div>
                          <div className="col-md-6 mb-3">
                            <small>From Date</small>
                            <input
                              type="date"
                              onChange={fromDate}
                              className="form-control"
                              value={createDate}
                              name="from_date"
                              id=""
                            />
                            {errors.createDate && (
                              <span style={{ color: 'red' }}>
                                {errors.createDate}
                              </span>
                            )}
                          </div>
                          <div className="col-md-6 mb-3">
                            <small>To Date</small>
                            <input
                              type="date"
                              onChange={toDate}
                              value={createToDate}
                              className="form-control"
                              name="to_date"
                              id=""
                            />
                            {errors.createToDate && (
                              <span style={{ color: 'red' }}>
                                {errors.createToDate}
                              </span>
                            )}
                          </div>

                          <div className="col-md-2 ms-auto">
                            <button
                              type="submit"
                              className="btn btn-warning w-100"
                            >
                              Create
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-md-3 text-center mb-3">
                        <small>Sponsor logo</small>
                        <div className="col-md-6 mb-3 m-auto">
                          <input
                            id="imageInput"
                            type="file"
                            name="sponsor_images"
                         
                            onChange={handleImageChange}
                          />
                         
                        </div>
                        <a href="" className="btn btn-sm btn-info">
                           Upload
                        </a>
                      </div> */}
                      <div className="col-md-3 text-center mb-3">
                        <small>Sponsor logo</small>
                        {show ? (
                          <div className="col-md-6 mb-3 m-auto">
                            <input
                              type="file"
                              name="sponsor_image"
                              id="imageInput"
                              accept="image/*"
                              onChange={handleFile}
                              style={{ cursor: 'pointer' }}
                            />
                            <img
                              alt="pujam_pandal"
                              src="assets/images/thumb.png"
                              className="img-fluid mt-3"
                              htmlfor="file"
                            />
                          </div>
                        ) : (
                          <img
                            src={file}
                            className="img-fluid mt-3"
                            alt="pujam_pandal"
                          />
                        )}

                        {/* <button href="" className="btn btn-sm btn-info" onClick={handleUpload}>
                           Upload
                        </button> */}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Sponsors
