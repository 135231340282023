import React, { useEffect, useState } from 'react';
import PageHeader from '../PageHeader/PageHeader'
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
// import PujariSidebar from '../Pujari/PujariSidebar';
import { getApiData, postApiResponse } from '../Pujari/API';

import SamagriKitModal from './SamagriKitModal';
import { GetAllSamagries, PostAllSamagries } from '../Pujari/Constants';
import SamagriKitView from './SamagriKitView';


const SamagriKit = () => {
  const [samagriKits, setSamagriKits] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [selectedKit, setSelectedKit] = useState(null);
  const [kitName, setKitName] = useState('');
  const [amount, setAmount] = useState('');
  const [kitItems, setKitItems] = useState([{ itemName: '', quantity: '', image: '' }]);
  const [isEditMode, setIsEditMode] = useState(false); 
  const [editKit, setEditKit] = useState(null); 
  const [uploadedFiles, setUploadedFiles] = useState([]); 


  useEffect(() => {
    fetchSamagriKits();
  }, []);

  const fetchSamagriKits = async () => {
    try {
      const response = await getApiData(GetAllSamagries)
      setSamagriKits(response.data);
      console.log(response.data)
    } catch (error) {
      console.error('Error fetching samagri kits:', error);
    }
  };

  const openModal = () => {
    setShowModal(true);
    setIsEditMode(false);
    setUploadedFiles([]);
  };

  const openEditModal = (kit) => {
    setShowModal(true);
    setIsEditMode(true);
    setEditKit(kit);
    setUploadedFiles(kit.files || []);
  };

  const closeModal = () => {
    setShowModal(false);
    setEditKit(null); 
  };

  const openViewModal = (kit) => {
    setSelectedKit(kit);
    setShowViewModal(true);
  };

  const closeViewModal = () => {
    setSelectedKit(null);
    setShowViewModal(false);
  };

  const deleteFile = (index) => {
    const updatedFiles = [...uploadedFiles];
    updatedFiles.splice(index, 1);
    setUploadedFiles(updatedFiles);
  };

  const addItem = () => {
    setKitItems([...kitItems, { itemName: '', quantity: '', image: '' }]);
  };

  const handleItemChange = (index, key, value) => {
    const updatedItems = [...kitItems];
    updatedItems[index][key] = value;
    setKitItems(updatedItems);
  };

  const saveKit = async () => {
    try {
      const postData = {
        kitName: kitName,
        amount: amount,
        items: kitItems.map(item => ({
          itemName: item.itemName,
          quantity: item.quantity,
          uploadImage: item.uploadImage
        }))
      };
      const savedData = await postApiResponse('https://gowinda.in/api/api/admin/samagriKit', postData);

      const newKit = {
        kitName: savedData.pack_name,
        pack_name: savedData.pack_name,
        finalAmount: savedData.finalAmount,
        additem: savedData.samagri.map(item => item[0]).join(', '),
        quantity: savedData.samagri.map(item => item[1]).join(', '),
        image: ''
      };

      setSamagriKits([...samagriKits, newKit]);

      fetchSamagriKits();

      closeModal();
    } catch (error) {
      console.error('Error saving kit:', error);
    }
  };

  // const openViewModal = (kit) => {
  //   // Implement as needed
  // };

  return (
    <div>
      <Header />
      <div className="app-menu navbar-menu" style={{ display: 'flex' }}>
        <Sidebar />
      </div>
      <PageHeader HeaderName="Samagri kit" />
      <div style={{ padding: '20px' }}>
        <div style={{ marginBottom: '20px', marginTop: '80px', display: 'flex', justifyContent: 'flex-end' }}>
          {/* <input type="text" placeholder="Search..." style={{ padding: '8px', borderRadius: '9px', border: '1px solid #ccc', marginRight: '10px', borderColor: 'maroon' }} /> */}
          <button onClick={openModal} style={{ padding: '8px 16px', borderRadius: '8px', backgroundColor: 'maroon', color: '#fff', border: 'none', cursor: 'pointer', borderColor: 'maroon' }}>Create Samagri Kit +</button>
        </div>
        <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', width: '80%', marginLeft: '250px'}}>
          <h4>All Samagri Kit</h4>
        <table style={{ width: '97%', borderCollapse: 'collapse',marginLeft: '22px' }}>
          <thead>
            <tr>
              <th style={{ border: '1px solid #ddd', padding: '8px', width: '10%', textAlign: 'center', backgroundColor: 'grey', color: 'white', fontWeight: 'normal' }}>S.No</th>
              <th style={{ border: '1px solid #ddd', padding: '8px', width: '35%', textAlign: 'center', backgroundColor: 'grey', color: 'white', fontWeight: 'normal' }}>Kit Name</th>
              <th style={{ border: '1px solid #ddd', padding: '8px', width: '35%', textAlign: 'center', backgroundColor: 'grey', color: 'white', fontWeight: 'normal' }}>Price</th>
              <th style={{ border: '1px solid #ddd', padding: '8px', width: '20%', textAlign: 'center', backgroundColor: 'grey', color: 'white', fontWeight: 'normal' }}>Action</th>
            </tr>
          </thead>
          <tbody>
            {samagriKits.map((kit, index) => (
              <tr key={index}>
                <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>{index + 1}</td>
                <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>{kit.pack_name}</td>
                <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>{kit.finalAmount}</td>
                <td style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'center' }}>
                  <div className='table_actions'>
                  <i class="fa-solid fa-edit" style={{ fontSize: "20px", color: "blue" , textAlign: 'center'}} onClick={() => openViewModal(kit)}></i>
                    {/* <button onClick={() => openViewModal(kit)} className='btn_view'>View</button> */}
                    {/* <i class="fa-solid fa-trash-can" style={{ fontSize: "16px", color: "red", marginLeft: '13px' }} onClick={()=>deleteModel(pooja.id,pooja.pooja_name)}></i> */}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
      </div>

      <SamagriKitModal
        openModal={showModal}
        setOpenModal={setShowModal}
        fetchSamagriKits={fetchSamagriKits}
        isEditMode={isEditMode}
        kitName={kitName}
        editKit={editKit}
        saveKit={saveKit}
        setKitName={setKitName}
        amount={amount}
        setAmount={setAmount}
        kitItems={kitItems}
        setKitItems={setKitItems}
        uploadedFiles={uploadedFiles}
        setUploadedFiles={setUploadedFiles}
        deleteFile={deleteFile}
      />

      <SamagriKitView
        isOpen={showViewModal}
        onClose={closeViewModal}
        kit={selectedKit}
      />

    </div>
  )
}

export default SamagriKit;
