import React, { useState, useEffect } from 'react'
import { fetchSevaTemples } from '../API/FetchAPI'
import { FaCalendarAlt } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import PageHeader from "../PageHeader/PageHeader";

const PujariPoojaViewDetails = () => {
    const [pooja, setPooja] = useState([]);
    const navigate = useNavigate();

    async function loadTemplesData() {  
        try {
          const data = await fetchSevaTemples('pooja/pooja-details/14');
          console.log('Data for Poojari:', data.data[0]);
          setPooja(data.data[0]);
          // Handle the data as needed
        } catch (error) {
          console.error('Error loading poojari data:', error);
        }
      }

    useEffect(() => {
        loadTemplesData();
    }, [])

    const handleButtonBack = () => {
        navigate('/PoojariViewFullDetails');
    };
    return (
        <div>
            <div>
                <div className="main-content">
                    <Header />
                    <div className="app-menu navbar-menu" style={{ display: 'flex' }}>
                        <Sidebar />
                    </div>
                    <div className="page-content">
                        <PageHeader HeaderName="Dashboard " />
                        <div className='container'>
                            <div className='row mt-3 justify-content-between'>
                                <div className='col-md-12'>
                                    <h4>Seva Details</h4>
                                </div>
                            </div>

                            <div className='Card_form'>
                                <div className='row mb-3'>
                                    <div className='col-md-12'>
                                        <button className='btn btn-warning' onClick={handleButtonBack}>Back </button>
                                    </div>
                                </div>

                                <form className='mt-3 mb-3'>
                                    <div className='row '>
                                        <div className='col-md-6'>
                                            <div className='row'>
                                                <div className='col-md-12'>
                                                    <div className='form-group mb-3'>
                                                        <label className='form-label'>Pooja Name</label>
                                                        <input type='text' value={pooja.pooja_name} className='form-control' readOnly />
                                                    </div>
                                                </div>
                                                <div className='col-md-12'>
                                                    <div className='form-group mb-3'>
                                                        <label className='form-label'>Seva Type</label>
                                                        <input type='text' value={pooja.seva_type} className='form-control' readOnly />
                                                    </div>
                                                </div>
                                                {/* <div className='col-md-3'>
                  <div className='form-group mb-3'>
                    <label className='form-label'>Count</label>
                    <input type='text' value={detail.count} className='form-control' readOnly />
                  </div>
                </div> */}
                                            
                                            </div>
                                        </div>
                                        
                                        <div className='col-md-6'>
                                            <div className='row'>
                                                <div className="col-md-8">
                                                    <img className='img-fluid' alt='' />
                                                </div>
                                                <div className="col-md-4">
                                                    <div className='row'>
                                                        <div className="col-md-12 mb-2">
                                                            <img src={pooja.image} className='img-fluid' alt='' />
                                                        </div>
                                                        <div className="col-md-12 mb-2">
                                                            <img src={pooja.image} className='img-fluid' alt='' />
                                                        </div>
                                                        <div className="col-md-12 mb-2">
                                                            <img src={pooja.image} className='img-fluid' alt='' />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12'>
                                            <div className='form-group mb-3'>
                                                <label className='form-label'>Description</label>
                                                <textarea rows={3} cols={12} value={pooja.description} placeholder='' className='form-control' readOnly />
                                            </div>
                                        </div>
                                        <div className='col-md-12'>
                                            <div className='form-group mb-3'>
                                                <label className='form-label'>Pooja Benefits</label>
                                                <textarea rows={3} cols={12} value={pooja.benfits} placeholder='' className='form-control' readOnly />
                                            </div>
                                        </div>
                                        <div className='col-md-12'>
                                            <div className='form-group mb-3'>
                                                <label className='form-label'>Pooja Process</label>
                                                <textarea rows={3} cols={12} value={pooja.process} placeholder='' className='form-control' readOnly />
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='form-group mb-3 calender_icon'>
                                                <label className='form-label'>Pooja Start Date</label>
                                                <input type='date' className='form-control' readOnly />
                                                <span><FaCalendarAlt /></span>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='form-group mb-3 calender_icon'>
                                                <label className='form-label'>Pooja End Date</label>
                                                <input type='date' className='form-control' readOnly />
                                                <span><FaCalendarAlt /></span>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='form-group mb-3'>
                                                <label className='form-label'>Pooja Start Time</label>
                                                <input type='text' className='form-control' readOnly />
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='form-group mb-3'>
                                                <label className='form-label'>Pooja End Time</label>
                                                <input type='text' className='form-control' readOnly />
                                            </div>
                                        </div>

                                        <div className='col-md-6'>
                                            <div className='form-group mb-3'>
                                                <label className='form-label'>Select Pooja Samagri Kit</label>
                                                <select className='form-control'>
                                                    <option value='' disabled selected>Select a kit</option>
                                                    <option value='kit1'>Pooja Samagri Kit 1</option>
                                                    <option value='kit2'>Pooja Samagri Kit 2</option>
                                                    <option value='kit3'>Pooja Samagri Kit 3</option>

                                                </select>
                                            </div>
                                        </div>

                                        <div className='col-md-6'>
                                            <div className='form-group mb-3'>
                                                <label className='form-label'>Donations Accepted</label>
                                                <input type='text' className='form-control' readOnly />
                                            </div>
                                        </div>

                                    </div>
                                </form>


                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PujariPoojaViewDetails
