import React, { useEffect, useState } from 'react';
import Modal from "react-bootstrap/Modal";
import { getApiData } from './API';
import { showToast } from './Toast';
import { GetAllPujaSamagriKit } from './Constants';

function CreatePoojaModel(props) {
  const { openModal, setOpenModal, GetPoojaDetails, formInput, setFormInput, editId } = props;
  const [samagriKit, setSamagriKit] = useState([]);
  const [errors, setErrors] = useState({}); 

  const getAllSamagriKit = async () => {
    try {
      const response = await getApiData(GetAllPujaSamagriKit);
      console.log('Samagri Kit data:', response.data);
      setSamagriKit(response.data);
    } catch (error) {
      console.log('Error fetching samagri details:', error);
    }
  };

  useEffect(() => {
    getAllSamagriKit();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormInput((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const url = 'https://gowinda.in/api/api/pooja/create';
    const token = localStorage.getItem('token');
  
    console.log('Submitting form data to:', url); 
    console.log('Form data:', formInput); 
  
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Referrer-Policy': 'strict-origin-when-cross-origin',
          Authorization : `Bearer ${token}`
        },
        body: JSON.stringify(formInput),
      });

      console.log(response,'api createe');
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const responseData = await response.json();
      console.log('Response data:', responseData);
      showToast('Pooja saved successfully!', 'success');
      setOpenModal(false);
      GetPoojaDetails(); 
    } catch (error) {
      console.error('Error submitting form:', error);
      showToast('Error saving Pooja. Please check the route and try again.', 'error');
    }
  };
  

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <Modal
      show={openModal}
      onHide={() => handleClose()}
      size="lg"
      aria-labelledby="example-modal-sizes-title-lg"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title
          id="example-modal-sizes-title-lg"
          style={{ color: "#A90000" }}
        >
          {editId ? "Update Pooja" : "Create Pooja"}
        </Modal.Title>
      </Modal.Header>
      <hr />
      <Modal.Body style={{ marginTop: "-20px" }}>
        <form id="poojaForm">
          <div className='row'>
            <div className="d-flex flex-wrap">
              <div className="col-md-6 mb-3 p-2">
                <div className="form-group">
                  <label className="form-label">Pooja Name</label>
                  <input
                    type="hidden"
                    className="form-control"
                    name="pooja_id"
                    value={editId || ''}
                  />
                  <input
                    type="text"
                    className="form-control"
                    name="pooja_name"
                    onChange={handleChange}
                    value={formInput.pooja_name || ''}
                  />
                </div>
              </div>
              <div className="col-md-6 mb-3 p-2">
                <div className="form-group">
                  <label className="form-label">Description</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleChange}
                    value={formInput.description || ''}
                    name="description"
                  />
                </div>
              </div>
              <div className="col-md-6 mb-3 p-2">
                <div className="form-group">
                  <label className="form-label">Duration</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleChange}
                    value={formInput.duration || ''}
                    name="duration"
                  />
                </div>
              </div>
              <div className="col-md-6 mb-3 p-2">
                <div className="form-group">
                  <label className="form-label">Dakshina</label>
                  <input
                    type="number"
                    className="form-control"
                    onChange={handleChange}
                    value={formInput.dakshina || ''}
                    name="dakshina"
                  />
                </div>
              </div>
              {/* <div className="col-md-6 mb-3 p-2">
                <div className="form-group">
                  <label className="form-label">Pooja Samagri</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleChange}
                    value={formInput.samagri || ''}
                    name="samagri"
                  />
                </div>
              </div>
              <div className="col-md-6 mb-3 p-2">
                <div className="form-group">
                  <label className="form-label">Samagri Price</label>
                  <input
                    type="number"
                    className="form-control"
                    onChange={handleChange}
                    value={formInput.samagri_price || ''}
                    name="samagri_price"
                  />
                </div>
              </div> */}
              <div className="col-md-6 mb-3 p-2">
                <div className="form-group">
                  <label className="form-label">Benfits</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleChange}
                    value={formInput.benfits  || ''}
                    name="benfits"
                  />
                </div>
              </div>
              <div className="col-md-6 mb-3 p-2">
                <div className="form-group">
                  <label className="form-label">Process</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleChange}
                    value={formInput.process || ''}
                    name="process"
                  />
                </div>
              </div>
              <div className="col-md-6 mb-3 p-2">
                <div className="form-group">
                  <label className="form-label">Samagri Kit</label>
                  <select
                    onChange={handleChange}
                    value={formInput.pack_id || ''}
                    className="form-select"
                    name="pack_id"
                  >
                    <option>--select--</option>
                    {samagriKit.map((kit, index) => (
                      <optgroup key={index} label={kit.pack_name}>
                        {kit.samagri.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.samagri_name}
                          </option>
                        ))}
                      </optgroup>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="modal-footer d-flex justify-content-end">
            <button
              type="button"
              className="back-button"
              onClick={handleClose}
            >
              Back
            </button>
            <button
              type="button"
              className="save-button"
              onClick={handleSubmit} 
            >
              {editId ? "Update" : "Save"}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default CreatePoojaModel;
