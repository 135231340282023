import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchSevaTemples } from '../API/FetchAPI';

function TempleDharshanam() {
  const navigate = useNavigate();
  const [dharsh, setDharsh] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state

  const handleButtonClick = () => {
    navigate('/TempleDharshanamViewDetails');
  };

  async function loadTemplesData() {
    try {
      const response = await fetchSevaTemples('dasrshanam/get-by-temple-id/6');
      const data = await response.json(); // Parse the JSON from the response
      console.log('Data for Temples:', data);
      // Check if data is an array
      if (Array.isArray(data)) {
        setDharsh(data);
      } else {
        console.error('Data is not an array:', data);
      }
    } catch (error) {
      console.error('Error loading temples data:', error);
    } finally {
      setLoading(false); // Set loading to false after data fetch
    }
  }

  useEffect(() => {
    loadTemplesData();
  }, []);

  // Display loading message or spinner while data is being fetched
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div className='Card_form'>
        <table className='table table-bordered table-hover'>
          <thead>
            <tr className='table-primary'>
              <th style={{ textAlign: 'center' }}>S.No</th>
              <th style={{ textAlign: 'center' }}>Seva Name</th>
              <th style={{ textAlign: 'center' }}>Image</th>
              <th style={{ textAlign: 'center' }}>Description</th>
              <th style={{ textAlign: 'center' }}>Location</th>
              <th style={{ textAlign: 'center' }}>Date of Event</th>
              <th style={{ textAlign: 'center' }}>Action</th>
            </tr>
          </thead>
          <tbody className='table_tbody'>
            {dharsh.map((item, index) => (
              <tr key={index}>
                <td>{item.id}</td>
                <td>{item.darshanam_name}</td>
                <td>
                  <img src={item.image} alt="Temple" />
                </td>
                <td>{item.description}</td>
                <td>{item.location}</td>
                <td>{item.created_by}</td>
                <td>
                  <button className='btn btn-primary' onClick={handleButtonClick}> View Full details </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default TempleDharshanam;
