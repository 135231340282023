import React, { useState, useEffect ,useRef} from 'react';
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import "./calendar.css";
import PujariSidebar from "../PujariSidebar";
import PageHeader from '../../PageHeader/PageHeader';
import Header from '../../Header/Header';


const CalendarDetails = () => {
  const calendarRef = useRef(null);
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null); // Initialize with null
  const [selectedDate, setSelectedDate] = useState(null);


  function FullCalendar (){

  };
  useEffect(() => {
    // Sample events data
    const sampleEvents = [
      {
        title: "Govinda seva",
        start: "2024-02-20",
        times: [
          "9:30 A.M - 10:30 A.M",
          "10:30 A.M - 11:30 A.M",
          "11:30 A.M - 12:30 P.M",
          "12:30 P.M - 1:30 P.M",
        ],
      },
      {
        title: "Abhishekam",
        start: "2024-02-22",
        times: [
          "9:30 A.M - 10:30 A.M",
          "10:30 A.M - 11:30 A.M",
          "11:30 A.M - 12:30 P.M",
          "12:30 P.M - 1:30 P.M",
        ],
      },
      // Add more events as needed
    ];

    setEvents(sampleEvents);
  }, []);
  

  useEffect(() => {
    if (events.length > 0 && selectedDate) {
      const eventsForSelectedDate = events.filter(
        (event) => event.start === selectedDate
      );
      setSelectedEvent(
        eventsForSelectedDate.length > 0 ? eventsForSelectedDate[0] : null
      );
    }
  }, [events, selectedDate]); 

  
  const handleEventClick = (clickInfo) => {
    const clickedEvent = clickInfo.event;
    setSelectedEvent(clickedEvent); 
  };

  // Function to handle date clicks
  const handleDateClick = (clickInfo) => {
    setSelectedEvent(null);
    const clickedDate = clickInfo.date;
    setSelectedDate(clickedDate);
  };

  useEffect(() => {
    if (calendarRef.current) {
      const calendar = calendarRef.current.getApi();
      calendar.gotoDate(new Date());
    }
  }, [calendarRef]);

  return (
    <div className="main-content">
       <Header />
      <div className="app-menu navbar-menu" style={{ display: "flex" }}>
        <PujariSidebar />
      </div>
      <div className="page-content">
        <PageHeader
          HeaderName="Calendar Management"
          style={{ color: "#0AAFC0" }}
        />
        <div className="container-fluid pt-3">
          <div className="row">
            <div className="col-md-8" style={{ width: "60%" }}>
              <div
                className="calendar-container"
                style={{ backgroundColor: "#fff" }}
              >
                <FullCalendar
                  ref={calendarRef}
                  plugins={[dayGridPlugin, interactionPlugin]}
                  initialView="dayGridMonth"
                  events={events}
                  dateClick={handleDateClick}
                  eventClick={handleEventClick}
                />
              </div>
            </div>
            <div className="col-md-4" style={{ width: "40%" }}>
              <div
                style={{
                  backgroundColor: "#F69727",
                  height: "100px",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  color: "#fff",
                }}
              >
                {selectedEvent ? (
                  <>
                    <span style={{ fontSize: "20px" }}>
                      {selectedEvent.start.getDate()}
                    </span>
                    <span>
                      {selectedEvent.start.toLocaleDateString("en-US", {
                        month: "long",
                        year: "numeric",
                      })}
                    </span>
                  </>
                ) : selectedDate ? (
                  <>
                    <span style={{ fontSize: "20px" }}>
                      {selectedDate.getDate()}
                    </span>
                    <span>
                      {selectedDate.toLocaleDateString("en-US", {
                        month: "long",
                        year: "numeric",
                      })}
                    </span>
                  </>
                ) : (
                  <>
                    <span style={{ fontSize: "20px" }}>
                      {new Date().getDate()}
                    </span>
                    <span>
                      {new Date().toLocaleDateString("en-US", {
                        month: "long",
                        year: "numeric",
                      })}
                    </span>
                  </>
                )}
              </div>

              <div
                style={{
                  backgroundColor: "#fff",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  color: "#fff",
                }}
              >
                {selectedEvent ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "10px",
                    }}
                  >
                    {selectedEvent.extendedProps.times.map(
                      (timeSlot, index) => (
                        <div key={index} className="event-container">
                          <span style={{ color: "#A90000" }}>
                            {selectedEvent.title}
                          </span>
                          <span style={{ color: "#000000" }}>
                            {selectedEvent.start.toLocaleDateString("en-US", {
                              day: "numeric",
                              month: "long",
                            })}{" "}
                            {timeSlot}
                          </span>
                        </div>
                      )
                    )}
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "10px",
                    }}
                  >
                    <span style={{ color: "#A90000" }}>No Events</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalendarDetails;
