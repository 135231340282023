import axios from 'axios'
const token = localStorage.getItem('token')
const apiBaseUrl = process.env.REACT_APP_API_BASEURL
const api = axios.create({
    baseURL: apiBaseUrl,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })
const getToken = () => {
    return localStorage.getItem("token");
  };


  export const GetAllBookingDetails = async () => {
    try {
      const response = await api.post('myservice/product-booking-details');
      console.log(response);
  
      // Check if response and response.data exist
      if (response && response?.data) {
        // Return all the data from the response
        return response?.data;
      } else {
        throw new Error('Response or response data is undefined');
      }
    } catch (error) {
      throw error;
    }
  };
  

  export const CreateNewProductDetails = async (requestData) => {
    try {
      const response = await api.post('templeProducts/create', requestData,
      {
        headers: {
          // Authorization: `Bearer ${getToken()}`,
          "Content-Type": "multipart/form-data",
        },

      })
      return response.data
    } catch (error) {

      throw error
    }
  }

  export const UpdateSevaDetails = async (requestData,) => {
    try {
      const response = await api.post(`temple-seva/edit/${requestData.id}`, requestData,
      {
        headers: {
          // Authorization: `Bearer ${getToken()}`,
          "Content-Type": "multipart/form-data",
        },

      })
      return response.data
    } catch (error) {

      throw error
    }
  }

  export const DeleteSevaDetails = async (id) => {
    try {
      const response = await api.delete(`temple-seva/delete/${id}`)
      return response.data
    } catch (error) {

      throw error
    }
  }