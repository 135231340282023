import React from 'react'
import { Link } from 'react-router-dom'
import PageHeader from '../PageHeader/PageHeader'
import Header from '../Header/Header'
import Sidebar from '../Sidebar/Sidebar'
import Footer from '../Footer/Footer'

function Payment() {
  return (
    <>
      <div className="main-content">
        <Header />

        <div className="app-menu navbar-menu" style={{ display: 'flex' }}>
          <Sidebar />

        </div>
        <div className="page-content">
          <PageHeader HeaderName="Make a Payment To Pandal" />

          <div className="container-fluid pt-3">
            <div className="row">
              <div className="col-xl-12 col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="col-md-3 ms-auto text-end">
                      <Link to="/list-of-all-pandals-rigistered" className="btn btn-info">
                        Back
                      </Link>
                    </div>
                    <form method="">
                      <div className="row">
                        <div className="col-md-10">
                          <div className="row">
                            <div className="col-md-3 col-6">
                              <small>Pandal Name</small>
                              <select className="form-select">
                                <option value="">Select Pandal Name</option>
                                <option value="">Ganesh Panda</option>
                                <option value="">Durga Pandal</option>
                              </select>
                            </div>
                            <div className="col-md-3 col-6">
                              <small>Payment Created for</small>
                              <select className="form-select">
                                <option value="">Select Created for</option>
                                <option value="">Ganesh Panda</option>
                                <option value="">Durga Pandal</option>
                              </select>
                            </div>
                            <div className="col-md-3 col-6">
                              <small>Payment Date</small>
                              <input type="date" className="form-control" name="" />
                            </div>
                            <div className="col-md-3 col-6">
                              <small>Expense Amount</small>
                              <input type="text" className="form-control" name="" />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2 text-end">
                          <small>&nbsp;</small>
                          <button
                            type="button"
                            className="btn btn-add waves-effect waves-light w_100"
                          >
                            Make a Payment
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
     
      </div>
      <Footer />
    </>
  )
}

export default Payment
