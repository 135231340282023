import React from 'react'
import { NavLink } from "react-router-dom";
import "../Sidebar/sidebar.css";

function PujariSidebar() {
    return (
        <div className="container-fluid" style={{ position: "auto" }}>
            <div id="scrollbar" className="menu-bg1">
                <div id="two-column-menu"> </div>
                <ul className="navbar-nav" id="navbar-nav">
                    <li className="nav-item">
                        <NavLink
                            className="nav-link menu-link"
                            to="/pujari-dashboard"
                            role="button"
                            aria-expanded="false"
                            aria-controls="sidebarDashboards"
                            activeClassName="active"
                        >
                            <i className="fa fa-tachometer-alt"></i>{" "}
                            <span data-key="t-dashboards">Dashboard</span>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink
                            className="nav-link menu-link"
                            to="/pujari-Profile"
                            role="button"
                            aria-expanded="false"
                            aria-controls="sidebarDashboards"
                            activeClassName="active"
                        >
                            <i className="fa fa-tachometer-alt"></i>{" "}
                            <span data-key="t-dashboards">Profile</span>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink
                            className="nav-link menu-link"
                            to="/poojaService"
                            role="button"
                            aria-expanded="false"
                            aria-controls="sidebarDashboards"
                            activeClassName="active"
                        >
                            <i className="fa fa-tachometer-alt"></i>{" "}
                            <span data-key="t-dashboards">Pooja/Service</span>
                        </NavLink>
                    </li>
                    {/* <li className="nav-item">
                        <NavLink
                            className="nav-link menu-link"
                            to="/pujariReviews"
                            role="button"
                            aria-expanded="false"
                            aria-controls="sidebarDashboards"
                            activeClassName="active"
                        >
                            <i className="fa fa-tachometer-alt"></i>{" "}
                            <span data-key="t-dashboards">Reviews</span>
                        </NavLink>
                    </li> */}
                    <li className="nav-item">
                        <NavLink
                            className="nav-link menu-link"
                            to="/poojaBookings"
                            role="button"
                            aria-expanded="false"
                            aria-controls="sidebarDashboards"
                            activeClassName="active"
                        >
                            <i className="fa fa-tachometer-alt"></i>{" "}
                            <span data-key="t-dashboards">My Bookings</span>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink
                            className="nav-link menu-link"
                            to="/poojaTemple"
                            role="button"
                            aria-expanded="false"
                            aria-controls="sidebarDashboards"
                            activeClassName="active"
                        >
                            <i className="fa fa-tachometer-alt"></i>{" "}
                            <span data-key="t-dashboards">Temple</span>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink
                            className="nav-link menu-link"
                            to="/poojaCalender"
                            role="button"
                            aria-expanded="false"
                            aria-controls="sidebarDashboards"
                            activeClassName="active"
                        >
                            <i className="fa fa-tachometer-alt"></i>{" "}
                            <span data-key="t-dashboards">Calender</span>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink
                            className="nav-link menu-link"
                            to="/poojariImages"
                            role="button"
                            aria-expanded="false"
                            aria-controls="sidebarDashboards"
                            activeClassName="active"
                        >
                            <i className="fa fa-tachometer-alt"></i>{" "}
                            <span data-key="t-dashboards">Pujari Images</span>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink
                            className="nav-link menu-link"
                            to="/pujari-bankdetails"
                            role="button"
                            aria-expanded="false"
                            aria-controls="sidebarDashboards"
                            activeClassName="active"
                        >
                            <i className="fa fa-tachometer-alt"></i>{" "}
                            <span data-key="t-dashboards">Bank Details</span>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink
                            className="nav-link menu-link"
                            to="/"
                            role="button"
                            aria-expanded="false"
                            aria-controls="sidebarDashboards"
                            onClick={() => {
                                localStorage.removeItem('token')
                            }}
                        >
                            <i className="fa fa-power-off"></i>{' '}
                            <span data-key="t-dashboards">Logout</span>
                        </NavLink>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default PujariSidebar