import React, { useState, useEffect } from 'react';
import PageHeader from '../PageHeader/PageHeader';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
import Footer from '../Footer/Footer';

function EmployeeDetail() {
  const navigate = useNavigate();
  const location = useLocation();
  const [details, setDetails] = useState({
    name: '',
    age: '',
    date_of_joining: '',
    mobile: '',
    designation: '',
    user_image: ''
  });

  useEffect(() => {
    if (location.state) {
      setDetails(location.state);
    }
  }, [location.state]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setDetails({
      ...details,
      [name]: value,
    });
  };

  return (
    <>
      <div className="main-content">
        <Header />
        <div className="app-menu navbar-menu" style={{ display: 'flex' }}>
          <Sidebar />
        </div>
        <div className="page-content">
          <PageHeader HeaderName="Employee Details" />
          <div className="container-fluid pt-3">
            <div className="row">
              <div className="col-xl-12 col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="col-md-3 ms-auto text-end">
                      <Link to="/employee" className="btn btn-info">
                        Back
                      </Link>
                    </div>
                    
                    <div className="row" style={{ marginLeft: '100px' }}>
                      <div className="col-md-9">
                        <div className="row">
                          <div className="col-md-9 mb-3">
                            <small>Employee Name</small>
                            <input
                              type="text"
                              name="name"
                              value={details.name}
                              className="form-control"
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-9 mb-3">
                            <small>Age</small>
                            <input
                              type="text"
                              name="age"
                              value={details.age}
                              className="form-control"
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-9 mb-3">
                            <small>Date of Joining</small>
                            <input
                              type="date"
                              name="date_of_joining"
                              value={details.date_of_joining}
                              className="form-control"
                              readOnly
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-9 mb-3">
                            <small>Phone Number</small>
                            <input
                              type="text"
                              name="mobile"
                              value={details.mobile}
                              className="form-control"
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-9 mb-3">
                            <small>Designation</small>
                            <select
                              name="designation"
                              value={details.designation}
                              className="form-control"
                              onChange={handleChange}
                            >
                              <option value="">Select Designation</option>
                              <option value="Manager">Manager</option>
                              <option value="Developer">Developer</option>
                              <option value="Designer">Designer</option>
                              <option value="Tester">Tester</option>
                              <option value="Analyst">Analyst</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="col-md-12 mb-3">
                          <p>User Image</p>
                          <label htmlFor="imageInput">
                            <img
                              src={details.user_image}
                              alt="user"
                              className="form-control"
                              style={{ width: '100%' }}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <Footer />
    </>
  );
}

export default EmployeeDetail;
