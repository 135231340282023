import React from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';

const ViewDetails = ({ show, handleClose, poojari }) => {
  if (!poojari) return null;

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title  style={{ color: "red" }}> Poojari Details</Modal.Title>
      </Modal.Header>
      <hr/>
      <Modal.Body>
       
        <Form>
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="poojariName">
                <Form.Label >Name</Form.Label>
                <Form.Control type="text" placeholder={poojari.name} readOnly />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="poojariImage">
                <Form.Label>Image</Form.Label>
                <div>
                  <img
                    src={poojari.imageUrl || 'default_image_url'}
                    alt={poojari.name}
                    style={{ width: '100px' }}
                  />
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={4}>
              <Form.Group controlId="poojariCategory">
                <Form.Label>Category</Form.Label>
                <Form.Control as="select">
                <option value="">Select</option>
                          <option value="1">Slot</option>
                          <option value="2">Unlimited</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={2}>
              <Form.Group controlId="poojariCount">
                <Form.Label>Age</Form.Label>
                <Form.Control type="number" placeholder={poojari.dob} readOnly />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Form.Group controlId="poojariMobile">
                <Form.Label>Mobile Number</Form.Label>
                <Form.Control type="text" defaultValue={poojari.mobile} readOnly />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="poojariDesignation">
                <Form.Label>Designation</Form.Label>
                <Form.Control type="text" placeholder="Enter designation" />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Form.Group controlId="poojariJoinDate">
                <Form.Label>Join Date</Form.Label>
                <Form.Control type="date" defaultValue={poojari.joinDate} />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="poojariEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" defaultValue={poojari.email} readOnly />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Back
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ViewDetails;
