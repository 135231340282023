import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';

const BankDetailsModalTemple = ({ isEditMode, bankdetails, onSave, onClose }) => {
    const [formValues, setFormValues] = useState({
    name: '',
    bankName: '',
    accountNumber: '',
    ifsc: '',
    address: '',
  });

  useEffect(() => {
    if (isEditMode) {
        setFormValues(bankdetails);
    }
  }, [isEditMode, bankdetails]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formValues.name || !formValues.bankName || !formValues.accountNumber || !formValues.ifsc || !formValues.address) {
      toast.error('All fields are required.');
      return;
    }
    onSave(formValues);
  };

  return (
    <Modal show onHide={onClose}>
      <Modal.Header closeButton>
      <Modal.Title style={{ color: isEditMode ? 'red' : 'red' }}>
          {isEditMode ? 'Edit Bank Details' : 'Create Bank Details'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formName">
            <Form.Label>Full Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={formValues.name}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="formBankName">
            <Form.Label>Bank Name</Form.Label>
            <Form.Control
              type="text"
              name="bankName"
              value={formValues.bankName}
              onChange={handleChange}
            />
          </Form.Group>
          {/* <Form.Group controlId="formCompanyName">
            <Form.Label>Company Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={formValues.name}
              onChange={handleChange}
            />
          </Form.Group> */}
          <Form.Group controlId="formAccountNumber">
            <Form.Label>Account Number</Form.Label>
            <Form.Control
              type="number"
              name="accountNumber"
              value={formValues.accountNumber}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="formIFSC">
            <Form.Label>IFSC Code</Form.Label>
            <Form.Control
              type="text"
              name="ifsc"
              value={formValues.ifsc}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="formAddress">
            <Form.Label>Address</Form.Label>
            <Form.Control
              type="text"
              name="address"
              value={formValues.address}
              onChange={handleChange}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default BankDetailsModalTemple;
