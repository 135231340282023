import React from 'react';
// import GoogleMapReact from 'google-map-react';

const MapContainer = () => {
  const mapApiKey = 'AIzaSyCN_4DMfPdC0fH9UFcRx0nv3ay7KUy60lk';

  const defaultProps = {
    center: {
      lat:  14.685564,
      lng: 77.595406,
    },
    zoom: 10,
  };

  return (
    <div style={{ height: '400px', width: '100%' }}>
      {/* <GoogleMapReact
        bootstrapURLKeys={{ key: mapApiKey }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      ></GoogleMapReact> */}
    </div>
  );
};

export default MapContainer;
