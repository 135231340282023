import React,{useState,useEffect} from 'react'
// import { TempleformDetails } from '../Pujari/Constants';
import {fetchSevaTemples} from '../API/FetchAPI'

function TempleDetailsForm() {
    const [detail, setDetails] = useState({});

    async function loadTemplesData() {  
        try {
          const data = await fetchSevaTemples('appUser/templeinfo/6');
        //   console.log('Data for Temples:', data);
          setDetails(data.data);
          // Handle the data as needed
        } catch (error) {
          console.error('Error loading temples data:', error);
        }
      }

      useEffect(()=> {
        loadTemplesData();
      }, [])


  return (
    <div>
        <div className='Card_form'> 
        <form>
        <div className='row'>
<div className='col-md-9'>
    <div className='row'>
        <div className='col-md-8'>
            <div className='form-group mb-3'>
                <label>Temple Name</label>
                <input type='text' value={detail.temple_name || ''} placeholder='' className='form-control' readOnly  />
            </div>
        </div>
        <div className='col-md-4'>
            <div className='form-group mb-3'>
                <label>Temple Registered Date</label>
                <input type='text' value={{}} placeholder='05-06-2023' className='form-control'  readOnly/>
            </div>
        </div>
        <div className='col-md-8'>
            <div className='form-group mb-3'>
                <label>Amenities</label>
                <input type='text' value={detail.description} placeholder='' className='form-control'  readOnly/>
            </div>
        </div>
        <div className='col-md-4'>
            <div className='form-group mb-3'>
                <label>Timings</label>
                <input type='text' value={{}} placeholder='10:00 AM-12:00 PM' className='form-control' readOnly/>
            </div>
        </div>
    </div>
</div>
<div className="col-md-3">
    <div className='form-group mb-3'>
        <img src='/public/assets/images/templeformImg.png' className='img-fluid' alt='' />
    </div>
</div>
<div className='col-md-12'>
            <div className='form-group mb-3'>
                <label>Description</label>
                <textarea rows={3} cols={12} value={detail.description} placeholder='Khairtabad, Hyderabad.' className='form-control' readOnly/>
            </div>
        </div>
        <div className='col-md-12'>
            <div className='form-group mb-3'>
                <label>History</label>
                <textarea rows={3} cols={12} value={detail.history} className='form-control' readOnly/>
            </div>
        </div>
        <div className='col-md-12'>
            <div className='form-group mb-3'>
                <label>Significance</label>
                <textarea rows={3} cols={12} value={detail.significance} className='form-control' readOnly/>
            </div>
        </div>
        <div className='col-md-12'>
            <div className='form-group mb-3'>
                <label>How to Reach</label>
                <textarea rows={3} cols={12} value={detail.significance} className='form-control' readOnly/>
            </div>
        </div>
</div>
        </form>
        </div>
    </div>
  )
}

export default TempleDetailsForm
