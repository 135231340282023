import React from 'react';
import PujariSidebar from './PujariSidebar';
import Header from '../Header/Header';
import PageHeader from '../PageHeader/PageHeader';
import { PujariImagesUpload, baseUrlAPi } from './Constants';

function PujariImages() {
    const token = localStorage.getItem('token');

    const handleFile = async (event) => {
        console.log("File input changed");
        const selectedFile = event.target.files[0];
        console.log("Selected file:", selectedFile);

        if (!selectedFile) {
            console.log("No file selected");
            return;
        }

        const formData = new FormData();
        formData.append('type', 1);
        formData.append("media", selectedFile);

        try {
            const response = await fetch(`${baseUrlAPi}${PujariImagesUpload}`, {
                method: "POST",
                body: formData,
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Accept": "application/json" // Specify that the API should return JSON data
                },
            });

            if (!response.ok) {
                throw new Error("Failed to upload image");
            }

            const data = await response.json();
            console.log("API Response:", data);
        } catch (error) {
            console.error("Error sending image to API:", error);
        }
    }

    return (
        <>
            <div className="main-content">
                <Header />
                <div className="app-menu navbar-menu" style={{ display: 'flex' }}>
                    <PujariSidebar />
                </div>
                <div className="page-content">
                    <PageHeader HeaderName="Pujari Images" />
                    <div className='container'>
                        <div className='row mt-2'>
                            <div className='col-md-3'>
                                <h3>Banner Images</h3>
                                <div>
                                    <label htmlFor="bannerImageUpload">
                                        <input name="bannerImage" type="file" id="bannerImageUpload" hidden onChange={handleFile} />
                                        <span style={{ fontSize: '100px', border: '1px solid black' }} className='ps-4 pe-4'>+</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className="col">
                                <h3>Images/Videos</h3>
                                <div>
                                    <label htmlFor="imageVideoUpload">
                                        <input name="image_video" type="file" id="imageVideoUpload" hidden onChange={handleFile} />
                                        <span style={{ fontSize: '100px', border: '1px solid black' }} className='ps-4 pe-4'>+</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PujariImages;
