/* eslint-disable jsx-a11y/img-redundant-alt */
import { React, useState, useEffect, useMemo } from "react";
import Header from "../../Header/Header";
import Sidebar from "../../Sidebar/Sidebar";
import PageHeader from "../../PageHeader/PageHeader";
import DataTable from "react-data-table-component";
import {
  CreateNewProductDetails,
  GetAllProductsDetails,
  UpdateProductDetails,
  DeleteProductsDetails
} from "../../API/TempleApis/productsApi";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FILE_BASEURL } from "../../Services/imageUrl";



const TempleProducts = () => {
  const [allProducts, setAllProducts] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [editProductData, setEditProductData] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState(false);
  const [viewData, setViewData] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [image, setImage] = useState([]);
  const [validationMessage, setValidationMessage] = useState('');
  const [formInput, setFormInput] = useState({
    productName: "",
    quantity: "",
    description: "",
    price: "",
    status: 1,
    image: [],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormInput((prevData) => ({ ...prevData, [name]: value }));
  };

  const getAllProductsDetails = async () => {
    try {
      const response = await GetAllProductsDetails();
      setAllProducts(response);
    } catch (error) {
      console.error("Error fetching poojari details:", error);
    }
  };

  useEffect(() => {
    getAllProductsDetails();
  }, []);

  const addNewProduct = () => {
    setOpenModal(true);
    setFormInput({
      productName: "",
      quantity: "",
      description: "",
      price: "",
      status: 1,
      image: "",
    });
  };

  const handleClose = () => {
    setOpenModal(false);
    setDeleteModal(false);
    setIsEditMode(false)
    // setFormInput(null)
    setFormInput({
      productName: "",
      quantity: "",
      description: "",
      price: "",
      status: 1,
      image: "",
    });
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    const validExtensions = ['image/png', 'image/jpeg', 'image/jpg'];

    const invalidFiles = files.filter(file => !validExtensions.includes(file.type));
    const validFiles = files.filter(file => validExtensions.includes(file.type));

    if (invalidFiles.length > 0) {
      setValidationMessage('Only .png, .jpg, .jpeg files are allowed.');
    } else {
      setValidationMessage('');
    }

    setFormInput((prevData) => ({
      ...prevData,
      image: [...prevData.image, ...validFiles],
    }));
  };

  const addItem = (array, setArray) => {
    const newItem = '';
    setArray([...array, newItem]);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formInput.image || !formInput.status) {
        // throw new Error('Please fill in all required fields');
      }

      console.log("Selected Images:", formInput.image);

      const formData = new FormData();
      formData.append("productName", formInput.productName);
      formData.append("quantity", formInput.quantity);
      formData.append("description", formInput.description);
      formData.append("price", formInput.price);
      formData.append("status", formInput.status);


      // Add status field
      for (let i = 0; i < formInput.image.length; i++) {
        formData.append("image[]", formInput.image[i]);
      }

       // Log FormData entries before sending
    for (const [key, value] of formData.entries()) {
      console.log(key, value);
    }

      console.log("FormData before sending:", formData);

      if (isEditMode) {
        formData.append("id", editProductData.id);
        await UpdateProductDetails(formData);
        toast.success("Product details updated successfully");
      } else {
        await CreateNewProductDetails(formData);
        toast.success("New product created successfully");
      }
      handleClose();
      getAllProductsDetails();
    } catch (error) {
      if (error.response && error.response.status === 421) {
        const errors = error.response.data.errors;
        if (errors) {
          Object.values(error.response.data.errors).map((item) => {
            toast.error(item[0], {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          });
        } else {
          toast.error("Validation error occurred", {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } else {
        toast.error(error.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

  }

  const editProductDetails = (selectedData) => {
    console.log("Selected Data for Edit:", selectedData); 
    setEditProductData(selectedData);
    setIsEditMode(true);
    setOpenModal(true);
    setFormInput({
      productName: selectedData.product_name || "",
      quantity: selectedData.quantity || "",
      description: selectedData.description || "",
      price: selectedData.price || "",
      status: selectedData.status || 1,
      image: [], 
    });
  };
  

  const columns = useMemo(
    () => [
      {
        name: "S.No",
        selector: "slno",
        sortable: true,
        format: (row, rowIndex) => rowIndex + 1,
      },
      {
        name: "Products",
        selector: "product_name",
        sortable: true,
      },
      {
        name: "Description",
        selector: "description",
        sortable: true,
      },
      {
        name: "Price",
        selector: "price",
        sortable: true,
      },
      {
        name: "Quantity",
        selector: "quantity",
        // cell: (row) => formatDate(row.pandal_start_date),
        sortable: true,
      },
      {
        name: "Status",
        selector: "1",
        sortable: true,
      },
      // {
      //   name: "Image",
      //   cell: (row) => (
      //     <img
      //       src={row.image}
      //       alt="Product"
      //       style={{ width: 50, height: 50 }}
      //     />
      //   ),
      //   // cell: (row) => formatDate(row.pandal_start_date),
      //   sortable: true,
      // },
      {
        name: "Actions",
        cell: (row) => (
          <div>
            <button
              class="btn btn-sm btn-edit me-2"
              onClick={() => editProductDetails(row)}
            >
              <i class="fa-solid fa-edit"></i>
            </button>
            <button
              class="btn btn-sm btn-delete me-2"
              onClick={() => openDeleteModal(row)}
            >
              <i class="fa-solid fa-trash-can"></i>
            </button>
          </div>
        ),
        width: "10%",
      },
    ],
    []
  );

  const openDeleteModal = (row) => {
    setViewData(row)
    setDeleteModal(true);
    setDeleteData({ id: row.id });
  };



  const deleteProductsData = async () => {
    try {
      await DeleteProductsDetails(deleteData); // Pass the id of the product to be deleted
      const successMessage = "Product deleted successfully";
      toast.success(successMessage, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      getAllProductsDetails();
      handleClose();
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const customStyles = {
    rows: {
      style: {
        // border: '0.1px solid #ddd',
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        color: "black",
        fontSize: "14px",
        backgroundColor: "#ccc",
        fontFamily: "Poppins",
        fontWeight: "600",
        border: "0.1px solid #ddd",
        display: "flex",
        justifyContent: "center",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        border: "0.1px solid #ddd",
        display: "flex",
        justifyContent: "center",
      },
    },
  };

  return (
    <>
      <div className="main-content">
        <Header />
        <div className="app-menu navbar-menu" style={{ display: "flex" }}>
          <Sidebar />
        </div>
        <div className="page-content">
          <PageHeader HeaderName="Products" />
          <div className="container-fluid pt-3">
            <div className="row mb-2">
              <div style={{ display: "flex", marginRight: "50px" }}>
                <div className="col-md-3 ms-auto mb-3">
                  {/* <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      name=""
                      placeholder="Search here"
                    />
                    {/* <button type="submit" className="input-group-text">
                      <i className="fa fa-search"></i>
                    </button> */}
                  {/* </div>  */}
                </div>

                <button
                  className="button-container"
                  style={{
                    marginTop: "0px",
                    height: "35px",
                    width: "150px",
                    marginLeft: "30px",
                  }}
                  onClick={addNewProduct}
                >
                  Create Product
                </button>
              </div>
            </div>
            <div className="table-responsive registered-img bg-white">
              <DataTable
                columns={columns}
                data={allProducts}
                pagination
                customStyles={customStyles}
                highlightOnHover
              //   noDataComponent="Loading..."
              />
            </div>
          </div>
          <Modal
            show={openModal}
            onHide={() => handleClose()}
            size="lg"
            aria-labelledby="example-modal-sizes-title-lg"
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header>
              <Modal.Title
                id="example-modal-sizes-title-lg"
                style={{ color: "#A90000" }}
              >
                {isEditMode ? "Edit Product Details" : "Create Product"}
              </Modal.Title>
            </Modal.Header>
            <hr />
            <Modal.Body style={{ marginTop: "-20px" }}>
              <form onSubmit={handleSubmit}>
                <div className="d-flex">
                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label className="form-label">Product Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="productName"
                        onChange={handleChange}
                        value={formInput.productName}
                      />
                    </div>
                  </div>

                  {/* <div
                    className="col-md-6 mb-3"
                    style={{ position: "relative", paddingLeft: "20px" }}
                  >
                    <div className="form-group">
                      <label className="form-label">Image</label>
                      <div style={{ position: "relative" }}>
                        <input
                          type="file"
                          className="form-control"
                          name="image"
                          onChange={handleImageChange}
                          accept=".png, .jpg, .jpeg"
                        />
                      </div>
                      <div style={{ position: "absolute",maxWidth:"50%" }}>
                        {formInput.image && (
                          <img
                            src={
                              formInput.image instanceof File
                                ? URL.createObjectURL(formInput.image)
                                : FILE_BASEURL+editProductData.image.replace("/home/gowinda/public_html/api/public/","")
                            } // Use createObjectURL to display the selected image or use the provided URL
                            alt="Selected Image"
                            style={{ width: "100%" }}
                          />
                        )}
                      </div>
                    </div>
                  </div> */}

                  <div
                    className="col-md-6 mb-3"
                    style={{ position: 'relative', paddingLeft: '20px' }}
                  >
                    <div className="form-group">
                      <label className="form-label" style={{marginRight: '10px'}}>Image</label>
                      {image.map((image, index) => (
                        <div key={index} className="d-flex align-items-center mb-3">
                          <div style={{ position: 'relative' }}>
                            <input
                              type="file"
                              className="form-control"
                              name="image"
                              onChange={handleImageChange}
                              accept=".png, .jpg, .jpeg"
                              multiple
                            />
                             {validationMessage && <div className="alert alert-danger">{validationMessage}</div>}

                          </div>

                        </div>
                      ))}
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={() => addItem(image, setImage)}
                      >
                        Add Image
                      </button>
                    </div>
                  </div>


                </div>

                <div className="col-md-6 mb-3">
                  <div className="form-group">
                    <label className="form-label">Quantity</label>
                    <input
                      type="number"
                      className="form-control"
                      onChange={handleChange}
                      value={formInput.quantity}
                      name="quantity"
                    />
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="form-group">
                    <label className="form-label">Description</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={handleChange}
                      value={formInput.description}
                      name="description"
                    />
                  </div>
                </div>

                <div className="col-md-6 mb-3">
                  <div className="form-group">
                    <label className="form-label">Price</label>
                    <input
                      type="number"
                      className="form-control"
                      onChange={handleChange}
                      value={formInput.price}
                      name="price"
                    />
                  </div>
                </div>

                <hr />
                <div className="modal-footer d-flex justify-content-end">
                  <button
                    type="button"
                    className="back-button"
                    onClick={handleClose}
                  >
                    Back
                  </button>
                  <button
                    type="submit"
                    className="save-button"
                  // onClick={handleCreateZone}
                  >
                    {isEditMode ? "Update" : "Save"}
                  </button>
                </div>
              </form>
            </Modal.Body>
          </Modal>
        </div>
      </div>
      <Modal
        show={deleteModal}
        onHide={() => handleClose()}
        aria-labelledby="example-modal-sizes-title-lg"
        backdrop="static"
        keyboard={false}
        style={{ borderRadius: "20px" }}
        centered
      >
        <Modal.Header
          style={{ backgroundColor: "#A90000", position: "relative" }}
        >
          <div className="delete-modal">
            <i
              class="fa-solid fa-trash-can"
              style={{ fontSize: "25px", color: "#fff" }}
            ></i>
          </div>
        </Modal.Header>
        <Modal.Body
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h3>Delete Product?</h3>
          <h6>You'll Permanently lose {viewData.product_name}</h6>
        </Modal.Body>
        <div className="modal-footer d-flex justify-content-center">
          <button type="button" className="back-button" onClick={handleClose}>
            Back
          </button>
          <button
            type="button"
            className="save-button"
            onClick={deleteProductsData}
          >
            Delete
          </button>
        </div>
      </Modal>
      {/* <Footer /> */}
    </>
  );
};

export default TempleProducts;
