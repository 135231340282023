import React, { useEffect, useState } from 'react'
import Header from '../Header/Header'
import PujariSidebar from './PujariSidebar'
import PageHeader from '../PageHeader/PageHeader'
import { getApiData } from './API';
import { PujariReviewsData } from './Constants';
import { showToast } from './Toast';

function PujariReviews() {
    const [reviewData, setReviewData] = useState([]);

    const getPujariReviews = async () => {
        try {
            const data = await getApiData(PujariReviewsData);
            console.log(data)
            setReviewData(data);
        } catch (error) {
            console.log(error);
            if (error.response.status === 429) {
                showToast('too Many Request', 'error');
            } else if (error.response.status === 404) {
                showToast('Not Found', 'error');
            } else {
                showToast('something went wrong', 'error');
            }
        }
    }

    useEffect(() => {
        getPujariReviews();
    }, [])
    return (
        <>
            <div className="main-content">
                <Header />
                <div className="app-menu navbar-menu" style={{ display: 'flex' }}>
                    <PujariSidebar />
                </div>
                <div className="page-content">
                    <PageHeader HeaderName="Pujari Reviews" />
                    <div className='container'>
                        <div className='row mt-3'>
                            <div className='col'>
                                <table className='table table-bordered table-stripped'>
                                    <thead className='bg-warning'>
                                        <tr>
                                            <td>SNo</td>
                                            <td>Reviewer Name</td>
                                            <td>Review</td>
                                            <td>Star Rating</td>
                                            <td>status</td>
                                            <td>Action</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {reviewData.map((review, index) => (
                                            <tr key={review.id}>
                                                <td>{++index}</td>
                                                <td>{review.user_name}</td>
                                                <td>{review.review}</td>
                                                <td>{review.rating}</td>
                                                <td>{review.status === 1 ? "Active" : (review.status === 2 ? "Pending" : "InActive")}</td>
                                                <td>{review.status === 1 ? <button className='btn btn-success btn-sm'>Hide</button> : <button className='btn btn-danger btn-sm'>Show</button>}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PujariReviews