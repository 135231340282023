import axios from 'axios'
const token = localStorage.getItem('token')
const apiBaseUrl = process.env.REACT_APP_API_BASEURL

const api = axios.create({
  baseURL: apiBaseUrl,
  headers: {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  },
})

const getToken = () => {
  return localStorage.getItem('token')
}

export const GetTempleProfile = async () => {
  try {
    const response = await api.get('temple/profile')
    // console.log(response.data, 'profile response from api')
    return response
  } catch (error) {
    throw error
  }
}
