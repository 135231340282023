import React from 'react';
import './NotFound.css'

function NotFound() {
  return (
    <div className="not_found">
      <div className="row justify-content-center">
        <div className="col" id="not_found_col_id">
          <div
            className="col"
            id="not_found_col_col_id" 
          >
            <p>404 </p>
            <p>Not Found</p>
            <h6 id='text_tag' >
              Sorry, the page you are looking for does not exist.
            </h6>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NotFound
