import React, { useState, useEffect } from 'react'
import { FaCalendarAlt } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { fetchSevaTemples } from '../API/FetchAPI';
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import PageHeader from "../PageHeader/PageHeader";

const TempleDharshanamViewDetails = () => {
    const navigate = useNavigate();
    const [dharsh, setDharsh] = useState([]);

    const handleButtonBack = () => {
        navigate('/viewfulldetails', { state: { activeTab: 'tab4' } });
    };
    async function loadTemplesData() {
        try {
            const data = await fetchSevaTemples('dasrshanam/get-by-temple-id/6');
            console.log('Data for Temples:', data);
            setDharsh(data);
            // Handle the data as needed
        } catch (error) {
            console.error('Error loading temples data:', error);
        }
    }

    useEffect(() => {
        loadTemplesData();
    }, [])


    return (
        <div>
            <div className="main-content">
                <Header />
                <div className="app-menu navbar-menu" style={{ display: 'flex' }}>
                    <Sidebar />
                </div>
                <div className="page-content">
                    <PageHeader HeaderName="Dashboard " />
                    <div className='container'>
                        <div className='row mt-3 justify-content-between'>
                            <div className='col-md-12'>
                                <h4>Dharshnam Details</h4>
                            </div>
                        </div>

                        <div className='Card_form'>
                            <div className='row mb-3'>
                                <div className='col-md-12'>
                                    <button className='btn btn-warning' onClick={handleButtonBack}>Back </button>
                                </div>
                            </div>

                            <form className='mt-3 mb-3'>
                                <div className='row '>
                                    <div className='col-md-6'>
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <div className='form-group mb-3'>
                                                    <label className='form-label'>Dharshanam Name</label>
                                                    <input type='text' value={dharsh.darshanam_name} className='form-control' readOnly />
                                                </div>
                                            </div>
                                            <div className='col-md-9'>
                                                <div className='form-group mb-3'>
                                                    <label className='form-label'>Dharshanam Type</label>
                                                    <input type='text' value={dharsh.darshnam_type} className='form-control' readOnly />
                                                </div>
                                            </div>
                                            <div className='col-md-3'>
                                                <div className='form-group mb-3'>
                                                    <label className='form-label'>Count</label>
                                                    <input type='text' value={dharsh.count} className='form-control' readOnly />
                                                </div>
                                            </div>
                                            <div className='col-md-12'>
                                                <div className='form-group mb-3'>
                                                    <label>Description</label>
                                                    <textarea rows={3} cols={12} value={dharsh.description} placeholder='' className='form-control' readOnly />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='row'>
                                            <div className="col-md-8">
                                                <img className='img-fluid' alt='' />
                                            </div>
                                            <div className="col-md-4">
                                                <div className='row'>
                                                    <div className="col-md-12 mb-2">
                                                        <img src={dharsh.image} className='img-fluid' alt='' />
                                                    </div>
                                                    <div className="col-md-12 mb-2">
                                                        <img src={dharsh.image} className='img-fluid' alt='' />
                                                    </div>
                                                    <div className="col-md-12 mb-2">
                                                        <img src={dharsh.image} className='img-fluid' alt='' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='form-group mb-3'>
                                            <label className='form-label'>Dharshanam Benefits</label>
                                            <textarea rows={3} cols={12} value={dharsh.benfits} placeholder='' className='form-control' readOnly />
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='form-group mb-3'>
                                            <label className='form-label'>Dharshanam Process</label>
                                            <textarea rows={3} cols={12} value={dharsh.process} placeholder='' className='form-control' readOnly />
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='form-group mb-3 calender_icon'>
                                            <label className='form-label'>Dharshanam Start Date</label>
                                            <input type='date' value={dharsh.start_date} className='form-control' readOnly />
                                            <span><FaCalendarAlt /></span>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='form-group mb-3 calender_icon'>
                                            <label className='form-label'>Dharshanam End Date</label>
                                            <input type='date' value={dharsh.end_date} className='form-control' readOnly />
                                            <span><FaCalendarAlt /></span>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='form-group mb-3'>
                                            <label className='form-label'>Dharshanam Start Time</label>
                                            <input type='text' value={dharsh.start_time} className='form-control' readOnly />
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='form-group mb-3'>
                                            <label className='form-label'>Dharshanam End Time</label>
                                            <input type='text'  value={dharsh.end_time} className='form-control' readOnly />
                                        </div>
                                    </div>

                                    <div className='col-md-6'>
                                        <div className='form-group mb-3'>
                                            <label className='form-label'>Price</label>
                                            <input type='text'  value={dharsh.end_time} className='form-control' readOnly />
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='form-group mb-3'>
                                            <label className='form-label'>Donations Accepted</label>
                                            <input type='text'  value={dharsh.donation_accepted} className='form-control' readOnly />
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='form-group mb-3'>
                                            <label className='form-label'>Select Pooja Samagri Kit</label>
                                            <select className='form-control'>
                                                <option value='' disabled selected>Select a kit</option>
                                                <option value='kit1'>Pooja Samagri Kit 1</option>
                                                <option value='kit2'>Pooja Samagri Kit 2</option>
                                                <option value='kit3'>Pooja Samagri Kit 3</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </form>


                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default TempleDharshanamViewDetails
