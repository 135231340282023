import { React, useState, useEffect, useMemo } from 'react'
import Header from '../../Header/Header'
import Sidebar from '../../Sidebar/Sidebar'
import PageHeader from '../../PageHeader/PageHeader'
import DataTable from 'react-data-table-component'
import {
  GetAllDarshanamDetails,
  CreateNewDarshanamDetails,
  DeleteDarshanamDetails,
  UpdateDarshanamDetails,
} from '../../API/TempleApis/darshanamApis'
import Modal from 'react-bootstrap/Modal'
import { FILE_BASEURL } from '../../Services/imageUrl'
import { toast } from 'react-toastify'
import { getApiData } from '../../Pujari/API';
import 'react-toastify/dist/ReactToastify.css'
import { GetAllPujaSamagriKit } from '../../Pujari/Constants'
import axios from 'axios';

const Darshanam = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASEURL
  const [darshanamData, setDarshanamData] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteData, setDeleteData] = useState(false)
  const [editDarshanamData, setEditDarshanamData] = useState('')
  const [isEditMode, setIsEditMode] = useState(false)
  const [samagriKit, setSamagriKit] = useState([])
  const [timeRows, setTimeRows] = useState([{ start_time: '', end_time: '' }]);
  const [dateRows, setDateRows] = useState([{ start_date: '', end_date: '' }]);
  const [images, setImages] = useState([]);
  const [packIds, setPackIds] = useState([]);
  const [formInput, setFormInput] = useState({
    darshanam_name: '',
    darshnam_type: '',
    count: '',
    description: '',
    start_date: '',
    end_date: '',
    start_time: [''],
    end_time: [''],
    price: '',
    image: [''],
    donation_accepted: '',
    temple_id: '',
    status: '1',
    process: '',
    benfits: '',
    samagrikit: '',
    pack_id: '',
  })
  const handleTimeChange = (e, index, field) => {
    const { value } = e.target;
    const newTimeRows = [...timeRows];
    newTimeRows[index][field] = e.target.value;
    setTimeRows(newTimeRows);
  };
  const handleDateChange = (e, index, field) => {
    const { value } = e.target;
    const newDateRows = [...dateRows];
    newDateRows[index][field] = e.target.value;
    setDateRows(newDateRows);
  };


  const getAllDarshanamData = async () => {
    try {
      const darshanamResponse = await GetAllDarshanamDetails()
      setDarshanamData(darshanamResponse)
    } catch (error) {
      console.error('Error fetching poojari details:', error)
    }
  }

  const getAllSamagriKit = async () => {
    try {
      const response = await getApiData(GetAllPujaSamagriKit);
      console.log('Samagri Kit data:', response.data);
      setSamagriKit(response.data);
    } catch (error) {
      console.log('Error fetching samagri details:', error);
    }
  };

  useEffect(() => {
    getAllSamagriKit();
  }, []);


  const removeTimeRow = (index) => {
    const newTimeRows = [...timeRows];
    newTimeRows.splice(index, 1);
    setTimeRows(newTimeRows);
  };

  const addTimeRow = () => {
    setTimeRows([...timeRows, { start_time: '', end_time: '' }]);
  };

  // const handleChange1 = (e) => {
  //   const { name, value } = e.target
  //   setFormInput((prevData) => ({ ...prevData, [name]: value }))
  // }

  // const handleImageChange1 = (e) => {
  //   const file = e.target.files[0]
  //   setFormInput((prevData) => ({
  //     ...prevData,
  //     image: file, // Set to file object, not string
  //   }))
  // }

  const handleUploadClick = () => {
    // Trigger click event on the file input element
    document.getElementById('fileInput').click()
  }


  const openDeleteModal = (row) => {
    setDeleteModal(true);
    setDeleteData(row);
  };


  const addNewDarshanam = () => {
    setOpenModal(true)
    setFormInput({
      darshanam_name: '',
      darshnam_type: '',
      count: '',
      description: '',
      start_date: '',
      end_date: '',
      start_time: [],
      end_time: [],
      price: '',
      image: [],
      donation_accepted: '',
      temple_id: '',
      status: '1',
      process: '',
      benfits: '',
      samagrikit: '',
      pack_id: '',
    })
    setTimeRows([{ start_time: '', end_time: '' }]);
    // setDateRows([{ start_date: '', end_date: '' }]);
  };


  const handleClose = () => {
    setOpenModal(false)
    setDeleteModal(false)
    setIsEditMode(false)
    setFormInput({
      darshanam_name: '',
      darshnam_type: '',
      count: '',
      description: '',
      start_date: '',
      end_date: '',
      start_time: [],
      end_time: [],
      price: '',
      image: [],
      donation_accepted: '',
      temple_id: '',
      status: '1',
      process: '',
      benfits: '',
      samagrikit: '',
      pack_id: '',
    })
    setTimeRows([{ start_time: '', end_time: '' }]);
    // setDateRows([{ start_date: '', end_date: '' }]);
  };


  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...formInput[name]];
    list[index] = value;
    setFormInput((prevState) => ({ ...prevState, [name]: list }));
  };
  const handleImageChange = (e, index) => {
    const file = e.target.files[0];
    const list = [...images];
    list[index] = file;
    setImages(list);
  };

  const addItem = (array, setArray) => {
    const newItem = '';
    setArray([...array, newItem]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const formData = new FormData()

      timeRows.forEach((row, index) => {
        formData.append(`start_time[${index}]`, row.start_time);
        formData.append(`end_time[${index}]`, row.end_time);
      });

      // Append image files
      images.forEach((image, index) => {
        formData.append(`image[${index}]`, image);
      });


      formData.append('darshanam_name', formInput.darshanam_name)
      formData.append('darshnam_type', formInput.darshnam_type)
      formData.append('count', formInput.count)
      formData.append('description', formInput.description)
      formData.append('start_date', formInput.start_date)
      formData.append('end_date', formInput.end_date)
      // formData.append('start_time', formInput.start_time)
      // formData.append('end_time', formInput.end_time)
      formData.append('price', formInput.price)
      formData.append('donation_accepted', formInput.donation_accepted)
      formData.append('temple_id', formInput.temple_id)
      formData.append('status', formInput.status)
      // formData.append('image', formInput.image)
      formData.append('benfits', formInput.benfits)
      formData.append('process', formInput.process)
      formData.append('samagrikit', formInput.samagrikit)
      formData.append('pack_id', formInput.pack_id);


      if (formInput.image instanceof File) {
        formData.append('image', formInput.image)
      }


      if (isEditMode) {
        formData.append('id', editDarshanamData.id)
        const updatedDarshanamData = { ...formInput }
        const response = await UpdateDarshanamDetails(updatedDarshanamData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        handleClose()
        getAllDarshanamData()
        toast.success('Darshanam details updated successfully')

      } else {
        const newSevaData = await CreateNewDarshanamDetails(formData)
        toast.success('Darshanam details updated successfully')
        handleClose()
        getAllDarshanamData()
      }
    } catch (error) {
      // console.log(error.response, 'response')
      if (error.response.status === 422) {
        Object.values(error.response.data.errors).map((item) => {
          toast.error(item[0], {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        })
      } else {
        toast.error(error.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      }
    }
  }

  const columns = useMemo(
    () => [
      {
        name: 'S.No',
        selector: 'slno',
        sortable: true,
        format: (row, rowIndex) => rowIndex + 1,
      },
      {
        name: ' Darshanam Name',
        selector: 'darshanam_name',
        sortable: true,
        width: "180px",
      },
      {
        name: 'Darshanam Type',
        selector: 'darshnam_type',
        sortable: true,
        width: "180px",
        cell: (row) => {
          return row.darshnam_type === 1 ? 'Slot' : 'Unlimited'
        },
      },

      {
        name: 'Description',
        selector: 'description',
        // cell: (row) => formatDate(row.pandal_start_date),
        sortable: true,
        width: "180px",
      },
      {
        name: 'Process',
        selector: 'process',
        // cell: (row) => formatDate(row.pandal_start_date),
        sortable: true,
      },
      {
        name: 'benefits',
        selector: 'benfits',
        // cell: (row) => formatDate(row.pandal_start_date),
        sortable: true,
      },
      {
        name: 'Samagrikit',
        selector: 'samagrikit',
        // cell: (row) => formatDate(row.pandal_start_date),
        sortable: true,
        width: "180px",
      },
      {
        name: 'Price',
        selector: 'price',
        // cell: (row) => formatDate(row.pandal_start_date),
        sortable: true,
      },
      {
        name: 'Actions',
        cell: (row) => (
          <div>
            <button
              class="btn btn-sm btn-edit me-2"
              onClick={() => editDarshanamDetails(row)}
            >
              <i class="fa-solid fa-edit"></i>
            </button>
            <button
              class="btn btn-sm btn-delete me-2"
              onClick={() => openDeleteModal(row)}
            // onClick={openDeleteModal}
            >
              <i class="fa-solid fa-trash-can"></i>
            </button>
          </div>
        ),
      },
    ],
    [],
  )

  const customStyles = {
    rows: {
      style: {
        // border: '0.1px solid #ddd',
      },
    },
    headCells: {
      style: {
        paddingLeft: '8px',
        paddingRight: '8px',
        color: 'black',
        fontSize: '14px',
        backgroundColor: '#ccc',
        fontFamily: 'Poppins',
        fontWeight: '600',
        border: '0.1px solid #ddd',
        display: 'flex',
        justifyContent: 'center',
      },
    },
    cells: {
      style: {
        paddingLeft: '8px',
        paddingRight: '8px',
        border: '0.1px solid #ddd',
        display: 'flex',
        justifyContent: 'center',
      },
    },
  }

  const editDarshanamDetails = (selectedData) => {
    setEditDarshanamData(selectedData);
    setIsEditMode(true);
    setOpenModal(true);
    setFormInput((prevFormInput) => ({
      ...prevFormInput,
      id: selectedData.id,
      darshanam_name: selectedData.darshanam_name,
      darshnam_type: selectedData.darshnam_type,
      count: selectedData.count,
      description: selectedData.description,
      start_date: selectedData.start_date,
      end_date: selectedData.end_date,
      start_time: selectedData.start_time,
      end_time: selectedData.end_time,
      price: selectedData.price,
      donation_accepted: selectedData.donation_accepted,
      image: selectedData?.image,
      status: selectedData.status,
      temple_id: selectedData.temple_id,
      process: selectedData.process,
      benfits: selectedData.benfits,
      samagrikit: selectedData.samagrikit,
      pack_id: selectedData.pack_id,
    }));
  };


  const deleteDarshanamData = async () => {
    try {
      await DeleteDarshanamDetails(deleteData.id)
      const successMessage = 'Darshanam Data Deleted'
      toast.success(successMessage, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      getAllDarshanamData()
      handleClose()
    } catch (error) {
      toast.error(error.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  return (
    <>
      <div className="main-content">
        <Header />
        <div className="app-menu navbar-menu" style={{ display: 'flex' }}>
          <Sidebar />
        </div>
        <div className="page-content">
          <PageHeader HeaderName="Darshanam" />
          <div className="container-fluid pt-3">
            <div className="row mb-2">
              <div style={{ display: 'flex', marginRight: '50px' }}>
                {/* <h5 style={{ color: "#A90000" }}>Pooja's</h5> */}
                <div className="col-md-3 ms-auto mb-3">
                  {/* <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      name=""
                      placeholder="Search here"
                    />
                    <button type="submit" className="input-group-text">
                      <i className="fa fa-search"></i>
                    </button>
                  </div> */}
                </div>

                <button
                  className="button-container"
                  style={{
                    marginTop: '0px',
                    height: '35px',
                    width: '150px',
                    marginLeft: '30px',
                  }}
                  onClick={addNewDarshanam}
                >
                  Create Darshanam
                </button>
              </div>
            </div>
            <div className="table-responsive registered-img bg-white">
              <DataTable
                columns={columns}
                data={darshanamData}
                pagination
                customStyles={customStyles}
                highlightOnHover

              />
            </div>
            <Modal
              show={openModal}
              onHide={() => handleClose()}
              size="lg"
              aria-labelledby="example-modal-sizes-title-lg"
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header>
                <Modal.Title
                  id="example-modal-sizes-title-lg"
                  style={{ color: '#A90000' }}
                >
                  {isEditMode ? 'Edit Darshanam Details' : 'Create Darshanam'}
                </Modal.Title>
              </Modal.Header>
              <hr />
              <Modal.Body style={{ marginTop: '-20px' }}>
                <form onSubmit={handleSubmit}>
                  <div className="d-flex">
                    <div className="col-md-6 mb-3">
                      <div className="form-group">
                        <label className="form-label">Name</label>
                        <input
                          type="text"
                          className="form-control"
                          name="darshanam_name"
                          onChange={(e) => setFormInput({ ...formInput, darshanam_name: e.target.value })}
                          value={formInput.darshanam_name}
                        />
                      </div>
                    </div>

                    <div
                      className="col-md-6 mb-3"
                      style={{ position: 'relative', paddingLeft: '20px' }}
                    >
                      <div className="form-group">
                        <label className="form-label" style={{ marginLeft: '10px' }}>Image</label>
                        {images.map((image, index) => (
                          <div key={index} className="d-flex align-items-center mb-3">
                            <div style={{ position: 'relative' }}>
                              <input
                                type="file"
                                className="form-control me-2"
                                onChange={(e) => handleImageChange(e, index)}
                              />

                            </div>

                          </div>
                        ))}
                        <button
                          type="button"
                          className="btn btn-primary btn-sm"
                          onClick={() => addItem(images, setImages)}
                          style={{ marginLeft: '15px' }}
                        >
                          Add Image
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 d-flex">
                    <div className="col-md-8 mb-3">
                      <div className="form-group">
                        <label className="form-label">Darshanam Type</label>
                        <select
                          className="form-select"
                          onChange={(e) => setFormInput({ ...formInput, darshnam_type: e.target.value })}
                          value={formInput.darshnam_type}
                          name="darshnam_type"
                        >
                          <option value="">Select</option>
                          <option value="1">Slot</option>
                          <option value="2">Unlimited</option>
                        </select>
                      </div>
                    </div>
                    {formInput.darshnam_type !== '2' && ( 
                      <div className="col-md-2 mb-3">
                        <div className="form-group" style={{ paddingLeft: '20px' }}>
                          <label className="form-label">Count</label>
                          <input
                            type="number"
                            className="form-control"
                            onChange={(e) => setFormInput({ ...formInput, count: '' })}
                            value={formInput.count}
                            name="count"
                            disabled={true}
                          />
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label className="form-label">Description</label>
                      <textarea
                        className="form-control"
                        rows="4"
                        columns="20"
                        onChange={(e) => setFormInput({ ...formInput, description: e.target.value })}
                        name="description"
                      >
                        {formInput.description}
                      </textarea>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="col-md-6 mb-3">
                      <div className="form-group">
                        <label className="form-label"> Seva benfits</label>
                        <textarea
                          className="form-control"
                          onChange={(e) => setFormInput({ ...formInput, benfits: e.target.value })}
                          value={formInput.benfits}
                          name="benfits"
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <div
                        className="form-group"
                        style={{ paddingLeft: '20px' }}
                      >
                        <label className="form-label">Seva Process</label>
                        <textarea

                          className="form-control"
                          onChange={(e) => setFormInput({ ...formInput, process: e.target.value })}
                          value={formInput.process}
                          name="process"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="col-md-6 mb-3">
                      <div className="form-group">
                        <label className="form-label">Darshanam Start Date</label>
                        {dateRows.map((row, index) => (
                          <div key={index} className="d-flex align-items-center mb-3">
                            <input
                              type="date"
                              className="form-control me-2"
                              onChange={(e) => setFormInput({ ...formInput, start_date: e.target.value })}
                              value={formInput.start_date}
                              name="start_date"
                              min={new Date().toISOString().split('T')[0]}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <div
                        className="form-group"
                        style={{ paddingLeft: '20px' }}>
                        <label className="form-label">Darshanam End Date</label>
                        {dateRows.map((row, index) => (
                          <div key={index} className="d-flex align-items-center mb-3">
                            <input
                              type="date"
                              className="form-control"
                              onChange={(e) => setFormInput({ ...formInput, end_date: e.target.value })}
                              value={formInput.end_date}
                              name="end_date"
                              min={formInput.start_date || new Date().toISOString().split('T')[0]}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="col-md-6 mb-3">
                      <div className="form-group">
                        <label className="form-label">
                          Darshanam Start Time
                        </label>
                        {timeRows.map((row, index) => (
                          <div key={index} className="d-flex align-items-center mb-3">
                            <input
                              type="time"
                              className="form-control me-2"
                              onChange={(e) => handleTimeChange(e, index, 'start_time')}
                              value={row.start_time}
                              name="start_time"
                              min="00:00"
                            />
                            {index > 0 && (
                              <button
                                type="button"
                                className="btn btn-danger btn-sm"
                                onClick={() => removeTimeRow(index)}
                              >
                                Remove
                              </button>
                            )}
                          </div>
                        ))}
                        <button
                          type="button"
                          className="btn btn-primary btn-sm"
                          onClick={addTimeRow}
                        >
                          Add Time
                        </button>
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <div
                        className="form-group"
                        style={{ paddingLeft: '20px' }}
                      >
                        <label className="form-label">Darshanam End Time</label>
                        {timeRows.map((row, index) => (
                          <div key={index} className="d-flex align-items-center mb-3">
                            <input
                              type="time"
                              className="form-control"
                              onChange={(e) => handleTimeChange(e, index, 'end_time')}
                              value={row.end_time}
                              name="end_time"
                              min="00:00"
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="col-md-6 mb-3">
                      <div className="form-group">
                        <label className="form-label">Price</label>
                        <input
                          type="number"
                          className="form-control"
                          onChange={(e) => setFormInput({ ...formInput, price: e.target.value })}
                          value={formInput.price}
                          name="price"
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <div
                        className="form-group"
                        style={{ paddingLeft: '20px' }}
                      >
                        <label className="form-label">Donations Accepted</label>
                        <select
                          className="form-select"
                          onChange={(e) => setFormInput({ ...formInput, donation_accepted: e.target.value })}
                          value={formInput.donation_accepted}
                          name="donation_accepted"
                        >
                          <option value="">Select</option>
                          <option value="1">Yes</option>
                          <option value="2">No</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="col-md-6 mb-3">
                      <div className="form-group">
                        <label className="form-label">Samagri Kit</label>
                        <select
                          className="form-select"
                          name="pack_id"
                          value={formInput.pack_id}
                          onChange={handleChange}
                        >
                          <option value="">--select--</option>
                          {samagriKit.map((kit, index) => (
                            <optgroup key={index} label={kit.pack_name}>
                              {kit.samagri.map((item) => (
                                <option key={item.id} value={item.id}>
                                  {item.samagri_name}
                                </option>
                              ))}
                            </optgroup>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className=" col-md-6 mb-3">

                      <label className="form-label" style={{ marginLeft: '20px' }}>Pack ID</label>
                      <select
                        className="form-control"
                        id="pack_id"
                        name="pack_id"
                        value={formInput.pack_id}
                        style={{ marginLeft: '20px', width: '360px' }}
                        onChange={(e) => setFormInput({ ...formInput, pack_id: e.target.value })}

                      >
                        <option value="">Select</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        {packIds.map((pack) => (
                          <option key={pack.id} value={pack.id}>{pack.name}</option>
                        ))}
                      </select>

                    </div>
                  </div>
                  <hr />
                  <div className="modal-footer d-flex justify-content-end">
                    <button
                      type="button"
                      className="back-button"
                      onClick={handleClose}
                    >
                      Back
                    </button>
                    <button
                      type="submit"
                      className="save-button"
                    // onClick={handleCreateZone}
                    >
                      {isEditMode ? 'Update' : 'Save'}
                    </button>
                  </div>
                </form>
              </Modal.Body>
            </Modal>
          </div>

          <Modal
            show={deleteModal}
            onHide={() => handleClose()}
            aria-labelledby="example-modal-sizes-title-lg"
            backdrop="static"
            keyboard={false}
            style={{ borderRadius: '20px' }}
            centered
          >
            <Modal.Header
              style={{ backgroundColor: '#A90000', position: 'relative' }}
            >
              <div className="delete-modal">
                <i
                  class="fa-solid fa-trash-can"
                  style={{ fontSize: '25px', color: '#fff' }}
                ></i>
              </div>
            </Modal.Header>
            <Modal.Body
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <h3>Delete Darshanam?</h3>
              <h6>You'll Permanently lose {deleteData.darshanam_name}</h6>
            </Modal.Body>
            <div className="modal-footer d-flex justify-content-center">
              <button
                type="button"
                className="back-button"
                onClick={handleClose}
              >
                Back
              </button>
              <button
                type="button"
                className="save-button"
                onClick={deleteDarshanamData}
              >
                Delete
              </button>
            </div>
          </Modal>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  )
}

export default Darshanam
