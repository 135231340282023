import React, { useState } from 'react';
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane
} from 'mdb-react-ui-kit';
import TempleDetailsForm from './TempleDetailsForm';
import BankDetailsOfTemple from './BankDetailsOfTemple';
import DonationsRecievedtable from './DonationsRecievedtable';
import TemplesSeva from './TemplesSeva';
import Templepoojari from './Templepoojari';
import TempleDharshanam from './TempleDharshanam';
import TempleProducts from './TempleProducts';

function TempleDetailsTabs() {
    const [basicActive, setBasicActive] = useState('tab1');
    const handleBasicClick = (value) => {
        if (value === basicActive) {
          return;
        }
        setBasicActive(value);
      };


  return (
    <div>
       <MDBTabs className='mb-3 mt-3 list_partners'>
        <MDBTabsItem className=''>
          <MDBTabsLink onClick={() => handleBasicClick('tab1')} active={basicActive === 'tab1'}>
          Temple Details
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleBasicClick('tab2')} active={basicActive === 'tab2'}>
          Bank Details Of Temple
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleBasicClick('tab3')} active={basicActive === 'tab3'}>
          Donations Received
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleBasicClick('tab4')} active={basicActive === 'tab4'}>
          Sevas
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleBasicClick('tab5')} active={basicActive === 'tab5'}>
          Poojari
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleBasicClick('tab6')} active={basicActive === 'tab6'}>
          Dharshanam
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleBasicClick('tab7')} active={basicActive === 'tab7'}>
          Products
          </MDBTabsLink>
        </MDBTabsItem>
      </MDBTabs>

      <MDBTabsContent>
        <MDBTabsPane open={basicActive === 'tab1'}>
            <TempleDetailsForm/>
        </MDBTabsPane>
        <MDBTabsPane open={basicActive === 'tab2'}> 
        <BankDetailsOfTemple />
        </MDBTabsPane>
        <MDBTabsPane open={basicActive === 'tab3'}>
           <DonationsRecievedtable />
        </MDBTabsPane>
        <MDBTabsPane open={basicActive === 'tab4'}>
            <TemplesSeva />
        </MDBTabsPane>
        <MDBTabsPane open={basicActive === 'tab5'}>
            <Templepoojari />
        </MDBTabsPane>
        <MDBTabsPane open={basicActive === 'tab6'}>
            <TempleDharshanam />
        </MDBTabsPane>
        <MDBTabsPane open={basicActive === 'tab7'}>
            <TempleProducts />
        </MDBTabsPane>
      </MDBTabsContent>
    </div>
  )
}

export default TempleDetailsTabs
