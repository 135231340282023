/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { React, useState, useEffect, useMemo } from "react";
import Header from "../../Header/Header";
import Sidebar from "../../Sidebar/Sidebar";
import PageHeader from "../../PageHeader/PageHeader";
import DataTable from "react-data-table-component";
import Modal from "react-bootstrap/Modal";
import {
  GetAllAuctionsDetails,
  CreateAuctiontDetails,
  UpdateAuctiontDetails,
  DeleteAuctionDetails,
} from "../../API/TempleApis/auctions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FILE_BASEURL } from "../../Services/imageUrl";
// import './myBookings.css'
const TempleAuction = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASEURL
  const [openModal, setOpenModal] = useState(false);
  const [getAllAuctions, setAllAuctions] = useState([]);
  const [selectedAuctions, setSelectedAuctions] = useState([]);
  const [activeTab, setActiveTab] = useState("ongoing");
  const [fixedAuctions, setFixedAuctions] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editAuctionData, setEditAuctionData] = useState('');
  const [deleteData, setDeleteData] = useState(false);
  const [viewData, setViewData] = useState(false);
  const [formInput, setFormInput] = useState({
    auctionName: "",
    location: "",
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
    startPrice: "",
    auctionType: "",
    image: "",
  });

  const FetchAllAuctionsDetails = async () => {
    try {
      const response = await GetAllAuctionsDetails();
      setAllAuctions(response);
    } catch (error) {
      console.error("Error fetching poojari details:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormInput((prevData) => ({ ...prevData, [name]: value }));
  };
  useEffect(() => {
    FetchAllAuctionsDetails();
  }, []);

  useEffect(() => {
    if (activeTab === "ongoing") {
      setSelectedAuctions(
        getAllAuctions.filter(
          (auction) => auction.status.toLowerCase() === "ongoing"
        )
      );
    } else if (activeTab === "upcoming") {
      setSelectedAuctions(
        getAllAuctions.filter(
          (auction) =>
            auction.status.toLowerCase() === "upcoming" &&
            auction.auctionType !== 2
        )
      );
    } else if (activeTab === "closed") {
      setSelectedAuctions(
        getAllAuctions.filter(
          (auction) => auction.status.toLowerCase() === "closed"
        )
      );
    } else if (activeTab === "fixed") {
      setSelectedAuctions(
        getAllAuctions.filter((auction) => auction.auctionType === 2)
      );
    }
  }, [activeTab, getAllAuctions]);

  useEffect(() => {
    if (getAllAuctions.length > 0) {
      const fixedAuctions = getAllAuctions.filter(
        (auction) => auction.auctionType === 2
      );

      setFixedAuctions(fixedAuctions);
    }
  }, [getAllAuctions]);

  const customStyles = {
    rows: {
      style: {
        // border: '0.1px solid #ddd',
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        color: "black",
        fontSize: "14px",
        backgroundColor: "#ccc",
        fontFamily: "Poppins",
        fontWeight: "600",
        border: "0.1px solid #ddd",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        border: "0.1px solid #ddd",
        display: "flex",
        justifyContent: "center",
      },
    },
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  const addNewAuction = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
    setDeleteModal(false);
    setIsEditMode(false)
    setFormInput({
      auctionName: "",
      location: "",
      startDate: "",
      endDate: "",
      startTime: "",
      endTime: "",
      startPrice: "",
      auctionType: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("auctionName", formInput.auctionName);
      formData.append("location", formInput.location);
      formData.append("startDate", formInput.startDate);
      formData.append("endDate", formInput.endDate);
      formData.append("startTime", formInput.startTime);
      formData.append("endTime", formInput.endTime);
      formData.append("startPrice", formInput.startPrice);
      formData.append("auctionType", formInput.auctionType); // Append auctionType
      formData.append("image", formInput.image);

      if (isEditMode) {
        formData.append("id", formInput.id); // Ensure you append the ID for update
        const response = await UpdateAuctiontDetails(formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        toast.success("Auction details updated successfully");
        handleClose();
        FetchAllAuctionsDetails();
        // Handle response if necessary
      } else {
        const response = await CreateAuctiontDetails(formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        toast.success("Auction details Created successfully");
        handleClose();
        FetchAllAuctionsDetails();
      }
    } catch (error) {
      // console.log(error.response, 'response')
      if (error.response.status === 421) {
        Object.values(error.response.data.errors).map((item) => {
          toast.error(item[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
      } else {
        toast.error(error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  const columns = useMemo(
    () => [
      {
        name: "S.No",
        selector: "slno",
        sortable: true,
        format: (row, rowIndex) => rowIndex + 1,
        width: "8%",
      },
      {
        name: "Location",
        selector: "location",
        sortable: true,
      },
      {
        name: "Auction Name",
        selector: "auctionName",
        sortable: true,
      },
      {
        name: "Date Of Auction",
        selector: "dateofAuction",
        sortable: true,
      },
      {
        name: "Status",
        selector: "status",
        // cell: (row) => formatDate(row.pandal_start_date),
        sortable: true,
      },

      {
        name: "Actions",
        cell: (row) => (
          <div>
            <button
              class="btn btn-sm btn-edit me-2"
              onClick={() => editAuctionDetails(row)}
            >
              <i class="fa-solid fa-edit"></i>
            </button>
            <button
              class="btn btn-sm btn-delete me-2"
              onClick={() => deleteAuctioModal(row)}
            >
              <i class="fa-solid fa-trash"></i>
            </button>
          </div>
        ),
        width: "10%",
      },
    ],
    []
  );

  const deleteAuctioModal = (row) => {
    setDeleteModal(true);
    setViewData(row);
    setDeleteData({ id: row.id });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFormInput((prevData) => ({
      ...prevData,
      image: file, // Set to file object, not string
    }));
  };

  const editAuctionDetails = (selectedData) => {
    setOpenModal(true);
    setIsEditMode(true);
    setEditAuctionData(selectedData)
    setFormInput((prevFormInput) => ({
      ...prevFormInput,
      id: selectedData.id,
      auctionName: selectedData.auctionName,
      auctionType: selectedData.auctionType,
      location: selectedData.location,
      startDate: selectedData.startDate,
      endDate: selectedData.endDate,
      startTime: selectedData.startTime,
      endTime: selectedData.endTime,
      startPrice: selectedData.startPrice,
      image: selectedData?.image,
    }));
  };

  const deleteAuctionData = async () => {
    try {
      await DeleteAuctionDetails(deleteData);
      const successMessage = "Auction Data Deleted";
      toast.success(successMessage, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      FetchAllAuctionsDetails();
      handleClose();
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <>
      <div className="main-content">
        <Header />
        <div className="app-menu navbar-menu" style={{ display: "flex" }}>
          <Sidebar />
        </div>
        <div className="page-content">
          <PageHeader HeaderName="Auction's" />
          <div className="container-fluid pt-3">
            <div className=" mb-3">
              <ul className="nav nav-tabs">
                <div className="col-md-3">
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        activeTab === "ongoing" ? "active" : ""
                      }`}
                      onClick={() => handleTabChange("ongoing")}
                    >
                      Ongoing Auctions
                    </a>
                  </li>
                </div>
                <div className="col-md-3">
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        activeTab === "upcoming" ? "active" : ""
                      }`}
                      onClick={() => handleTabChange("upcoming")}
                    >
                      Upcoming Auctions
                    </a>
                  </li>
                </div>
                <div className="col-md-3">
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        activeTab === "closed" ? "active" : ""
                      }`}
                      onClick={() => handleTabChange("closed")}
                    >
                      Closed Auctions
                    </a>
                  </li>
                </div>
                {fixedAuctions.length > 0 && (
                  <div className="col-md-3">
                    <li className="nav-item">
                      <a
                        className={`nav-link ${
                          activeTab === "fixed" ? "active" : ""
                        }`}
                        onClick={() => handleTabChange("fixed")}
                      >
                        Fixed Auctions
                      </a>
                    </li>
                  </div>
                )}
              </ul>
            </div>

            <div className="row mb-2">
              <div style={{ display: "flex", marginRight: "50px" }}>
                <div className="col-md-3 ms-auto mb-3">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      name=""
                      placeholder="Search here"
                    />
                    <button type="submit" className="input-group-text">
                      <i className="fa fa-search"></i>
                    </button>
                  </div>
                </div>

                <button
                  className="button-container"
                  style={{
                    marginTop: "0px",
                    height: "35px",
                    width: "150px",
                    marginLeft: "30px",
                  }}
                  onClick={addNewAuction}
                >
                  Create Auction
                </button>
              </div>
            </div>
            <div className="tab-content">
              <div className="tab-pane active" id="one" role="tabpanel">
                <div className="table-responsive registered-img bg-white">
                  <DataTable
                    columns={columns}
                    data={selectedAuctions} // Filtered ongoing auctions
                    pagination
                    customStyles={customStyles}
                    highlightOnHover
                    //   noDataComponent="Loading..."
                  />
                </div>
              </div>
            </div>
            <Modal
              show={openModal}
              onHide={() => handleClose()}
              size="lg"
              aria-labelledby="example-modal-sizes-title-lg"
              backdrop="static"
            >
              <Modal.Header>
                <Modal.Title
                  id="example-modal-sizes-title-lg"
                  style={{ color: "#A90000" }}
                >
                  {isEditMode ? "Edit Auction Details" : "Create Auction"}
                </Modal.Title>
              </Modal.Header>
              <hr />
              <Modal.Body style={{ marginTop: "-20px" }}>
                <form onSubmit={handleSubmit}>
                  <div className="d-flex">
                    <div className="col-md-6 mb-3">
                      <div className="form-group">
                        <label className="form-label">Auction Name</label>
                        <input
                          type="text"
                          className="form-control"
                          value={formInput.auctionName}
                          name="auctionName"
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div
                      className="col-md-6 mb-3"
                      style={{ position: "relative", paddingLeft: "20px" }}
                    >
                      <div className="form-group">
                        <label className="form-label">Image</label>
                        <div style={{ position: "relative" }}>
                          <input
                            type="file"
                            className="form-control"
                            name="image"
                            onChange={handleImageChange}
                            accept=".png, .jpg, .jpeg"
                          />
                        </div>
                        <div style={{ position: "absolute" }}>
                          {formInput.image && (
                            <img
                              src={
                                formInput.image instanceof File
                                  ? URL.createObjectURL(formInput.image)
                                  : FILE_BASEURL + editAuctionData.image
                              }
                              alt="Selected Image"
                              style={{ width: "280px",height:'220px' }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label className="form-label">Location</label>
                      <input
                        type="text"
                        className="form-control"
                        value={formInput.location}
                        name="location"
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label className="form-label">
                        Date Of Auction Start
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        value={formInput.startDate}
                        name="startDate"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label className="form-label">Date Of Auction End</label>
                      <input
                        type="date"
                        className="form-control"
                        value={formInput.endDate}
                        name="endDate"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="col-md-6 mb-3">
                      <div className="form-group">
                        <label className="form-label">Auction Start Time</label>
                        <input
                          type="time"
                          className="form-control"
                          value={formInput.startTime}
                          name="startTime"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <div
                        className="form-group"
                        style={{ paddingLeft: "20px" }}
                      >
                        <label className="form-label">Auction End Time</label>
                        <input
                          type="time"
                          className="form-control"
                          value={formInput.endTime}
                          name="endTime"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="col-md-6 mb-3">
                      <div className="form-group">
                        <label className="form-label">Auction Type</label>
                        <select
                          className="form-select"
                          value={formInput.auctionType}
                          name="auctionType"
                          onChange={handleChange}
                        >
                          <option value="">Select Auction Type</option>
                          <option value="1">Live</option>
                          <option value="2">Fixed</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <div
                        className="form-group"
                        style={{ paddingLeft: "20px" }}
                      >
                        <label className="form-label">Auction Price</label>
                        <input
                          type="text"
                          className="form-control"
                          value={formInput.startPrice}
                          name="startPrice"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="modal-footer d-flex justify-content-end">
                    <button
                      type="button"
                      className="back-button"
                      onClick={handleClose}
                    >
                      Back
                    </button>
                    <button
                      type="submit"
                      className="save-button"
                      // onClick={handleCreateZone}
                    >
                      {isEditMode ? "Update" : "Save"}
                    </button>
                  </div>
                </form>
              </Modal.Body>
            </Modal>
          </div>

          <Modal
            show={deleteModal}
            onHide={() => handleClose()}
            aria-labelledby="example-modal-sizes-title-lg"
            backdrop="static"
            keyboard={false}
            style={{ borderRadius: "20px" }}
            centered
          >
            <Modal.Header
              style={{ backgroundColor: "#A90000", position: "relative" }}
            >
              <div className="delete-modal">
                <i
                  class="fa-solid fa-trash-can"
                  style={{ fontSize: "25px", color: "#fff" }}
                ></i>
              </div>
            </Modal.Header>
            <Modal.Body
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <h3>Delete Auction?</h3>
              <h6>You'll Permanently lose {viewData.auctionName}</h6>
            </Modal.Body>
            <div className="modal-footer d-flex justify-content-center">
              <button
                type="button"
                className="back-button"
                onClick={handleClose}
              >
                Back
              </button>
              <button
                type="button"
                className="save-button"
                onClick={deleteAuctionData}
              >
                Delete
              </button>
            </div>
          </Modal>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default TempleAuction;
