import React, { useEffect, useState, useMemo } from 'react';
import PageHeader from '../PageHeader/PageHeader';
import * as XLSX from 'xlsx';
import DataTable from 'react-data-table-component';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Papa from 'papaparse';
import { useNavigate } from 'react-router-dom';
import './validation.css';
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
import { GetAllPandalsList } from '../Pujari/Constants';
import {  approveRejectTemple } from '../API/Api';
import { approveRejectPujari } from '../API/Api';
import Footer from '../Footer/Footer';
import { getApiData } from '../Pujari/API';
import { GetAllPoojari,GetAllTemplesList } from '../Pujari/Constants';

function Validation() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]); 
  const [pandal, setPandal] = useState([]);
  const [pujari, setPujari] = useState([]);
  const [activeTab, setActiveTab] = useState('temples');
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const navigate = useNavigate();
  const [templeData, setTempleData] = useState([]); 
  const [searchTerm, setSearchTerm] = useState('');

  const clickSevaDetails = (row) => {
    navigate('/validations-details', { state: row });
  };
  const clickSevaDetails1 = (row) => {
    navigate('/validations-details1', { state: row });
  };
  const clickSevaDetails2 = (row) => {
    navigate('/validations-details2', { state: row });
  };

  const fetchData = async (apiFunction) => {
    try {
      const data = await apiFunction();
      setData(data);
      console.log(data)
      setLoading(false);
      setTotalRows(data.length); 
    } catch (error) {
      console.error('Error fetching table data:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchDataForTab = () => {
      switch (activeTab) {
        case 'temples':
          const temples = fetchData(GetAllTemplesList);
          setTempleData(temples.data);
          break;
          case 'pujari':
            const pujari =  fetchData(GetAllPoojari);
            setPandal(pujari)
            break;
      
        case 'pandals':
          const pandals =  fetchData(GetAllPandalsList);
          setPandal(pandals)
          break;
        // default:
        //   break;
      }
      setLoading(false);
    };

    fetchDataForTab();
  }, [activeTab]);

  useEffect(() => {
    const fetchDataForPandal = async () => {
      try {
        const result = await getApiData(GetAllPandalsList); // Await the result of fetchData
        setData(result); // Set the pujari state with the fetched data
        console.log(result)
      } catch (error) {
        console.error('Error fetching pujari data:', error);
        setLoading(false);
      }
    };
  
    if (activeTab === 'pandals') {
      fetchDataForPandal();
    }
  }, [activeTab]);


  useEffect(() => {
    const fetchDataForTemple = async () => {
      try {
        const result = await getApiData(GetAllTemplesList);
        setTempleData(result.data); // Set temple data state
        setTotalRows(result.data.length); // Update total rows
        setLoading(false);
      } catch (error) {
        console.error('Error fetching temple data:', error);
        setLoading(false);
      }
    };
  
    if (activeTab === 'temples') {
      fetchDataForTemple();
    }
  }, [activeTab]);
  

  useEffect(() => {
    const fetchDataForTab = async () => {
      try {
        const result = await getApiData(GetAllPoojari); // Await the result of fetchData
        setPujari(result); // Set the pujari state with the fetched data
        console.log(result)
      } catch (error) {
        console.error('Error fetching pujari data:', error);
        setLoading(false);
      }
    };
  
    if (activeTab === 'pujari') {
      fetchDataForTab();
    }
  }, [activeTab]);
  
  

  // Define function to handle temple approval/rejection
  const handlePujariApproval = async (pujariId, status, rejectedReason = null) => {
    try {
      await approveRejectPujari(pujariId, status, rejectedReason);
      // Refresh pujari data after approval/rejection
      // fetchData(getAllPujari);
    } catch (error) {
      console.error('Error handling pujari approval/rejection:', error);
    }
  };
  const handleTempleApproval = async (templeId, status, rejectedReason = null) => {
    try {
      await approveRejectTemple(templeId, status, rejectedReason);
      // Refresh temple data after approval/rejection
      fetchData();
    } catch (error) {
      console.error('Error handling temple approval/rejection:', error);
    }
  };
  // const handlePandalApproval = async (pandalId, status, rejectedReason = null) => {
  //   try {
  //     await approveRejectPandal(pandalId, status, rejectedReason);
  //     // Refresh pandal data after approval/rejection
  //     fetchData(getAllModeration_Validation);
  //   } catch (error) {
  //     console.error('Error handling pandal approval/rejection:', error);
  //   }
  // };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };
   
  const filteredData = (data) => {
    if (searchTerm) {
      return data.filter((item) =>
        Object.values(item).some((val) =>
          String(val).toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }
    return data;
  };



const renderTabContent = () => {
  switch (activeTab) {
    case 'temples':
      return (
        
        <DataTable
        
          columns={columnsTemples}
          data={templeData} 
          pagination
          paginationPerPage={perPage}
          paginationRowsPerPageOptions={[5, 10, 15]}
          paginationTotalRows={totalRows}
          customStyles={customStyles}
        />
      );
    case 'pujari':
      return (
        <DataTable
          columns={columnsPujari}
          data={pujari} 
          pagination
          paginationPerPage={perPage}
          paginationRowsPerPageOptions={[5, 10, 15]}
          paginationTotalRows={totalRows}
          customStyles={customStyles}
        />
      );
    case 'pandals':
      return (
        <DataTable
          columns={columnsPandals}
          data={data}
          pagination
          paginationPerPage={perPage}
          paginationRowsPerPageOptions={[5, 10, 15]} 
          paginationTotalRows={totalRows}
          customStyles={customStyles}
        />
      );
    default:
      return null;
  }
};
// const renderTabContent = () => {
//   switch (activeTab) {
//     case 'temples':
//       return (
//         <table className="table">
//           <thead>
//             <tr>
//               <th>Sl.No</th>
//               <th>Temple Name</th>
//               <th>Phone Number</th>
//               <th>Email</th>
//               <th>Actions</th>
//             </tr>
//           </thead>
//           <tbody>
//             {templeData.map((temple, index) => (
//               <tr key={index}>
//                 <td>{index + 1}</td>
//                 <td>{temple.temple_name}</td>
//                 <td>{temple.phone_number}</td>
//                 <td>{temple.email}</td>
//                 <td>
//                   <button className="btn btn-sm btn-edit me-2" onClick={() => clickSevaDetails(temple)}>
//                     View Full Details
//                   </button>
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       );
//     case 'pujari':
//       return (
//         <table className="table">
//           <thead>
//             <tr>
//               <th>Sl.No</th>
//               <th>Pujari Name</th>
//               <th>Phone Number</th>
//               <th>Email</th>
//               <th>Actions</th>
//             </tr>
//           </thead>
//           <tbody>
//             {pujari.map((p, index) => (
//               <tr key={index}>
//                 <td>{index + 1}</td>
//                 <td>{p.pujari_name}</td>
//                 <td>{p.phone_number}</td>
//                 <td>{p.email}</td>
//                 <td>
//                   <button className="btn btn-sm btn-edit me-2" onClick={() => clickSevaDetails(p)}>
//                     View Full Details
//                   </button>
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       );
//     case 'pandals':
//       return (
//         <table className="table">
//           <thead>
//             <tr>
//               <th>Sl.No</th>
//               <th>Pandal Name</th>
//               <th>Phone Number</th>
//               <th>Email</th>
//               <th>Actions</th>
//             </tr>
//           </thead>
//           <tbody>
//             {pandal.map((p, index) => (
//               <tr key={index}>
//                 <td>{index + 1}</td>
//                 <td>{p.pandal_name}</td>
//                 <td>{p.phone_number}</td>
//                 <td>{p.email}</td>
//                 <td>
//                   <button className="btn btn-sm btn-edit me-2" onClick={() => clickSevaDetails(p)}>
//                     View Full Details
//                   </button>
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       );
//     default:
//       return null;
//   }
// };


  // const columnsTemples = useMemo(() => [
  //   {
  //     name: 'Sl.No',
  //     selector: ( index) => index + 1,
  //     sortable: true,
  //   },
  //   { 
  //     name: 'Temple Name',
  //     selector: 'temple_name',
  //     sortable: true,
  //   },
  //   {
  //     name: 'Phone Number',
  //     selector: 'phone_number',
  //     sortable: true,
  //   },
  //   {
  //     name: 'Email',
  //     selector: 'email',
  //     sortable: true,
  //   },
  //   {
  //     name: 'Actions',
  //     cell: (row) => (
  //       <div>
  //         <button
  //           className="btn btn-sm btn-edit me-2"
  //           onClick={() => clickSevaDetails(row)}
  //         >
  //           View Full Details
  //         </button>
  //       </div>
  //     ),
  //   },
  // ], []);
  
 
  const columnsTemples = [
    {
      name: "S.No",
      selector: (row) => row["sno"],
      sortable: true,
      format: (row, rowIndex) => rowIndex + 1,
    },
    {
      name: "Temple Name",
      selector: (row) => row["temple_name"],
      sortable: true,
    },
    {
      name: "Phone Number",
      selector: (row) =>row["phone_number"],
      sortable: true,
    },
    {
      name: "Email",
      // width: '12%',
      selector: (row) => (
        <span
        // style={{ color: "blue" }}
        // onClick={() => handleDeploymentClick(row["zoneId"])}
        >
          {row["email"]}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) =>row["status"],
      sortable: true,
    },
    {
          name: 'Actions',
          cell: (row) => (
            <div>
              <button
                className="btn btn-sm btn-edit me-2"
                onClick={() => clickSevaDetails2(row)}
              >
                View Full Details
              </button>
            </div>
          ),
        },
  ];
  
  const columnsPujari = [
    {
      name: "S.No",
      selector: (row) => row["sno"],
      sortable: true,
      format: (row, rowIndex) => rowIndex + 1,
    },
    {
      name: "Temple Name",
      selector: (row) => row["temple_name"],
      sortable: true,
    },
    {
      name: "Phone Number",
      selector: (row) =>row["mobile"],
      sortable: true,
    },
    {
      name: "Email",
      // width: '12%',
      selector: (row) => (
        <span
        // style={{ color: "blue" }}
        // onClick={() => handleDeploymentClick(row["zoneId"])}
        >
          {row["email"]}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) =>row["status"],
      sortable: true,
    },
    {
          name: 'Actions',
          cell: (row) => (
            <div>
              <button
                className="btn btn-sm btn-edit me-2"
                onClick={() => clickSevaDetails1(row)}
              >
                View Full Details
              </button>
            </div>
          ),
        },
  ];
  
  // const columnsPujari = useMemo(() => [
  //   {
  //     name: 'Sl.No',
  //     selector: ( index) => index + 1,
  //     sortable: true,
  //   },
  //   {
  //     name: 'Pujari Name',
  //     selector: 'pandal_name',
  //     sortable: true,
  //   },
  //   {
  //     name: 'Phone Number',
  //     selector: 'phone_number', 
  //     sortable: true,
  //   },
  //   {
  //     name: 'Email',
  //     selector: 'user_email',
  //     sortable: true,
  //   },
  //   {
  //     name: 'Actions',
  //     cell: (row) => (
  //       <div>
  //         <button
  //           className="btn btn-sm btn-edit me-2"
  //           onClick={() => clickSevaDetails(row)}
  //         >
  //           View Full Details
  //         </button>
  //       </div>
  //     ),
  //   },
  // ], []);
  
  const columnsPandals = useMemo(() => [
    {
      name: "S.No",
      selector: (row) => row["sno"],
      sortable: true,
      format: (row, rowIndex) => rowIndex + 1,
    },
    {
      name: 'Pandal Name',
      selector:(row)=>row['pandal_name'],
      sortable: true,
    },
    {
      name: 'Phone Number',
      selector:(row)=> row['help_mobile'], 
      sortable: true,
    },
    {
      name: 'Email',
      selector:(row)=> row['user_email'],
      sortable: true,
    },
    {
      name: 'Actions',
      cell: (row) => (
        <div>
          <button
            className="btn btn-sm btn-edit me-2"
            onClick={() => clickSevaDetails(row)}
          >
            View Full Details
          </button>
        </div>
      ),
    },
  ], []);

  const downloadAsPDF = () => {
    const doc = new jsPDF();
    doc.autoTable({
      head: [['Sl.No', 'Pandal Name', 'Phone Name', 'Email']],
      body: data.map((row, index) => [index + 1, row.pandal_name, row.phone_name, row.user_email]),
    });
    doc.save('table_data.pdf');
  };

  const downloadAsExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'table_data.xlsx');
  };

  const handleDownloadCSV = () => {
    const csv = Papa.unparse(data);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'table_data.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };



  const customStyles = {
    rows: {
      style: {
        // border: '0.1px solid #ddd',
      },
    },
    headCells: {
      style: {
        paddingLeft: '8px',
        paddingRight: '8px',
        color: 'black',
        fontSize: '14px',
        backgroundColor: '#ccc',
        fontFamily: 'Poppins',
        fontWeight: '600',
        border: '0.1px solid #ddd',
        display: 'flex',
        justifyContent: 'center',
      },
    },
    cells: {
      style: {
        paddingLeft: '8px',
        paddingRight: '8px',
        border: '0.1px solid #ddd',
        display: 'flex',
        justifyContent: 'center',
      },
    },
  }



  return (
    <>
      <div className="main-content">
        <Header />
        <div className="app-menu navbar-menu" style={{ display: 'flex' }}>
          <Sidebar />
        </div>
        <div className="page-content">
          <PageHeader HeaderName="Validations" />
          <div className='card'>
          <div className='card-body'>

          <div className="container-fluid pt-3">
            <ul className="nav nav-tabs"  style={{ marginBottom: '20px' }}>
              <li className="nav-item">
                <button
                  className={`nav-link ${activeTab === 'temples' ? 'active' : ''}`}  style={{ width: '300px' }}onClick={() => setActiveTab('temples')}>
                  List Of Temples
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link ${activeTab === 'pujari' ? 'active' : ''}`} style={{ width: '300px' }} onClick={() => setActiveTab('pujari')}>
                  List Of Pujari
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link ${activeTab === 'pandals' ? 'active' : ''}`}  style={{ width: '300px' }} onClick={() => setActiveTab('pandals')}>
                  List Of Pandals
                </button>
              </li>
            </ul>
            
            <div className="tab-content">
            <h4  style={{fontSize:'15px' }}>All Validations Received</h4>
            
              {/* <div className="d-flex justify-content-end mt-3 mb-3">
              
                <input
                  type="text"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={handleSearch}
                  className="form-control w-25"
                />
              </div> */}

            <div className="row mb-5">
              <div className="col-md-12">
                <div className="card card-body">
                  <div className="col-xl-12 col-md-12 table-responsive">
                    {loading ? (
                      <p>Loading...</p>
                    ) : (
                      <>
                        <div className="table table-responsive registered-img bg-white">
                          {renderTabContent()}
                        </div>
                        <div className="col-md-12 text-end">
                          <div className="button-group">
                            <button
                              title="Download Excel"
                              className="button-excel-format"
                              onClick={downloadAsExcel}
                            >
                              Excel
                            </button>
                            <button
                              title="Download CSV"
                              className="button-csv-format"
                              onClick={handleDownloadCSV}
                            >
                              CSV
                            </button>
                            <button
                              title="Download PDF"
                              className="button-pdf-format"
                              onClick={downloadAsPDF}
                            >
                              PDF
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        </div>
      </div>
      </div>
      <Footer />
    </>
  );
}

export default Validation;
