import axios from 'axios'

const token = localStorage.getItem('token')
const apiBaseUrl = process.env.REACT_APP_API_BASEURL

//Api Headers ffff
const api = axios.create({
  baseURL: apiBaseUrl,
  headers: {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  },
})


// GetTemplesData
export const fetchTemples = async () => {
  try {
    const response = await api.get('admin/templeAllverified');
    if (response.status === 302) {
      // Handle redirection if needed
      console.log('Redirected to:', response.headers.get('Location'));
    }
    // return response.then(data => console.log('dataaa',data));
    return response.data.data
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error; // Re-throw the error for the caller to handle
  }
};

//Get PoojariData
export const fetchPujaris = async () => {
  try {
    const response = await api.get('admin/pujariAllverified');
    console.log(response.data,'response from api')
    if (response.status === 302) {
      // Handle redirection if needed
      console.log('Redirected to:', response.headers.get('Location'));
    }
    // return response.then(data => console.log('dataaa',data));
    return response.data
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error; // Re-throw the error for the caller to handle
  }
};






// // GetTemplesSevaData
// export const fetchTemplesSeva = async () => {
//   try {
//     const response = await api.get('admin/templeAll');
//     if (response.status === 302) {   
//       // Handle redirection if needed
//       console.log('Redirected to:', response.headers.get('Location'));
//     }
//     // return response.then(data => console.log('dataaa',data));
//     return response.data.data
//   } catch (error) {
//     console.error('Error fetching data:', error);
//     throw error; // Re-throw the error for the caller to handle
//   }
// };


//Employee Api Headers
const imageApi = axios.create({
  baseURL: apiBaseUrl,
  headers: {
    'Content-Type': 'multipart/form-data',
    Authorization: `Bearer ${token}`,
    // 'Content-Type': 'application/json',
  },
})

//Master >> PACKAGES
export const getAllPackages = async () => {
  try {
    const response = await api.get('package/getAllPackage')
    return response.data.map((item) => ({
      ...item,
      created_at: new Date(item.created_at).toLocaleDateString('en-GB'),
    }))
  } catch (error) {
    console.error('Error fetching packages:', error)
    throw error
  }
}

export const createPackage = async (requestData) => {
  try {
    const response = await api.post('package/create', requestData)
    return response.data
  } catch (error) {
    console.error('Error creating package:', error)
    throw error
  }
}

export const postApiResponse = async (apiUrl,requestData) => {
  try{
    const response = await api.post(apiUrl,requestData);
    return response;
  }catch(error){
    throw error;
  }
}


export const updatePackage = async (id, requestData) => {
  try {
    const response = await api.post('package/update', requestData)
    return response.data
  } catch (error) {
    console.error('Error updating package:', error)
    throw error
  }
}

export const deletePackage = async (id) => {
  try {
    const response = await api.delete(`package/delete/${id}`)
    return response.data
  } catch (error) {
    console.error('Error deleting package:', error)
    throw error
  }
}

//Master >> Location
export const fetchCountryDetails = async () => {
  try {
    const url = `${apiBaseUrl}location/getAllCountries`
    const response = await api.get(url)
    return response.data
  } catch (error) {
    console.error('Error fetching country data:', error)
    throw error
  }
}

export const fetchStateDetails = async (country_id) => {
  try {
    const url = `${apiBaseUrl}location/getStateByCountry/${country_id}`
    const response = await api.get(url)
    if (response.data.message === 'No data found') {
      return []
    } else {
      return response.data
    }
  } catch (error) {
    console.error('Error fetching state data:', error)
    throw error
  }
}

export const fetchCityDetails = async (state_id) => {
  try {
    const url = `${apiBaseUrl}location/getCityByState/${state_id}`
    const response = await api.get(url)
    if (response.data.message === 'No data found') {
      return []
    } else {
      return response.data
    }
  } catch (error) {
    console.error('Error fetching city data:', error)
    throw error
  }
}

export const getAllLocations = async () => {
  try {
    const response = await api.get('location/getAlllocations')
    return response.data.map((item) => ({
      ...item,
      created_at: new Date(item.created_at).toLocaleDateString('en-GB'),
    }))
  } catch (error) {
    console.error('Error fetching locations:', error)
    throw error
  }
}

export const createLocation = async (requestData) => {
  try {
    const response = await api.post('location/create', requestData)
    return response.data
  } catch (error) {
    console.error('Error creating location:', error)
    throw error
  }
}

export const updateLocation = async (id, requestData) => {
  try {
    const response = await api.post('location/update', requestData)
    return response.data
  } catch (error) {
    console.error('Error updating location:', error)
    throw error
  }
}

export const deleteLocation = async (id) => {
  try {
    const response = await api.delete(`location/delete/${id}`)
    return response.data
  } catch (error) {
    console.error('Error deleting location:', error)
    throw error
  }
}

//Masters >> Designations
export const getAllDesignations = async () => {
  try {
    const response = await api.get(
      'memberdesignation/getAllmemberdesignations',
    )
    return response.data.map((item) => ({
      ...item,
      created_at: new Date(item.created_at).toLocaleDateString('en-GB'),
    }))
  } catch (error) {
    console.error('Error fetching designations:', error)
    throw error
  }
}

export const deleteDesignation = async (id) => {
  try {
    const response = await api.delete(`memberdesignation/delete/${id}`)
    return response.data
  } catch (error) {
    console.error('Error deleting designation:', error)
    throw error
  }
}

export const createDesignation = async (requestData) => {
  try {
    const response = await api.post('memberdesignation/create', requestData)
    return response.data
  } catch (error) {
    console.error('Error creating designation:', error)
    throw error
  }
}

export const updateDesignation = async (id, requestData) => {
  try {
    const response = await api.post('memberdesignation/update', requestData)
    return response.data
  } catch (error) {
    console.error('Error updating designation:', error)
    throw error
  }
}

//Masters >> Seva Type
export const getAllSevaTypes = async () => {
  try {
    const response = await api.get('sevatype/getAllsevatypes')
    return response.data.map((item) => ({
      ...item,
      created_at: new Date(item.created_at).toLocaleDateString('en-GB'),
    }))
  } catch (error) {
    console.error('Error fetching locations:', error)
    throw error
  }
}

export const deleteSevaType = async (id) => {
  try {
    const response = await api.delete(`sevatype/delete/${id}`)
    return response.data
  } catch (error) {
    console.error('Error deleting seva type:', error)
    throw error
  }
}

export const createSevaType = async (requestData) => {
  try {
    const response = await api.post('sevatype/create', requestData)
    return response.data
  } catch (error) {
    console.error('Error creating seva type:', error)
    throw error
  }
}

export const updateSevaType = async (id, requestData) => {
  try {
    const response = await api.post('sevatype/update', requestData)
    return response.data
  } catch (error) {
    console.error('Error updating seva type:', error)
    throw error
  }
}

//Masters >> Expense Type
export const getAllExpenseTypes = async () => {
  try {
    const response = await api.get('expenselist/getAllexpenselists')
    return response.data.map((item) => ({
      ...item,
      created_at: new Date(item.created_at).toLocaleDateString('en-GB'),
    }))
  } catch (error) {
    console.error('Error fetching locations:', error)
    throw error
  }
}

export const deleteExpenseType = async (id) => {
  try {
    const response = await api.delete(`expenselist/delete/${id}`)
    return response.data
  } catch (error) {
    console.error('Error deleting expense type:', error)
    throw error
  }
}

export const createExpenseType = async (requestData) => {
  try {
    const response = await api.post('expenselist/create', requestData)
    return response.data
  } catch (error) {
    console.error('Error creating expense type:', error)
    throw error
  }
}

export const updateExpenseType = async (id, requestData) => {
  try {
    const response = await api.post('expenselist/update', requestData)
    return response.data
  } catch (error) {
    console.error('Error updating expense type:', error)
    throw error
  }
}

//Reviews
export const getAllReviews = async () => {
  try {
    const response = await api.get('review/getAllreviews')
    return response.data.map((item) => ({
      ...item,
      // created_at: new Date(item.created_at).toLocaleDateString('en-GB'),
    }))
  } catch (error) {
    console.error('Error fetching Reviews:', error)
    throw error
  }
}

export const deleteReview = async (reviewId) => {
  try {
    const response = await api.delete(`review/delete/${reviewId}`)
    return response.data
  } catch (error) {
    throw error
  }
}

export const getReviewById = async (id) => {
  try {
    const response = await api.get(`review/getById/${id}`)
    return response.data
  } catch (error) {
    throw error
  }
}

export const acceptReview = async (reviewId) => {
  try {
    const response = await api.get(`review/approve/${reviewId}`)
    return response.data
  } catch (error) {
    throw error
  }
}

export const rejectReview = async (reviewId) => {
  try {
    const response = await api.get(`review/reject/${reviewId}`)
    return response.data
  } catch (error) {
    throw error
  }
}

//Auctions
export const OngoingAuctions = async () => {
  try {
    const response = await api.get('auction/OnGoing')
    return response.data.map((item) => ({
      ...item,
      created_at: new Date(item.created_at).toLocaleDateString('en-GB'),
    }))
  } catch (error) {
    console.error('Error fetching OnGoing Auctions:', error)
    throw error
  }
}

export const LiveParticipantAuctions = async (id) => {
  try {
    const response = await api.get(`bidding/getByAuctionId/${id}`)
    return response.data.map((item) => ({
      ...item,
    }))
  } catch (error) {
    console.error('Error fetching live Participant Auctions:', error)
    throw error
  }
}

export const UpComingAuctions = async () => {
  try {
    const response = await api.get('auction/UpComing')
    return response.data.map((item) => ({
      ...item,
      created_at: new Date(item.created_at).toLocaleDateString('en-GB'),
    }))
  } catch (error) {
    console.error('Error fetching Upcoming Auctions:', error)
    throw error
  }
}

export const closedAuctions = async () => {
  try {
    const response = await api.get('auction/Closed')
    return response.data.map((item) => ({
      ...item,
      created_at: new Date(item.created_at).toLocaleDateString('en-GB'),
    }))
  } catch (error) {
    console.error('Error fetching closed Auctions:', error)
    throw error
  }
}

//Employees
export const GetallEmployee = async () => {
  try {
    const response = await api.get('user/getAllEmployees')
    return response.data.map((item) => ({
      ...item,
      created_at: new Date(item.created_at).toLocaleDateString('en-GB'),
    }))
  } catch (error) {
    console.error('Error fetching Employees: ', error)
    throw error
  }
}

export const deleteEmployee = async (employeeId) => {
  try {
    const response = await api.get(`user/softDelete/${employeeId}`)
    return response.data
  } catch (error) {
    throw error
  }
}

export const createEmployee = async (requestData) => {
  try {
    const response = await imageApi.post('user/createEmployee', requestData)
    return response.data
  } catch (error) {
    console.error('Error creating employee:', error)
    throw error
  }
}

//Partners
export const getAllPartners = async () => {
  try {
    const response = await api.get('pandal/getAllpandals')
    const data = response.data.map((item) => ({
      ...item,
    }))
    return data
  } catch (error) {
    console.error('Error fetching Partners:', error)
    throw error
  }
}

//Pandal Details
export const deletePandalPost = async (postId) => {
  try {
    const response = await api.get(`posts/softDelete/${postId}`)
    return response.data
  } catch (error) {
    throw error
  }
}

//Users
export const getAllUsers = async () => {
  try {
    const response = await api.get('user/getAllusers')
    const data = response.data.map((item) => ({
      ...item,
      date: new Date(item?.created_at).toLocaleDateString('en-GB'),
    }))
    return data
  } catch (error) {
    console.error('Error fetching Users:', error)
    throw error
  }
}

//Sponsors
export const getSponsorEnquired = async () => {
  try {
    const response = await api.get('brand/getAllBrands')
    const data = response.data.map((item) => ({
      ...item,
    }))
    return data
  } catch (error) {
    console.error('Error fetching Enquired Sponsors:', error)
    throw error
  }
}

export const getSponsorRegistered = async () => {
  try {
    const response = await api.get('sponsor/getAllsponsors')
    const data = response.data.map((item) => ({
      ...item,
    }))
    return data
  } catch (error) {
    console.error('Error fetching Registered Sponsors:', error)
    throw error
  }
}

export const createSponsor = async (requestData) => {
  try {
    const response = await api.post('sponsor/create', requestData)
    return response.data
  } catch (error) {
    console.error('Error creating sponsor:', error)
    throw error
  }
}

export const getSponsorPackage = async () => {
  try {
    const response = await api.get('package/getAllActive')
    const data = response.data.map((item) => ({
      ...item,
    }))
    return data
  } catch (error) {
    console.error('Error fetching package Sponsors:', error)
    throw error
  }
}

export const getSponsorLocation = async () => {
  try {
    const response = await api.get('location/getAllActive')
    const data = response.data.map((item) => ({
      ...item,
    }))
    return data
  } catch (error) {
    console.error('Error fetching location Sponsors:', error)
    throw error
  }
}

//Validations
export const getAllModeration_Validation = async () => {
  try {
    const response = await api.get('pandal/PandalValidations')
    const data = response.data.map((item) => ({
      ...item,
    }))
    return data
  } catch (error) {
    console.error('Error fetching Registered Moderation/Validation:', error)
    throw error
  }
}

//Validations Details
export const acceptValidationDetail = async (validationId) => {
  try {
    const response = await api.get(`pandal/approvePandal/${validationId}`)
    return response.data
  } catch (error) {
    throw error
  }
}

export const getValidationPermissionDataById = async (id) => {
  try {
    const response = await api.get(`pandalpermission/getByPandalId/${id}`)
    const data = response.data.map((item) => ({
      ...item,
    }))
    return data
  } catch (error) {
    console.error('Error fetching ValidationPermission:', error)
    throw error
  }
}

export const getValidationRejectData = async (requestData) => {
  try {
    const response = await imageApi.post('pandal/rejectPandal', requestData)
    return response.data
  } catch (error) {
    console.error('Error Validation Reject:', error)
    throw error
  }
}
//validation temple
// export const getAllTemples = async () => {
//   try {
//     const response = await api.get('admin/templeAll', {
//       headers: {
//         Authorization: `Bearer ${token}`,
//         'Content-Type': 'application/json',
//       },
//     });

//     if (!response.ok) {
//       throw new Error(`Failed to fetch temple data: ${response.statusText}`);
//     }
//     const data = await response.json();
//     return data;
//   } catch (error) {
//     console.error('Error fetching temple data:', error);
//     throw new Error('Failed to fetch temple data. Please try again later.');
//   }
// };


export const getTempleById = async (templeId) => {
  try {
    const response = await api.get(`admin/getTempleById/1/${templeId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching temple by ID:', error);
    throw error;
  }
};

export const approveRejectTemple = async (templeId, status, rejectedReason = null) => {
  try {
    const response = await api.post('admin/templeApproveStatus1', {
      temple_id: templeId,
      status: status,
      rejected_reason: rejectedReason, // Only needed if status is 2 (rejected)
    });
    return response.data;
  } catch (error) {
    console.error('Error updating temple approval status:', error);
    throw error;
  }
};

//validation pujari
// Function to fetch all pujari
export const getAllPujari = async () => {
  try {
    const response = await api.get('pujari/getAllPujari');
    if (!response.ok) {
      throw new Error('Failed to fetch pujari data');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};

// Function to fetch pujari by ID
export const getPujariById = async (pujariId) => {
  try {
    const response = await fetch(`pujari/getById/${pujariId}`);
    if (!response.ok) {
      throw new Error('Failed to fetch pujari by ID');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching pujari by ID:', error);
    throw error;
  }
};

// Function to handle pujari approval/rejection
export const approveRejectPujari = async (pujariId, status, rejectedReason = null) => {
  try {
    const response = await fetch('admin/pujariApproveStatus', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        pujari_id: pujariId,
        status: status,
        rejected_reason: rejectedReason,
      }),
    });
    if (!response.ok) {
      throw new Error('Failed to update pujari approval status');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error updating pujari approval status:', error);
    throw error;
  }
};


//Pandal Details
export const getDonationsById = async (id) => {
  try {
    const response = await api.get(`donation/getByPandalId/${id}`)
    const data = response.data.map((item) => ({
      ...item,
      date: new Date(item?.created_at).toLocaleDateString('en-GB'),
    }))
    return data
  } catch (error) {
    console.error('Error fetching Donations:', error)
    throw error
  }
}

export const getAllDonations = async () => {
  try {
    const response = await api.get('donation/getAlldonations')
    const data = response.data.map((item) => ({
      ...item,
    }))
    return data
  } catch (error) {
    console.error('Error fetching Donations:', error)
    throw error
  }
}

export const getPandalBankDetailsById = async (id) => {
  try {
    const response = await api.get(`banks/getByPandalId/${9}`)
    const data = response.data.map((item) => ({
      ...item,
    }))
    return data
  } catch (error) {
    console.error('Error fetching Donations:', error)
    throw error
  }
}

export const getPandalSevaById = async (id) => {
  // console.log(id)
  try {
    const response = await api.get(`seva/getByPandalId/${id}`)
    const data = response.data.map((item) => ({
      ...item,
    }))
    return data
  } catch (error) {
    console.error('Error fetching Seva:', error)
    throw error
  }
}

export const getPandalMemberById = async (id) => {
  try {
    const response = await api.get(`member/getByPandalId/${id}`)
    const data = response.data.map((item) => ({
      ...item,
    }))
    return data
  } catch (error) {
    console.error('Error fetching Member:', error)
    throw error
  }
}

export const getPandalPostById = async (id) => {
  try {
    const response = await api.get(`posts/getByPandalId/${id}`)
    const data = response.data.map((item) => ({
      ...item,
    }))
    return data
  } catch (error) {
    console.error('Error fetching Post:', error)
    throw error
  }
}

//User details
export const  getUserDonationsById = async (id) => {
  try {
    const response = await api.get(`donation/getByUserId/${9}`)
    const data = response.data.map((item) => ({
      ...item,
      date: new Date(item?.created_at).toLocaleDateString('en-GB'),
    }))
    return data
  } catch (error) {
    console.error('Error fetching Donations:', error)
    throw error
  }
}

export const getUserPostsById = async (id) => {
  try {
    const response = await api.get(`posts/getByUserId/${9}`)
    const data = response.data.map((item) => ({
      ...item,
    }))
    return data
  } catch (error) {
    console.error('Error fetching Donations:', error)
    throw error
  }
}

export const deleteUser = async (id) => {
  try {
    const response = await api.delete(`posts/softDelete/${id}`)
    return response.data
  } catch (error) {
    console.error('Error deleting User:', error)
    throw error
  }
}

//Notification
export const createNotification = async (requestData) => {
  try {
    const response = await api.post('notification/create', requestData)
    return response.data
  } catch (error) {
    console.error('Error Creating Notification:', error)
    throw error
  }
}