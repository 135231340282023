import axios from 'axios'

const token = localStorage.getItem('token')
const apiBaseUrl = process.env.REACT_APP_API_BASEURL

const api = axios.create({
    baseURL: apiBaseUrl,
    headers: {
      Authorization: `Bearer ${token}`,
      // 'Content-Type': 'application/json',
      'Content-Type': 'multipart/form-data',
    },
  })


export const getApiData = async (apiUrl)=> {
  try{
    const res = await api.get(apiUrl);
    return res.data;
  }catch(error){
    throw error;
  }
}

export const postApiResponse = async (apiUrl,requestData) => {
  try{
    const response = await api.post(apiUrl,requestData);
    return response;
  }catch(error){
    throw error;
  }
}

export const deleteApiResponse = async (apiUrl,requestData) => {
  try{
    const response = await api.delete(apiUrl);
    return response;
  }catch(error){
    throw error;
  }
}