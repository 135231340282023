// FetchAPI.jsx
export const fetchSevaTemples = async (apiurl, method = 'GET', body = null) => {
  const apiBaseUrl = process.env.REACT_APP_API_BASEURL;
  const token = localStorage.getItem('token');

  try {
    const fullUrl = `${apiBaseUrl}${apiurl}`;
    const options = {
      method,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };

    if (body) {
      options.body = JSON.stringify(body);
    }
console.log(options)
    const response = await fetch(fullUrl, options);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
