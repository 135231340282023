import axios from "axios";
const token = localStorage.getItem("token");
const apiBaseUrl = process.env.REACT_APP_API_BASEURL;
const api = axios.create({
  baseURL: apiBaseUrl,
  headers: {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  },
});
const getToken = () => {
  return localStorage.getItem("token");
};

export const GetAllDarshanamDetails = async () => {
  try {
    const response = await api.get("dasrshanam/getall-darshanam");
    return response.data.map((item) => ({
      ...item,
      created_at: new Date(item.created_at).toLocaleDateString("en-GB"),
    }));
  } catch (error) {
    throw error;
  }
};

export const CreateNewDarshanamDetails = async (requestData) => {
  try {
    const response = await api.post("dasrshanam/create", requestData, {
      headers: {
        // Authorization: `Bearer ${getToken()}`,
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const UpdateDarshanamDetails = async (requestData,) => {
  try {
    const response = await api.post(`dasrshanam/edit/${requestData.id}`, requestData,
    {
      headers: {
        // Authorization: `Bearer ${getToken()}`,
        "Content-Type": "multipart/form-data",
      },

    })
    return response.data
  } catch (error) {

    throw error
  }
}


export const DeleteDarshanamDetails = async (id) => {
  try {
    const response = await api.delete(`dasrshanam/delete/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
