import axios from 'axios'
const token = localStorage.getItem('token')
const apiBaseUrl = process.env.REACT_APP_API_BASEURL

const api = axios.create({
  baseURL: apiBaseUrl,
  headers: {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  },
})

const getToken = () => {
  return localStorage.getItem('token')
}

export const PujariCreate = async (requestData) => {
  try {
    const response = await api.post('pujari/create', requestData, {
      headers: {
        // Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'multipart/form-data',
      },
    })
    return response.data
  } catch (error) {
    throw error
  }
}

export const getAllCategory = async () => {
  try {
    const response = await api.get('pujari/getAllCategories')
    const data = response.data.map((item) => ({
      ...item,
    }))
    return data
  } catch (error) {
    throw error
  }
}

export const getAllLanguage = async () => {
  try {
    const response = await api.get('pujari/getAllLanguage')
    const data = response.data.map((item) => ({
      ...item,
    }))
    return data
  } catch (error) {
    throw error
  }
}

export const getAllSunSign = async () => {
  try {
    const response = await api.get('pujari/getAllSunsign')
    const data = response.data.map((item) => ({
      ...item,
    }))
    return data
  } catch (error) {
    throw error
  }
}

export const getAllKycDoc = async () => {
  try {
    const response = await api.get('pujari/getAllKycDoc')
    const data = response.data.map((item) => ({
      ...item,
    }))
    return data
  } catch (error) {
    throw error
  }
}

export const getAllEduDoc = async () => {
  try {
    const response = await api.get('pujari/getAllEduDoc')
    const data = response.data.map((item) => ({
      ...item,
    }))
    return data
  } catch (error) {
    throw error
  }
}

export const getAllAwardDoc = async () => {
  try {
    const response = await api.get('pujari/getAllAwardDoc')
    const data = response.data.map((item) => ({
      ...item,
    }))
    return data
  } catch (error) {
    throw error
  }
}
