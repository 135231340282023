import React, {useState,useEffect}from 'react'
import { fetchSevaTemples } from '../API/FetchAPI';

function DonationsRecievedtable() {


    const [donations, setDonations] = useState([]);



  async function loadTemplesData() {  
    try {
      const data = await fetchSevaTemples('temple/get-donation-details/6');
      console.log('Data for Temples:', data);
      setDonations(data.data);
      // Handle the data as needed
    } catch (error) {
      console.error('Error loading temples data:', error);
    }
  }

  useEffect(()=> {
    loadTemplesData();
  }, [])


  const totalDonations = donations.reduce((total, donation) => total + donation.amount, 0);
  return (
    <div>
         <div className='Card_form '>    
      <table className='table table-bordered table-hover'>
                <thead>
                  <tr className='table-primary'>
                    <th style={{ textAlign: 'center' }}>Sl.No</th>
                    <th style={{ textAlign: 'center' }}>Donor Name</th>
                    <th style={{ textAlign: 'center' }}>Description</th>
                    <th style={{ textAlign: 'center' }}>Mobile Number</th>
                    <th style={{ textAlign: 'center' }}>Date of Donation</th>
                    <th style={{ textAlign: 'center' }}>Donation Amount</th>
                  </tr>
                </thead>
                <tbody className='table_tbody'>
                {/* {donations.map((donation. index) => ( */}
                   {donations.map((donation, index) => ( 
                 <tr key={index}>
                 <td>{donation.id}</td>
                 <td>{donation.name}</td>
                 <td>
                 <td>{donation.dities_id}</td>
                   </td>
                 <td>{donation.mobile}</td>
                 <td>
                   {donation.created_at}
                 </td>
                 <td>{donation.amount}</td>
               </tr>
                ))}
                </tbody>
                <tfoot className='table_tbody'>
                  <tr>
                    <td colSpan="5" className='text-right'><strong>Total Donations Received :</strong></td>
                    <td><strong>{totalDonations}</strong></td>
                  </tr>
                </tfoot>
              </table>
    </div>
    </div>
  )
}

export default DonationsRecievedtable
