import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import PageHeader from '../PageHeader/PageHeader'
import Header from '../Header/Header'
import Sidebar from '../Sidebar/Sidebar'
import Footer from '../Footer/Footer'

function AuctionThreeView() {
  // const navigate = useNavigate()
  const location = useLocation()
  const details = location.state

  return (
    <>
      <div className="main-content">
        <Header />
        <div className="app-menu navbar-menu" style={{ display: 'flex' }}>
          <Sidebar />
        </div>
        <div className="page-content">
          <PageHeader HeaderName="Closed Auctions - Details View" />
          <div className="container-fluid pt-3">
            <div className="row">
              <div className="col-xl-12 col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="col-md-3 ms-auto text-end">
                      <Link to="/auctions" className="btn btn-info">
                        Back
                      </Link>
                    </div>
                    <div className="row">
                      <div className="col-md-4 mb-3">
                        <small>Auction Name</small>
                        <input
                          type="text"
                          name="auction_name"
                          value={details.auction_name}
                          className="form-control"
                          readOnly
                        />
                      </div>
                      <div className="col-md-4 mb-3">
                        <small>Auction Estimation</small>
                        <input
                          type="text"
                          name="estimation"
                          value={details.estimation}
                          className="form-control"
                          readOnly
                        />
                      </div>
                      <div className="col-md-4 mb-3">
                        <small>Auction Start Date and Time</small>
                        <input
                          type="datetime-local"
                          name="auction_from_datetime"
                          value={details.auction_from_datetime}
                          className="form-control"
                          readOnly
                        />
                      </div>
                      <div className="col-md-4 mb-3">
                        <small>Auction End Date and Time</small>
                        <input
                          type="datetime-local"
                          name="auction_to_datetime"
                          value={details.auction_to_datetime}
                          className="form-control"
                          readOnly
                        />
                      </div>
                      {/* <div className="col-md-4 mb-3">
                        <small>Auction Start Time</small>
                        <input
                          type="text"
                          name=""
                          className="form-control"
                          readOnly
                        />
                      </div>
                      <div className="col-md-4 mb-4">
                        <small>Auction End Time</small>
                        <input
                          type="text"
                          name=""
                          className="form-control"
                          readOnly
                        />
                      </div> */}
                      <div className="col-md-12 mb-3">
                        <h4>Auction Winner</h4>
                      </div>
                      <div className="col-md-4 mb-4">
                        <small>User Name</small>
                        <input
                          type="text"
                          name=""
                          className="form-control"
                          placeholder="Karthik"
                          readOnly
                        />
                      </div>
                      <div className="col-md-4 mb-4">
                        <small>Mobile Number</small>
                        <input
                          type="text"
                          name=""
                          className="form-control"
                          placeholder="8585969654"
                          readOnly
                        />
                      </div>
                      <div className="col-md-4 mb-4">
                        <small>E-Mail ID</small>
                        <input
                          type="text"
                          name=""
                          className="form-control"
                          placeholder="sample@gmail.com"
                          readOnly
                        />
                      </div>
                      <div className="col-md-4 mb-4">
                        <small>Date Of Joining</small>
                        <input
                          type="text"
                          name=""
                          className="form-control"
                          placeholder="15-07-2023"
                          readOnly
                        />
                      </div>
                      <div className="col-md-4 mb-4">
                        <small>Auction Bid Amount</small>
                        <input
                          type="text"
                          name=""
                          className="form-control"
                          placeholder="3500"
                          readOnly
                        />
                      </div>
                      <div className="col-md-4 mb-4">
                        <small>&nbsp;</small>
                        <Link
                          to="/auction-user-profile"
                          className="btn btn-warning mt-3"
                        >
                          View User full Profile
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default AuctionThreeView
