import React from 'react'
import PageHeader from '../PageHeader/PageHeader'
import Header from '../Header/Header'
import Sidebar from '../Sidebar/Sidebar'
import Footer from '../Footer/Footer'

function AuctionProfile() {
  return (
    <>
      <div className="main-content">
        <Header />
        <div className="app-menu navbar-menu" style={{ display: 'flex' }}>
          <Sidebar />
        </div>
        <div className="page-content">
          <PageHeader HeaderName="Auction User Full Details" />

          <div className="container-fluid pt-3">
            <div className="row">
              <div className="col-xl-12 col-md-12">
                <div className="card card-body">
                  <div className="row">
                    <div className="col-md-2 mb-3">
                      <small>User Image</small>
                      <div className="col-md-12">
                        <img
                          src="/assets/images/thumb.png"
                          className="img-fluid"
                          alt='pujam_pandal'
                        />
                      </div>
                    </div>
                    <div className="col-md-10 mb-3">
                      <div className="row">
                        <div className="col-md-6 mb-3">
                          <small>User Name</small>
                          <input
                            type="text"
                            className="form-control"
                            name=""
                            id=""
                          />
                        </div>
                        <div className="col-md-6 mb-3">
                          <small>Mobile Number</small>
                          <input
                            type="tel"
                            className="form-control"
                            name=""
                            id=""
                          />
                        </div>
                        <div className="col-md-6 mb-3">
                          <small>E-Mail ID</small>
                          <input
                            type="email"
                            className="form-control"
                            name=""
                            id=""
                          />
                        </div>
                        <div className="col-md-6 mb-3">
                          <small>Date Of Joining</small>
                          <input
                            type="date"
                            className="form-control"
                            name=""
                            id=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
      <Footer />
    </>
  )
}

export default AuctionProfile
