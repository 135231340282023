import React, {useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom';
import { fetchSevaTemples } from '../API/FetchAPI';
// import { fetchSevaTemples } from '../API/Api';
// import { fetchTemplesSeva } from '../API/Api';
// import {fetchTemples} from '../API/Api'



function TemplesSeva() {
    const navigate = useNavigate();

    const [seva, setSeva] = useState([]);
    // const handleBasicClick = (value) => {
    //   if (value === basicActive) {
    //     return;
    //   }
    //   setBasicActive(value);
    // };

    const handleButtonClick = () => {
        navigate('/ViewFullSevadetails');
      };
    

      async function loadTemplesData() {  
        try {
          const data = await fetchSevaTemples('temple-seva/get-by-temple-id/6');
          // console.log('Data for Temples:', data);
          setSeva(data);
          // Handle the data as needed
        } catch (error) {
          console.error('Error loading temples data:', error);
        }
      }

      useEffect(()=> {
        loadTemplesData();
      }, [])
      
      


  return (
    <div>
      <div className='Card_form'>
      <table className='table table-bordered table-hover'>
                <thead>
                  <tr className='table-primary'>
                    <th style={{ textAlign: 'center' }}>Sl.No</th>
                    <th style={{ textAlign: 'center' }}>Seva Name</th>
                    <th style={{ textAlign: 'center' }}>Image</th>
                    <th style={{ textAlign: 'center' }}>Description</th>
                    <th style={{ textAlign: 'center' }}>Location</th>
                    <th style={{ textAlign: 'center' }}>Date of Event</th>
                    <th style={{ textAlign: 'center' }}>Action</th>
                  </tr>
                </thead>
                <tbody className='table_tbody'>
                {seva.map((seva, index) => ( 
                  <tr key={index}>
                    <td>{seva.id}</td>
                    <td>{seva.seva_name}</td>
                    <td>
                      <img src={seva.image} alt="Temple" />
                    </td>
                    <td>{seva.description}</td>
                    <td>
                      {seva.location}
                    </td>
                    <td>{seva.created_at}</td>
                    <td>
                      <button className='btn btn-primary' onClick={handleButtonClick}> View Full details </button>
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>
      </div>
    </div>
  )
}

export default TemplesSeva
