import { useEffect, useState } from 'react';
import PageHeader from '../PageHeader/PageHeader';
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import './validation.css';
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
import { toast } from 'react-toastify';
import Footer from '../Footer/Footer';
import { HandleAcceptRejectTemple2 } from '../Pujari/Constants';
import { postApiResponse } from '../API/Api';

//Temple details components
function ValidationDetails2() {
  const location = useLocation();
  const navigate = useNavigate();
  const receivedData = location.state;
  const id = receivedData?.pujari_id;

  const [templeData, setTempleData] = useState(null);
  const [templeName, setTempleName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [hNo, setHNo] = useState('');
  const [kycDocument, setKycDocument] = useState('');
  const [highlights, setHighlights] = useState('');
  const [cities, setCities] = useState('');
  const [otherCities, setOtherCities] = useState('');
  const [timing, setTiming] = useState('');
  const [description, setDescription] = useState('');
  const [history, setHistory] = useState('');
  const [significance, setSignificance] = useState('');
  const [drinkingWater, setDrinkingWater] = useState('');
  const [restaurants, setRestaurants] = useState('');
  const [poojaItems, setPoojaItems] = useState('');
  const [busStand, setBusStand] = useState('');
  const [railwayStation, setRailwayStation] = useState('');
  const [airport, setAirport] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalImage, setModalImage] = useState('');
  const [rejectionReason, setRejectionReason] = useState('');
  const [error, setError] = useState({});
  const [text, setText] = useState('');
  const [reject, setReject] = useState(false);

  useEffect(() => {
    // Fetch temple data by ID
    const fetchTempleById = async () => {
      try {
        const response = await fetch(`https://gowinda.in/api/api/admin/getTempleById/${receivedData.temple_id}`);
        if (response.ok) {
          const data = await response.json();
          setTempleData(data);

        } else {
          throw new Error('Failed to fetch temple data');
        }
      } catch (error) {
        console.error('Error fetching temple data:', error);
       
      }
    };
    if (receivedData?.temple_id) {
      fetchTempleById();
    }
  }, [receivedData]);
  

  // Function to handle accept/reject
  const HandleAcceptRejectPooja2 = async (status, rejectedReason) => {
    const requestData = {
      temple_id: receivedData.id,
      status: status,
      reject_reason: text,
    };
    try {
      const response = await postApiResponse(HandleAcceptRejectTemple2, requestData)
      console.log(response, "response")
      if (response.ok) {
        toast.success('Temple status updated successfully', { position: 'top-right' });
        navigate('/validation');
      } else {
        throw new Error('Failed to update temple status');
      }
    } catch (error) {
      console.error('Error updating temple status:', error);
     
    }
  };

  // Function to handle approve
  const onApproveData = () => {
    HandleAcceptRejectPooja2(1);
    navigate('/validation');
  };
 
  //   if (!text) {
  //     setError({ text: 'Reason Required' });
  //     return;
  //   }
  //   handleAcceptReject(2, text);
  //   setReject(false);
  // };

  const handleChange = (event) => {
    setText(event.target.value);
  };

  const backPage = () => {
    navigate('/validation');
  };

  const onRejectData = async () => {
    setReject(true);
   
  };

  
  const onSendReason = (e) => {
    e.preventDefault();
    const validationErrors = {};
    if (!text) {
      validationErrors.text = 'Reason Required';
    }
    if (Object.keys(validationErrors).length === 0) {
      HandleAcceptRejectPooja2(2, text); 
      setReject(false);
      backPage();
    }
    setError(validationErrors);
  };

  const openModal = (imageUrl) => {
    setModalImage(imageUrl);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  useEffect(() => {
    HandleAcceptRejectPooja2()
  }, [])

  return (
    <>
      <div className="main-content">
        <Header />
        <div className="app-menu navbar-menu" style={{ display: 'flex' }}>
          <Sidebar />
        </div>
        <div className="page-content" style={{ position: 'sticky', Bottom: '0px' }}>
          <PageHeader HeaderName="Validation Full Details" />
          <div className="container-fluid pt-3">
            <h3 style={{ color: 'red', marginBottom: '25px' }}>Temple Registration</h3>
            <div className="row mb-4">
              <div className="col-md-4">
                <small>Temple Name</small>
                <input
                  type="text"
                  id="templeName"
                  className="form-control"
                  value={receivedData?.temple_name}
                  readOnly
                />
              </div>
              <div className="col-md-4">
                <small>Phone Number</small>
                <input
                  type="text"
                  id="phoneNumber"
                  className="form-control"
                  value={receivedData?.phone_number}
                  readOnly
                />
              </div>
              <div className="col-md-4">
                <small>Email ID</small>
                <input
                  type="text"
                  id="email"
                  className="form-control"
                  value={receivedData?.email}
                  readOnly
                />
              </div>
            </div>
            <h5>Address</h5>
            <div className="row mb-4">
              <div className="col-md-4">
                <small>H.No</small>
                <input
                  type="text"
                  id="hNo"
                  className="form-control"
                  value={receivedData?.hno}
                  readOnly
                />
              </div>
              <div className="col-md-4">
                <small>Email ID</small>
                <input
                  type="text"
                  id="email"
                  className="form-control"
                  value={receivedData?.email}
                  readOnly
                />
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-md-12">
                <label htmlFor="address">Address</label>
                <textarea
                  id="address"
                  className="form-control"
                  value={receivedData?.address}
                  readOnly
                />
              </div>
            </div>
            <h4>KYC Documents</h4>
            <div className="row mb-4">
              <div className="col-md-4">
                <small>Document</small>
                <input
                  type="text"
                  id="kycDocument"
                  className="form-control"
                  value={receivedData?.document_type}
                  readOnly
                />
              </div>
              <div className="col-md-4">
                <small>Document</small>
                <input
                  type="text"
                  id="kycDocument"
                  className="form-control"
                  value={receivedData?.document}
                  readOnly
                />
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <button
                    className="from-icon form-btn btn-sm btn btn-info"
                    onClick={() => openModal(kycDocument)}
                  >
                    View
                  </button>
                </div>
              </div>

              <h5>Highlights</h5>

              <div className="row mb-4">

                <div className="col-md-4">
                  <small>Dities</small>
                  <input
                    type="text"
                    id="cities"
                    className="form-control"
                    value={receivedData?.dities_id}
                    readOnly
                  />
                </div>
                <div className="col-md-4">
                  <small>Other Dities</small>
                  <input
                    type="text"
                    id="otherCities"
                    className="form-control"
                    value={receivedData?.dities_id}
                    readOnly
                  />
                </div>
                <div className="col-md-2">
                  <small>Opening Time</small>
                  <input
                    type="text"
                    id="openingTime"
                    className="form-control"
                    value={receivedData?.opening_time}
                    readOnly
                  />
                </div>
                <div className="col-md-2">
                  <small>Closing Time</small>
                  <input
                    type="text"
                    id="closingTime"
                    className="form-control"
                    value={receivedData?.closing_time}
                    readOnly
                  />

                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-md-12">
                <small>Description</small>
                <textarea
                  id="description"
                  className="form-control"
                  value={receivedData?.description}
                  readOnly
                />
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-md-12">
                <small>History</small>
                <textarea
                  id="history"
                  className="form-control"
                  value={receivedData?.history}
                  readOnly
                />
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-md-12">
                <small>Significance</small>
                <textarea
                  id="significance"
                  className="form-control"
                  value={receivedData?.significance}
                  readOnly
                />
              </div>
            </div>
            <h4>Facilities</h4>
            <div className="row mb-4">
              <div className="col-md-4">
                <small>Drinking Water</small>
                <input
                  type="text"
                  id="drinkingWater"
                  className="form-control"
                  value={receivedData?.drinking_water}
                  readOnly
                />
              </div>
              <div className="col-md-4">
                <small>Restaurants</small>
                <input
                  type="text"
                  id="restaurants"
                  className="form-control"
                  value={receivedData?.restaurants}
                  readOnly
                />
              </div>
              <div className="col-md-4">
                <small>Pooja Items</small>
                <input
                  type="text"
                  id="poojaItems"
                  className="form-control"
                  value={receivedData?.pooja_items}
                  readOnly
                />
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-md-4">
                <small>Rest Rooms</small>
                <input
                  type="text"
                  id="drinkingWater"
                  className="form-control"
                  value={receivedData?.rest_rooms}
                  readOnly
                />
              </div>
              <div className="col-md-4">
                <small>Marriage Halls</small>
                <input
                  type="text"
                  id="restaurants"
                  className="form-control"
                  value={receivedData?.marriage_halls}
                  readOnly
                />
              </div>
              <div className="col-md-4">
                <small>Darmashala</small>
                <input
                  type="text"
                  id="poojaItems"
                  className="form-control"
                  value={receivedData?.darmashala}
                  readOnly
                />
              </div>
            </div>
            <h4>Nearby Transport Facilities</h4>
            <div className="row mb-4">
              <div className="col-md-4">
                <small>Bus Stand</small>
                <input
                  type="text"
                  id="busStand"
                  className="form-control"
                  value={receivedData?.near_by_busstand}
                  readOnly
                />
              </div>
              <div className="col-md-4">
                <small>Railway Station</small>
                <input
                  type="text"
                  id="railwayStation"
                  className="form-control"
                  value={receivedData?.near_by_railway_station}
                  readOnly
                />
              </div>
              <div className="col-md-4">
                <small>Airport</small>
                <input
                  type="text"
                  id="airport"
                  className="form-control"
                  value={receivedData?.near_by_airport}
                  readOnly
                />
              </div>
            </div>
            <div className="row mb-4">
            {receivedData.status === 3 ?
              <div className="col-md-12 d-flex justify-content-center">
                <button
                  className="btn btn-success m-2"
                  type="submit"
                  onClick={() => onApproveData(receivedData.id)}
                  style={{ width: '200px', fontSize: '16px', backgroundColor: 'green' }}>
                  Approve</button>
                <button
                  className="btn btn-danger"
                  type="submit"
                  onClick={() => onRejectData(true)}
                  style={{ width: '200px', fontSize: '16px', backgroundColor: 'maroon', height: '42px', marginTop: '8px' }}>
                  Reject</button>
              </div>
              :""}
            </div>
            
            {reject ?
              <div>
                <div className="col-md-12 mb-3">
                  <small>Rejection Reason </small>
                  <textarea
                    className="form-control"
                    rows="3"
                    onChange={handleChange}
                  >
                    {text}
                  </textarea>
                  {error.text && (
                    <div style={{ color: 'red' }}>{error.text}</div>
                  )}
                </div>

                <div className="col-md-12 mb-3 text-end">
                  <button
                    className="btn btn-warning"
                    onClick={onSendReason}
                  >
                    <i className="far fa-paper-plane"></i>&nbsp; Send
                  </button>
                </div>
              </div>
              :
              ''

            }

          </div>
        </div>
     
      </div >
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="KYC Document"
      >
        <div>
          <img src={modalImage} alt="KYC Document" className="modal-image" />
          <button onClick={closeModal}>Close</button>
        </div>
      </Modal>
      <Footer />
    </>
  );
}

export default ValidationDetails2;
