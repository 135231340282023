import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import PageHeader from '../PageHeader/PageHeader'
import Header from '../Header/Header'
import Sidebar from '../Sidebar/Sidebar'
import Footer from '../Footer/Footer'

function AuctionTwoView() {
  // const navigate = useNavigate()
  const location = useLocation()
  const details = location.state

  return (
    <>
      <div className="main-content">
        <Header />
        <div className="app-menu navbar-menu" style={{ display: 'flex' }}>
          <Sidebar />
        </div>
        <div className="page-content">
          <PageHeader HeaderName="Upcoming Auctions - Details View" />

          <div className="container-fluid pt-3">
            <div className="row">
              <div className="col-xl-12 col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="col-md-3 ms-auto text-end">
                      <Link to="/auctions" className="btn btn-info">
                        Back
                      </Link>
                    </div>
                    <div className="row">
                      <div className="col-md-4 mb-3">
                        <small>Auction Name</small>
                        <input
                          type="text"
                          name="auction_name"
                          value={details.auction_name}
                          className="form-control"
                          readOnly
                        />
                      </div>
                      <div className="col-md-4 mb-3">
                        <small>Auction Estimation</small>
                        <input
                          type="text"
                          name="estimation"
                          value={details.estimation}
                          className="form-control"
                          readOnly
                        />
                      </div>
                      <div className="col-md-4 mb-3">
                        <small>Auction Start Date and Time</small>
                        <input
                          type="datetime-local"
                          name="auction_from_datetime"
                          value={details.auction_from_datetime}
                          className="form-control"
                          readOnly
                        />
                      </div>
                      <div className="col-md-4 mb-3">
                        <small>Auction End Date and Time</small>
                        <input
                          type="datetime-local"
                          name="auction_to_datetime"
                          value={details.auction_to_datetime}
                          className="form-control"
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- container-fluid --> */}
          </div>
          {/* <!-- End Page-content --> */}
        </div>
   
      </div>
      <Footer />
    </>
  )
}

export default AuctionTwoView
