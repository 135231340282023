import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import './sidebar.css'

function Sidebar() {
  const userType = localStorage.getItem('user_type')
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };


  return (
    <>
      <div className="container-fluid" style={{ position: 'auto' }}>
        <div id="scrollbar" className="menu-bg1">
          <div id="two-column-menu"> </div>
          <ul className="navbar-nav" id="navbar-nav">
            {userType === '5' ? (
              <>
                <li className="nav-item">
                  <NavLink
                    className="nav-link menu-link"
                    to="/temple-dashboard"
                    role="button"
                    aria-expanded="false"
                    aria-controls="sidebarDashboards"
                    activeClassName="active"
                  >
                    <i className="fa fa-tachometer-alt"></i>{' '}
                    <span data-key="t-dashboards">Temple Dashboard</span>
                  </NavLink>
                </li>
                {/* <li className="nav-item">
                <NavLink
                  className="nav-link menu-link"
                  to="/temples"
                  role="button"
                  aria-expanded="false"
                  aria-controls="sidebarDashboards"
                >
                  <i class="fa-solid fa-gopuram"></i>
                  <span data-key="t-dashboards">Temples</span>
                </NavLink>
              </li> */}
                <li className="nav-item">
                  <NavLink
                    className="nav-link menu-link"
                    to="/temple-profile"
                    role="button"
                    aria-expanded="false"
                    aria-controls="sidebarDashboards"
                  >
                    <i class="fa-solid fa-gopuram"></i>
                    <span data-key="t-dashboards">Profile</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link menu-link"
                    to="/sevas"
                    role="button"
                    aria-expanded="false"
                    aria-controls="sidebarDashboards"
                  >
                    <i class="fa-solid fa-handshake-simple"></i>
                    <span data-key="t-dashboards">Seva's</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link menu-link"
                    to="/pujari"
                    role="button"
                    aria-expanded="false"
                    aria-controls="sidebarDashboards"
                  >
                    <i className="fa-solid fa-user"></i>{' '}
                    <span data-key="t-dashboards">Pujari</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link menu-link"
                    to="/darshanam"
                    role="button"
                    aria-expanded="false"
                    aria-controls="sidebarDashboards"
                  >
                    <i class="fa-solid fa-handshake-simple"></i>
                    <span data-key="t-dashboards">Darshanam</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link menu-link"
                    to="/temple-auction"
                    role="button"
                    aria-expanded="false"
                    aria-controls="sidebarDashboards"
                  >
                    <i class="fa-solid fa-handshake-simple"></i>
                    <span data-key="t-dashboards">Temple Auctions</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link menu-link"
                    to="/temple-products"
                    role="button"
                    aria-expanded="false"
                    aria-controls="sidebarDashboards"
                  >
                    <i class="fa-solid fa-handshake-simple"></i>
                    <span data-key="t-dashboards">Products</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link menu-link"
                    to="/temple-photos"
                    role="button"
                    aria-expanded="false"
                    aria-controls="sidebarDashboards"
                  >
                    <i class="fa-solid fa-gopuram"></i>
                    <span data-key="t-dashboards">Photos/Videos</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link menu-link"
                    to="/temple-donations"
                    role="button"
                    aria-expanded="false"
                    aria-controls="sidebarDashboards"
                  >
                    <i class="fa-solid fa-handshake-simple"></i>
                    <span data-key="t-dashboards">Donations</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link menu-link"
                    to="/my-bookings"
                    role="button"
                    aria-expanded="false"
                    aria-controls="sidebarDashboards"
                  >
                    <i class="fa-solid fa-calendar-days"></i>
                    <span data-key="t-dashboards">My Bookings</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link menu-link"
                    to="/temple-reviews"
                    role="button"
                    aria-expanded="false"
                    aria-controls="sidebarDashboards"
                  >
                    <i class="fa-solid fa-handshake-simple"></i>
                    <span data-key="t-dashboards">Temple Reviews</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link menu-link"
                    to="/temple-bankdetails"
                    role="button"
                    aria-expanded="false"
                    aria-controls="sidebarDashboards"
                  >
                    <i class="fa-solid fa-handshake-simple"></i>
                    <span data-key="t-dashboards">Bank Details</span>
                  </NavLink>
                </li>
              </>

            ) : (
              <>
                <li className="nav-item">
                  <NavLink
                    className="nav-link menu-link"
                    to="/dashboard"
                    role="button"
                    aria-expanded="false"
                    aria-controls="sidebarDashboards"
                    activeClassName="active"
                  >
                    <i className="fa fa-tachometer-alt"></i>{' '}
                    <span data-key="t-dashboards">Dashboard</span>
                  </NavLink>
                </li>

                <li className="nav-item">
                  <a
                    className="nav-link menu-link"
                    href="#sidebarLanding"
                    data-bs-toggle="collapse"
                    role="button"
                    onClick={toggleDropdown}
                    aria-expanded={isOpen}
                    aria-controls="sidebarLanding"
                  >
                    <i className="fa-solid fa-gear"></i>
                    <span data-key="t-landing">Masters</span>
                  </a>
                  <div className={`collapse menu-dropdown ${isOpen ? 'show' : ''}`} id="sidebarLanding">
                    <ul className="nav nav-sm flex-column">
                      <li className="nav-item">
                        <NavLink
                          to="/addnewlocations"
                          className="nav-link"
                          data-key="t-one-page"
                          onClick={(e) => e.stopPropagation()}
                        >
                          {' '}
                          Add New Locations{' '}
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/addnewpackages"
                          className="nav-link"
                          data-key="t-nft-landing"
                        >
                          {' '}
                          Add New Packages{' '}
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/addnewdesignations"
                          className="nav-link"
                          data-key="t-nft-landing"
                        >
                          {' '}
                          Add New Designations{' '}
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/addnewsevatype"
                          className="nav-link"
                          data-key="t-nft-landing"
                        >
                          {' '}
                          Add New Seva Type{' '}
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/addnewexpensetype"
                          className="nav-link"
                          data-key="t-nft-landing"
                        >
                          {' '}
                          Add New Expense Type{' '}
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </li>

                <li className="nav-item">
                  <NavLink
                    className="nav-link menu-link"
                    to="/employee"
                    role="button"
                    aria-expanded="false"
                    aria-controls="sidebarDashboards"
                  >
                    <i className="fa-solid fa-user-friends"></i>{' '}
                    <span data-key="t-dashboards">Employee</span>
                  </NavLink>
                </li>
                {/* <li className="nav-item">
                  <NavLink
                    className="nav-link menu-link"
                    to="/calendar"
                    role="button"
                    aria-expanded="false"
                    aria-controls="sidebarDashboards"
                  >
                    <i class="fa-solid fa-calendar-days"></i>
                    <span data-key="t-dashboards">Calendar</span>
                  </NavLink>
                </li> */}
                {/* <li className="nav-item">
                  <NavLink
                    className="nav-link menu-link"
                    to="/pooja-services"
                    role="button"
                    aria-expanded="false"
                    aria-controls="sidebarDashboards"
                  >
                    <i class="fa-solid fa-handshake-simple"></i>
                    <span data-key="t-dashboards">Pooja/Services</span>
                  </NavLink>
                </li> */}

                <li className="nav-item">
                  <NavLink
                    className="nav-link menu-link"
                    to="/partnertabs"
                    role="button"
                    aria-expanded="false"
                    aria-controls="sidebarDashboards"
                  >
                    <i className="fa-solid fa-user-friends"></i>{' '}
                    <span data-key="t-dashboards">Partners</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link menu-link"
                    to="/list-of-all-users-rigistered"
                    role="button"
                    aria-expanded="false"
                    aria-controls="sidebarDashboards"
                  >
                    <i className="fa-solid fa-users"></i>{' '}
                    <span data-key="t-dashboards">Users</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link menu-link"
                    to="/sponsors"
                    role="button"
                    aria-expanded="false"
                    aria-controls="sidebarDashboards"
                  >
                    <i className="fa-solid fa-hand-holding-usd"></i>{' '}
                    <span data-key="t-dashboards">Sponsors</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link menu-link"
                    to="/auctions"
                    role="button"
                    aria-expanded="false"
                    aria-controls="sidebarDashboards"
                  >
                    <i className="fa-solid fa-gavel"></i>{' '}
                    <span data-key="t-dashboards">Auctions</span>
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink
                    className="nav-link menu-link"
                    to="/validation"
                    role="button"
                    aria-expanded="false"
                    aria-controls="sidebarDashboards"
                  >
                    <i className="fa-solid fa-circle-check"></i>{' '}
                    <span data-key="t-dashboards">Validations</span>
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink
                    className="nav-link menu-link"
                    to="/reviews"
                    role="button"
                    aria-expanded="false"
                    aria-controls="sidebarDashboards"
                  >
                    <i className="fa-solid fa-star-half-alt"></i>{' '}
                    <span data-key="t-dashboards">Reviews</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link menu-link"
                    to="/notifications"
                    role="button"
                    aria-expanded="false"
                    aria-controls="sidebarDashboards"
                  >
                    <i className="fa-solid fa-bell"></i>{' '}
                    <span data-key="t-dashboards">Push Notifications</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link menu-link"
                    to="/addbanners"
                    role="button"
                    aria-expanded="false"
                    aria-controls="sidebarDashboards"
                  >
                   <i class="fa-solid fa-image"></i>{' '}
                    <span data-key="t-dashboards">Add Banners</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link menu-link"
                    to="/samagrikit"
                    role="button"
                    aria-expanded="false"
                    aria-controls="sidebarDashboards"
                  >
                   <i class="fa-solid fa-image"></i>{' '}
                    <span data-key="t-dashboards">Samagri Kit</span>
                  </NavLink>
                </li>
                
              </>
            )}
            <li className="nav-item">
              <NavLink
                className="nav-link menu-link"
                to="/"
                role="button"
                aria-expanded="false"
                aria-controls="sidebarDashboards"
                onClick={() => {
                  localStorage.removeItem('token')
                }}
              >
                <i className="fa fa-power-off"></i>{' '}
                <span data-key="t-dashboards">Logout</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
      <div className="sidebar-background"></div>
    </>
  )
}

export default Sidebar
