import React, { useState, useEffect } from 'react';
import { fetchSevaTemples } from '../API/FetchAPI';

function BankDetailsOfTemple() {
  const [bankdetails, setBankDetails] = useState(null); // Initialize with null to check loading state

  async function loadTemplesData() {
    try {
      const response = await fetchSevaTemples('temple/getBankDetails');
      const data = await response.json(); // Ensure the response is parsed to JSON
      console.log('Data for Temples:', data);
      setBankDetails(data.data); // Adjust this according to your actual API response structure
    } catch (error) {
      console.error('Error loading temples data:', error);
    }
  }

  useEffect(() => {
    loadTemplesData();
  }, []);

  if (!bankdetails) {
    return <div>Loading...</div>; // Display a loading state while fetching data
  }

  return (
    <div>
      <div className='Card_form Bank_deatils_main'>
        <div className='bank_img_main'>
          <img src="../../../assets/images/bank.png" className='img-fluid' alt='' />
        </div>
        {/* <div className='text-end'>
          <img src="../../../assets/images/edit_form.png" className='img-fluid' alt='' />
        </div> */}
        <div className='row '>
          <div className='col-md-2 ms-auto position_relative'>
            <p><b>Bank Name </b> <span className='span_p'>:</span></p>
            <p><b>Company Name </b> <span className='span_p'>:</span></p>
            <p><b>Account No </b> <span className='span_p'>:</span></p>
            <p><b>IFSC Code </b> <span className='span_p'>:</span></p>
            <p><b>Address </b> <span className='span_p'>:</span></p>
          </div>
          <div className="col-md-3 me-auto position_relative">
            <p>{bankdetails.bankName}</p>
            <p>{bankdetails.companyName}</p>
            <p>{bankdetails.accountNumber}</p>
            <p>{bankdetails.ifsc}</p>
            <p>{bankdetails.address}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BankDetailsOfTemple;
