import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import PageHeader from '../PageHeader/PageHeader'
import Header from '../Header/Header'
import Sidebar from '../Sidebar/Sidebar'
import {  getAllReviews } from '../API/Api'
import DataTable from 'react-data-table-component'
import { jsPDF } from 'jspdf'
import html2canvas from 'html2canvas'
import 'jspdf-autotable'
import * as XLSX from 'xlsx'
import Footer from '../Footer/Footer'

function Review() {
  const [reviews, setReviews] = useState([])
  const navigate = useNavigate()
  const [searchData, setSearchData] = useState('')
  const [loading, setLoading] = useState(true)
  const [selectedReview, setSelectedReview] = useState(null)

  const fetchTableData = async () => {
    try {
      const data = await getAllReviews()
      setReviews(data)
      setLoading(false)
    } catch (error) {
      console.error('Error fetching table data:', error)
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchTableData()
  }, [])

  const customStyles = {
    rows: {
      style: {
        // border: '0.1px solid #ddd',
      },
    },
    headCells: {
      style: {
        paddingLeft: '8px',
        paddingRight: '8px',
        color: 'black',
        fontSize: '14px',
        backgroundColor: '#ccc',
        fontFamily: 'Poppins',
        fontWeight: '600',
        border: '0.1px solid #ddd',
        display: 'flex',
        justifyContent: 'center',
      },
    },
    cells: {
      style: {
        paddingLeft: '8px',
        paddingRight: '8px',
        border: '0.1px solid #ddd',
        display: 'flex',
        justifyContent: 'center',
      },
    },
  }

  const columns = [
    {
      name: 'Sl.No',
      selector: (row) => row['slno'],
      sortable: true,
      format: (row, rowIndex) => rowIndex + 1,
      width: '10%',
    },
    {
      name: 'Reviewer Name',
      selector: (row) => row['user_name'],
      sortable: true,
    },
    {
      name: 'Review Given On Pandal',
      selector: (row) => row['pandal_name'],
      sortable: true,
      width: '20%',
    },
    {
      name: 'Status',
      cell: (row) => (row.status === '1' ? 'Active' : 'Inactive'),
      // selector: (row) => row['status'],
      sortable: true,
    },
    {
      name: 'Verification',
      selector: (row) => [
        row['verified'] === '1'
          ? 'Pending'
          : row['verified'] === '2'
            ? 'Approved'
            : row['verified'] === '3'
              ? 'Rejected'
              : '',
      ],
      sortable: true,
    },
    {
      name: 'Review	',
      selector: (row) => row['review'],
      sortable: true,
    },
    {
      name: 'Actions',
      cell: (row) => (
        <div className="d-flex">
          {/* <Link to={`/user-reviews`}> */}
          <button
            className="btn btn-sm btn-edit me-2"
            onClick={() => handleGetInfo(row)}
          >
            {' '}
            View Full details{' '}
          </button>
          {/* </Link> */}
          {/* <button
            className="btn btn-sm btn-delete"
            onClick={() => handleDeleteReview(row['id'])}
          >
            <i className="fa-solid fa-trash-can"></i>
          </button> */}
        </div>
      ),
    },
  ]

  const handleGetInfo = async (row) => {
    navigate(
      '/user-reviews',
      { state: row },
      // { state: { ...row } },
    )
    // console.log(row, 'handleGetInfo')
  }

  const handleSearch = (e) => {
    const searchValue = e.target.value
    setSearchData(searchValue)
  }

  const filteredReviews = reviews.filter((review) =>
    review.user_name.toLowerCase().includes(searchData.toLowerCase()),
  )

  //Downloading PDF Format
  const columnsForPDF = [
    {
      name: 'Sl.No',
      selector: (row) => row['slno'],
      sortable: true,
      format: (rowIndex) => rowIndex + 1,
    },
    {
      name: 'Reviewer Name',
      selector: (row) => row['user_name'],
      sortable: true,
    },
    {
      name: 'Review Given On Pandal',
      selector: (row) => row['pandal_name'],
      sortable: true,
    },
    {
      name: 'Status',
      cell: (row) => (row.status === '1' ? 'Active' : 'Inactive'),
      sortable: true,
    },
    {
      name: 'Review',
      selector: (row) => row['review'],
      sortable: true,
    },
  ]
  const handleDownloadPDF = () => {
    const table = document.querySelector('.table-responsive table')
    html2canvas(table).then((canvas) => {
      const pdf = new jsPDF()
      const pdfData = filteredReviews.map((review, index) => [
        index + 1,
        review['user_name'],
        review['pandal_name'],
        review['status'] === '1' ? 'Active' : 'Inactive',
        review['review'],
      ])

      pdf.autoTable({
        head: [columnsForPDF.map((column) => column.name)],
        body: pdfData,
      })

      pdf.save('reviews_data.pdf')
    })
  }

  //Downloading Excel Format
  const handleDownloadExcel = () => {
    const columnsWithoutActions = columns.filter(
      (column) => column.name !== 'Actions',
    )
    const tableData = filteredReviews.map((review, index) => [
      index + 1,
      review['user_name'],
      review['pandal_name'],
      review['status'] === '1' ? 'Active' : 'Inactive',
      review['review'],
    ])
    const worksheet = XLSX.utils.aoa_to_sheet([
      columnsWithoutActions.map((column) => column.name),
      ...tableData,
    ])
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Table Data')
    const excelFile = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
    const blob = new Blob([excelFile], { type: 'application/octet-stream' })
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = 'review_data.xlsx'
    a.click()
    URL.revokeObjectURL(url)
  }

  //Downloading CSV Format
  const handleDownloadCSV = () => {
    const columnsWithoutActions = columns.filter(
      (column) => column.name !== 'Actions',
    )
    const tableData = filteredReviews.map((review, index) => [
      index + 1,
      review['user_name'],
      review['pandal_name'],
      review['status'] === '1' ? 'Active' : 'Inactive',
      review['review'],
    ])
    const csvData = [
      columnsWithoutActions.map((column) => column.name),
      ...tableData,
    ]
    const csvContent = csvData.map((row) => row.join(',')).join('\n')
    const blob = new Blob([csvContent], { type: 'text/csv' })
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = 'review_data.csv'
    a.click()
    URL.revokeObjectURL(url)
  }

  return (
    <>
      <div className="main-content">
        <Header />
        <div className="app-menu navbar-menu" style={{ display: 'flex' }}>
          <Sidebar />
        </div>
        <div className="page-content">
          <PageHeader HeaderName="All Users Reviews" />
          <div className="container-fluid pt-3">
            <h4>All Users Reviews</h4>
            <div className="row mb-5">
              {/* <div className="col-md-3 ms-auto mb-3">
                <input
                  type="text"
                  className="form-control"
                  name=""
                  placeholder="Search By Reviewer Name..."
                  value={searchData}
                  onChange={handleSearch}
                />
                {/* <button type="submit" className="from-icon btn">
                  <i className="fa fa-search"></i>
                </button> */}
              {/* </div> */} 
              {loading ? (
                <p>Loading...</p>
              ) : searchData !== '' && filteredReviews.length === 0 ? (
                <p>Data Not Found</p>
              ) : (
                <>
                  <div className="col-xl-12 col-md-12">
                    <div className="table-responsive registered-img bg-white">
                      <table className="table table-bordered table-striped m-0">
                        <DataTable
                          columns={columns}
                          data={filteredReviews}
                          pagination
                          customStyles={customStyles}
                          highlightOnHover
                          noDataComponent="Loading..."
                        />
                      </table>
                    </div>
                  </div>
                  <div className="col-md-12 text-end">
                    <div className="button-group">
                      <button
                        title="Download Excel"
                        className="button-excel-format"
                        onClick={handleDownloadExcel}
                      >
                        Excel
                      </button>
                      <button
                        title="Download CSV"
                        className="button-csv-format"
                        onClick={handleDownloadCSV}
                      >
                        CSV
                      </button>
                      <button
                        title="Download PDF"
                        className="button-pdf-format"
                        onClick={handleDownloadPDF}
                      >
                        PDF
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        
      </div>
      <Footer />
    </>
  )
}

export default Review
