import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import PageHeader from '../../PageHeader/PageHeader'
import {
  createLocation,
  deleteLocation,
  fetchCityDetails,
  fetchCountryDetails,
  fetchStateDetails,
  getAllLocations,
  updateLocation,
} from '../../API/Api'
import { jsPDF } from 'jspdf'
import html2canvas from 'html2canvas'
import 'jspdf-autotable'
import * as XLSX from 'xlsx'
import Header from '../../Header/Header'
import Sidebar from '../../Sidebar/Sidebar'
import Footer from '../../Footer/Footer'

function AddNewLocation() {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm()

  const user_id = localStorage.getItem('user_id')
  const [locations, setLocations] = useState([])
  const [countryName, setCountryName] = useState([])
  const [stateName, setStateName] = useState([])
  const [cityName, setCityName] = useState([])
  const [selectedStatus, setSelectedStatus] = useState('')
  const [selectedLocation, setSelectedLocation] = useState(null)
  const [loading, setLoading] = useState(true)
  const [updateStateId, setUpdateStateId] = useState('')
  const [updateCityId, setUpdateCityId] = useState('')

  const fetchCountryData = () => {
    fetchCountryDetails()
      .then((data) => setCountryName(data))
      .catch((error) => console.error('Error fetching country data:', error))
    fetchTableData()
  }

  const selectedCountryId = watch('country_id')
  const getStateOptions = () => {
    if (selectedCountryId) {
      fetchStateDetails(selectedCountryId)
        .then((data) => setStateName(data))
        .catch((error) => console.error('Error fetching state data:', error))
    } else {
      setStateName([])
    }
  }

  const selectedStateId = watch('state_id')
  const getCityOptions = () => {
    if (selectedStateId) {
      fetchCityDetails(selectedStateId)
        .then((data) => setCityName(data))
        .catch((error) => console.error('Error fetching city data:', error))
    } else {
      setCityName([])
    }
  }
  // const fetchCountryData = async () => {
  //   try {
  //     const url =
  //       process.env.REACT_APP_API_BASEURL + `api/location/getAllCountries`
  //     const headers = {
  //       Authorization: `Bearer ${token}`,
  //       'Content-Type': 'application/json',
  //     }
  //     const response = await fetch(url, { headers })
  //     const data = await response.json()
  //     setCountryName(data)
  //   } catch (error) {
  //     console.error('Error fetching data:', error)
  //   }
  // }
  // const fetchStateData = async (country_id) => {
  //   try {
  //     const url =
  //       process.env.REACT_APP_API_BASEURL +
  //       `api/location/getStateByCountry/${country_id}`
  //     const headers = {
  //       Authorization: `Bearer ${token}`,
  //       'Content-Type': 'application/json',
  //     }
  //     const response = await fetch(url, { headers })
  //     const data = await response.json()
  //     if (data.message === 'No data found') {
  //       setStateName([])
  //     } else {
  //       setStateName(data)
  //     }
  //   } catch (error) {
  //     console.error('Error fetching data:', error)
  //   }
  // }
  // const fetchCityData = async (state_id) => {
  //   try {
  //     const url =
  //       process.env.REACT_APP_API_BASEURL +
  //       `api/location/getCityByState/${state_id}`
  //     const headers = {
  //       Authorization: `Bearer ${token}`,
  //       'Content-Type': 'application/json',
  //     }
  //     const response = await fetch(url, { headers })
  //     const data = await response.json()
  //     if (data.message === 'No data found') {
  //       setCityName([])
  //     } else {
  //       setCityName(data)
  //     }
  //   } catch (error) {
  //     console.error('Error fetching data:', error)
  //   }
  // }

  // const getStateOptions = () => {
  //   if (selectedCountryId) {
  //     fetchStateData(selectedCountryId)
  //   } else {
  //     setStateName([])
  //   }
  // }

  // const getCityOptions = () => {
  //   if (selectedStateId) {
  //     fetchCityData(selectedStateId)
  //   } else {
  //     setCityName([])
  //   }
  // }

  const fetchTableData = async () => {
    try {
      const data = await getAllLocations()
      // setLocations(data)
      const sortedData = data.sort(
        (a, b) =>
          new Date(b.created_at.split('/').reverse().join('-')) -
          new Date(a.created_at.split('/').reverse().join('-')),
      )
      setLocations(sortedData)
      setLoading(false)
    } catch (error) {
      console.error('Error fetching table data:', error)
      setLoading(false)
    }
  }

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value)
  }

  const customStyles = {
    rows: {
      style: {
        // border: '0.1px solid #ddd',
      },
    },
    headCells: {
      style: {
        paddingLeft: '8px',
        paddingRight: '8px',
        color: 'black',
        fontSize: '14px',
        backgroundColor: '#ccc',
        fontFamily: 'Poppins',
        fontWeight: '600',
        border: '0.1px solid #ddd',
        display: 'flex',
        justifyContent: 'center',
      },
    },
    cells: {
      style: {
        paddingLeft: '8px',
        paddingRight: '8px',
        border: '0.1px solid #ddd',
        display: 'flex',
        justifyContent: 'center',
      },
    },
  }

  const columns = [
    {
      name: 'Sl.No',
      selector: (row) => row['slno'],
      // selector: 'slno',
      sortable: true,
      format: (row, rowIndex) => rowIndex + 1,
    },
    {
      name: 'Country Name',
      selector: (row) => row['country_name'],
      sortable: true,
    },
    {
      name: 'State Name',
      selector: (row) => row['state_name'],
      sortable: true,
    },
    {
      name: 'City Name',
      selector: (row) => row['city_name'],
      sortable: true,
    },
    {
      name: 'Area Name',
      selector: (row) => row['area_name'],
      sortable: true,
    },
    {
      name: 'Status',
      cell: (row) => (row.status === '1' ? 'Active' : 'Inactive'),
      // selector: (row) => [
      //   row['status'] === '1'
      //     ? 'Active'
      //     : row['status'] === '2'
      //     ? 'InActive'
      //     : '',
      // ],
      sortable: true,
    },
    {
      name: 'Date Added',
      selector: (row) => row['created_at'],
      // selector: 'created_at',
      sortable: true,
    },
    {
      name: 'Actions',
      cell: (row) => (
        <div className="d-flex">
          <button
            className="btn btn-sm btn-edit me-2"
            onClick={() => UpdateLocation(row)}
          >
            <i className="fa-regular fa-pen-to-square"></i>{' '}
          </button>
          <button
            className="btn btn-sm btn-delete"
            onClick={() => DeleteLocation(row.id)}
          >
            <i className="fa-solid fa-trash-can"></i>
          </button>
        </div>
      ),
    },
  ]

  useEffect(() => {
    fetchCountryData()
    fetchTableData()
  }, [])

  useEffect(() => {
    getStateOptions()
    getCityOptions()
  }, [selectedCountryId, selectedStateId])

  const addNewLocation = async (data) => {
    try {
      const country_id = data.country_id || ''
      const state_id = data.state_id || ''
      const city_id = data.city_id || ''
      const area_name = data.area_name || ''
      const id = data.id || ''
      const status = data.status || ''
      const created_by = user_id
      const updated_by = user_id
      const requestData = {
        country_id,
        state_id,
        city_id,
        area_name,
        status: selectedStatus,
        created_by,
        updated_by,
        id,
      }
      if (selectedLocation) {
        await updateLocation(selectedLocation.id, requestData)
        const successMessage = 'Location Updated'
        toast.success(successMessage, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
        reset()
        setSelectedStatus('')
        setSelectedLocation(null)
        fetchTableData()
      } else {
        await createLocation(requestData)
        const successMessage = 'New Location Added'
        toast.success(successMessage, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
        reset()
        setSelectedStatus('')
        fetchTableData()
      }
    } catch (error) {
      //  (error.response.data.errors, 'error.response')
      if (error.response.status === 422) {
        Object.values(error.response.data.errors).map((item) => {
          toast.error(item[0], {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        })
      } else {
        toast.error(error.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      }
    }
  }

  const DeleteLocation = async (id) => {
    try {
      await deleteLocation(id)
      const successMessage = 'Location Deleted'
      toast.success(successMessage, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      fetchTableData()
    } catch (error) {
      toast.error(error.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  const UpdateLocation = (location) => {
    setSelectedLocation(location)
    setValue('country_id', location.country_id)
    setValue('state_id', location.state_id)
    setValue('city_id', location.city_id)
    setValue('area_name', location.area_name)
    setValue('id', location.id)
    setSelectedStatus(location.status)
    setUpdateStateId(location.state_id)
    setUpdateCityId(location.city_id)
  }

  const handleKeyDown = (event, callback) => {
    if (event.key === 'Enter' && event.shiftKey === false) {
      event.preventDefault()
      callback(addNewLocation)
    }
  }

  //Downloading PDF Format
  const columnsForPDF = [
    {
      name: 'Sl.No',
      selector: (row) => row['slno'],
      sortable: true,
      format: (rowIndex) => rowIndex + 1,
    },
    {
      name: 'Country Name',
      selector: (row) => row['country_id'],
      sortable: true,
    },
    {
      name: 'State Name',
      selector: (row) => row['state_id'],
      sortable: true,
    },
    {
      name: 'City Name',
      selector: (row) => row['city_id'],
      sortable: true,
    },
    {
      name: 'Area Name',
      selector: (row) => row['area_name'],
      sortable: true,
    },
    {
      name: 'Status',
      cell: (row) => (row.status === '1' ? 'Active' : 'Inactive'),
      sortable: true,
    },
    {
      name: 'Date Added',
      selector: (row) => row['created_at'],
      sortable: true,
    },
  ]
  const handleDownloadPDF = () => {
    const table = document.querySelector('.table-responsive table')
    html2canvas(table).then((canvas) => {
      // const imgData = canvas.toDataURL('image/png')
      const pdf = new jsPDF()
      // const pdfWidth = pdf.internal.pageSize.getWidth()
      // const pdfHeight = (canvas.height * pdfWidth) / canvas.width
      const pdfData = locations.map((location, index) => [
        index + 1,
        location['country_id'],
        location['state_id'],
        location['city_id'],
        location['area_name'],
        location['status'] === '1' ? 'Active' : 'Inactive',
        location['created_at'],
      ])

      pdf.autoTable({
        head: [columnsForPDF.map((column) => column.name)],
        body: pdfData,
      })

      pdf.save('locations_data.pdf')
    })
  }

  //Downloading Excel Format
  const handleDownloadExcel = () => {
    const columnsWithoutActions = columns.filter(
      (column) => column.name !== 'Actions',
    )
    const tableData = locations.map((location, index) => [
      index + 1,
      location['country_id'],
      location['state_id'],
      location['city_id'],
      location['area_name'],
      location['status'] === '1' ? 'Active' : 'Inactive',
      location['created_at'],
    ])
    const worksheet = XLSX.utils.aoa_to_sheet([
      columnsWithoutActions.map((column) => column.name),
      ...tableData,
    ])
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Table Data')
    const excelFile = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
    const blob = new Blob([excelFile], { type: 'application/octet-stream' })
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = 'locations_data.xlsx'
    a.click()
    URL.revokeObjectURL(url)
  }

  //Downloading CSV Format
  const handleDownloadCSV = () => {
    const columnsWithoutActions = columns.filter(
      (column) => column.name !== 'Actions',
    )
    const formatDate = (dateString) => {
      if (!dateString) {
        return ''
      }
      const dateParts = dateString.split('/')
      if (dateParts.length !== 3) {
        // console.log('Invalid date format:', dateString)
        return ''
      }
      const formattedDate = `${dateParts[1]}/${dateParts[0]}/${dateParts[2]}`
      const timestamp = Date.parse(formattedDate)
      if (isNaN(timestamp)) {
        // console.log('Invalid date:', dateString)
        return ''
      }
      const date = new Date(timestamp)
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' }
      return new Intl.DateTimeFormat('en-US', options).format(date)
    }
    const tableData = locations.map((location, index) => [
      index + 1,
      location['country_id'],
      location['state_id'],
      location['city_id'],
      location['area_name'],
      location['status'] === '1' ? 'Active' : 'Inactive',
      formatDate(location['created_at']),
    ])
    const worksheet = XLSX.utils.aoa_to_sheet([
      columnsWithoutActions.map((column) => column.name),
      ...tableData,
    ])
    const columnWidths = columnsWithoutActions.map((column) => ({
      wch: column.name.length + 2,
    }))
    worksheet['!cols'] = columnWidths
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Table Data')
    const excelFile = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
    const blob = new Blob([excelFile], { type: 'application/octet-stream' })
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = 'location_data.csv'
    a.click()
    URL.revokeObjectURL(url)
  }

  return (
    <>
      <div className="main-content">
        <Header />
        <div className="app-menu navbar-menu" style={{ display: 'flex' }}>
          <Sidebar />
        </div>
        <div className="page-content">
          <PageHeader HeaderName="Masters > Add New Location" />
          <div className="container-fluid pt-3">
            <div className="row">
              <div className="col-xl-12 col-md-12">
                <div className="card">
                  <div className="card-body">
                    <form
                      onSubmit={handleSubmit(addNewLocation)}
                      onKeyDown={(e) => {
                        handleKeyDown(e, handleSubmit)
                      }}
                    >
                      <div className="row">
                        <div className="col-md-10">
                          <div className="row">
                            <div className="col-md-3 col-6">
                              <small>Add Country</small>
                              <select
                                name="country_id"
                                className="form-select"
                                {...register('country_id')}
                              >
                                <option value="">Select Country</option>
                                {countryName.map((country) => (
                                  <option key={country.id} value={country.id}>
                                    {country.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="col-md-3 col-6">
                              <small>Add State</small>
                              <select
                                name="state_id"
                                className="form-select"
                                {...register('state_id')}
                              >
                                <option value="">Select State</option>
                                {stateName.map((state) => (
                                  <option key={state.id} value={state.id}>
                                    {state.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="col-md-3 col-6">
                              <small>Add City</small>
                              <select
                                name="city_id"
                                className="form-select"
                                {...register('city_id')}
                              >
                                <option value="">Select City</option>
                                {cityName.map((city) => (
                                  <option
                                    selected={
                                      city.id === updateCityId ? 'selected' : ''
                                    }
                                    key={city.id}
                                    value={city.id}
                                  >
                                    {city.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="col-md-3 col-6">
                              <small>Add Area</small>
                              <input
                                type="text"
                                className="form-control"
                                name="area_name"
                                {...register('area_name')}
                              />
                            </div>
                            <div className="col-md-3 col-6 mt-2">
                              <small>Add Status</small>
                              <select
                                name="status"
                                className="form-select"
                                {...register('status')}
                                onChange={handleStatusChange}
                                value={selectedStatus}
                              >
                                <option value="">Select Status</option>
                                <option value="1">Active</option>
                                <option value="2">InActive</option>
                              </select>
                            </div>
                            <div className="col-md-2 col-6">
                              <div>
                                <small style={{ display: 'none' }}>ID</small>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="id"
                                  {...register('id')}
                                  style={{ display: 'none' }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2 text-end">
                          <small>&nbsp;</small>
                          <div className="col-md-12 text-end">
                            <button
                              type="submit"
                              className="btn btn-add waves-effect waves-light w_100"
                            >
                              {selectedLocation ? 'Update' : 'Add'}
                            </button>
                            <small>&nbsp;</small>
                            <button
                              type="reset"
                              className="btn btn-warning waves-effect waves-light w_100"
                              onClick={async () => {
                                setSelectedLocation(null)
                                setValue('country_id', '')
                                setValue('state_id', '')
                                setValue('city_id', '')
                                setValue('area_name', '')
                                setSelectedStatus('')
                                reset()
                              }}
                            >
                              Clear
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                {loading ? (
                  <p>Loading...</p>
                ) : locations.length === 0 ? (
                  <p>Data Not Found</p>
                ) : (
                  <>
                    <div className="card">
                      <div className="table-responsive">
                        <table className="table table-bordered table-striped m-0">
                          <DataTable
                            columns={columns}
                            data={locations}
                            pagination
                            customStyles={customStyles}
                            highlightOnHover
                            noDataComponent="Loading..."
                          />
                        </table>
                      </div>
                    </div>
                    <div className="col-md-12 text-end">
                      <div className="button-group">
                        <button
                          title="Download Excel"
                          className="button-excel-format"
                          onClick={handleDownloadExcel}
                        >
                          Excel
                        </button>
                        <button
                          title="Download CSV"
                          className="button-csv-format"
                          onClick={handleDownloadCSV}
                        >
                          CSV
                        </button>
                        <button
                          title="Download PDF"
                          className="button-pdf-format"
                          onClick={handleDownloadPDF}
                        >
                          PDF
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default AddNewLocation
