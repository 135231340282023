import axios from 'axios'
const token = localStorage.getItem('token')
const apiBaseUrl = process.env.REACT_APP_API_BASEURL
const api = axios.create({
    baseURL: apiBaseUrl,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })
const getToken = () => {
    return localStorage.getItem("token");
  };


  export const GetAllPoojariDetails = async () => {
    try {
      const response = await api.get('pujari/getByTempleId/1')
      console.log(response)
      return response.data.map((item) => ({
        ...item,
        created_at: new Date(item.created_at).toLocaleDateString('en-GB'),
      }))
    } catch (error) {
      throw error
    }
  }

  export const CreateNewProductDetails = async (requestData) => {
    try {
      const response = await api.post('templeProducts/create', requestData,
      {
        headers: {
          // Authorization: `Bearer ${getToken()}`,
          "Content-Type": "multipart/form-data",
        },

      })
      return response.data
    } catch (error) {

      throw error
    }
  }

  // Assuming you have axios or a similar library for making HTTP requests


// export const updatePoojariStatus = async ({ pujari_id, temple_status }) => {
//   const response = await axios.post('/temple/updatepoojaristatus', {
//     pujari_id,
//     temple_status,
//   });
//   return response.data;
// };


export const updatePoojariStatus = async ({ pujari_id, temple_status }) => {
  try {
    const response = await api.post(`temple/updatepoojaristatus/${pujari_id , temple_status}`, 
    {
      headers: {
        // Authorization: `Bearer ${getToken()}`,
        "Content-Type": "multipart/form-data",
      },

    })
    return response.data
  } catch (error) {

    throw error
  }
}

  export const UpdateSevaDetails = async (requestData,) => {
    try {
      const response = await api.post(`temple-seva/edit/${requestData.id}`, requestData,
      {
        headers: {
          // Authorization: `Bearer ${getToken()}`,
          "Content-Type": "multipart/form-data",
        },

      })
      return response.data
    } catch (error) {

      throw error
    }
  }

  export const DeleteSevaDetails = async (id) => {
    try {
      const response = await api.delete(`temple-seva/delete/${id}`)
      return response.data
    } catch (error) {

      throw error
    }
  }