import React, { useState } from 'react'
import PageHeader from '../PageHeader/PageHeader'
import Header from '../Header/Header'
import Sidebar from '../Sidebar/Sidebar'
import Footer from '../Footer/Footer'
import { createNotification } from '../API/Api'
import { toast } from 'react-toastify'

function Notification() {
  const user_id = localStorage.getItem('user_id')
  const [changeTitle, setChangeTitle] = useState('')
  const [errors, setErrors] = useState({})
  const [notificationName, setNotificationTme] = useState('')
  const [sendNotification, setSendNotification] = useState('')
  const [message, setMessage] = useState('')
  const [status, setStaus] = useState('')

  const onChangeTitle = (e) => {
    setChangeTitle(e.target.value)
  }
  const onChangeNotificationName = (e) => {
    setNotificationTme(e.target.value)
  }
  const onChangeSendNotificationName = (e) => {
    setSendNotification(e.target.value)
  }
  const onChangeMessage = (e) => {
    setMessage(e.target.value)
  }

  const onChangeStatus = (e) => {
    setStaus(e.target.value)
  }

  // const sendDetails = (e) => {
  //   e.preventDefault()
  // }

  const onCreateData = async (data) => {
    try {
      const bookingData = {
        title: changeTitle,
        name: notificationName,
        send_to: sendNotification,
        message: message,
        status: status,
        created_by: user_id,
      }
      await createNotification(bookingData)
      const successMessage = 'Notification Sent Successfully'
      toast.success(successMessage, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      setChangeTitle('')
      setNotificationTme('')
      setSendNotification('')
      setMessage('')
      setStaus('')
    } catch (error) {
      if (error.response.status === 422) {
        Object.values(error.response.data.errors).map((item) => {
          toast.error(item[0], {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        })
      } else {
        toast.error(error.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      }
    }
  }

  // const onCreateData = async () => {
  //   const bookingData = {
  //     title: changeTitle,
  //     name: notificationName,
  //     send_to: sendNotification,
  //     message: message,
  //     status: status,
  //     created_by: user_id,
  //   }
  //   // console.log(bookingData)
  //   const headers = {
  //     Authorization: `Bearer ${token}`,
  //     'Content-Type': 'application/json',
  //   }
  //   await axios
  //     .post(
  //       process.env.REACT_APP_API_BASEURL + `api/notification/create`,
  //       bookingData,
  //       { headers },
  //     )
  //     .then(async (res) => {
  //       // console.log(res.data);
  //       setChangeTitle('')
  //       setNotificationTme('')
  //       setSendNotification('')
  //       setMessage('')
  //       setStaus('')
  //     })
  //     .catch((error) => {
  //       // console.log(error);
  //     })
  // }

  const validateAndSubmit = (e) => {
    e.preventDefault()
    const validationErrors = {}

    // Validate name field

    !changeTitle
      ? (validationErrors.changeTitle = 'Required')
      : delete validationErrors['changeTitle']

    !notificationName
      ? (validationErrors.notificationName = 'Required')
      : delete validationErrors['notificationName']

    !sendNotification
      ? (validationErrors.sendNotification = 'Required')
      : delete validationErrors['sendNotification']

    !message
      ? (validationErrors.message = 'Required')
      : delete validationErrors['message']
    !status
      ? (validationErrors.status = 'Required')
      : delete validationErrors['status']
    setErrors(validationErrors)
    !Object.keys(validationErrors).length && onCreateData()
    // !Object.keys(validationErrors).length && handleEdit()
  }

  return (
    <>
      <div className="main-content">
        <Header />
        <div className="app-menu navbar-menu" style={{ display: 'flex' }}>
          <Sidebar />
        </div>
        <div className="page-content">
          <PageHeader HeaderName="User Review" />

          <div className="container-fluid pt-3">
            <form onSubmit={validateAndSubmit}>
              <div className="card card-body">
                <div className="row mb-5">
                  <div className="col-md-4 mb-3">
                    <small>Notification Title</small>
                    <input
                      type="text"
                      className="form-control"
                      name=""
                      onChange={onChangeTitle}
                      value={changeTitle}
                    />
                    {errors.changeTitle && (
                      <span style={{ color: 'red' }}>{errors.changeTitle}</span>
                    )}
                  </div>
                  <div className="col-md-4 mb-3">
                    <small>Notification Name</small>
                    <input
                      type="text"
                      className="form-control"
                      name=""
                      onChange={onChangeNotificationName}
                      value={notificationName}
                    />
                    {errors.notificationName && (
                      <span style={{ color: 'red' }}>
                        {errors.notificationName}
                      </span>
                    )}
                  </div>
                  <div className="col-md-4 mb-3">
                    <small style={{ display: 'flex' }}>
                      Send Notification To
                    </small>
                    <select
                      id="dropdown"
                      style={{ height: '36px', width: '100%' }}
                      onChange={onChangeSendNotificationName}
                      value={sendNotification}
                      className="form-control"
                    >
                      <option value="">Select an option</option>
                      <option value="1"> To All Partners Registered</option>
                      <option value="2"> To All Users Registered</option>

                      {/* Add more options as needed */}
                    </select>
                    {errors.sendNotification && (
                      <span style={{ color: 'red' }}>
                        {errors.sendNotification}
                      </span>
                    )}
                  </div>
                  <div class="col-md-4 col-6">
                    <small style={{ display: 'flex' }}>Status</small>
                    <select
                      id="dropdown"
                      onChange={onChangeStatus}
                      value={status}
                      name="status"
                      className="form-control"
                      style={{ height: '36px', width: '100%' }}
                    >
                      <option value="">Select an option</option>
                      <option value="1"> Active</option>
                      <option value="2"> InActive</option>

                      {/* Add more options as needed */}
                    </select>
                    {errors.status && (
                      <span style={{ color: 'red' }}>{errors.status}</span>
                    )}
                  </div>
                  <div className="col-md-12 mb-3" style={{ marginTop: '20px' }}>
                    <small>Notification Messages</small>
                    <textarea
                      className="form-control"
                      rows="4"
                      onChange={onChangeMessage}
                      value={message}
                    />
                    {errors.message && (
                      <span style={{ color: 'red' }}>{errors.message}</span>
                    )}
                  </div>

                  <div className="col-md-12 mt-2 text-end">
                    <button type="submit" className="btn btn-success">
                      <i className="fa fa-paper-plane"></i>&nbsp; Send
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Notification