import React, {useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom';
import { fetchSevaTemples } from '../API/FetchAPI';

function TempleProducts  ()  {
    const navigate = useNavigate();

    const [product, setproduct] = useState([]);

    const handleButtonClick = () => {
        navigate('/viewfullSevadetails');
      };
      

      async function loadTemplesData() {  
        try {
          const data = await fetchSevaTemples('templeProducts/byTempleId/6');
          // console.log('Data for Temples:', data);
          setproduct(data.data);
          console.log(data.data, 'new data')
          // Handle the data as needed
        } catch (error) {
          console.error('Error loading temples data:', error);
        }
      }

      useEffect(()=> {
        loadTemplesData();
      }, [])
      
    

  return (
    <div>
      <div className='Card_form'>
      <table className='table table-bordered table-hover'>
                <thead>
                  <tr className='table-primary'>
                    <th style={{ textAlign: 'center' }}>Sl.No</th>
                    <th style={{ textAlign: 'center' }}>Products</th>
                  
                    <th style={{ textAlign: 'center' }}>Description</th>
                    <th style={{ textAlign: 'center' }}>Price</th>
                    <th style={{ textAlign: 'center' }}>Image</th>
                    <th style={{ textAlign: 'center' }}>Action</th>
                  </tr>
                </thead>
                <tbody className='table_tbody'>
                {product.map((product, index) => ( 
                  <tr key={index}>
                    <td>{product.id}</td>
                    <td>{product.product_name}</td>
                    
                    <td>{product.description}</td>
                    <td>
                      {product.price}
                    </td>
                    <td>
                      <img src={product.images} alt="Temple" />
                    </td>
                    <td>
                      <button className='btn btn-primary' onClick={handleButtonClick}> View Full details </button>
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>
      </div>
    </div>
  )
}

export default TempleProducts
