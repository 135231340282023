import React, { useState, useRef } from 'react';
import PageHeader from '../PageHeader/PageHeader';
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
import Footer from '../Footer/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';

function Banners() {
  const inputRef = useRef(null);
  const [image, setImage] = useState(null);
  const [uploading, setUploading] = useState(false);
  const token = localStorage.getItem('token');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [errors, setErrors] = useState({});

  const handleImageChange = (event) => {
    const selectedFile = event.target.files[0];
    setImage(selectedFile);
  };

  const handleSave = async () => {
    const validationErrors = {};

    if (!title) {
      validationErrors.title = 'Title is required';
    }

    if (!description) {
      validationErrors.description = 'Description is required';
    }

    if (!image) {
      validationErrors.image = 'Image is required';
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setUploading(true);

    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('image', image);

    try {
      const response = await fetch('https://gowinda.in/api/api/admin/bannerImages', {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
      });

      if (response.ok) {
        console.log('Image uploaded successfully');
        // Handle success, e.g., show a success message
      } else {
        console.error('Failed to upload image');
        // Handle failure, e.g., show an error message
      }
    } catch (error) {
      console.error('Error uploading image:', error);
      // Handle error, e.g., show an error message
    } finally {
      setUploading(false);
    }
  };

  return (
    <div>
      <div className="main-content">
        <Header />
        <div className="app-menu navbar-menu" style={{ display: 'flex' }}>
          <Sidebar />
        </div>
        <div className="page-content">
          <PageHeader HeaderName="Add Banners" />
          <div className='container'>
            <div className='row mt-2'>
              <div className='col-md-6'>
                <h3>Banner Details</h3>
                <div className="mb-3">
                  <label htmlFor="titleInput" className="form-label">Title</label>
                  <input 
                    type="text" 
                    className="form-control" 
                    id="titleInput" 
                    value={title} 
                    onChange={(e) => setTitle(e.target.value)} 
                  />
                  {errors.title && <div className="text-danger">{errors.title}</div>}
                </div>
                <div className="mb-3">
                  <label htmlFor="descriptionInput" className="form-label">Description</label>
                  <textarea 
                    className="form-control" 
                    id="descriptionInput" 
                    rows="3" 
                    value={description} 
                    onChange={(e) => setDescription(e.target.value)}
                  ></textarea>
                  {errors.description && <div className="text-danger">{errors.description}</div>}
                </div>
                <div className="mb-3">
                  <label htmlFor="bannerImageUpload" className="form-label">Upload Image</label>
                  <input 
                    name="bannerImage" 
                    type="file" 
                    className="form-control" 
                    id="bannerImageUpload" 
                    onChange={handleImageChange} 
                  />
                  {errors.image && <div className="text-danger">{errors.image}</div>}
                </div>
                <button 
                  type="button" 
                  className="btn btn-primary" 
                  onClick={handleSave} 
                  disabled={uploading}
                >
                  {uploading ? 'Uploading...' : 'Save & Upload'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Banners;
