import React, { useEffect, useState } from 'react'
import PageHeader from '../../PageHeader/PageHeader'
// import ApexCharts from 'apexcharts'
import Sidebar from '../../Sidebar/Sidebar'
import Header from '../../Header/Header'
import {
  getAllDonations,
  getAllModeration_Validation,
  getAllPartners,
  getAllUsers,
  getSponsorEnquired,
  getSponsorRegistered,
} from '../../API/Api'
import Footer from '../../Footer/Footer'

function TempleDashboard() {
  const [partnerCount, setPartnerCount] = useState(0)
  const [userCount, setUserCount] = useState(0)
  const [registerSponsorCount, setRegisterSponsorCount] = useState(0)
  const [enquirySponsorCount, setEnquirySponsorCount] = useState(0)
  const [moderationCount, setModerationCount] = useState(0)
  const [donationCount, setDonationCount] = useState(0)

  const partnersCount = async () => {
    try {
      const partners = await getAllPartners()
      setPartnerCount(partners.length)
    } catch (error) {
      console.error('Error fetching partner count:', error)
    }
  }
  const usersCount = async () => {
    try {
      const users = await getAllUsers()
      setUserCount(users.length)
    } catch (error) {
      console.error('Error fetching partner count:', error)
    }
  }
  const registerSponsorsCount = async () => {
    try {
      const sponsors = await getSponsorRegistered()
      setRegisterSponsorCount(sponsors.length)
    } catch (error) {
      console.error('Error fetching Sponsor count:', error)
    }
  }
  const enquirySponsorsCount = async () => {
    try {
      const sponsorEnquired = await getSponsorEnquired()
      setEnquirySponsorCount(sponsorEnquired.length)
    } catch (error) {
      console.error('Error fetching Sponsor count:', error)
    }
  }
  const moderationsCount = async () => {
    try {
      const moderations = await getAllModeration_Validation()
      setModerationCount(moderations.length)
    } catch (error) {
      console.error('Error fetching Moderation count:', error)
    }
  }
  const donationsCount = async () => {
    try {
      const donations = await getAllDonations()
      setDonationCount(donations.length)
    } catch (error) {
      console.error('Error fetching donations count:', error)
    }
  }

  useEffect(() => {
    partnersCount()
    usersCount()
    registerSponsorsCount()
    enquirySponsorsCount()
    moderationsCount()
    donationsCount()
  }, [])

  return (
    <>
      <div className="main-content">
        <Header />
        <div className="app-menu navbar-menu" style={{ display: 'flex' }}>
          <Sidebar />
        </div>
        <div className="page-content">
          <PageHeader HeaderName="Temple Dashboard" />
          <div className="container-fluid pt-3">
            <div className="row dashboardMain">
              <div className="col-md-4">
                <div className="card card1">
                  <div className="card-body">
                    <div className="">
                      <p>
                        Donations Received{' '}
                      </p>
                      <p>{partnerCount}</p>
                      <img
                        src="/assets/images/save-money.png"
                        alt="pujam_pandal"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card card2">
                  <div className="card-body">
                    <div className="">
                      <p>
                        Seva Bookings
                      </p>
                      <p>{userCount}</p>
                      <img
                        src="/assets/images/group.png"
                        alt="pujam_pandal"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card card3">
                  <div className="card-body">
                    <div className="">
                      <p>
                        Product Bookings
                      </p>
                      <p>{registerSponsorCount}</p>
                      <img
                        alt="pujam_pandal"
                        src="/assets/images/save-money.png"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card card4">
                  <div className="card-body">
                    <div className="">
                      <p>
                        Darshanam Bookings
                      </p>
                      <p>{donationCount}</p>
                      <img
                        alt="pujam_pandal"
                        src="/assets/images/user1.png"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card card5">
                  <div className="card-body">
                    <div className="">
                      <p>
                        No.of Auctions
                      </p>
                      <p>{enquirySponsorCount}</p>
                      <img
                        src="/assets/images/auction.png"
                        alt="pujam_pandal"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default TempleDashboard
