import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import PageHeader from '../PageHeader/PageHeader'
import DataTable from 'react-data-table-component'
import { useNavigate } from 'react-router-dom'
import * as XLSX from 'xlsx'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import Papa from 'papaparse'
import { deleteUser, getUserDonationsById, getUserPostsById } from '../API/Api'
import Header from '../Header/Header'
import { toast } from 'react-toastify'
import Sidebar from '../Sidebar/Sidebar'
import Footer from '../Footer/Footer'
import axios from 'axios';

function UserDetail() {
  const [donationData, setDonationData] = useState([])
  const [pandalPostData, setPandalPostData] = useState([])
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const location = useLocation()
  // const token = localStorage.getItem('token')
  const receivedData = location.state
  const date = new Date(receivedData.created_at).toLocaleDateString('en-GB')
  const id = receivedData?.id

  const onPostButton = (row) => {
    navigate('/view-post', { state: row })
  }

  const getByUserId = async () => {
    try {
      const modifiedData = await getUserDonationsById(id)
      setDonationData(modifiedData)
      setLoading(false)
    } catch (error) {
      console.error('Error fetching table data:', error)
      setLoading(false)
    }
  }

  // const getByUserId = async () => {
  //   try {
  //     const url =
  //       process.env.REACT_APP_API_BASEURL + `api/donation/getByUserId/${id}`
  //     const headers = {
  //       Authorization: `Bearer ${token}`,
  //       'Content-Type': 'application/json',
  //     }

  //     const response = await fetch(url, { headers })
  //     const data = await response.json()

  //     const modifiedData = data.map((item) => ({
  //       ...item,
  //        date: new Date(item?.created_at).toLocaleDateString('en-GB'),
  //     }))
  //     setDonationData(modifiedData)
  //   } catch (error) {
  //     console.error('Error fetching data:', error)
  //   }
  // }

  const pandalPostFetchData = async () => {
    try {
      const data = await getUserPostsById(id)
      setPandalPostData(data)
      setLoading(false)
    } catch (error) {
      console.error('Error fetching table data:', error)
      setLoading(false)
    }
  }

  // const pandalPostFetchData = async () => {
  //   try {
  //     const url =
  //       process.env.REACT_APP_API_BASEURL + `api/posts/getByUserId/${id}`
  //     const headers = {
  //       Authorization: `Bearer ${token}`,
  //       'Content-Type': 'application/json',
  //     }

  //     const response = await fetch(url, { headers })
  //     const data = await response.json()
  //     setPandalPostData(data)
  //   } catch (error) {
  //     console.error('Error fetching data:', error)
  //   }
  // }
  const deleteUser = async (id) => {
    const response = await axios.delete(`posts/softDelete/${id}`);
    return response.data;
  };

  const onDeleteData = async (id) => {
    try {
      await deleteUser(id)
      const successMessage = 'Post Deleted'
      toast.success(successMessage, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      pandalPostFetchData()
    } catch (error) {
      console.error('Error deleting data:', error)
    }
  }


  // const onDeleteData = async (id) => {
  //   const headers = {
  //     Authorization: `Bearer ${token}`,
  //     'Content-Type': 'application/json',
  //   }
  //   try {
  //     await axios.get(
  //       process.env.REACT_APP_API_BASEURL + `api/posts/softDelete/${id}`,
  //       { headers },
  //     )
  //     const successMessage = 'Post Deleted'
  //     toast.success(successMessage, {
  //       position: 'top-right',
  //       autoClose: 3000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //     })
  //     pandalPostFetchData()
  //   } catch (error) {
  //     console.error('Error deleting data:', error)
  //   }
  // }

  const columns = [
    {
      name: 'Sl.No',
      selector: 'slno',
      sortable: true,
      format: (row, rowIndex) => rowIndex + 1,
    },
    {
      name: 'Name Of Pandal',
      selector: 'pandal_name',
      sortable: true,
    },
    {
      name: 'Date Of Donation',
      
      selector: 'date',
      sortable: true,
    },
    {
      name: 'Total Donation Amount',
      selector: 'donation_amount',
      sortable: true,
    },
  ]

  const columns2 = [
    {
      name: 'Sl.No',
      selector: 'slno',
      sortable: true,
      format: (row, rowIndex) => rowIndex + 1,
    },
    {
      name: 'Post/Feed',
      selector: 'description',
      sortable: true,
      // cell: (row) =>
      // <img key={row} src={row?.images[0]?.image} alt={row.name} style={{ width: '50px', height: '50px', marginRight: '5px' }} />
    },
    {
      name: 'Actions',
      cell: (row) => (
        <div>
          <button
            class="btn btn-sm btn-edit me-2"
            onClick={() => onPostButton(row)}
          >
            <i className="fa-regular fa-eye"></i>{' '}
          </button>
          <button
            class="btn btn-sm btn-delete"
            onClick={() => onDeleteData(row.id)}
          >
            <i className="fa-solid fa-trash-can"></i>
          </button>
        </div>
      ),
    },
    // {
    //   name: 'Image',
    //   cell: (row) =>
    //     <img key={row} src={row?.images[0]?.image} style={{ width: '50px', height: '50px', marginRight: '5px' }} />
    // },
  ]

  const customStyles = {
    rows: {
      style: {
        // border: '0.1px solid #ddd',
      },
    },
    headCells: {
      style: {
        paddingLeft: '8px',
        paddingRight: '8px',
        color: 'black',
        fontSize: '14px',
        backgroundColor: '#ccc',
        fontFamily: 'Poppins',
        fontWeight: '600',
        border: '0.1px solid #ddd',
        display: 'flex',
        justifyContent: 'center',
      },
    },
    cells: {
      style: {
        paddingLeft: '8px',
        paddingRight: '8px',
        border: '0.1px solid #ddd',
        display: 'flex',
        justifyContent: 'center',
      },
    },
  }

  const downloadAsPDF = () => {
    const columnsForPDF = [...columns]
    columnsForPDF.pop()
    const doc = new jsPDF()
    const pdfData = []
    donationData.forEach((row, index) => {
      pdfData.push([
        index + 1,
        row.pandal_name,
        row.date,
        row.donation_amount,

        // Add the image name to the row data
      ])
    })

    doc.autoTable({
      head: [columns.map((column) => column.name)],
      body: pdfData,
      showHead: 'firstPage',
    })
    doc.save('table_data.pdf')
  }

  const downloadAsExcel = () => {
    const excelData = donationData.map((item, index) => ({
      'S.No': index + 1,
      'Name Of Pandal': item.pandal_name,
      'Date Of Donation': item.date,
      'Total Donation Amount': item.donation_amount,

      // Age: item.age,
    }))

    const worksheet = XLSX.utils.json_to_sheet(excelData)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
    XLSX.writeFile(workbook, 'table_data.xlsx')
  }

  const handleDownloadCSV = () => {
    const csvData = donationData.map((item, index) => ({
      'S.No': index + 1,
      'Name Of Pandal': item.pandal_name,
      'Date Of Donation': item.date,
      'Total Donation Amount': item.donation_amount,
    }))

    const csv = Papa.unparse(csvData)
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.setAttribute('href', url)
    link.setAttribute('download', 'table_data.csv')
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const downloadAsPDF1 = () => {
    const columnsForPDF = [...columns2]
    columnsForPDF.pop()
    const doc = new jsPDF()
    const pdfData = []
    pandalPostData.forEach((row, index) => {
      pdfData.push([
        index + 1,
        row.description,

        // Add the image name to the row data
      ])
    })

    doc.autoTable({
      head: [columnsForPDF.map((column) => column.name)],
      body: pdfData,
      showHead: 'firstPage',
    })
    doc.save('table_data.pdf')
  }

  const downloadAsExcel1 = () => {
    const excelData = pandalPostData.map((item, index) => ({
      'S.No': index + 1,
      'Post/Feed': item.description,

      // Age: item.age,
    }))

    const worksheet = XLSX.utils.json_to_sheet(excelData)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
    XLSX.writeFile(workbook, 'table_data.xlsx')
  }

  const handleDownloadCSV1 = () => {
    const csvData = pandalPostData.map((item, index) => ({
      'S.No': index + 1,
      'Post/Feed': item.description,
    }))

    const csv = Papa.unparse(csvData)
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.setAttribute('href', url)
    link.setAttribute('download', 'table_data.csv')
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  useEffect(() => {
    getByUserId()
    pandalPostFetchData()
  }, [])

  return (
    <>
      <div className="main-content">
        <Header />
        <div className="app-menu navbar-menu" style={{ display: 'flex' }}>
          <Sidebar />
        </div>
        <div className="page-content">
          <PageHeader HeaderName="Users Full Details" />

          <div className="container-fluid pt-3">
            <div className="row">
              <div className="col-xl-12 col-md-12">
                <div className="card">
                  <div className="card-body">
                    <ul className="nav nav-tabs">
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          data-bs-toggle="tab"
                          href="#one"
                        >
                          About User
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#two"
                        >
                          Donations
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#three"
                        >
                          User Posts
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="tab-content">
                  <div className="tab-pane active" id="one">
                    <div className="card card-body">
                      <div className="row">
                        <div className="col-md-2 mb-3">
                          <small>User Image</small>
                          <div className="col-md-12">
                            <img
                              src={receivedData?.user_image}
                              className="img-fluid"
                              alt='pujam_pandal'
                            />
                          </div>
                        </div>
                        <div className="col-md-10 mb-3">
                          <div className="row">
                            <div className="col-md-6 mb-3">
                              <small>User Name</small>
                              <input
                                type="text"
                                className="form-control"
                                value={receivedData?.name}
                                name=""
                                id=""
                              />
                            </div>
                            <div className="col-md-6 mb-3">
                              <small>Mobile Number</small>
                              <input
                                type="tel"
                                className="form-control"
                                value={receivedData?.mobile}
                                name=""
                                id=""
                              />
                            </div>
                            <div className="col-md-6 mb-3">
                              <small>E-Mail ID</small>
                              <input
                                type="email"
                                className="form-control"
                                value={receivedData?.email}
                                name=""
                                id=""
                              />
                            </div>
                            <div className="col-md-6 mb-3">
                              <small>Date Of Joining</small>
                              <input
                                // type="date"
                                className="form-control"
                                value={date}
                                name="date"
                                id=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="two">
                    <div className="card card-body">
                      <div className="row mb-3">
                        {loading ? (
                          <p>Loading...</p>
                        ) : donationData.length === 0 ? (
                          <p>Data Not Found</p>
                        ) : (
                          <div className="col-md-12 table-responsive">
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th>Sl. No</th>
                                  <th>Name of Pandal</th>
                                  <th>Date of Donation</th>
                                  <th>Total Donation Amount</th>
                                </tr>
                              </thead>
                              <tbody>
                                {donationData.map((item, index) => (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.pandal_name}</td>
                                    <td>{item.date}</td>
                                    <td>{item.donation_amount}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        )}
                      </div>
                      <div className="col-md-12 text-end">
                        <div className="button-group">
                          <button
                            title="Download Excel"
                            className="button-excel-format"
                            onClick={downloadAsExcel}
                          >
                            Excel
                          </button>
                          <button
                            title="Download CSV"
                            className="button-csv-format"
                            onClick={handleDownloadCSV}
                          >
                            CSV
                          </button>
                          <button
                            title="Download PDF"
                            className="button-pdf-format"
                            onClick={downloadAsPDF}
                          >
                            PDF
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="tab-pane fade" id="three">
                    <div className="card card-body">
                      {loading ? (
                        <p>Loading...</p>
                      ) : pandalPostData.length === 0 ? (
                        <p>Data Not Found</p>
                      ) : (
                        <>
                          <div className="col-md-12 table-responsive">
                            <DataTable
                              pagination
                              customStyles={customStyles}
                              data={pandalPostData}
                              columns={columns2}
                              highlightOnHover
                              noDataComponent="Loading..."
                            />
                          </div>
                          <div className="col-md-12 text-end">
                            <div className="button-group">
                              <button
                                title="Download Excel"
                                className="button-excel-format"
                                onClick={downloadAsExcel1}
                              >
                                Excel
                              </button>
                              <button
                                title="Download CSV"
                                className="button-csv-format"
                                onClick={handleDownloadCSV1}
                              >
                                CSV
                              </button>
                              <button
                                title="Download PDF"
                                className="button-pdf-format"
                                onClick={downloadAsPDF1}
                              >
                                PDF
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <Footer />
    </>
  )
}

export default UserDetail
