import React, { useState, useEffect, useMemo } from 'react';
import Header from '../../Header/Header';
import Sidebar from '../../Sidebar/Sidebar';
import PageHeader from '../../PageHeader/PageHeader';
import DataTable from 'react-data-table-component';
import { GetAllPoojariDetails, updatePoojariStatus } from '../../API/TempleApis/poojari';
import ViewDetails from './ViewDetails';

const Poojari = () => {
  const [poojariDetails, setPoojariDetails] = useState([]);
  const [selectedPoojari, setSelectedPoojari] = useState([]);
  const [activeTab, setActiveTab] = useState('active'); // Default active tab
  const [showModal, setShowModal] = useState(false);
  const [currentPoojari, setCurrentPoojari] = useState(null);

  useEffect(() => {
    fetchAllPoojariDetails();
  }, []);

  useEffect(() => {
    filterPoojariData(activeTab);
  }, []);

  const fetchAllPoojariDetails = async () => {
    try {
      const response = await GetAllPoojariDetails();
      setSelectedPoojari(response);
      setPoojariDetails(response);
      filterPoojariData(activeTab, response);
    } catch (error) {
      console.error('Error fetching poojari details:', error);
    }
  };

  const filterPoojariData = (tab, data = poojariDetails) => {
    let filteredData = [];
    if (tab === 'active') {
      filteredData = data.filter((poojari) => poojari.status === 1);
    } else if (tab === 'requested') {
      filteredData = data.filter((poojari) => poojari.status === 2);
    } else if (tab === 'declined') {
      filteredData = data.filter((poojari) => poojari.status === 3);
    }
    setPoojariDetails(filteredData);
  };

  const handleViewDetails = (row) => {
    setCurrentPoojari(row);
    setShowModal(true);
  };

  const handleApprove = async (pujari_id) => {
    try {
      await updatePoojariStatus({ pujari_id, temple_status: '1' });
      fetchAllPoojariDetails(); // Refresh poojari details after approval
    } catch (error) {
      console.error('Error approving poojari:', error);
    }
  };

  const handleReject = async (pujari_id) => {
    try {
      await updatePoojariStatus({ pujari_id, temple_status: '3' });
      fetchAllPoojariDetails(); // Refresh poojari details after rejection
    } catch (error) {
      console.error('Error rejecting poojari:', error);
    }
  };

  const columns = useMemo(() => {
    if (activeTab === 'requested') {
      return [
        {
          name: 'S.No',
          selector: 'slno',
          sortable: true,
          width: '10%',
          format: (row, rowIndex) => rowIndex + 1,
        },
        {
          name: 'Name',
          selector: 'name',
          sortable: true,
        },
        {
          name: 'Address',
          selector: 'Address',
          sortable: true,
        },
        {
          name: 'Email',
          selector: 'email',
          sortable: true,
        },
        {
          name: 'Mobile',
          selector: 'mobile',
          sortable: true,
        },

        {
          name: 'View Details',
          cell: (row) => (
            <div>
              <button
                className="btn btn-sm btn-view me-2"
                style={{
                  backgroundColor: '#60BEF2',
                  color: '#fff',
                  border: 'none',
                  height: '30px',
                  borderRadius: '5px',
                }}
                onClick={() => handleViewDetails(row)}>
              
                View Details
              </button>
            </div>
          ),
        },
        {
          name: 'Actions',
          cell: (row) => (
            <div className="d-flex" style={{ height: '25px' }}>
              <button
                className="btn btn-sm btn-edit me-2"
                style={{ backgroundColor: 'green' }}
                onClick={() => handleApprove(row)}
              >
                Approve
              </button>
              <button
                className="btn btn-sm btn-edit me-2"
                style={{ backgroundColor: 'red' }}
                onClick={() => handleReject(row)}
              >
                Deny
              </button>
            </div>
          ),
        },
      ]
    } else {
      return [
        {
          name: 'S.No',
          selector: 'slno',
          sortable: true,
          width: '10%',
          format: (row, rowIndex) => rowIndex + 1,
        },
        {
          name: 'Name',
          selector: 'name',
          sortable: true,
        },
        {
          name: 'Address',
          selector: 'Address',
          sortable: true,
        },
        {
          name: 'Email',
          selector: 'email',
          sortable: true,
        },
        {
          name: 'Mobile',
          selector: 'mobile',
          sortable: true,
        },
        {
          name: 'View Details',
          cell: (row) => (
            <div>
              <button
                className="btn btn-sm btn-view me-2"
                style={{
                  backgroundColor: '#60BEF2',
                  color: '#fff',
                  border: 'none',
                  height: '30px',
                  borderRadius: '5px',
                }}
                onClick={() => handleViewDetails(row)}>
            
                View Details
              </button>
            </div>
          ),
        },

      
      ]
    }
  }, [activeTab])

  const customStyles = {
    rows: {
      style: {
        // border: '0.1px solid #ddd',
      },
    },
    headCells: {
      style: {
        paddingLeft: '8px',
        paddingRight: '8px',
        color: 'black',
        fontSize: '14px',
        backgroundColor: '#ccc',
        fontFamily: 'Poppins',
        fontWeight: '600',
        border: '0.1px solid #ddd',
        display: 'flex',
        justifyContent: 'center',
      },
    },
    cells: {
      style: {
        paddingLeft: '8px',
        paddingRight: '8px',
        border: '0.1px solid #ddd',
        display: 'flex',
        justifyContent: 'center',
      },
    },
  }

  return (
    <>
      <div className="main-content">
        <Header />
        <div className="app-menu navbar-menu" style={{ display: 'flex' }}>
          <Sidebar />
        </div>
        <div className="page-content">
          <PageHeader HeaderName="Pujari's" />
          <div className="container-fluid pt-3">
            <div className="mb-3">
              <ul className="nav nav-tabs">
                <div className="col-md-4">
                  <li className="nav-item">
                    <a
                      className={`nav-link ${activeTab === 'active' ? 'active' : ''}`}
                      onClick={() => setActiveTab('active')}
                    >
                      Active
                    </a>
                  </li>
                </div>
                <div className="col-md-4">
                  <li className="nav-item">
                    <a
                      className={`nav-link ${activeTab === 'requested' ? 'active' : ''}`}
                      onClick={() => setActiveTab('requested')}
                    >
                      Requested
                    </a>
                  </li>
                </div>
                <div className="col-md-4">
                  <li className="nav-item">
                    <a
                      className={`nav-link ${activeTab === 'declined' ? 'active' : ''}`}
                      onClick={() => setActiveTab('declined')}
                    >
                      Declined
                    </a>
                  </li>
                </div>
              </ul>
            </div>
            <div className="table-responsive registered-img bg-white">
              <DataTable
                columns={columns}
                data={selectedPoojari}
                pagination
                highlightOnHover
                striped
                noHeader
              />
            </div>
          </div>
        </div>
      </div>
      {currentPoojari && (
        <ViewDetails
          show={showModal}
          handleClose={() => setShowModal(false)}
          poojari={currentPoojari}
        />
      )}
    </>
  );
};

export default Poojari;
