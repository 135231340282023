export const PoojaCreate = 'pooja/create';
export const GetAllPooja = 'pooja/getAllPooja';
export const GetSinglePooja = 'pooja/getById/';
export const PoojaUpdate = 'pooja/update';
export const DeletePooja = 'pooja/delete/';
export const PujariReviewsData = 'pujari/getReviewById';
export const PujariPujaBookingsData = 'pujari/PujaBookings';
export const PujariProfileInfo = 'pujari/getProfileInfo';
export const PujariImagesUpload = 'pujari/bannerImageInfo';
// export const baseUrlAPi = 'http://127.0.0.1:8000/api/';
export const baseUrlAPi = 'https://gowinda.in/api/api/';
export const CalenderBlockCreate = 'pujari/createBlockDate';
export const PujariDateEvent = 'pujari/pujariEvents';
export const PujariEventApproveDeny = 'pujari/updatePoojaBookingStatus';
export const GetAllPujariBlockDates = 'pujari/getBlockDateByPujariId';
export const GetAllPujariImages = 'pujari/getImagesPujari/14';
export const GetAllPujaSamagriKit = 'admin/samagriKitList';
export const GetAllPoojari = 'pujari/getAllPujari';
export const GetAllPandalsList = 'pandal/PandalValidations';
export const GetAllTemplesList = 'admin/templeAll';
export const GetAllPoojariById = 'pujari/getById/14';
export const HandleAcceptRejectPooja = 'admin/pujariApproveStatus';
export const HandleAcceptRejectTemple2 = 'admin/templeApproveStatus';
export const GetAllSamagries = 'admin/samagriKitList';
export const PostAllSamagries = 'admin/samagriKit';
export const GetPartnersTemples = 'admin/templeAll';
export const imgUrl = 'https://gowinda.in/api/public/';
export const GetTempleSevaFullDeatils ='temple-seva/get-by-temple-id/6'
export const TempleformDetails ='appUser/templeinfo/6'
export const BankofTempleDetails ='temple/getBankDetails'
