import React, { useState } from 'react';
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane
} from 'mdb-react-ui-kit';
import PujariAboutPoojari from './PujariAboutPoojari';
import PujariPooja from './PujariPooja';

function PoojariDetailsTabs  ()  {
    const [basicActive, setBasicActive] = useState('tab1');
    const handleBasicClick = (value) => {
        if (value === basicActive) {
          return;
        }
        setBasicActive(value);
      };


  return (
    <div>
        <MDBTabs className='mb-3 mt-3 list_partners'>
        <MDBTabsItem className=''>
          <MDBTabsLink onClick={() => handleBasicClick('tab1')} active={basicActive === 'tab1'}>
          About Poojari
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleBasicClick('tab2')} active={basicActive === 'tab2'}>
          Pooja's
          </MDBTabsLink>
        </MDBTabsItem>
      </MDBTabs>

      <MDBTabsContent>
        <MDBTabsPane open={basicActive === 'tab1'}>
            <PujariAboutPoojari />
        </MDBTabsPane>
        <MDBTabsPane open={basicActive === 'tab2'}> 
        <PujariPooja />
        </MDBTabsPane>
        
      </MDBTabsContent>
      
    </div>
  )
}

export default PoojariDetailsTabs
