import React, { useState, useEffect } from 'react'
import { FaCalendarAlt } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { fetchSevaTemples } from '../API/FetchAPI';

function ViewFullSevaDetailsComponent() {
  const navigate = useNavigate();
  const [sevadetails, setSevaDetails] = useState([]);

  const handleButtonBack = () => {
    navigate('/viewfulldetails', { state: { activeTab: 'tab4' } });
  };

  async function loadTemplesData() {
    try {
      const data = await fetchSevaTemples('temple-seva/getByTempleSevaId/1');
      //   console.log('Data for Temples:', data.data);
      setSevaDetails(data.data);
      // console.log(data.data, 'seva data');
      // Handle the data as needed
    } catch (error) {
      console.error('Error loading temples data:', error);
    }
  }

  useEffect(() => {
    loadTemplesData();
  }, [])



  return (
    <div>
      <div className='Card_form'>
        <div className='row mb-3'>
          <div className='col-md-12'>
            <button className='btn btn-warning' onClick={handleButtonBack}>Back </button>
          </div>
        </div>

        <form className='mt-3 mb-3'>
          <div className='row '>
            <div className='col-md-6'>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='form-group mb-3'>
                    <label className='form-label'>Seva Name</label>
                    <input type='text' value={sevadetails.seva_name} className='form-control' readOnly />
                  </div>
                </div>
                <div className='col-md-9'>
                  <div className='form-group mb-3'>
                    <label className='form-label'>Seva Type</label>
                    <input type='text' value={sevadetails.seva_type} className='form-control' readOnly />
                  </div>
                </div>
                <div className='col-md-3'>
                  <div className='form-group mb-3'>
                    <label className='form-label'>Count</label>
                    <input type='text' value={sevadetails.count} className='form-control' readOnly />
                  </div>
                </div>
                <div className='col-md-12'>
                  <div className='form-group mb-3'>
                    <label>Description</label>
                    <textarea rows={3} cols={12} value={sevadetails.description} placeholder='' className='form-control' readOnly />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='row'>
                <div className="col-md-8">
                  <img className='img-fluid' alt='' />
                </div>
                <div className="col-md-4">
                  <div className='row'>
                    <div className="col-md-12 mb-2">
                      <img src={sevadetails.image} className='img-fluid' alt='' />
                    </div>
                    <div className="col-md-12 mb-2">
                      <img src={sevadetails.image} className='img-fluid' alt='' />
                    </div>
                    <div className="col-md-12 mb-2">
                      <img src={sevadetails.image} className='img-fluid' alt='' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group mb-3'>
                <label className='form-label'>Seva Benefits</label>
                <textarea rows={3} cols={12} value={sevadetails.benfits} placeholder='' className='form-control' readOnly />
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group mb-3'>
                <label className='form-label'>Seva Process</label>
                <textarea rows={3} cols={12} value={sevadetails.benfits} placeholder='' className='form-control' readOnly />
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group mb-3 calender_icon'>
                <label className='form-label'>Seva Start Date</label>
                <input type='date' className='form-control' readOnly />
                <span><FaCalendarAlt /></span>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group mb-3 calender_icon'>
                <label className='form-label'>Seva End Date</label>
                <input type='date' className='form-control' readOnly />
                <span><FaCalendarAlt /></span>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group mb-3'>
                <label className='form-label'>Seva Start Time</label>
                <input type='text' className='form-control' readOnly />
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group mb-3'>
                <label className='form-label'>Seva End Time</label>
                <input type='text' className='form-control' readOnly />
              </div>
            </div>

            <div className='col-md-6'>
              <div className='form-group mb-3'>
                <label className='form-label'>Price</label>
                <input type='text' className='form-control' readOnly />
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group mb-3'>
                <label className='form-label'>Donations Accepted</label>
                <input type='text' className='form-control' readOnly />
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group mb-3'>
                <label className='form-label'>Select Pooja Samagri Kit</label>
                <input type='text' className='form-control' readOnly />
              </div>
            </div>
          </div>
        </form>


      </div>
    </div>
  )
}

export default ViewFullSevaDetailsComponent
