import { useState, useEffect } from "react";
import Header from "../../Header/Header";
import Sidebar from "../../Sidebar/Sidebar";
import PageHeader from "../../PageHeader/PageHeader";
import { useDropzone } from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import {
  CreateNewImage,
  GetAllTemplePhotosDetails,
} from "../../API/TempleApis/templePhotosApis";
import "react-toastify/dist/ReactToastify.css";
import { FILE_BASEURL } from "../../Services/imageUrl";

const TemplePhotos = () => {
  const [getImages, setImages] = useState([]);
  const [imagesType1, setImagesType1] = useState([]);
  const [imagesType2, setImagesType2] = useState([]);

  const fetchTemplePhotos = async () => {
    try {
      const response = await GetAllTemplePhotosDetails();
      setImages(response);
      const type1Images = response.filter((file) => file.type === 1);
      const type2Images = response.filter((file) => file.type === 2);
      setImagesType1(type1Images);
      setImagesType2(type2Images);
    } catch (error) {
      console.error("Error fetching temple photos: ", error);
    }
  };

  useEffect(() => {
    fetchTemplePhotos();
  }, []);

  const handleCreateImage = async (file, type) => {
    try {
      const formData = new FormData();
      formData.append("banner_image", file);
      formData.append("temple_id", "1");
      formData.append("status", "1");
      formData.append("type", type);
      const response = await CreateNewImage(formData);
      console.log("New image created:", response);
      fetchTemplePhotos();
    } catch (error) {
      console.error("Error creating new image:", error);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
      "video/mp4": [],
    },
    onDrop: (acceptedFiles) => {
      acceptedFiles.forEach((file) => handleCreateImage(file, 1));
    },
  });

  const handleFileSelectionType2 = (files) => {
    // Make sure files is an array
    const filesArray = Array.from(files);
    filesArray.forEach((file) => handleCreateImage(file, 2));
  }
  return (
    <>
      <div className="main-content">
        <Header />
        <div className="app-menu navbar-menu" style={{ display: "flex" }}>
          <Sidebar />
        </div>
        <div className="page-content">
          <PageHeader HeaderName="Photos/Videos" />
          <section className="container p-3">
            <div className="dropzone">
              <input {...getInputProps()} />
              <h5>Banner Image</h5>
              <aside style={thumbsContainer}>
                {imagesType1.map((file, index) => (
                  <div style={thumb} key={file.name}>
                    <div style={thumbInner}>
                      {file.banner_image.toLowerCase().endsWith(".mp4") ? (
                        <video
                          src={FILE_BASEURL + file.banner_image}
                          style={img}
                          controls
                        />
                      ) : (
                        <img
                          src={
                            file.banner_image.startsWith("http")
                              ? file.banner_image
                              : FILE_BASEURL + file.banner_image
                          }
                          style={img}
                          alt={file.name}
                        />
                      )}
                    </div>
                  </div>
                ))}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    cursor: "pointer",
                    marginTop: "50px",
                    marginBottom: "50px",
                  }}
                  {...getRootProps()}
                >
                  <FontAwesomeIcon icon={faPlus} size="2x" />
                  <h5>Add Image</h5>
                </div>
              </aside>
              <h5>Image/Video</h5>
              <aside style={thumbsContainer}>
                {imagesType2.map((file, index) => (
                  <div style={thumb} key={file.name}>
                    <div style={thumbInner}>
                      {file.banner_image.toLowerCase().endsWith(".mp4") ? (
                        <video
                          src={FILE_BASEURL + file.banner_image}
                          style={img}
                          controls
                        />
                      ) : (
                        <img
                          src={
                            file.banner_image.startsWith("http")
                              ? file.banner_image
                              : FILE_BASEURL + file.banner_image
                          }
                          style={img}
                          alt={file.name}
                        />
                      )}
                    </div>
                  </div>
                ))}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    const input = document.createElement("input");
                    input.setAttribute("type", "file");
                    input.setAttribute("accept", "image/*,video/mp4");
                    input.setAttribute("multiple", "multiple");
                    input.onchange = (e) => handleFileSelectionType2(e.target.files);
                    input.click();
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} size="2x" />
                  <h5>Add Image</h5>
                </div>
              </aside>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default TemplePhotos;


const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
};

const thumb = {
  display: "flex",
  borderRadius: 2,
  marginBottom: 8,
  marginRight: 8,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
};

const img = {
  display: "block",
  width: "300px",
  height: "150px",
};
