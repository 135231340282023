import React from 'react'

function PageHeader({ HeaderName ,username }) {
  return (
    <>
      <div className="breadcrum">
        <div className="row align-items-between">
          <div className="col-md-6 col-6">
            <p style={{color:'rgb(236 114 107)',fontSize:'15px'}}>
              <b>{HeaderName}</b>
            </p>
          </div>
          <div className="col-md-6 col-6 text-end">
            <p>
             Username : <b>{username}</b>
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default PageHeader
