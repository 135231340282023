import React  from 'react'
import PageHeader from '../PageHeader/PageHeader'
import Sidebar from '../Sidebar/Sidebar'
import Header from '../Header/Header' 
import { useLocation, useNavigate } from 'react-router-dom'
import { acceptReview, deleteReview, rejectReview } from '../API/Api'
import { toast } from 'react-toastify'
import Footer from '../Footer/Footer'

function UserReview() {
  const navigate = useNavigate()
  const location = useLocation()
  const details = location.state
  // const ratingValue = (details.rating && details.rating.toString()) || '1'
  const ratingValue = details.rating.toString()

  const handleAcceptReview = async (reviewId) => {
    try {
      await acceptReview(reviewId)
      toast.success('Accepted successfully')
      navigate('/reviews')
    } catch (error) {
      console.error('Error accepting review:', error)
      toast.error('Error accepting review')
    }
  }

  const handleRejectReview = async (reviewId) => {
    try {
      await rejectReview(reviewId)
      toast.success('Rejected successfully')
      navigate('/reviews')
    } catch (error) {
      console.error('Error rejected review:', error)
      toast.error('Error rejected review')
    }
  }

  const handleDeleteReview = async (reviewId) => {
    try {
      await deleteReview(reviewId)
      toast.success('Deleted successfully')
      navigate('/reviews')
    } catch (error) {
      console.error('Error deleting review:', error)
      toast.error('Error deleting review')
    }
  }

  return (
    <>
      <div className="main-content">
        <Header />
        <div className="app-menu navbar-menu" style={{ display: 'flex' }}>
          <Sidebar />
        </div>

        <div className="page-content">
          <PageHeader HeaderName="User Review" />
          <div className="container-fluid pt-3">
            <div className="card card-body">
              <div className="row mb-5">
                <div className="col-md-4 mb-3">
                  <small>Reviewer Name</small>
                  <input
                    type="text"
                    className="form-control"
                    value={details.user_name}
                    name="user_name"
                    readOnly
                  />
                </div>

                <div className="col-md-4 mb-3">
                  <small>Reviewer email id</small>

                  <input
                    type="text"
                    className="form-control"
                    name="user_email"
                    value={details.user_email}
                    readOnly
                  />
                </div>

                <div className="col-md-4 mb-3">
                  <small>Pandal name which review has given</small>

                  <input
                    type="text"
                    className="form-control"
                    name="pandal_name"
                    value={details.pandal_name}
                    readOnly
                  />
                </div>

                <div className="col-md-12 mb-3">
                  <small>Review</small>

                  {/* <small>Pandal name which review has given</small> */}

                  <textarea
                    className="form-control"
                    rows="4"
                    name="review"
                    value={details.review}
                    readOnly
                  ></textarea>
                </div>

                <div className="col-md-5">
                  <small>Rating Given :</small>

                  <div className="clearfix"></div>

                  <div className="rate">
                    <input
                      type="radio"
                      id="star5"
                      name="rating"
                      checked={ratingValue === '5'}
                      readOnly
                    />

                    <label htmlFor="star5" title="text">
                      5 stars
                    </label>

                    <input
                      type="radio"
                      id="star4"
                      name="rating"
                      checked={ratingValue === '4'}
                      readOnly
                    />

                    <label htmlFor="star4" title="text">
                      4 stars
                    </label>

                    <input
                      type="radio"
                      id="star3"
                      name="rating"
                      checked={ratingValue === '3'}
                      readOnly
                    />

                    <label htmlFor="star3" title="text">
                      3 stars
                    </label>

                    <input
                      type="radio"
                      id="star2"
                      name="rating"
                      checked={ratingValue === '2'}
                      readOnly
                    />

                    <label htmlFor="star2" title="text">
                      2 stars
                    </label>

                    <input
                      type="radio"
                      id="star1"
                      name="rating"
                      checked={ratingValue === '1'}
                      readOnly
                    />

                    <label htmlFor="star1" title="text">
                      1 star
                    </label>
                  </div>
                </div>

                <div className="col-md-7 mt-5 text-end">
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => handleDeleteReview(details['id'])}
                  >
                    <i className="fa fa-trash-alt"></i> Delete
                  </button>
                  &nbsp;&nbsp;
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => handleRejectReview(details['id'])}
                  >
                    <i className="fa fa-times"></i> Reject
                  </button>
                  &nbsp;&nbsp;
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={() => handleAcceptReview(details['id'])}
                  >
                    <i className="far fa-check-circle"></i> Accept
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- end main content--> */}
        </div>
 
      </div>
      <Footer />
    </>
  )
}

export default UserReview
