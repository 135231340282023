import React, { useState, useEffect } from 'react'
import PageHeader from '../PageHeader/PageHeader'
import { GetallEmployee, createEmployee, deleteEmployee } from '../API/Api'
import DataTable from 'react-data-table-component'
import { jsPDF } from 'jspdf'
import html2canvas from 'html2canvas'
import 'jspdf-autotable'
import * as XLSX from 'xlsx'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import Header from '../Header/Header'
import Sidebar from '../Sidebar/Sidebar'
import Footer from '../Footer/Footer'

function Employee() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()

  const navigate = useNavigate()
  const [employee, setEmployee] = useState([])
  const [loading, setLoading] = useState(true)

  const fetchEmployeeData = async () => {
    try {
      const data = await GetallEmployee()
      const sortedData = data.sort(
        (a, b) =>
          new Date(b.created_at.split('/').reverse().join('-')) -
          new Date(a.created_at.split('/').reverse().join('-')),
      )
      setEmployee(sortedData)
      setLoading(false)
    } catch (error) {
      console.error('Error fetching table data:', error)
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchEmployeeData()
  }, [])

  const customStyles = {
    rows: {
      style: {
        // border: '0.1px solid #ddd',
      },
    },
    headCells: {
      style: {
        paddingLeft: '8px',
        paddingRight: '8px',
        color: 'black',
        fontSize: '14px',
        backgroundColor: '#ccc',
        fontFamily: 'Poppins',
        fontWeight: '600',
        border: '0.1px solid #ddd',
        display: 'flex',
        justifyContent: 'center',
      },
    },
    cells: {
      style: {
        paddingLeft: '8px',
        paddingRight: '8px',
        border: '0.1px solid #ddd',
        display: 'flex',
        justifyContent: 'center',
      },
    },
  }

  const columns = [
    {
      name: 'Sl.No',
      selector: (row) => row['slno'],
      sortable: true,
      format: (row, rowIndex) => rowIndex + 1,
      width : '8%',
    },
    {
      name: 'Employee Name',
      selector: (row) => row['name'],
      sortable: true,
    },
    {
      name: 'Age',
      selector: (row) => row['age'],
      sortable: true,
    },
    {
      name: 'Phone Number',
      selector: (row) => row['mobile'],
      sortable: true,
    },
    {
      name: 'Email',
      selector: (row) => row['email'],
      sortable: true,
    },
    {
      name: 'Designation',
      selector: (row) => row['designation'],
      sortable: true,
    },
    {
      name: 'Status',
      cell: (row) => (row.status === '1' ? 'Active' : 'Inactive'),
      sortable: true,
    },
    {
      name: 'Date of Joining',
      selector: (row) => row['date_of_joining'],
      sortable: true,
    },
    {
      name: 'Actions',
      cell: (row) => (
        <div className="d-flex">
          <button
            className="btn btn-sm btn-edit me-2"
            onClick={() => handleGetInfo(row)}
          >
            <i className="fa-regular fa-pen-to-square"></i>{' '}
          </button>
          <button
            className="btn btn-sm btn-delete"
            onClick={() => handleDeleteEmployee(row.id)}
          >
            <i className="fa-solid fa-trash-can"></i>
          </button>
        </div>
      ),
    },
  ]

  const handleGetInfo = (row) => {
    navigate('/employee-detail', { state: row })
    console.log(row, 'handleGetInfo')
  }

  const handleDeleteEmployee = async (id) => {
    try {
      await deleteEmployee(id)
      const successMessage = 'Employee Deleted'
      toast.success(successMessage, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      fetchEmployeeData()
    } catch (error) {
      toast.error(error.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  //Downloading PDF Format
  const columnsForPDF = [
    {
      name: 'Sl.No',
      selector: (row) => row['slno'],
      sortable: true,
      format: (rowIndex) => rowIndex + 1,
    },
    {
      name: 'Employee Name',
      selector: (row) => row['name'],
      sortable: true,
    },
    {
      name: 'Age',
      selector: (row) => row['age'],
      sortable: true,
    },
    {
      name: 'Phone Number',
      selector: (row) => row['mobile'],
      sortable: true,
    },
    {
      name: 'Email',
      selector: (row) => row['email'],
      sortable: true,
    },
    {
      name: 'Designation',
      selector: (row) => row['designation'],
      sortable: true,
    },
    {
      name: 'Status',
      cell: (row) => (row.status === '1' ? 'Active' : 'Inactive'),
      sortable: true,
    },
    {
      name: 'Date of Joining',
      selector: (row) => row['date_of_joining'],
      sortable: true,
    },
  ]
  const handleDownloadPDF = () => {
    const table = document.querySelector('.table-responsive table')
    html2canvas(table).then((canvas) => {
      const pdf = new jsPDF()
      const pdfData = employee.map((employee_detail, index) => [
        index + 1,
        employee_detail['name'],
        employee_detail['age'],
        employee_detail['mobile'],
        employee_detail['email'],
        employee_detail['designation'],
        employee_detail['status'] === '1' ? 'Active' : 'Inactive',
        employee_detail['date_of_joining'],
      ])

      pdf.autoTable({
        head: [columnsForPDF.map((column) => column.name)],
        body: pdfData,
      })

      pdf.save('employees_data.pdf')
    })
  }
  //Downloading Excel Format
  const handleDownloadExcel = () => {
    const columnsWithoutActions = columns.filter(
      (column) => column.name !== 'Actions',
    )
    const tableData = employee.map((employee_detail, index) => [
      index + 1,
      employee_detail['name'],
      employee_detail['age'],
      employee_detail['mobile'],
      employee_detail['email'],
      employee_detail['designation'],
      employee_detail['status'] === '1' ? 'Active' : 'Inactive',
      employee_detail['date_of_joining'],
    ])
    const worksheet = XLSX.utils.aoa_to_sheet([
      columnsWithoutActions.map((column) => column.name),
      ...tableData,
    ])
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Table Data')
    const excelFile = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
    const blob = new Blob([excelFile], { type: 'application/octet-stream' })
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = 'employees_data.xlsx'
    a.click()
    URL.revokeObjectURL(url)
  }
  //Downloading CSV Format
  const handleDownloadCSV = () => {
    const columnsWithoutActions = columns.filter(
      (column) => column.name !== 'Actions',
    )
    const formatDate = (dateString) => {
      if (!dateString) {
        return ''
      }
      const dateParts = dateString.split('/')
      if (dateParts.length !== 3) {
        // console.log('Invalid date format:', dateString)
        return ''
      }
      const formattedDate = `${dateParts[1]}/${dateParts[0]}/${dateParts[2]}`
      const timestamp = Date.parse(formattedDate)
      if (isNaN(timestamp)) {
        // console.log('Invalid date:', dateString)
        return ''
      }
      const date = new Date(timestamp)
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' }
      return new Intl.DateTimeFormat('en-US', options).format(date)
    }
    const tableData = employee.map((employee_detail, index) => [
      index + 1,
      employee_detail['name'],
      employee_detail['age'],
      employee_detail['mobile'],
      employee_detail['email'],
      employee_detail['designation'],
      employee_detail['status'] === '1' ? 'Active' : 'Inactive',
      formatDate(employee_detail['date_of_joining']),
    ])
    const worksheet = XLSX.utils.aoa_to_sheet([
      columnsWithoutActions.map((column) => column.name),
      ...tableData,
    ])
    const columnWidths = columnsWithoutActions.map((column) => ({
      wch: column.name.length + 2,
    }))
    worksheet['!cols'] = columnWidths
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Table Data')
    const excelFile = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
    const blob = new Blob([excelFile], { type: 'application/octet-stream' })
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = 'employee_data.csv'
    a.click()
    URL.revokeObjectURL(url)
  }

  const [selectedImage, setSelectedImage] = useState(null)

  const handleImageChange = (event) => {
    const file = event.target.files[0]
    setSelectedImage(file)
  }

  const handleCreateEmployee = async (data) => {
    try {
      

      const formData = new FormData()
      const name = data.name || ''
      const email = data.email || ''
      const mobile = data.mobile || ''
      const age = data.age || ''
      const designation = data.designation || ''
      const date_of_joining = data.date_of_joining || ''
      const password = data.password || ''
      formData.append('name', name)
      formData.append('email', email)
      formData.append('mobile', mobile)
      formData.append('age', age)
      formData.append('designation', designation)
      formData.append('date_of_joining', date_of_joining)
      formData.append('password', password)
      // formData.append('image', selectedImage) // Append the selectedImage to the FormData
      if (selectedImage) {
        formData.append('image', selectedImage)
      }

      await createEmployee(formData)

      // const requestData = {
      //   name,
      //   mobile,
      //   age,
      //   designation,
      //   date_of_joining,
      //   image,
      //   password,
      // }

      // await createEmployee(requestData) // Use the createEmployee function and pass the formData

      const successMessage = 'Employee Created'
      toast.success(successMessage, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      setSelectedImage(null)
      // Clear the form fields after successful creation
      reset()
      // Fetch the updated employee data after creation
      fetchEmployeeData()
    } catch (error) {
      if (error.response.status === 422) {
        Object.values(error.response.data.errors).map((item) => {
          toast.error(item[0], {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        })
      } else {
        toast.error(error.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      }
    }
  }

  const handleKeyDown = (event, callback) => {
    if (event.key === 'Enter' && event.shiftKey === false) {
      event.preventDefault()
      callback(handleCreateEmployee)
    }
  }

  return (
    <>
      <div className="main-content">
        <Header />
        <div className="app-menu navbar-menu" style={{ display: 'flex' }}>
          <Sidebar />
        </div>
        <div id="layout-wrapper">
          <div className="page-content">
            <PageHeader HeaderName="Employee" />
            <div className="container-fluid pt-3">
              <div className="row">
                <div className="col-xl-12 col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <ul className="nav nav-tabs">
                        <li className="nav-item">
                          <a
                            className="nav-link active"
                            data-bs-toggle="tab"
                            href="#home"
                          >
                            List Of All Employees
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            data-bs-toggle="tab"
                            href="#menu1"
                          >
                            Create a New Employee
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  {/* <!-- Tab panes --> */}
                  <div className="tab-content">
                    <div className="tab-pane active" id="home">
                      <h5 className="mb-3">List of all Employees</h5>
                      {loading ? (
                        <p>Loading...</p>
                      ) : employee.length === 0 ? (
                        <p>Data Not Found</p>
                      ) : (
                        <>
                          <div className="table-responsive">
                            <table className="table table-bordered table-striped bg-white">
                              <DataTable
                                columns={columns}
                                data={employee}
                                pagination
                                customStyles={customStyles}
                                highlightOnHover
                                noDataComponent="Loading..."
                              />
                            </table>
                          </div>
                          <div className="col-md-12 text-end">
                            <div className="button-group">
                              <button
                                title="Download Excel"
                                className="button-excel-format"
                                onClick={handleDownloadExcel}
                              >
                                Excel
                              </button>
                              <button
                                title="Download CSV"
                                className="button-csv-format"
                                onClick={handleDownloadCSV}
                              >
                                CSV
                              </button>
                              <button
                                title="Download PDF"
                                className="button-pdf-format"
                                onClick={handleDownloadPDF}
                              >
                                PDF
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="tab-pane fade" id="menu1">
                      <h5 className="mb-3">Create a New Employee</h5>
                      <form
                        onSubmit={handleSubmit(handleCreateEmployee)}
                        onKeyDown={(e) => {
                          handleKeyDown(e, handleSubmit)
                        }}
                      >
                        <div className="card card-body">
                          <div className="row mb-3">
                            <div className="col-md-8">
                              <div className="row">
                                <div className="col-md-6 mb-3">
                                  <small><strong>Employee Name</strong></small>
                                  <input
                                    type="text"
                                    name="name"
                                    {...register('name')}
                                    className="form-control"
                                  />
                                
                                </div>
                                <div className="col-md-6 mb-3">
                                  <small><strong>Phone Number</strong></small>
                                  <input
                                    type="tel"
                                    name="mobile"
                                    {...register('mobile')}
                                    className="form-control" 
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6 mb-3">
                                  <small><strong>Age</strong></small>
                                  <input
                                    type="text"
                                    name="age"
                                    {...register('age')}
                                    className="form-control"
                                  />
                                </div>
                                <div className="col-md-6 mb-3">
                                  <small><strong>Email</strong></small>
                                  <input
                                    type="text"
                                    name="email"
                                    {...register('email')}
                                    className="form-control"
                                  />
                                </div>
                                <div className="col-md-6 mb-3">
                                  <small><strong>Designation</strong></small>
                                  <input
                                    type="text"
                                    name="designation"
                                    {...register('designation')}
                                    className="form-control"
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6 mb-3">
                                  <small><strong>Date of Joining</strong></small>
                                  <input
                                    type="date"
                                    name="date_of_joining"
                                    {...register('date_of_joining')}
                                    className="form-control"
                                  />
                                </div>
                                <div className="col-md-6 mb-3">
                                  <small><strong>Create password</strong></small>
                                  <input
                                    type="text"
                                    name="password"
                                    {...register('password')}
                                    className="form-control"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <p><strong> User Image</strong></p>
                              <label htmlFor="imageInput">Choose File:</label>
                              <input
                                id="imageInput"
                                type="file"
                                name="image"
                                {...register('image')}
                                // accept="image/*"
                                onChange={handleImageChange}
                              />
                              {selectedImage && (
                                <div>
                                  <img
                                    className="img-fluid"
                                    src={URL.createObjectURL(selectedImage)}
                                    alt="pujam_pandal"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                          
                          <div className="col-md-12 text-end">
                            <button type="submit" className="btn btn-success">
                              Create Employee
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Employee
