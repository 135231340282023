import  { useEffect, useState,useRef  } from 'react';
import PageHeader from '../PageHeader/PageHeader';
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import './validation.css';
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
import { toast } from 'react-toastify';
import Footer from '../Footer/Footer';
// import { GetAllPoojariById } from '../Pujari/Constants';
import  {HandleAcceptRejectPooja} from '../Pujari/Constants';

import { postApiResponse } from '../API/Api';

function ValidationDetail1() {
    const location = useLocation();
    const navigate = useNavigate();
    const receivedData = location.state;
    const id = receivedData?.pujari_id
    const [pujariData, setPujariData] = useState(null);
    const [fullName, setFullName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [age, setAge] = useState('');
    const [sunSign, setSunSign] = useState('');
    const [gothram, setGothram] = useState('');
    const [category, setCategory] = useState('');
    const [languages, setLanguages] = useState([]);
    const [gender, setGender] = useState('');
    const [experience, setExperience] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalImage, setModalImage] = useState('');
    const [viewImage, setViewImage] = useState(null);
    const [showKycImage, setShowKycImage] = useState(false);
    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [rejectionReason, setRejectionReason] = useState('');
    const [error, setError] = useState({})
    const [text, setText] = useState('')
    const [reject, setReject] = useState(false)
    const approveButtonRef = useRef(null);
    const rejectButtonRef = useRef(null);

    const kyc_document = receivedData?.images?.[0]?.image;
    const educnal_doc = receivedData?.images?.[0]?.image;
    const award_picture = receivedData?.images?.[0]?.image;

    useEffect(() => {
        // Fetch temple data by ID
        const fetchPujariById = async () => {
            try {
                const response = await fetch(`http://192.168.1.66:8000/api/pujari/getById/14${receivedData.id}`);
                if (response.ok) {
                    const data = await response.json();
                    setPujariData(data);
                } else {
                    throw new Error('Failed to fetch temple data');
                }
            } catch (error) {
                console.error('Error fetching temple data:', error);
                // Handle error
            }
        };
        if (receivedData?.temple_id) {
            fetchPujariById();
          }
    }, [receivedData]);

    // Function to handle accept/reject
    const HandleAcceptRejectPooja1 = async (status, rejectedReason) => {
        const requestData = {
            pujari_id: receivedData.id,
            status: status,
            reject_reason: text,
        };
        try {
            const response = await postApiResponse(HandleAcceptRejectPooja, requestData)
            console.log(response, "response")
            if (response.ok) {
              toast.success('Pujari status updated successfully', { position: 'top-right' });
              navigate('/validation');
            } else {
              throw new Error('Failed to update pujari status');
            }
          } catch (error) {
            console.error('Error updating pujari status:', error);
            // toast.error('Failed to update pujari status', { position: 'top-right' });
          }
        };

    // Function to handle approve
    const onApproveData =  () => {
        // HandleAcceptRejectPooja1(1)
        navigate('/validation');
    };
    
       
       
    const handleChange = (event) => {
        setText(event.target.value);
      };


    const backPage = () => {
        navigate('/validation')
    }


    const onRejectData = async () => {
        setReject(true);
      
    };

    const onSendReason = (e) => {
        e.preventDefault()
        HandleAcceptRejectPooja1(2)
        const validationErrors = {}
        !text
            ? (validationErrors.text = 'Reason Required')
            : delete validationErrors['text']
        !Object.keys(validationErrors).length && onRejectData() && backPage()
        setError(validationErrors)
    }

   
    const openModal = (imageUrl) => {
        setModalImage(imageUrl);
        setModalIsOpen(true);
    };

    const handleViewKycImage = () => {
        setShowKycImage(true);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClickOpen1 = () => {
        setOpen1(true);
    };

    const handleClickOpen2 = () => {
        setOpen2(true);
    };

    const closeModal = () => {
        setOpen(false);
        setOpen1(false);
        setOpen2(false);
        setModalIsOpen(false);
    };

    function updateButtonVisibility(status) {
        if (status === 3) {
            return { display: 'block' }; // Show the buttons
        }
        return { display: 'none' }; // Hide the buttons for other statuses
    }

    useEffect(() => {
        if (approveButtonRef.current && rejectButtonRef.current) {
            updateButtonVisibility(receivedData?.status, approveButtonRef.current);
            updateButtonVisibility(receivedData?.status, rejectButtonRef.current);
        }
    }, [receivedData?.status]);


    

    // useEffect(() => {
    //     HandleAcceptRejectPooja1()
    //   }, [])



    return (
        <>
            <div className="main-content">
                <Header />
                <div className="app-menu navbar-menu" style={{ display: 'flex' }}>
                    <Sidebar />
                </div>
                <div className="page-content">
                    <PageHeader HeaderName="Validation Full Details" />
                    <div className="container-fluid pt-3">
                        <h3 style={{ color: 'red', marginBottom: '25px' }}>Poojari Registration</h3>
                        <h5>Personal Details</h5>
                        <div className="row mb-4">
                            <div className="col-md-4">
                                <small>Full Name</small>
                                <input
                                    type="text"
                                    id="fullName"
                                    className="form-control"
                                    value={receivedData?.name}
                                    readOnly
                                />
                            </div>
                            <div className="col-md-4">
                                <small>Phone Number</small>
                                <input
                                    type="text"
                                    id="phoneNumber"
                                    className="form-control"
                                    value={receivedData?.mobile}
                                    readOnly
                                />
                            </div>
                            <div className="col-md-4">
                                <small>Age</small>
                                <input
                                    type="text"
                                    id="age"
                                    className="form-control"
                                    value={receivedData?.dob}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col-md-4">
                                <small>Sun Sign</small>
                                <input
                                    type="text"
                                    id="sunSign"
                                    className="form-control"
                                    value={receivedData?.sun_sign}
                                    readOnly
                                />
                            </div>
                            <div className="col-md-4">
                                <small>Gothram</small>
                                <input
                                    type="text"
                                    id="gothram"
                                    className="form-control"
                                    value={receivedData?.gothram}
                                    readOnly
                                />
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="category">Category</label>
                                <select
                                    id="category"
                                    className="form-control"
                                    value={receivedData?.category}
                                    readOnly
                                >
                                    <option value={receivedData?.category}></option>
                                    <option value="Astrologer">Astrologer</option>
                                    <option value="Category 2">Category 2</option>
                                    <option value="Category 3">Category 3</option>
                                </select>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col-md-4">
                                <label>Languages</label><br />
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <div style={{ marginRight: '10px' }}>
                                        <input
                                            type="checkbox"
                                            value="English"
                                            checked={receivedData?.languages.includes('English')}
                                            readOnly
                                        /> English
                                    </div>
                                    <div style={{ marginRight: '10px' }}>
                                        <input
                                            type="checkbox"
                                            value="Hindi"
                                            checked={receivedData?.languages.includes('Hindi')}
                                            readOnly
                                        /> Hindi
                                    </div>
                                    <div>
                                        <input
                                            type="checkbox"
                                            value="Telugu"
                                            checked={receivedData?.languages.includes('Telugu')}
                                            readOnly
                                        /> Telugu
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4" style={{ display: 'flex', flexDirection: 'column' }}>
                                <label>Gender</label>
                                <div style={{ display: 'flex', flexDirection: 'row' , marginRight: '10px'}}>
                                       <input type="radio" name="addressType" value="Home" checked={receivedData?.address_type.includes('Home')} readOnly /> Home
                                    <input type="radio" name="gender" value="Female" onChange={(e) => setGender(e.target.value)} style={{ marginLeft: '10px' }} /> Female
                                </div>
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="experience">Experience</label>
                                <input
                                    type="text"
                                    id="experience"
                                    className="form-control"
                                    value={receivedData?.experience}
                                    readOnly
                                />
                            </div>
                        </div>
                        <h3>Address</h3>
                        <div className="row mb-4">
                            <div className="col-md-4">
                                <label>Address</label>
                                <div >
                                    <input type="radio" name="addressType" value="Home" checked={receivedData?.address_type.includes('Home')} readOnly  /> Home
                                    <input type="radio" name="addressType" value="Work" checked={receivedData?.address_type.includes('Work')} readOnly /> Work
                                    <input type="radio" name="addressType" value="Office" checked={receivedData?.address_type.includes('Office')} readOnly /> Office
                                </div>
                            </div>
                            <div className="col-md-4">
                                <small>H.No</small>
                                <input
                                    type="text"
                                    id="hNo"
                                    className="form-control"
                                    value={receivedData?.hno}
                                    readOnly
                                />
                            </div>
                            <div className="col-md-4">
                                <small>Email ID</small>
                                <input
                                    type="text"
                                    id="email"
                                    className="form-control"
                                    value={receivedData?.email}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col-md-12">
                                <label htmlFor="address">Address</label>
                                <textarea
                                    id="address"
                                    className="form-control"
                                    value={receivedData?.Address}
                                    readOnly
                                />
                            </div>
                        </div>
                        <h4>KYC Documents</h4>
                        <div className="row mb-4">
                            <div className="col-md-4">
                                <small>KYC Document Type</small>
                                <input
                                    type="text"
                                    id="kycDocumentType"
                                    className="form-control"
                                    value={receivedData?.kyc_doc_type}
                                    readOnly
                                />
                            </div>
                            <div className="col-md-4">
                                <small>KYC Document</small>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <input
                                        type="text"
                                        id="kycDocument"
                                        className="form-control"
                                    />
                                    <button
                                        className="from-icon form-btn btn-sm btn btn-info"
                                        onClick={handleClickOpen}
                                    >
                                        View
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col-md-4">
                                <small>Educational Document Type</small>
                                <input
                                    type="text"
                                    id="educationalDocumentType"
                                    className="form-control"
                                    value={receivedData?.educnal_doc_type}
                                    readOnly
                                />
                            </div>
                            <div className="col-md-4">
                                <small>Educational Document</small>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <input
                                        type="text"
                                        id="educationalDocument"
                                        className="form-control"
                                    />
                                    <button
                                        className="from-icon form-btn btn-sm btn btn-info"
                                        onClick={handleClickOpen1}
                                    >
                                        View
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col-md-4">
                                <small>Award Document Type</small>
                                <input
                                    type="text"
                                    id="awardDocumentType"
                                    className="form-control"
                                    value={receivedData?.award_name}
                                    readOnly
                                />
                            </div>
                            <div className="col-md-4">
                                <small>Award Document</small>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <input
                                        type="text"
                                        id="awardDocument"
                                        className="form-control"
                                    />
                                    <button
                                        className="from-icon form-btn btn-sm btn btn-info"
                                        onClick={handleClickOpen2}
                                    >
                                        View
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-4">
                            {receivedData.status === 3 ?
                            <div className="col-md-12 mb-3  text-center">
                                <button
                                    className="btn btn-success"
                                    ref={approveButtonRef}

                                    type="submit"
                                    onClick={() => onApproveData(receivedData.id)}
                                    style={{ width: '140px', marginRight: '28px' }}>
                                    Approve</button>
                                <button
                                    className="btn btn-danger"
                                    ref={rejectButtonRef}
                                    type="submit"
                                    onClick={() => onRejectData(true)}
                                    style={{ width: '140px', marginRight: '28px' }}>
                                    Reject</button>
                            </div>
                            :""}
                            {reject ? (
                                <div>
                                    <div className="col-md-12 mb-3">
                                        <small>Rejection Reason </small>
                                        <textarea
                                            className="form-control"
                                            rows="3"
                                            onChange={handleChange}
                                        >
                                            {text}
                                        </textarea>
                                        {error.text && (
                                            <div style={{ color: 'red' }}>{error.text}</div>
                                        )}
                                    </div>

                                    <div className="col-md-12 mb-3 text-end">
                                        <button
                                            className="btn btn-warning"
                                            onClick={onSendReason}
                                        >
                                            <i className="far fa-paper-plane"></i>&nbsp; Send
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                ''

                            )}
                        </div>
                       
                        <Modal
                            isOpen={modalIsOpen}
                            onRequestClose={closeModal}
                            style={customStyles}
                        >
                            <img
                                src={modalImage}
                                alt="Document"
                                style={{ width: '100%', height: '100%', borderRadius: '0px' }}
                            />
                        </Modal>
                        <Modal isOpen={open} onRequestClose={closeModal} style={customStyles}>
                            <img
                                src={kyc_document}
                                alt="pujam_pandal"
                                style={{ width: '70%', height: '330px', borderRadius: '0px' }}
                            />
                            {/* <button onClick={closeModal}>close</button> */}
                        </Modal>
                        <Modal isOpen={open1} onRequestClose={closeModal} style={customStyles}>
                            <img
                                src={educnal_doc}
                                alt="pujam_pandal"
                                style={{ width: '70%', height: '330px', borderRadius: '0px' }}
                            />
                            {/* <button onClick={closeModal}>close</button> */}
                        </Modal>
                        <Modal isOpen={open2} onRequestClose={closeModal} style={customStyles}>
                            <img
                                src={award_picture}
                                alt="pujam_pandal"
                                style={{ width: '70%', height: '330px', borderRadius: '0px' }}
                            />
                            {/* <button onClick={closeModal}>close</button> */}
                        </Modal>
                    </div>
                </div>
                </div>
            <Footer />
           
        </>
    );
}

export default ValidationDetail1;

const customStyles = {
    content: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        height: '400px',
        border: 'none',
        backgroundColor: 'none',
        overflow: 'hidden',
        borderRadius: '4px',
        padding: '20px',
        width: '70%',
    },
};

